import React, { useState,useEffect } from 'react';

import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllExpense, expenseItems } from "../../reducers/expenseSlice";
import { setActivePage, setActiveTab } from '../../reducers/homePageSlice';
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';
import './expense.css'
import { ExpensePayoutModal } from './ExpensePayoutModal';

import { Table } from 'antd';
import 'antd/dist/antd.css';
import "../Customer/antdStyles.css"


export function ExpenseList(props) {
  const dispatch = useDispatch();
   
  let content;
  // const expensesStatus = useSelector((state) => state.expenses.status)
  // const error = useSelector((state) => state.expenses.error)
  // const allExpenses = useSelector(selectAllExpense)

  const expenseItemsList = useSelector(selectAllExpense);

  // console.log(expenseItemsList,"expenseItemsList");
  const [showExpensePayout, setShowExpensePayout] = useState(false);
  const handleCloseExpensePayout = () => setShowExpensePayout(false);
  const handleClickExpensePayout = (e) => {
    setShowExpensePayout(true);

  }


  const handleClickAddExpense = (e) => {
    dispatch(setActivePage(pageComponents.ADDEXPENSE));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }
  const handleClickAddEmployee = (e) => {
    dispatch(setActivePage(pageComponents.ADDEMPLOYEE));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }
  const columns = [

    {
      title: 'Transaction Date',
      // dataIndex: 'custnam',

      fixed: 'left',
      align: 'center',
      render: (text, record) => (

        <h6 style={{ height: '20px' }}>{text?.date != null && text?.date != undefined && text?.date}</h6>
      ),
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Emp Name',
      // dataIndex: 'phno',
      align: 'center',
      fixed: 'left',
      render: (text, record) => (

        <h6 class="align-middle">{text?.expnam != null && text?.expnam != undefined && text?.empnam}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },

    {
      title: 'Role',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.role != null && text?.role != undefined && text?.role}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },

    {
      title: 'Narration',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.naration != null && text?.naration != undefined && text?.naration}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    }, 
    {
      title: 'Amount',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.amount != null && text?.amount != undefined && text?.amount}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },
    {
      title: 'Expense Type',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.expnam != null && text?.expnam != undefined && text?.expnam}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },
    

  ]

  // if (expensesStatus === 'loading') {
  //   content = <div className="col-md-2">Loading...</div>
  // } else if (expensesStatus === 'succeeded') {
    // content = expenseItemsList.map((node, index) => (
    //   <tr style={{height:'10px'}}>
                 
    //   <th class="align-middle " scope="row" style={{textAlign:'center'}}>{index+1}</th>
    //    <td class="align-middle" style={{textAlign:'center'}}>{node?.date != null && node?.date != undefined && node?.date}</td>
    //    <td class="align-middle" style={{textAlign:'center'}}>{node?.expnam != null && node?.expnam != undefined && node?.empnam}</td>
    //    <td class="align-middle" style={{textAlign:'center'}}>{node?.role != null && node?.role != undefined && node?.role}</td>
    //    <td class="align-middle" style={{textAlign:'center'}}>{node?.naration != null && node?.naration != undefined && node?.naration}</td>
    //    <td class="align-middle" style={{textAlign:'center'}}>{node?.amount != null && node?.amount != undefined && node?.amount}</td>
    //    <td class="align-middle" style={{textAlign:'center'}}>{node?.expnam != null && node?.expnam != undefined && node?.expnam}</td>
     
    // </tr>
    // ))
  // } else if (expensesStatus === 'failed') {
  //   content = <div className="col-md-12">{error}</div>
  // }

   
  React.useEffect(() => {
    console.log("eijedjndsjon");
    const item = [
      {
        tokenCreate: localStorage.getItem('token'),
      },
    ];
    dispatch(expenseItems(item));
    //dispatch(cartItems(tokenCreate));
  }, []);
  React.useEffect(() => {
    console.log("====================================");
    console.log(expenseItemsList, "cameeeeeeeee");
    console.log("====================================");
    }, []);
    const backtoCashier = (e) => {
      dispatch(setActivePage(pageComponents.BILLING));
      //dispatch(setActiveTab(cashierTabs.DINE_IN));
  
    }


   

  return (
    <>
      <div class="row mt-3 ">
        <div class="col-md-2">
          <PageTitle titleHeader="Expense"  >
            <h3>Expense List</h3>
          </PageTitle>
        </div>
        <div class="col-md-2 pt-5">
   
        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">
        <button type="button" class="btn btn-secondary btn-lg btn-block  p-4" onClick={()=>backtoCashier()}>Back</button>
       
        </div>
        <div class="col-md-2">
        <button type="button" class="padding4 btn btn-secondary btn-lg btn-block padding4" onClick={()=>handleClickExpensePayout()}>New Expense Payout</button>
       
        {/* <button type="button" class="btn btn-secondary btn-lg btn-block p-4" onClick={()=>handleClickAddEmployee()}>Add Employee</button> */}
        <ExpensePayoutModal show={showExpensePayout}
                    animation={true} handleClose={handleCloseExpensePayout} >

                </ExpensePayoutModal>
        </div>
        <div class="col-md-2 pr-4">
          <button type="button" class="btn btn-secondary btn-lg btn-block p-4" onClick={()=>handleClickAddExpense()}>Add Expense</button>
        </div>
      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">

          <div class="row w-100 border shadow flex-grow-1 tableFixHead">
            {/* <table class="table ametable " >
              <thead>
                <tr style={{height:'10px'}}>
                  <th scope="col" style={{textAlign:'center'}}>S.No</th>
                  <th scope="col" style={{textAlign:'center'}}>Transaction Date</th>
                  <th scope="col" style={{textAlign:'center'}}>Emp Name</th>
                  <th scope="col" style={{textAlign:'center'}}>Role</th>
                  <th scope="col" style={{textAlign:'center'}}>Narration</th>
                  <th scope="col" style={{textAlign:'center'}}>Amount</th>
                  <th scope="col" style={{textAlign:'center'}}>Expense Type </th>
                  
                </tr>
              </thead>
              <tbody>
                 {content}

              </tbody>
            </table> */}
            <Table className="table-striped"
                    // pagination= { {total : customerItemsList.length,
                    // showTotal : (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    // showSizeChanger : true,onShowSizeChange: onShowSizeChange ,itemRender : itemRender 
                    // } }
                    style={{ overflowX: 'auto', textAlign: 'center',width:'100%' }}
                    // style={{ overflowX: 'auto' }}
                    columns={columns}
                    // scroll={true}
                    pagination={false}
                    // pagination={{ pageSize: 5 }}
                    // bordered
                    // bordered
                    dataSource={expenseItemsList}
                    rowKey={record => record.id}
                  // onChange={console.log("change")}
                  />
          </div>

        </div>


      </div>
    
    </>


  );

}
