import React, { useState } from 'react';

import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { backToPreviousPage } from '../../reducers/homePageSlice';
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';


export function AddExpense(props) {


  const dispatch = useDispatch();
 const [name,setName] = useState("")

  const addExpenseNewReason = () =>{

  }

const Addexpense = () => {
    //POST jsonconst date = new Date();
    
    const date = new Date();
    console.log(date, "anandgreenangel");
    const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
    let dataToSend = {
      token:localStorage.getItem('token'),
      expnam: name,
      regusr: localStorage.getItem('UserID'),
      regdte: datetime,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_addexpense", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.AddExpenseTypeArr[0].AddExpenseType != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.AddExpenseTypeArr.length; i++) {
            arr.push({
              atype: responseJson.AddExpenseTypeArr[i].AddExpenseType,
            });
          }

          console.log("HIII");
          dispatch(backToPreviousPage())
          // Actions.expensePayoutCash();
        } else {
         
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };
  return (
    <>
      <div class="row mt-3 ml-1">
        <div class="col-md-2">
          <PageTitle titleHeader="Expense"  >
            <h3>Add Expense</h3>
          </PageTitle>
        </div>
        <div class="col-md-2 pt-5">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">
       
        </div>
        <div class="col-md-2 pr-4">
          <button type="button" class="btn btn-florogreen btn-lg btn-block p-4"  onClick={() => dispatch(backToPreviousPage())}>Back</button>
        </div>
      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column ml-4 pl-3 mt-3">
          
            <div class="form-group w-50 mb-auto">
              <label for="exampleInputEmail1" class="text-muted">Expense Name</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>setName(e.target.value)}/>
            </div>
           
            <div class="form-group text-right mr-3">  <button type="submit" class="btn btn-lg btn-infogreen mt-4 p-4 w-20" onClick={()=>Addexpense()}>Save</button></div>
        


        </div>


      </div>

    </>


  );

}
