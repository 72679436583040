import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { pageComponents,cashierTabs,posType,ClosingSalesType } from '../utilities/Constants';
 


const homePageAdapter = createEntityAdapter();
const emptyInitialState = homePageAdapter.getInitialState({
  activePage: pageComponents.BILLING,
  previousPage: pageComponents.BILLING,
  activeTab: cashierTabs.COUNTER_SALE,
  posType: posType.RESTAURANT,
})

// const initialState = emptyInitialState;
const initialState = homePageAdapter.upsertMany(emptyInitialState, 
  [{id:pageComponents.CLOSINGSALES,PageName:"Closing Sales",ClosingSalesType:ClosingSalesType.WITH_DENOMINATION},{id:pageComponents.ONLINEORDERS,PageName:"Online Orders"}]);

export const homePageSlice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    setActivePage:(state, action)=>{
      state.previousPage = state.activePage
      state.activePage = action.payload
    },
    backToPreviousPage:(state, action)=>{
      //state.previousPage = state.activePage
      state.activePage = state.previousPage
    },
    setActiveTab:(state, action)=>{
      state.activeTab = action.payload
    },
    updatePage:(state, action)=>{
      
      homePageAdapter.updateOne(state,action.payload);
    },

  },
   
});

export const { setActivePage,setActiveTab,updatePage,backToPreviousPage} = homePageSlice.actions;

export default homePageSlice.reducer;
export const { selectAll: selectAllPages,selectById: selectPageById} = homePageAdapter.getSelectors((state) => state.homePage);

// export const selectItemsForSearch = createSelector(
//   [selectAllItems, (state, searchTerm) => searchTerm],
//   (items, searchTerm) => items.filter((item) => item.name === searchTerm)
// )
 export const selectActivePage = (state) => state.activePage;
 export const selectActiveTab = (state) => state.activeTab;
