import React, { Component } from 'react';
import { backToPreviousPage, setActivePage } from '../../reducers/homePageSlice';
import { updateBill, updateQty, addBill, addBillItem, selectBillById, selectItem, addRemarks } from '../../reducers/billsSlice';
import { useSelector, useDispatch } from 'react-redux';
import '../Payment/Payment.css';
import '../Payment/PaymentSummary.css';
import { pageComponents } from '../../utilities/Constants';
import { selectAllRefund } from '../../reducers/refundSlice';

export const RefundPaymentSummary = (props) => {
  console.log(props, "props-payment");
  const refundCartSubTotalAmount = useSelector((state) => state.constVar.refundCartSubTotalAmount)
  const refuntTotalAmount = useSelector((state) => state.constVar.refundCartAmount)

  const RefundDiscountValue = useSelector((state) => state.constVar.RefundDiscount)
  console.log(RefundDiscountValue, "RefundDiscountValue");
  const { discountPrecentage } = useSelector((state) => state.touch);
  const { totalamount } = useSelector((state) => state.touch);
  const { subtotalamount } = useSelector((state) => state.touch);
  const cartItemsList = useSelector(selectAllRefund);
  const dispatch = useDispatch();
  const activeBillId = useSelector((state) => state.bills.activeBillID)
  const bill = useSelector((state) => selectBillById(state, activeBillId))
  const billingItems = bill === undefined ? undefined : bill.lineitems;
  // console.log(discountPrecentage,"discountPrecentage");
  try {
    var billamnt = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].amount) +
      parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    ).toFixed(2);

    var billamnt2 = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal)
    ).toFixed(2);

    var roundamnt = Math.round(billamnt);
    var roundamnt2 = Math.round(billamnt2);
  } catch (error) {
    console.log(error);
  }

  const discountDelete = () => {
    dispatch({ type: "discountPrecentage", payload: '' })
    dispatch({ type: "totalamount", payload: roundamnt })
    dispatch({ type: "subtotalamount", payload: roundamnt2 })
    dispatch({ type: "dueamount", payload: 0 });
    props.setstate('Remove')
  }

  const goBackToPreviousPageFunc = () => {

    discountDelete()

    dispatch(setActivePage(pageComponents.BILLING));
    // dispatch(backToPreviousPage())
  }
  //  console.log(discountPrecentage,"discountPrecentage");
  return (

    <div class="row flex-grow-1">
      <div class="col-md-12 d-none d-md-flex flex-column ">
        <div class="row flex-grow-1 tablePaymentSummary">
          <div class="col-md-12 d-none d-md-flex flex-column p-4 ">
            <table class="table border">
              <thead>
                <tr className="no-top-border">
                  <th className="no-top-border h6fontSize "><b>S.No</b></th>
                  <th className="no-top-border h6fontSize"><b>ITEMS</b></th>
                  <th className="no-top-border h6fontSize "><b>QTY</b></th>
                  {/* text-center */}
                  {/* <th className="no-top-border h6fontSize"><b>DELETE</b></th> */}
                  <th className="no-top-border h6fontSize"><b>TOTAL</b></th>

                </tr>
              </thead>
              <tbody>
                {
                  cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                  cartItemsList.map((item, index) =>

                  (

                    <tr  >
                      <td>{index + 1}</td>
                      <td >
                        <div class="h6fontSize">{item?.prodname}</div>
                        {/* <div><small>Qty: {item.qty}, {item.remark}</small></div> */}
                      </td>
                      <td>

                        <span class="text-danger paddingLeft paddingRight   ">{parseInt(item?.mainqty)}   </span>

                      </td>
                      <td class="text-center padding2">{item?.amount}</td>

                    </tr>
                  ))

                }

              </tbody>
            </table>

          </div>
        </div>

        <div class="row   PaymentSummary p-4">
          <div class="col-md-12 d-none d-md-flex flex-column">
            <div class="row flex-grow-1">
              <div class="col-md-6">
                <h4 class="text-danger txt-h4">Sub Total</h4>
              </div>
              <div class="col-md-6 pr-2  ">
                <h4 class="text-danger text-right txt-h4">
                  {refundCartSubTotalAmount ? parseFloat(refundCartSubTotalAmount).toFixed(2) : "0.00"}

                  {/* { cartItemsList.length > 0 && cartItemsList[cartItemsList.length -1].subTotal.toFixed(2)} */}
                  {/* {cartItemsList.length > 0 &&
                   cartItemsLislt[0].name !="yasar" && 
                     cartItemsList[cartItemsList?.length - 1].subTotal ?
                      parseFoat(cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2)) :"0.00"} */}
                </h4>
              </div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-md-6 ">
                <h4 class="text-danger txt-h4">TAX</h4>


                {/* <td>No Of Qty</td>
                                            <td>{cartItemsList?.length > 0 && cartItemsList[cartItemsList?.length - 1].qty ?parseFloat(cartItemsList[cartItemsList?.length - 1].qty):0  }</td>
                                      
                                             </tr>
                                        <tr class="pb-2">

                                            <td>Sub Total</td>
                                            {/* <td>{subTotal.toFixed(2)}</td> */}
                {/* <td></td>
                                       
                                        </tr>
                                        <tr class="pb-2">

                                            <td>GST</td>
                                            {/* <td>{gst.toFixed(2)}</td>
                                             */}
                {/* <td></td>
                                       
                                        </tr>  */}
              </div>
              <div class="col-md-6 pr-2 " >
                <h4 class="text-danger text-right txt-h4">{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].totalgst ? cartItemsList[cartItemsList?.length - 1].totalgst.toFixed(2) : "0.00"}</h4>
              </div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-md-6">
                <h4 class="text-danger txt-h4" onClick={() => discountDelete()}>Discount</h4>
              </div>
              <div class="col-md-6 pr-2 ">
                <h4 class="text-danger text-right txt-h4">{RefundDiscountValue}</h4>
              </div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-md-6">
                <h4 class="text-danger txt-h4">Rounding</h4>
              </div>
              <div class="col-md-6 pr-2 ">
                <h4 class="text-danger text-right txt-h4">0.00</h4>
              </div>
            </div>
            <div class="row flex-grow-1">
              <div class="col-md-6">
                <h4 class="text-danger display-4 txt-h4-total">Total</h4>
              </div>
              <div class="col-md-6 pr-2 ">
                <h4 class="text-danger text-right display-4 txt-h4-total">{refuntTotalAmount ? parseFloat(refuntTotalAmount).toFixed(2) : "0.00"}</h4>

                {/* <h4 class="text-danger text-right display-4 txt-h4-total">{roundamnt != null && roundamnt!= undefined && roundamnt}</h4> */}

              </div>
            </div>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-md-6">
            <button type="button" class="btn btn-florogreen Btn__large btn-block  btn-block-height" onClick={() => goBackToPreviousPageFunc()}>BACK</button>

          </div>
          <div class="col-md-6 d-none d-md-flex flex-column">
            <button type="button" class="btn btn-infogreen Btn__large btn-block  btn-block-height">REDEEM POINTS</button>
          </div>
          {/* <div class="col-md-2 d-none d-md-flex flex-column mt-2"> mt-2 p-4
p-4
          </div> */}
        </div>
      </div>
    </div>

  );

}
