import React, { useState } from 'react';
import './ViewOrders.css';
import { Tables } from './Tables';
import { Takeaway } from './Takeaway';
import { cashierTabs } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux'
import { fetchTables } from '../../reducers/tablesSlice';
import { BusyOrders } from './BusyOrders';

export function ViewOrders(props) {
 const [tokenCreate,setTokenCreate] = useState([]);

  const dispatch = useDispatch()
  const tab = useSelector((state) => state.homePage.activeTab);

  let ViewOrders;
    
    if (tab === cashierTabs.DINE_IN) {

let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
          var item = [
            {
            link: link,
            },
            ];
            console.log(link,"link");
          dispatch(fetchTables(item));

      ViewOrders=  <Tables/>
    } 
    else if(tab === cashierTabs.TAKE_AWAY)
    { 
      let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_takeaway";
        var item = [
          {
          link: link,
          },
          ];

        dispatch(fetchTables(item));

      ViewOrders= <Takeaway />
    }
    else if(tab === cashierTabs.BUSY_ORDERS)
    { 
      let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_busytable";
        var item = [
          {
          link: link,
          },
          ];

        dispatch(fetchTables(item));

      ViewOrders= <BusyOrders />
    }
    else {
      let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
        var item = [
          {
          link: link,
          },
          ];

        dispatch(fetchTables(item));

      ViewOrders= <Tables/>
    }
   
  
    return (

      <div class="row flex-grow-1" style={{overflowY:'scroll'}}>
        <div class="col-12 d-none d-sm-flex flex-column ">
          {
             ViewOrders
              }
          
          
        {/* </div>
        <div class="col-6 d-none d-sm-flex flex-column">
          <Takeaway /> */}
        </div>

      </div >

    );
  
}
