import React, { useState,useEffect } from 'react';

import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSuppliers, selectAllSuppliers} from '../../reducers/supplierSlice';
import { setActivePage } from '../../reducers/homePageSlice';
import { PageTitle } from '../Commons/PageTitle';
import { SupplierPayoutModal } from './SupplierPayoutModal';


export function SupplierList(props) {
  const dispatch = useDispatch();
  
  let content;
  // const suppliersStatus = useSelector((state) => state.suppliers.status)
  // const error = useSelector((state) => state.suppliers.error)
  // const allSuppliers = useSelector(selectAllSuppliers)
  const [showSupplierPayout, setShowSupplierPayout] = useState(false);
  const handleCloseSupplierPayout = () => setShowSupplierPayout(false);
  const handleClickSupplierPayout = (e) => {
    setShowSupplierPayout(true);

  }


  const handleClickAddSupplier = (e) => {
    dispatch(setActivePage(pageComponents.ADDSUPPLIER));
 
  }
  
  // useEffect(() => {
  //   if (suppliersStatus === 'idle') {
  //     dispatch(fetchSuppliers())
  //   }
  // }, [suppliersStatus, dispatch])

  // if (suppliersStatus === 'loading') {
  //   content = <div className="col-md-2">Loading...</div>
  // } else if (suppliersStatus === 'succeeded') {
    // content = allSuppliers.map((supplier, index) => (
    //   <tr>
    //   <th scope="row">{index+1}</th>
    //   <td>{supplier.name}</td>
    //   <td>{supplier.id}</td>
      
    //   <td>Otto</td>
    //   <td>@mdo</td>
    //   <td> <span class="badge px-4 py-2 bg-orange text-white">View</span></td>
    // </tr>
    // ))
  // } else if (suppliersStatus === 'failed') {
  //   content = <div className="col-md-12">{error}</div>
  // }

   


  return (
    <>
      <div class="row mt-3 ">
        <div class="col-md-2">
          <PageTitle titleHeader="Supplier"  >
            <h3>Supplier List</h3>
          </PageTitle>
        </div>
        <div class="col-md-2 pt-5">
   
        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">
        <button type="button" class="btn btn-secondary btn-lg btn-block padding4" onClick={()=>handleClickSupplierPayout()}>New Supplier Payout</button>
        <SupplierPayoutModal show={showSupplierPayout}
                    animation={true} handleClose={handleCloseSupplierPayout} >

                </SupplierPayoutModal>
        </div>
        <div class="col-md-2 pr-4">
          <button type="button" class="btn btn-secondary btn-lg btn-block p-4" onClick={()=>handleClickAddSupplier()}>Add Supplier</button>
        </div>
      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">

          <div class="row w-100 border shadow flex-grow-1 tableFixHead">
            <table class="table ametable " >
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                 
                  <th scope="col">Email id</th>
                  <th scope="col">Address</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* {content} */}

              </tbody>
            </table>
          </div>

        </div>


      </div>
    
    </>


  );

}
