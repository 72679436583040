import React, { useEffect,useState } from 'react';
// import { Calc } from '../Commons/Calc';
import { PageTitle } from '../Commons/PageTitle';
import { pageComponents, ClosingSalesType } from '../../utilities/Constants'
import { setActivePage, updatePage, selectPageById,backToPreviousPage } from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import '../Commons/Calc.css'
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from "react-to-print";
import { cartItems } from '../../reducers/CartSlice';

export function FloatingCash() {
  
  const [floatcashaddedData, setfloatcashaddedData] = useState(0);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const [companyDetails, setCompanyDetails] = useState();
  const dispatch = useDispatch();
  const [numericValue, setNumericValue] = useState("");
  const onBeforeGetContentResolve = React.useRef(null);
  const draftRef = React.useRef(null);
  const [state, setstate] = React.useState("");
const [cash, setcash] = React.useState([]);
const date = new Date();
  
  console.log(date, "anandgreenangel");
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  console.log(datetime, date, "anandangelcart");


const addNumber = (num) => {
  setNumericValue(numericValue + num)

}

const deleteNumber = () => {

  setNumericValue(numericValue.slice(0, -1))
}
const clearAll = () => {

  setNumericValue("");
}
const getdata = () => {
  //POST json
  let dataToSend = {
    // wanip: tokenCreate[0].Wanip,
    // dbusr: tokenCreate[0].DBUser,
    // dbpwd: tokenCreate[0].DBPwd,
    // dbnam: tokenCreate[0].DBname,

    wanip: "mysql.amepos.in",
    dbusr: "user_amepos",
    dbpwd: 'bismillah$12',
    dbnam: "amepos_10",
    token:localStorage.getItem('token'),
  };
  let formBody = [];
  for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
    method: "POST",
    body: formBody,
    headers: {
      //Header Defination
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
      //alert(JSON.stringify(responseJson));
      console.log(responseJson);
      if (responseJson.clientdtlArr[0].clientdtl != 2) {
        setCompanyDetails(responseJson.clientdtlArr[0])
        // var text =
        //   "[C]<b><font size='big' font-family='cambria' font-weight='bold'>   " +
        //   responseJson.clientdtlArr[0].companynam +
        //   "</font></b>\n" +
        //   "[C]<font size='normal' font-family='cambria' font-weight='bold'>    " +
        //   responseJson.clientdtlArr[0].addr1 +
        //   ", " +
        //   responseJson.clientdtlArr[0].addr2 +
        //   "</font>\n" +
        //   "[C]<font size='normal' font-family='cambria' font-weight='bold'>    PH NO: " +
        //   responseJson.clientdtlArr[0].addr3 +
        //   "</font>\n" +
        //   "[C]<font size='normal' font-family='cambria' font-weight='bold'>    GST NO: " +
        //   responseJson.clientdtlArr[0].gstno +
        //   "</font>\n" +
        //   "[C]<b><font size='normal' font-family='cambria' font-weight='bold'>    LAST RECEIPT</font></b>\n";
        // goToReceipt(text);

        // console.log("====================================");
        // console.log(text);
        // console.log("====================================",responseJson);
        // return text;
      } else {
        // setErrortext("Please check your email id or password");
        console.log("Please check your email id or password");
        // alert("Sorry No Data");
        //navigation.replace('DrawerNavigationRoutes')
      }
    })
    //If response is not in json then in error
    .catch((error) => {
      //alert(JSON.stringify(error));
      console.error(error);
    });
};

  const getValue= () => {
    // if(floatcashaddedData != 0){
      let dataToSend = {
        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token:localStorage.getItem('token'),
        terminal: localStorage.getItem('TerminalName'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getcash", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          console.log(responseJson);
          if (responseJson.CashArr[0].Cash != 2) {
            const arr = [];
            for (let i = 0; i < responseJson.CashArr.length; i++) {
              arr.push({
                floatcashadded:  floatcashaddedData,
                totalfcash: responseJson.CashArr[i].floatcash,
              });
            }
            
  console.log(floatcashaddedData,"floatcashaddedData");
            if(parseInt(responseJson.CashArr[0].floatcash) != '0'){
              alert(`Floating Cash of ${responseJson.CashArr[0].floatcash} is Already Registered For Today.Do You Want to Proceed Adding Somemore Float Cash?`)
            }
            // else{
  
              setcash(arr);
            // }
            // set
            console.log("HIII");
          } else {
            // setErrortext("Please check your email id or password");
            console.log("Please check your email id or password");
            // alert("Sorry No Data");
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });
    // }
    
   
   
   
   
   
   }
   useEffect(()=>{
    getValue()
    getdata()
   },[])



   const getFloat = () => {
    //POST json
    let dataToSend = {
      token:localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      cash: numericValue,
      // terminal: terminalno,
      openusr: localStorage.getItem('UserID'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_floatcash", {
    // fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_insertfloatcash", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.FloatArr[0].Float != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.FloatArr.length; i++) {
            arr.push({
              floatcashadded: responseJson.FloatArr[i].floatcashadded,
              totalfcash: responseJson.FloatArr[i].totalfcash,
            });
          }
          
          // setfloatcashaddedData((responseJson.FloatArr[i].floatcashadded)
          setcash(arr);
          clearAll()
          console.log(arr,"pav");
          // if(parseInt(arr[0].totalfcash) != '0'){
          //   alert(`Floating Cash of ${arr[0].totalfcash} is Already Registered For Today.Do You Want to Proceed Adding Somemore Float Cash?`)
          // }
          // if(arr[0].floatcashadded)
          // getValue();
          console.log("HIII");
          handlePrint()
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          // alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };
  /////////////////////////////////////////////

  const handleAfterPrint = React.useCallback(() => {
      
    dispatch({type:"discountPrecentage",payload:''});
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
   alert("Floating Cash added")
   dispatch(setActivePage(pageComponents.BILLING));
   dispatch(cartItems())
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {

    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 


    
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    
    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

    React.useEffect(() => {
      if (
        text === "New, Updated Text!" &&
        typeof onBeforeGetContentResolve.current === "function"
      ) {
        onBeforeGetContentResolve.current();
      }
    }, [onBeforeGetContentResolve.current, text]);
  

    ///////////////////////////////////////////////

    
  return (

    <div class="row flex-grow-1 ">
      <div class= "col-12 col-sm-9 d-none d-sm-flex flex-column ">
        <PageTitle titleHeader="Floating Cash" >
         
        </PageTitle>
        <div class="row ml-1 mt-4">
      <div class="col">
        <div class="row">
          <div class="col p-2">

            <h2 class="display-4 "><strong>Total Amount</strong></h2>
          </div>
        </div>
        <div class="row">
          <div class="col p-2">
            <h2 class="text-danger display-4 "><strong>
                  {cash.length != 0 ? <>{cash[0].totalfcash}</> : null}</strong></h2>
          </div>
        </div>

      </div>

    </div>
      </div>
      <div class="col col-sm-3 d-none d-sm-flex flex-column align-middle">
       
      <div class="flex-grow-1"></div><div >
        {/* <Calc enter="false" /> */}
        <div class="btn-group-vertical  mt-4" role="group"  >
{/* ml-4 */}
      <div class="row text-center my-4 flex-wrap w-100">
        <div class=" w-100">
          <input class="text-center form-control-lg square-edges padding__5 inputCardNumber border" type="text" value={numericValue} />
        </div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
        {/* { content} */}
       <div class="col-4 padding__4 border "onClick={() => deleteNumber()}><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>
      </div>





    </div>
      <button class="btn btn-florogreen btn-lg btn-block padding__4 row" onClick={()=>getFloat()}>Confirm Floating Cash</button>
      <button class="btn btn-florogreen btn-lg btn-block padding__4 row"  onClick={() => dispatch(backToPreviousPage())}>Back</button></div><div class="flex-grow-1"></div>
      </div>
      <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " >
               <p className="centered" style={{fontSize:'14px' ,borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>{companyDetails?.companynam}</p>
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.addr1}{companyDetails?.addr2}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>PH NO:{companyDetails?.addr3}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST No:{companyDetails?.gstno}</p>
               <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>FLOATING CASH RECEIPT</p>
                
                
               {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div> */}
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                 
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Date :{datetime != null && datetime != undefined ? datetime :""}</p>
               </div>
               <table className="centered" style={{borderCollapse:'collapse'}}>
                           
                            <tbody  style={{borderTop:'1px dotted black',borderBottom:'1px dotted black',borderCollapse:'collapse'}}>
                            <tr>
                                       <td className='totalCash'   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>OPENING CASH : </td>
                                       <td className="Amount  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{companyDetails?.currency.split(",",1)} {cash.length != 0 ? <>{cash[0].floatcashadded}</> : null}</td>


                                       </tr>
                                       <tr  >
                                       <td className='totalCash'   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>TOTAL OPENING CASH : </td>
                                       <td className="Amount  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{companyDetails?.currency.split(",",1)} {cash.length != 0 ? <>{cash[0].totalfcash}</> : null}</td>


                                           
                                           {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}
                           
                                       </tr>
                                      
                                 

                            </tbody>
                        </table> 
               
             
                       

                    </div>
                </div>
                
           </div>


    </div>



  );

}
