import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PaymentType } from './PaymentType';
import { EWallet } from './EWallet';
import { CardNumberForm } from './CardNumberForm';
import { TextBoxAmount } from './TextBoxAmount';
import { emptyCart, selectBillById } from '../../reducers/billsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, paymentMode } from '../../utilities/Constants'
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { fetchTables } from '../../reducers/tablesSlice';
import './PaymentMode.css'
// import DiscountPopUp from './DiscountPopUp';
import { useReactToPrint } from "react-to-print";
import { setActivePage } from '../../reducers/homePageSlice';
import ReactToPrint from 'react-to-print-advanced';
import { confirmAlert } from 'react-confirm-alert';
// import { useBarcode } from 'react-barcodes';
import 'react-confirm-alert/src/react-confirm-alert.css';
import QRCode from 'qrcode'
import { selectAllRefund } from '../../reducers/refundSlice';
import { balancePaidAmt, paidAmount } from '../../reducers/ConstantSlice';
// import QRCode from "react-qr-code";
// import Barcode from 'react-barcode'
// import LogoAme from '../../assets/images/logo.png';

export function RefundPaymentMode({ state, setstate }) {

  // const [kotData, setKotData] = useState();
  const kotRef = useRef(null);
  const [loadingKOT, setLoadingKOT] = useState(false);
  const [textKOT, setTextKOT] = useState("old boring text");
  
  const oldBillNo = useSelector((state) => state.constVar.oldBillNo)
  const dummyPaidAmount = useSelector((state) => state.constVar.dummyPaidAmount)
  const refuntTotalAmount = useSelector((state) => state.constVar.refundCartAmount)
  const LogoAme = localStorage.getItem("logo");
  const { Box, balance } = useSelector((state) => state.touch)
  console.log(balance, "Currentbalance");
  const onBeforeGetContentResolveKOT = useRef(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("old boring text");
  const onBeforeGetContentResolve = useRef(null);
  const draftRef = useRef(null);
  const [billNoScan, setBillNoScan] = useState("0");
  const { dueamount } = useSelector((state) => state.touch)
  // const {totalasomount} =useSelector((state)=>state.touch)
  console.log(dueamount, "dueamount");
  const componentRef = useRef();
  const { discountPrecentage } = useSelector((state) => state.touch);
  console.log(discountPrecentage, "discountPrecentage");
  const cartItemsList = useSelector(selectAllRefund);
  const [balanceAmt, setbalanceAmt] = useState(0);
  const [paymentAmountData, setPaymentAmountData] = useState();
  const [paymentAmountDataKOT, setPaymentAmountDataKOT] = useState();
  const [dueAmount, setDueAmount] = useState(0);
  const [cardNumberData, setCardNumberData] = useState("");
  const [cardNameData, setCardNameData] = useState("");
  // const pageStyle = `{ size: 2.5in 4in }`;
  const pageStyle = "@page { size: 2.5in 4in }"
  //   const [modelDiscountPopUp, setModelDiscountPopUp] = useState(false)
  const [showbillFooter, setShowbillFooter] = useState(true)
  const [showHeaderSettings, setShowHeaderSettings] = useState(false)
  const [showTaxDetails, setShowTaxDetails] = useState(true)
  const [billBodyContent, setBillBodyContent] = useState(false)
  const [tableHeaderSettings, setTableHeaderSettings] = useState(3);
  const [denominations, setDenominations] = useState();
  
  const billNo  = useSelector((state) => state.refund.billno);
  console.log(billNo,"billNo");
  const [nameonCard, setNameonCard] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [kotData, setKotData] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [ewalletType, setEwalletType] = useState();
  // const [modelDiscountPopUp,setModelDiscountPopUp] = useState(false);
  const [dueTotalAmount, setDueTotalAmount] = useState(0);
  const [numericValue, setNumericValue] = useState("");
  const balancePaid = useSelector((state) => state.constVar.balancePaidAmt)
  const paidTotalAmount = useSelector((state) => state.constVar.paidAmount)
  const [sliceItem, setSliceItem] = useState('')
  const activeBillId = useSelector((state) => state.constVar.paymentModeStatus)
  const [discat, setdiscat] = useState([]);
  const { totalamount } = useSelector((state) => state.touch)
  const dispatch = useDispatch();
  const activeBillIdData = useSelector((state) => state.bills.activeBillID)
  console.log(activeBillId, "activeBillIdData");
  const bill = useSelector((state) => selectBillById(state, activeBillIdData))
  const payMode = bill === undefined ? undefined : bill.paymentMode;
  console.log(totalamount, "totalamount");
  const date = new Date();

  const RefundDiscountValue = useSelector((state) => state.constVar.RefundDiscount)
  console.log(RefundDiscountValue, "RefundDiscountValue2");
  console.log(date, "anandgreenangel");
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  console.log(datetime, date, "anandangelcart");
  try {
    if (cartItemsList.length > 0 && cartItemsList[0].name != "yasar") {

      var billamnt = (
        parseFloat(cartItemsList[cartItemsList.length - 1].amount)
      ).toFixed(2);

      var billamnt2 = (
        parseFloat(cartItemsList[cartItemsList.length - 1].subTotal)
      ).toFixed(2);

      var billamnt3 = (
        parseFloat(cartItemsList[cartItemsList.length - 1].gst)
      ).toFixed(2);
      var roundamnt3 = Math.round(billamnt3);
      var roundamnt2 = Math.round(billamnt2);
      var roundamnt = Math.round(billamnt);
      var subTotal = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList.length - 1].subTotal
    }
  } catch (error) {
    console.log(error);
  }
  const [billamount, totalbillamount] = useState(roundamnt);


  console.log(Box, "Box@SS");
  const deleteNumber = () => {
    setNumericValue(numericValue.substring(0, numericValue.length - 1))

    setPaymentValue(numericValue.substring(0, numericValue.length - 1))
  }


  const addNumber = (num) => {
    setNumericValue(numericValue + num)
    // setPaymentValue(numericValue + num)

  }


  const addToPaidAmtfun = () => {
    if (parseInt(numericValue) > 0) {

      console.log("================");
      console.log(balancePaid, paidTotalAmount, typeof (paidTotalAmount), typeof (balancePaid), numericValue, "balancePaid");

      var map = parseInt(balancePaid) - parseInt(numericValue)
      console.log(map, "map");

      var map2 = parseInt(paidTotalAmount) + parseInt(numericValue)
      console.log(map2, "map2");

      dispatch(paidAmount(map2));
      dispatch(balancePaidAmt(map))
      setNumericValue('')

      if (map <= 0) {
        alert("paid")
      }
    }
  }
  const makeExactPayment = () => {
    console.log(refuntTotalAmount, dummyPaidAmount, "balancePaid");

    dispatch(paidAmount(refuntTotalAmount));
    dispatch(balancePaidAmt(0))

    changeData()
  }

  const changeData = () =>{
    var customerId = ''
    var payno = ''
    if (localStorage.getItem("Customerid") != null && localStorage.getItem("Customerid") != undefined) {
      customerId = localStorage.getItem("Customerid")
     
    } else {
      customerId = 1
    }
    if (activeBillId.paymentMode == 'CASH') {
      payno = '1';
    } else if (activeBillId.paymentMode == 'CARD') {
      payno = '2';
    } else if (activeBillId.paymentMode == 'EWALLET') {
      payno = '4'
    }


    makePayment(customerId,payno)
  }
  const makePayment = (customerId,payno) => {
    
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    console.log(datetime, date, "anandangelcart");

    let dataToSend = {
      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),
      openin: localStorage.getItem('TerminalName'),
      custid: customerId,
      orddte: datetime,
      paymode: payno,
      billamt: paidTotalAmount,
      round: '0',
      paidamt: paidTotalAmount,
      nameoncard: '',
      cardno: '',
      card: '',
      cash: paidTotalAmount,
      balance: balancePaid,
      gst: '0',
      cess: '0',
      gross: '0',
      payusr:  localStorage.getItem('UserID'),
      discount: RefundDiscountValue,
      vat: '0',
      oldbillno: billNo,
      itemsarray: cartItemsList
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_refundbill.php", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.GetPrintArr[0].GetPrint != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.GetPrintArr.length; i++) {
            arr.push({
              clrbusy: responseJson.GetPrintArr[i].ClearBusyTable,
            });
          }

          console.log("HIII");
        } else {
          // alert("Sorry No Data");
          console.log("Sorry No Data");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }



  const handlePrint = () => {

  }
  /////////////////////////////////

  const amountPayment = async () => {
    await goToPayment()
    handlePrint()
  }

  const addNumberonSelected = (i) => {
    if (numericValue === '') {
      let numb = 0

      console.log(numb, "numb");
      var add = numb + i
      console.log(add, "add");
      var str = String(add)
      console.log(str, "str");

      setNumericValue(str)
      // setPaymentValue(str)

    } else {
      let numb = parseInt(numericValue);

      console.log(numb, "numb");
      var add = numb + i
      console.log(add, "add");
      var str = String(add)
      console.log(str, "str");
      setNumericValue(str)
      // setPaymentValue(str)
    }
  }


  const setPaymentValue = (i) => {

    dispatch({ type: "ADD_TOUCH", payload: i });
    dispatch({ type: "balance", payload: 1 });
    dispatch({ type: "dueamount", payload: 1 });

    var blc = i - billamount;
    setbalanceAmt(blc)
    setDueAmount(i)

    setNumericValue(i)
    denominationPopupdueamount(blc, i)

    console.log(i, blc, "setPaymentValue");

    // console.log(blc,"denominationPopupdueamount");
    // alert(numericValue)

    // if(blc > 0 && numericValue != null && numericValue != undefined){
    //   // console.log("0000000");
    //   setDueTotalAmount(0)
    // }
    // else if(blc < 0 && numericValue != null && numericValue != undefined){
    //   setDueTotalAmount(blc)
    // }
  }
  const denominationPopupdueamount = (i, amount) => {
    if (i > 0 || i == 0) {
      console.log(i, amount, "denominationPopupdueamount");
      alertPaymentBalance(i, amount)
      // console.log(dueamount,"denominationPopupdueamount");
    }
  }

  const getdata = () => {

  }

  useEffect(() => {
  }, [])




  const goToPayment = () => {

  }
  const getClear = () => {
    //POST json
    let dataToSend = {
      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),
      terminal: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })

      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
            arr.push({
              clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
            });
          }

          dispatch({ type: "discountPrecentage", payload: '' })
          //  dispatch(setActivePage(pageComponents.VIEWTABLES));
          console.log("HIII");
        } else {
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  ///////// KOT ///////////////

  console.log(cardNumber, nameonCard);
  const setExactAmountFunc = (billamount) => {
    // await 
    console.log(billamount, "cashbillamount");
    setNumericValue(billamount)
    alertPaymentBalance(0, billamount)
  }

  const alertPaymentBalance = (i, amount) => {

  }


  const printStatement = () => {

  }

  console.log(paymentAmountData, "paymentAmountData");
  /////////////////////////



  //////////////////////
  const handlePrintKOT = () => {

  }

  const getclient = () => {
    //POST json
    let dataToSend = {
      token: localStorage.getItem('token'),


    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.clientdtlArr[0].discounts);
        if (responseJson.clientdtlArr.clientdtl != 2) {
          const arr = responseJson.clientdtlArr[0].discounts.split(",", 5)
          setdiscat(arr)


          console.log("HIII");
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  const discount = (value) => {

  }

  const discountByValuePopUp = () => {
    // setModelDiscountPopUp(true)

    // setstate("DiscountByValue")
  }


  const goToKot = (i, amount) => { }




  console.log(numericValue, "cash");
  return (
    <div class="row flex-grow-1">
      <div class="col-md-9 d-none d-md-flex flex-column">
        <div class="row flex-grow-1">

          {activeBillId?.paymentMode === 'CARD' ? <CardNumberForm setNameonCard={setNameonCard} setCardNumber={setCardNumber} /> : <TextBoxAmount dueAmount={numericValue} roundamnt={roundamnt} balanceAmt={balanceAmt} dueTotalAmount={dueTotalAmount} />}
        </div>
        <div class="row flex-grow-1">
          <div class="col-md-3 d-none d-md-flex flex-column">

            {discat.map((item, index) => (
              <div class="row flex-grow-1 mb-1">

                <div class="col-md-12">
                  {item == "0" ?
                    <button class="btn btn-block Btn__lg  btn-block-height" type="submit"
                      style={{

                        backgroundColor: state === parseInt(item) ? "green" : "#646464",
                        color: 'white'
                      }}
                    //    onClick={() => {
                    //    discount(parseInt(item));
                    //  }}
                    ></button>
                    :
                    <button class="btn btn-block Btn__lg  btn-block-height" type="submit"
                      style={{

                        backgroundColor: state === parseInt(item) ? "green" : "#646464",
                        color: 'white'
                      }}
                      onClick={() => {
                        discount(parseInt(item));
                      }}>{item}%{"\n"}Discount</button>

                  }


                </div>

              </div>
            ))}
            <div class="row flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn btn-block Btn__lg btn-block-height"
                  style={{

                    backgroundColor: state == "DiscountByValue" ? "green" : "#646464",
                    color: 'white'
                  }}
                  type="submit" onClick={() => discountByValuePopUp()}>Discount By Value</button>
              </div>
            </div>

          </div>
          <div class="col-md-6  d-none d-md-flex flex-column">


            <div class="row flex-grow-1">
              <div class="col-md-12  d-none d-md-flex flex-column">
                <div class="row flex-grow-1 ">
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("1")}>1</button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("2")}>2</button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("3")}>3</button>
                  </div>
                </div>
                <div class="row  flex-grow-1 ">
                  <div class="col-md-4  p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("4")}>4</button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("5")}>5</button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("6")}>6</button>
                  </div>
                </div>
                <div class="row  flex-grow-1">
                  <div class="col-md-4  p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("7")}>7</button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("8")}>8</button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("9")}>9</button>
                  </div>
                </div>
                <div class="row  flex-grow-1 ">
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => deleteNumber()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("0")}>0</button>
                  </div>
                  <div class="col-md-4 p-1">
                    <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height " onClick={() => addToPaidAmtfun()}  >OK</button>
                  </div>
                </div>






                <div class="row  flex-grow-1 " >
                  <div class="col-md-12 p-1">

                    <div class="ml-1 flex-fill" style={{ height: '100%' }}>
                      <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={() => makeExactPayment()}>

                        Exact Amount
                      </button>
                    </div>


                  </div>
                </div>



              </div>


            </div>




          </div>

          <div class="col-md-3  d-none d-md-flex flex-column">

            <div class="row flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(10)}>{companyDetails?.currency.split(",", 1)}  10 </button>
              </div>
            </div>
            <div class="row flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(20)} >{companyDetails?.currency.split(",", 1)} 20</button>
              </div>
            </div>
            <div class="row  flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(50)} >{companyDetails?.currency.split(",", 1)} 50</button>
              </div>
            </div>
            <div class="row   flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn btn-block  Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(100)} >{companyDetails?.currency.split(",", 1)} 100</button>
              </div>
            </div>
            <div class="row   flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(200)} >{companyDetails?.currency.split(",", 1)} 200</button>
              </div>
            </div>
            <div class="row  flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(500)}>{companyDetails?.currency.split(",", 1)} 500</button>
              </div>
            </div>
            <div class="row  flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(2000)}>{companyDetails?.currency.split(",", 1)} 2000</button>
              </div>
            </div>
          </div>




        </div>
      </div>
      <div class="col-md-3 d-none d-md-flex flex-column">

        {activeBillId?.paymentMode === paymentMode.EWALLET ? <EWallet printStatement={printStatement} setEwalletType={setEwalletType} /> : <PaymentType />

        }
      </div>
    </div>


  );

}
