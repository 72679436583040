import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PaymentType } from './PaymentType';
import { EWallet } from './EWallet';
import { CardNumberForm } from './CardNumberForm';
import { TextBoxAmount } from './TextBoxAmount';
import { emptyCart, selectBillById } from '../../reducers/billsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, paymentMode } from '../../utilities/Constants'
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { fetchTables } from '../../reducers/tablesSlice';
import './PaymentMode.css'
import DiscountPopUp from './DiscountPopUp';
import { useReactToPrint } from "react-to-print";
import { setActivePage } from '../../reducers/homePageSlice';
import ReactToPrint from 'react-to-print-advanced';
import { confirmAlert } from 'react-confirm-alert';
import { useBarcode } from 'react-barcodes';
import 'react-confirm-alert/src/react-confirm-alert.css';
import QRCode from 'qrcode'
import { TakeAwayPayment } from './TakeAwayPayment';
// import QRCode from "react-qr-code";
// import Barcode from 'react-barcode'
// import LogoAme from '../../assets/images/logo.png';

export function PaymentMode({ state, setstate }) {

  // const [kotData, setKotData] = useState();
  const kotRef = useRef(null);
  const [loadingKOT, setLoadingKOT] = useState(false);
  const [textKOT, setTextKOT] = useState("old boring text");

  const LogoAme = localStorage.getItem("logo");
  const { Box, balance } = useSelector((state) => state.touch)
  console.log(balance, "Currentbalance");
  const onBeforeGetContentResolveKOT = useRef(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("old boring text");
  const onBeforeGetContentResolve = useRef(null);
  const draftRef = useRef(null);
  const [billNoScan, setBillNoScan] = useState("0");
  const { dueamount } = useSelector((state) => state.touch)
  // const {totalamount} =useSelector((state)=>state.touch)
  console.log(dueamount, "dueamount");
  const componentRef = useRef();
  const { discountPrecentage } = useSelector((state) => state.touch);
  console.log(discountPrecentage, "discountPrecentage");
  const cartItemsList = useSelector(selectAllCart);
  const [balanceAmt, setbalanceAmt] = useState(0);
  const [paymentAmountData, setPaymentAmountData] = useState();
  const [paymentAmountDataKOT, setPaymentAmountDataKOT] = useState();
  const [dueAmount, setDueAmount] = useState(0);
  const [cardNumberData, setCardNumberData] = useState("");
  const [cardNameData, setCardNameData] = useState("");
  // const pageStyle = `{ size: 2.5in 4in }`;
  const pageStyle = "@page { size: 2.5in 4in }"
  const [modelDiscountPopUp, setModelDiscountPopUp] = useState(false)
  const [showbillFooter, setShowbillFooter] = useState(true)
  const [showHeaderSettings, setShowHeaderSettings] = useState(false)
  const [showTaxDetails, setShowTaxDetails] = useState(true)
  const [billBodyContent, setBillBodyContent] = useState(false)
  const [tableHeaderSettings, setTableHeaderSettings] = useState(3);
  const [denominations, setDenominations] = useState();

  const [nameonCard, setNameonCard] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [kotData, setKotData] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [ewalletType, setEwalletType] = useState();
  // const [modelDiscountPopUp,setModelDiscountPopUp] = useState(false);
  const [dueTotalAmount, setDueTotalAmount] = useState(0);
  const [numericValue, setNumericValue] = useState("");

  const [sliceItem, setSliceItem] = useState('')
  const activeBillId = useSelector((state) => state.constVar.paymentModeStatus)
  const [discat, setdiscat] = useState([]);
  const { totalamount } = useSelector((state) => state.touch)
  const dispatch = useDispatch();
  const activeBillIdData = useSelector((state) => state.bills.activeBillID)
  console.log(activeBillId, "activeBillIdData");
  const bill = useSelector((state) => selectBillById(state, activeBillIdData))
  const payMode = bill === undefined ? undefined : bill.paymentMode;
  console.log(totalamount, "totalamount");
  const date = new Date();

  console.log(date, "anandgreenangel");
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  console.log(datetime, date, "anandangelcart");
  try {
    if (cartItemsList.length > 0 && cartItemsList[0].name != "yasar") {

      var billamnt = (
        parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
        parseFloat(cartItemsList[cartItemsList.length - 1].gst)
      ).toFixed(2);

      var billamnt2 = (
        parseFloat(cartItemsList[cartItemsList.length - 1].subTotal)
      ).toFixed(2);

      var billamnt3 = (
        parseFloat(cartItemsList[cartItemsList.length - 1].gst)
      ).toFixed(2);
      var roundamnt3 = Math.round(billamnt3);
      var roundamnt2 = Math.round(billamnt2);
      var roundamnt = Math.round(billamnt);
      var subTotal = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList.length - 1].subTotal
    }
  } catch (error) {
    console.log(error);
  }
  const [billamount, totalbillamount] = useState(roundamnt);
  // const { Box } = useSelector((state) => state.touch);


  console.log(Box, "Box@SS");
  const deleteNumber = () => {
    setNumericValue(numericValue.substring(0, numericValue.length - 1))

    setPaymentValue(numericValue.substring(0, numericValue.length - 1))
  }

  // const deleteNumber=() =>{
  //   setNumericValue(numericValue.slice(0, -1))

  // }
  const addNumber = (num) => {
    // setNumericValue(numericValue + num)
    setPaymentValue(numericValue + num)
    // dispatch({ type: "ADD_TOUCH", payload: Box+num });

  }

  ///////////////////////////////


  const handleAfterPrint = useCallback(() => {

    dispatch({ type: "discountPrecentage", payload: '' });
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    localStorage.removeItem('Box');
    localStorage.removeItem('balance');
    localStorage.removeItem('totalamount');
    localStorage.removeItem('payno');
    localStorage.removeItem('nameonCard');
    localStorage.removeItem('discount');
    localStorage.removeItem('cardNumber');
    localStorage.removeItem('totalPaid');
    localStorage.removeItem('CustomerName');
    localStorage.removeItem('Customerid');
    localStorage.removeItem('custId');
    localStorage.setItem('takenoMode', '0');
    localStorage.removeItem('takeawayOnlinePayment');
    localStorage.removeItem('ewalletType');
    dispatch(setActivePage(pageComponents.BILLING));
    dispatch(cartItems())

    // dispatch(setActivePage(pageComponents.BILLING));
    // dispatch(selectActiveTab(cashierTabs.COUNTER_SALE))
    // (setActiveTab(cashierTabs.COUNTER_SALE))
  }, []);

  const handleBeforePrint = useCallback(() => {
    getdata()
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,

    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });
  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);


  /////////////////////////////////

  const amountPayment = async () => {
    await goToPayment()
    handlePrint()
  }

  const addNumberonSelected = (i) => {
    if (numericValue === '') {
      let numb = 0

      console.log(numb, "numb");
      var add = numb + i
      console.log(add, "add");
      var str = String(add)
      console.log(str, "str");
      setPaymentValue(str)

    } else {
      let numb = parseInt(numericValue);

      console.log(numb, "numb");
      var add = numb + i
      console.log(add, "add");
      var str = String(add)
      console.log(str, "str");
      setPaymentValue(str)
    }
  }
  const setPaymentValue = (i) => {

    dispatch({ type: "ADD_TOUCH", payload: i });
    dispatch({ type: "balance", payload: 1 });
    dispatch({ type: "dueamount", payload: 1 });

    var blc = i - billamount;
    setbalanceAmt(blc)
    setDueAmount(i)

    setNumericValue(i)
    denominationPopupdueamount(blc, i)

    console.log(i, blc, "setPaymentValue");

    // console.log(blc,"denominationPopupdueamount");
    // alert(numericValue)

    // if(blc > 0 && numericValue != null && numericValue != undefined){
    //   // console.log("0000000");
    //   setDueTotalAmount(0)
    // }
    // else if(blc < 0 && numericValue != null && numericValue != undefined){
    //   setDueTotalAmount(blc)
    // }
  }
  const denominationPopupdueamount = (i, amount) => {
    if (i > 0 || i == 0) {
      console.log(i, amount, "denominationPopupdueamount");
      alertPaymentBalance(i, amount)
      // console.log(dueamount,"denominationPopupdueamount");
    }
  }
  const getdata = () => {
    //POST json
    let dataToSend = {
      // wanip: tokenCreate[0].Wanip,
      // dbusr: tokenCreate[0].DBUser,
      // dbpwd: tokenCreate[0].DBPwd,
      // dbnam: tokenCreate[0].DBname,

      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson.clientdtlArr[0].billformatdetails[0],"billformatdetails");
        if (responseJson.clientdtlArr[0].clientdtl != 2) {
          setCompanyDetails(responseJson.clientdtlArr[0])
          setTableHeaderSettings(responseJson.clientdtlArr[0].billformatdetails[1].status)
          localStorage.setItem('billNo', responseJson.clientdtlArr[0].billno);
          if (responseJson.clientdtlArr[0].denominations != undefined && responseJson.clientdtlArr[0].denominations != null && responseJson.clientdtlArr[0].denominations.length > 0) {
            var itemDenomination = responseJson.clientdtlArr[0].denominations.split(",")
            setDenominations(itemDenomination)
          }
          
          if(responseJson.clientdtlArr[0].billformatdetails[4].status == '1'){
            setShowbillFooter(true)
            }else{
              setShowbillFooter(false)
            }
          if(responseJson.clientdtlArr[0].billformatdetails[0].status == '1'){
          setShowHeaderSettings(true)
          }else{
            setShowHeaderSettings(false)
          }
          if(responseJson.clientdtlArr[0].billformatdetails[3].status == '1'){

            setShowTaxDetails(true)
          }else{
            setShowTaxDetails(false)

          }
          if(responseJson.clientdtlArr[0].billformatdetails[2].status == '1'){

            setBillBodyContent(true)
          }else{
            setBillBodyContent(false)

          }
          

        } else {
          console.log("Please check your email id or password");
          alert("Sorry No Data");
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  useEffect(() => {
    getclient()
    getdata()

    setSliceItem(localStorage.getItem("tableno").slice(0, 1))
    // handleClickLastReceipt()
  }, [])
 


  console.log(showTaxDetails,"showTaxDetails");
  console.log(activeBillId.paymentMode, "rrrrss");
  const goToPayment = () => {
    var tablevalue = ''
      if (localStorage.getItem('tableno') == 'CT') {
      //   hsnoconst = localStorage.getItem('hsno')
        tablevalue = `CT-${localStorage.getItem('TerminalName')}`
      } else {
      //   hsnoconst = 0
        tablevalue = localStorage.getItem('tableno')
      }
    const date = new Date();
    console.log(date, "anandgreenangel");

    console.log(numericValue, "numericValue-234");
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    console.log(datetime, date, "anandangelcart");
    //POST json
    const billamnt = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
      parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    ).toFixed(2);
    const roundamnt = Math.round(billamnt);
    var gross =
      roundamnt - parseFloat(cartItemsList[cartItemsList.length - 1].gst);
    const round = roundamnt - billamnt
    const num1 = Box;
    console.log(Box, "yyyyy2222222");
    const num2 = roundamnt;
    console.log(activeBillId.paymentMode, "rrrr");
    // add two numbers
    const sum = num1 - num2;
    console.log(sum, "oooooo");
    var payno = '';
    var nameonCard = '';
    var cardno = '';
    var totalamountcard = '';
    var totalamountcash = '';
    var amtpaid = '';
    if (activeBillId.paymentMode == 'CASH') {
      payno = '1';
      nameonCard = '';
      totalamountcash = localStorage.getItem('totalPaid')
      amtpaid = localStorage.getItem('totalPaid')
    } else if (activeBillId.paymentMode == 'CARD') {
      payno = '2';

      cardno = localStorage.getItem('cardno');
      nameonCard = localStorage.getItem('nameonCard');
      totalamountcard = localStorage.getItem('totalamount')
      amtpaid = localStorage.getItem('totalamount')
    } else if (activeBillId.paymentMode == 'EWALLET') {
      payno = '4'
      nameonCard = '';
      cardno = '';
      totalamountcash = localStorage.getItem('totalamount')
      amtpaid = localStorage.getItem('totalamount')
    } else if (activeBillId.paymentMode == 'ONLINE_AC') {
      payno = '5';
      nameonCard = '';
      cardno = '';
      totalamountcash = localStorage.getItem('totalamount')
      amtpaid = localStorage.getItem('totalamount')
    }
    else if (activeBillId.paymentMode == 'ONLINE_CARD') {
      payno = '7';
      nameonCard = '';
      cardno = '';
      totalamountcash = localStorage.getItem('totalamount')
      amtpaid = localStorage.getItem('totalamount')
    }
    else if (activeBillId.paymentMode == 'ONLINE_COD') {
      payno = '6';
      nameonCard = '';
      cardno = '';
      totalamountcash = localStorage.getItem('totalamount')
      amtpaid = localStorage.getItem('totalamount')
    }

    // HOSTING_ENV="local"
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      tabno: tablevalue,
      custid: localStorage.getItem("custId"), //or name
      orddte: datetime,
      payusr: localStorage.getItem('UserID'),
      openin: localStorage.getItem('TerminalName'),
      paymode: localStorage.getItem('payno'),
      // paymode:"12",
      billamt: localStorage.getItem('totalamount'),
      round: round,
      paidamt: amtpaid,
      // paidamt:200,
      nameoncard: localStorage.getItem('nameonCard'),//or emptystring
      cardno: localStorage.getItem('cardNumber'),
      card: totalamountcard,
      // card:12,
      cash: totalamountcash,
      balance: localStorage.getItem('balance'),
      gst: parseFloat(cartItemsList[cartItemsList.length - 1].gst).toFixed(2),
      cess: 0,
      gross: gross,
      discount: localStorage.getItem('discount'),
      vat: 0
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printbill", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);

        if (responseJson.GetPrintArr[0].GetPrint != 2 && responseJson.GetPrintArr[0].Printtyp != 0) {
          const arr = [];

          setBillNoScan(responseJson?.GetPrintArr[0].billno)
          const arryjs = [];
          setPaymentAmountData(responseJson)

          for (let i = 0; i < responseJson.GetPrintArr.length; i++) {
            arryjs.push({

              kotno: responseJson.GetPrintArr[i].kotno
            })
          }
          const obj = [...new Map(arryjs.map(item => [JSON.stringify(item), item])).values()];
          console.log(obj, "obj");

          setPaymentAmountDataKOT(obj)
          //  setCancelKotNumber(obj)
          //  printing(text, responseJson.GetPrintArr[0].ipaddress);
          console.log("HIII");
          let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
          var item = [
            {
              link: link,
              //  tokenCreate: tokenCreate,
            },
          ];

          handlePrint()
          dispatch(fetchTables(item));
          getClear()
        } else {
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          dispatch(setActivePage(pageComponents.BILLING));
          dispatch(cartItems())

        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getClear = () => {
    //POST json
    let dataToSend = {
      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),
      terminal: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
            arr.push({
              clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
            });
          }

          dispatch({ type: "discountPrecentage", payload: '' })
          //  dispatch(setActivePage(pageComponents.VIEWTABLES));
          console.log("HIII");
        } else {
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  ///////// KOT ///////////////

  console.log(cardNumber, nameonCard);
  const setExactAmountFunc = (billamount) => {
    // await 
    console.log(billamount, "cashbillamount");
    setNumericValue(billamount)
    alertPaymentBalance(0, billamount)
  }
  const alertPaymentBalance = (i, amount) => {
    console.log(activeBillId.paymentMode, i, amount, "activeBillId.paymentMode");
    if (activeBillId.paymentMode == 'CASH') {
      confirmAlert({
        customUI: ({ onClose }) => {

          return (
            <div className="alert" style={{ background: '#2d5f2e', color: '#ffe77b' }}>
              <h1 className="alert__title"> {`Balance : ${(i).toFixed(2)}`}</h1>
              {/* <p className="alert__body">You want to delete this file?</p> */}
              <button
                // onClick={printStatement(i,amount)}
                onClick={() => {
                  printStatement(i.toFixed(2), amount);
                  onClose();
                }}
                className="alert__btn alert__btn--no" style={{
                  padding: '10px',
                  color: '#2d5f2e',
                  background: '#ffe77b',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  width: '100%'
                }}>{'OK'}</button>
              {/* <button
            onClick={() => {
              this.handleClickDelete();
              onClose();
            }}
            className="alert__btn alert__btn--yes"
          >
            Yes, Delete it!
          </button> */}
            </div>
          );
        },
        // onKeypressEscape:''
        // keyCodeForClose:'13',
        closeOnClickOutside: false,
        closeOnEscape: false,
        overlayClassName: "overlayClassNamePopup",
      });
      //   confirmAlert({
      //     title: `Balance : ${i}`,
      //     message: "",
      //     buttons: [
      //       {
      //         label: 'OK',
      //         onClick: () => printStatement(i,amount)
      //       },

      //       {
      //         label: 'No',
      //         onClick: () =>  days()
      //       }
      //     ],

      //   overlayClassName: "overlayClassNamePopup" ,
      // closeOnEscape: false,
      // closeOnClickOutside: false,
      //   });
    } else if (activeBillId.paymentMode == 'CARD') {
      confirmAlert({
        customUI: ({ onClose }) => {

          return (
            <div className="alert" style={{ background: '#2d5f2e', color: '#ffe77b' }}>
              <h1 className="alert__title"> {'Balance : 0.00'}</h1>
              {/* <p className="alert__body">You want to delete this file?</p> */}
              <button
                // onClick={printStatement(i,amount)}
                onClick={() => {
                  printStatement(i, amount);
                  onClose();
                }}
                className="alert__btn alert__btn--no" style={{
                  padding: '10px',
                  color: '#2d5f2e',
                  background: '#ffe77b',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  width: '100%'
                }}>{'OK'}</button>
              {/* <button
            onClick={() => {
              this.handleClickDelete();
              onClose();
            }}
            className="alert__btn alert__btn--yes"
          >
            Yes, Delete it!
          </button> */}
            </div>
          );
        },
        // onKeypressEscape:''
        // keyCodeForClose:'13',
        closeOnClickOutside: false,
        closeOnEscape: false,
        overlayClassName: "overlayClassNamePopup",
      });

      // confirmAlert({
      //   title: `Balance : 0.00`,
      //   message: "",
      //   buttons: [
      //     {
      //       label: 'OK',
      //       onClick: () => printStatement(i, amount)
      //     },
      //     {
      //       label: 'No',
      //       onClick: () =>  days()
      //     }
      //   ],

      //   closeOnEscape: false,
      //   closeOnClickOutside: false,
      // });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {

          return (
            <div className="alert" style={{ background: '#2d5f2e', color: '#ffe77b' }}>
              <h1 className="alert__title"> {'Balance : 0.00'}</h1>
              {/* <p className="alert__body">You want to delete this file?</p> */}
              <button
                // onClick={printStatement(i,amount)}
                onClick={() => {
                  printStatement(i, amount);
                  onClose();
                }}
                className="alert__btn alert__btn--no" style={{
                  padding: '10px',
                  color: '#2d5f2e',
                  background: '#ffe77b',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  width: '100%'
                }}>{'OK'}</button>
              {/* <button
            onClick={() => {
              this.handleClickDelete();
              onClose();
            }}
            className="alert__btn alert__btn--yes"
          >
            Yes, Delete it!
          </button> */}
            </div>
          );
        },
        // onKeypressEscape:''
        // keyCodeForClose:'13',
        closeOnClickOutside: false,
        closeOnEscape: false,
        overlayClassName: "overlayClassNamePopup",
      });
      // confirmAlert({
      //   title: `Balance : 0.00`,
      //   message: "",
      //   buttons: [
      //     {
      //       label: 'OK',
      //       onClick: () => printStatement(i, amount)
      //     },
      //     {
      //       label: 'No',
      //       onClick: () =>  days()
      //     }
      //   ],

      //   closeOnEscape: false,
      //   closeOnClickOutside: false,
      // });
    }
    // if(datetoint > 0){

    // }

  }

  const printStatement = async (i, amount) => {
    // await goToPayment()
    // await handleClickLastReceipt();
    // await getDataUsingPost();

    console.log(i, amount, "i,amount");
    if (localStorage.getItem('tableno') != "CT") {

      await goToKot(i, amount)
    } else {

      console.log(ewalletType, "ewalletType");
      console.log(numericValue, totalamount, Box, "numericValue1");
      console.log("Please check your email id or password");
      getClear()
      var discount = "";
      var payno = '';
      var nameonCardData = '';
      var balanceData = '';
      var cardno = '';
      var customerId = '';
      var balance22 = 0;
      var totalPaid = '';
      if (localStorage.getItem("Customerid") != null && localStorage.getItem("Customerid") != undefined) {
        customerId = localStorage.getItem("Customerid")
        console.log(customerId, "Customerid");
      } else {
        customerId = 1
        console.log(customerId, "Customerid")
      }
      if (discountPrecentage != null && discountPrecentage != undefined) {
        discount = discountPrecentage
      } else {
        discount = '0.00'
      }

      if (activeBillId.paymentMode == 'CASH') {
        cardno = '';
        payno = '1';
        balanceData = Box
        nameonCardData = '';
        totalPaid = numericValue
        balance22 = parseInt(i);
      } else if (activeBillId.paymentMode == 'CARD') {
        payno = '2';
        balanceData = totalamount.toFixed(2);

        balance22 = "0.00";
        cardno = cardNumber;
        nameonCardData = nameonCard;
      } else if (activeBillId.paymentMode == 'EWALLET') {
        payno = '4'
        nameonCardData = localStorage.getItem("ewalletType");
        cardno = '';
        balance22 = "0.00";
      } else if (activeBillId.paymentMode == 'ONLINE_AC') {
        payno = '5';
        balanceData = totalamount.toFixed(2);

        totalPaid = totalamount.toFixed(2)
        balance22 = "0.00";
        cardno = cardNumber;
        nameonCardData = localStorage.getItem('takeawayOnlinePayment');
      }
      else if (activeBillId.paymentMode == 'ONLINE_CARD') {
        payno = '7';
        balanceData = totalamount.toFixed(2);

        totalPaid = totalamount.toFixed(2)
        balance22 = "0.00";
        cardno = cardNumber;
        nameonCardData = localStorage.getItem('takeawayOnlinePayment');
      }
      else if (activeBillId.paymentMode == 'ONLINE_COD') {
        payno = '6';
        balanceData = totalamount.toFixed(2);

        totalPaid = totalamount.toFixed(2)
        balance22 = "0.00";
        cardno = cardNumber;
        nameonCardData = localStorage.getItem('takeawayOnlinePayment');
      }

      console.log(balance, i, Box, 'balance');
      localStorage.setItem('payno', payno)
      localStorage.setItem('Box', amount)
      localStorage.setItem('balance', balance22)
      localStorage.setItem('nameonCard', nameonCardData)
      localStorage.setItem('totalamount', totalamount)
      localStorage.setItem('totalPaid', amount)

      localStorage.setItem('cardNumber', cardno)
      localStorage.setItem('discount', discount)

      localStorage.setItem('custId', customerId)
      goToPayment()

    }
  }

  console.log(paymentAmountData, "paymentAmountData");
  /////////////////////////

  const handleAfterPrintKOT = useCallback(() => {
    goToPayment()
  }, []);

  const handleBeforePrintKOT = useCallback(() => {
  }, []);

  const handleOnBeforeGetContentKOT = useCallback(() => {
    setLoadingKOT(true);
    setTextKOT("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolveKOT.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoadingKOT(false);
        setTextKOT("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoadingKOT, setTextKOT]);

  const reactToPrintContentKOT = useCallback(() => {
    return kotRef.current;
  }, [kotRef.current]);

  const handlePrintKOT = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContentKOT,
    onBeforePrint: handleBeforePrintKOT,

    content: reactToPrintContentKOT,
    onAfterPrint: handleAfterPrintKOT,
    removeAfterPrint: true
  });
  useEffect(() => {
    if (
      textKOT === "New, Updated Text!" &&
      typeof onBeforeGetContentResolveKOT.current === "function"
    ) {
      onBeforeGetContentResolveKOT.current();
    }
  }, [onBeforeGetContentResolveKOT.current, textKOT]);


  //////////////////////


  const getclient = () => {
    //POST json
    let dataToSend = {
      token: localStorage.getItem('token'),


    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.clientdtlArr[0].discounts);
        if (responseJson.clientdtlArr.clientdtl != 2) {
          const arr = responseJson.clientdtlArr[0].discounts.split(",", 5)
          setdiscat(arr)


          console.log("HIII");
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };
  const discount = (value) => {
    setstate(value)
    var percentage = (value / 100) * roundamnt2
    var fix = roundamnt2 - percentage + roundamnt3
    var subTotalfix = roundamnt2 - percentage
    var round = Math.round(fix)
    totalbillamount(fix)
    // props.discount(round)


    dispatch({ type: "discountPrecentage", payload: percentage })
    dispatch({ type: "totalamount", payload: round })
    dispatch({ type: "subtotalamount", payload: subTotalfix })

    dispatch({ type: "dueamount", payload: 1 })
  }

  const discountByValuePopUp = () => {
    setModelDiscountPopUp(true)

    setstate("DiscountByValue")
  }

  const CloseEditQuantity = () => setModelDiscountPopUp(false);
  const goToKot = (i, amount) => {
    let dataToSend = {
      token: localStorage.getItem('token'),

      tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        console.log(responseJson, "kot");

        if (responseJson.PrintKotArr[0].PrintKot != 2) {
          setKotData(responseJson)

          console.log(ewalletType, "ewalletType");
          console.log(Box, "numericValue2");
          let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
          var item = [
            {
              link: link,
              tokenCreate: localStorage.getItem('token'),
            },
          ];
          var discount = "";
          var payno = '';
          var nameonCardData = '';
          var balanceData = '';
          var cardno = '';
          var customerId = '';
          var balance22 = 0;

          var totalPaid = '';
          console.log(balance, balanceAmt, i, 'balance2');
          console.log(numericValue, "numericValue3");
          if (discountPrecentage != null && discountPrecentage != undefined) {
            discount = discountPrecentage
          } else {
            discount = '0.00'
          }
          if (localStorage.getItem("Customerid") != null && localStorage.getItem("Customerid") != undefined) {
            customerId = localStorage.getItem("Customerid")
            console.log(customerId, "Customerid");
          } else {
            customerId = 1
            console.log(customerId, "Customerid")
          }
          if (activeBillId.paymentMode == 'CASH') {
            cardno = '';
            payno = '1';
            balanceData = amount
            // balanceData =Box
            nameonCardData = '';
            balance22 = i;
            totalPaid = amount
          } else if (activeBillId.paymentMode == 'CARD') {
            payno = '2';
            balanceData = totalamount.toFixed(2);

            cardno = cardNumber;
            nameonCardData = nameonCard;
            balance22 = "0.00";
          } else if (activeBillId.paymentMode == 'EWALLET') {
            payno = '4'
            nameonCardData = localStorage.getItem("ewalletType");
            balanceData = totalamount.toFixed(2);
            cardno = '';
            balance22 = "0.00";
          } else if (activeBillId.paymentMode == 'ONLINE_AC') {
            payno = '5';
            balanceData = totalamount.toFixed(2);

            totalPaid = totalamount.toFixed(2)
            balance22 = "0.00";
            cardno = cardNumber;
            nameonCardData = localStorage.getItem('takeawayOnlinePayment');
          }
          else if (activeBillId.paymentMode == 'ONLINE_CARD') {
            payno = '7';
            balanceData = totalamount.toFixed(2);

            totalPaid = totalamount.toFixed(2)
            balance22 = "0.00";
            cardno = cardNumber;
            nameonCardData = localStorage.getItem('takeawayOnlinePayment');
          }
          else if (activeBillId.paymentMode == 'ONLINE_COD') {
            payno = '6';
            balanceData = totalamount.toFixed(2);

            totalPaid = totalamount.toFixed(2)
            balance22 = "0.00";
            cardno = cardNumber;
            nameonCardData = localStorage.getItem('takeawayOnlinePayment');
          }
          console.log(balance, balanceAmt, balanceData, 'balance');

          localStorage.setItem('totalPaid', totalPaid)
          console.log(balance, i, 'balance');
          localStorage.setItem('payno', payno)
          localStorage.setItem('Box', balanceData)
          localStorage.setItem('balance', balance22)
          localStorage.setItem('nameonCard', nameonCardData)
          localStorage.setItem('totalamount', totalamount)
          localStorage.setItem('cardNumber', cardno)
          localStorage.setItem('discount', discount)
          dispatch(fetchTables(item));
          localStorage.setItem('custId', customerId)
          
          handlePrintKOT();
          getClear();
        }
        else {

          console.log(numericValue, "numericValue4");
          console.log("Please check your email id or password");
          getClear()
          var discount = "";
          var payno = '';
          var nameonCardData = '';
          var balanceData = '';
          var cardno = '';
          var customerId = '';
          var totalPaid = '';
          var balance22 = 0;
          if (localStorage.getItem("Customerid") != null && localStorage.getItem("Customerid") != undefined) {
            customerId = localStorage.getItem("Customerid")
            console.log(customerId, "Customerid");
          } else {
            customerId = 1
            console.log(customerId, "Customerid")
          }
          if (discountPrecentage != null && discountPrecentage != undefined) {
            discount = discountPrecentage
          } else {
            discount = '0.00'
          }

          if (activeBillId.paymentMode == 'CASH') {
            cardno = '';
            payno = '1';
            balanceData = amount
            nameonCardData = '';
            balance22 = i;
            totalPaid = amount
          } else if (activeBillId.paymentMode == 'CARD') {
            payno = '2';
            balanceData = totalamount.toFixed(2);

            balance22 = "0.00";
            cardno = cardNumber;
            nameonCardData = nameonCard;
          } else if (activeBillId.paymentMode == 'EWALLET') {
            payno = '4'
            nameonCardData = localStorage.getItem("ewalletType");
            cardno = '';
            balance22 = "0.00";
          } else if (activeBillId.paymentMode == 'ONLINE_AC') {
            payno = '5';
            balanceData = totalamount.toFixed(2);

            balance22 = "0.00";
            cardno = cardNumber;
            totalPaid = totalamount.toFixed(2)
            nameonCardData = localStorage.getItem('takeawayOnlinePayment');
          }
          else if (activeBillId.paymentMode == 'ONLINE_CARD') {
            payno = '7';
            balanceData = totalamount.toFixed(2);

            totalPaid = totalamount.toFixed(2)
            balance22 = "0.00";
            cardno = cardNumber;
            nameonCardData = localStorage.getItem('takeawayOnlinePayment');
          }
          else if (activeBillId.paymentMode == 'ONLINE_COD') {
            payno = '6';
            balanceData = totalamount.toFixed(2);

            totalPaid = totalamount.toFixed(2)
            balance22 = "0.00";
            cardno = cardNumber;
            nameonCardData = localStorage.getItem('takeawayOnlinePayment');
          }

          localStorage.setItem('totalPaid', totalPaid)
          console.log(balance, i, 'balance');
          localStorage.setItem('payno', payno)
          localStorage.setItem('Box', balanceData)
          localStorage.setItem('balance', balance22)
          localStorage.setItem('nameonCard', nameonCardData)
          localStorage.setItem('totalamount', totalamount)
          localStorage.setItem('cardNumber', cardno)
          localStorage.setItem('discount', discount)

          localStorage.setItem('custId', customerId)
          goToPayment()



          // getClear();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // https://amepos.io/amepos-proassist
  const { inputRef } = useBarcode({
    value: billNoScan,
    options: {
      format: "CODE39",
      displayValue: true,
      // background: '#ffc0cb',

    }
  });

  // const {QRCodeRef} = QRCode.toDataURL({
  //   value: 'https://amepos.io/amepos-proassist',
  // })

  // console.log(QRCodeRef,"QRCodeRefQRCodeRef");

  // const {inputRef} = Barcode({
  //   value:'https://amepos.io/amepos-proassist',
  //   Option:{

  //       width: 2,
  //       height: 100,
  //       format: "CODE128",
  //       displayValue: true,
  //       fontOptions: "",
  //       font: "monospace",
  //       textAlign: "center",
  //       textPosition: "bottom",
  //       textMargin: 2,
  //       fontSize: 20,
  //       background: "green",
  //       lineColor: "#000000",
  //       margin: 10,
  //       marginTop: undefined,
  //       marginBottom: undefined,
  //       marginLeft: undefined,
  //       marginRight: undefined

  //   }
  // })

  console.log(numericValue, "cash");
  return (
    <div class="row flex-grow-1">
      <div class="col-md-9 d-none d-md-flex flex-column">
        {/*  <TextBoxAmount/>  {activeBillId?.paymentMode == */}
        <div class="row flex-grow-1">
          {/* <img  src={`data:image/png;base64,${LogoAme}`} /> */}
          {/* paymentMode.CARD?<CardNumberForm />:<TextBoxAmount/>} */}
          {/* {payMode == 'CARD'? <CardNumberForm />   : <TextBoxAmount dueAmount={dueAmount} balanceAmt={balanceAmt} totalAmount={totalAmount}/>} */}

          {activeBillId?.paymentMode === 'CARD' ? <CardNumberForm setNameonCard={setNameonCard} setCardNumber={setCardNumber} /> : <TextBoxAmount dueAmount={numericValue} roundamnt={roundamnt} balanceAmt={balanceAmt} dueTotalAmount={dueTotalAmount} />}
          {/* numericValue */}
        </div>
        <div class="row flex-grow-1">
          <div class="col-md-3 d-none d-md-flex flex-column">

            {discat.map((item, index) => (
              <div class="row flex-grow-1 mb-1">

                <div class="col-md-12">
                  {item == "0" ?
                    <button class="btn btn-block Btn__lg  btn-block-height" type="submit"
                      style={{

                        backgroundColor: state === parseInt(item) ? "green" : "#646464",
                        color: 'white'
                      }}
                    //    onClick={() => {
                    //    discount(parseInt(item));
                    //  }}
                    ></button>
                    :
                    <button class="btn btn-block Btn__lg  btn-block-height" type="submit"
                      style={{

                        backgroundColor: state === parseInt(item) ? "green" : "#646464",
                        color: 'white'
                      }}
                      onClick={() => {
                        discount(parseInt(item));
                      }}>{item}%{"\n"}Discount</button>

                  }


                </div>

              </div>
            ))}
            <div class="row flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn btn-block Btn__lg btn-block-height"
                  style={{

                    backgroundColor: state == "DiscountByValue" ? "green" : "#646464",
                    color: 'white'
                  }}
                  type="submit" onClick={() => discountByValuePopUp()}>Discount By Value</button>
              </div>
            </div>

          </div>
          <div class="col-md-6  d-none d-md-flex flex-column">


            <div class="row flex-grow-1">
              {localStorage.getItem('takenoMode')?.length > 0 && localStorage.getItem('takenoMode') == '0' ?
                <div class="col-md-12  d-none d-md-flex flex-column">
                  <div class="row flex-grow-1 ">
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("1")}>1</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("2")}>2</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("3")}>3</button>
                    </div>
                  </div>
                  <div class="row  flex-grow-1 ">
                    <div class="col-md-4  p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("4")}>4</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("5")}>5</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("6")}>6</button>
                    </div>
                  </div>
                  <div class="row  flex-grow-1">
                    <div class="col-md-4  p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("7")}>7</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("8")}>8</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("9")}>9</button>
                    </div>
                  </div>
                  <div class="row  flex-grow-1 ">
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => deleteNumber()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" onClick={() => addNumber("0")}>0</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" >OK</button>
                    </div>
                  </div>
                 
                  <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
                      <div class="print-container" ref={kotRef} style={{ margin: "0", padding: "0" }}>
                        <div class="ticket " >
                          {sliceItem == "T" ?
                            <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
                            :
                            (localStorage.getItem("tableno") == 'CT' ?
                              <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>CounterSale No. {localStorage.getItem('tableno')}</p>

                              :
                              <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Table No. {localStorage.getItem('tableno')}</p>

                            )
                          }


                          <table className="centered" style={{ borderCollapse: 'collapse' }}>

                            <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                              {
                                kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length > 0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                                kotData?.PrintKotArr[0].ItemsArray.map((item, index) =>

                                (
                                  <tr  >
                                    <td className='description' style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                                    <td className="quantity  " style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                    {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                         <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}

                                  </tr>
                                ))

                              }

                            </tbody>
                          </table>
                          <p className="centered" style={{ fontSize: '10px', borderTop: '1px dotted black', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

                          <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{"<<< AME>>>"}</p>


                        </div>
                      </div>
                    </div>




                  <div class="row  flex-grow-1 " >
                    <div class="col-md-12 p-1">

                      {/* {localStorage.getItem('takenoMode')?.length && localStorage.getItem('takenoMode') == '0' ? */}
                      {
                        localStorage.getItem('takenoMode')?.length > 0 && localStorage.getItem('takenoMode') == '0' ?
                          <div class="ml-1 flex-fill" style={{ height: '100%' }}>
                            <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={() => setExactAmountFunc(billamount)}>

                              Exact Amount
                            </button>
                          </div>
                          : <div class="ml-1 flex-fill" style={{ height: '100%' }}>
                            {/* onClick={()=>alertPaymentBalance(0,billamount)} */}
                            <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen">

                              Exact Amount
                            </button>
                          </div>

                      }
                      {/* <div class="ml-1 flex-fill" style={{height:'100%'}}>
                      <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={()=>alertPaymentBalance(0,billamount)}>

                        Exact Amount
                      </button>
                    </div> */}
                      {/* 
                  {dueamount < 1 || activeBillId.paymentMode == 'CARD' || activeBillId.paymentMode == 'EWALLET' ?
                    <div class="ml-1 flex-fill">
                      <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={alertPaymentBalance}>

                        Exact Amount
                      </button>
                    </div>

                      <ReactToPrint


                     onBeforePrint={()=>handleClickLastReceipt()}  
                      pageStyle={pageStyle}
                      onBeforePrint={()=>amountPayment()}
                      trigger={() =>

                        <div class="ml-1 flex-fill">
                        <button  type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" >Exact Amount </button>
                        </div>
                        }
                      content={()=> draftRef.current}
                       />
                    <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={() => amountPayment()}>Exact Amount</button>

                    : <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height " style={{ backgroundColor: '#f33f3f', color: 'white' }} >Make Exact Amount</button>
                  } */}
                    </div>
                    {/* #e0eb97 */}
                  </div>



                </div>
                :
                <div class="col-md-12  d-none d-md-flex flex-column">
                  <div class="row flex-grow-1 ">
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">1</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">2</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">3</button>
                    </div>
                  </div>
                  <div class="row  flex-grow-1 ">
                    <div class="col-md-4  p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">4</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">5</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">6</button>
                    </div>
                  </div>
                  <div class="row  flex-grow-1">
                    <div class="col-md-4  p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">7</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">8</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">9</button>
                    </div>
                  </div>
                  <div class="row  flex-grow-1 ">
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" ><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height">0</button>
                    </div>
                    <div class="col-md-4 p-1">
                      <button type="button" class="btn btn-infogreen Btn__lg btn-block py-3 btn-block-height" >OK</button>
                    </div>
                  </div>
                 





                  <div class="row  flex-grow-1 " >
                    <div class="col-md-12 p-1">

                      {/* {localStorage.getItem('takenoMode')?.length && localStorage.getItem('takenoMode') == '0' ? */}
                      {
                        localStorage.getItem('takenoMode')?.length > 0 && localStorage.getItem('takenoMode') == '0' ?
                          <div class="ml-1 flex-fill" style={{ height: '100%' }}>
                            <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={() => setExactAmountFunc(billamount)}>

                              Exact Amount
                            </button>
                          </div>
                          : <div class="ml-1 flex-fill" style={{ height: '100%' }}>
                            {/* onClick={()=>alertPaymentBalance(0,billamount)} */}
                            <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen">

                              Exact Amount
                            </button>
                          </div>

                      }
                      {/* <div class="ml-1 flex-fill" style={{height:'100%'}}>
                        <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={()=>alertPaymentBalance(0,billamount)}>

                          Exact Amount
                        </button>
                      </div> */}
                      {/* 
                    {dueamount < 1 || activeBillId.paymentMode == 'CARD' || activeBillId.paymentMode == 'EWALLET' ?
                      <div class="ml-1 flex-fill">
                        <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={alertPaymentBalance}>

                          Exact Amount
                        </button>
                      </div>

                        <ReactToPrint


                       onBeforePrint={()=>handleClickLastReceipt()}  
                        pageStyle={pageStyle}
                        onBeforePrint={()=>amountPayment()}
                        trigger={() =>

                          <div class="ml-1 flex-fill">
                          <button  type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" >Exact Amount </button>
                          </div>
                          }
                        content={()=> draftRef.current}
                         />
                      <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height btn-florogreen" onClick={() => amountPayment()}>Exact Amount</button>

                      : <button type="button" class="btn  Btn__lg Btn__lg btn-block py-2 btn-block-height " style={{ backgroundColor: '#f33f3f', color: 'white' }} >Make Exact Amount</button>
                    } */}
                    </div>
                    {/* #e0eb97 */}
                  </div>



                </div>
              }

            </div>




          </div>
          {/* <div class="col-md-3  d-none d-md-flex flex-column">
            {denominations.map((i)=>(
              <div class="row flex-grow-1 mb-1">
              <div class="col-md-12">
                <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={() => addNumberonSelected(parseInt(i))}>{companyDetails?.currency.split(",",1)}  {i} </button>
              </div>
            </div>
            ))}
            
            </div> */}
          {denominations != null && denominations != undefined && denominations != "" && denominations.length > 0 && localStorage.getItem('takenoMode')?.length > 0 && localStorage.getItem('takenoMode') == '0'
            ?

            <div class="col-md-3  d-none d-md-flex flex-column">

              <div class="row flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(10)}>{companyDetails?.currency.split(",", 1)}  10 </button>
                </div>
              </div>
              <div class="row flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(20)} >{companyDetails?.currency.split(",", 1)} 20</button>
                </div>
              </div>
              <div class="row  flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(50)} >{companyDetails?.currency.split(",", 1)} 50</button>
                </div>
              </div>
              <div class="row   flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block  Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(100)} >{companyDetails?.currency.split(",", 1)} 100</button>
                </div>
              </div>
              <div class="row   flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(200)} >{companyDetails?.currency.split(",", 1)} 200</button>
                </div>
              </div>
              <div class="row  flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(500)} >{companyDetails?.currency.split(",", 1)} 500</button>
                </div>
              </div>
              <div class="row  flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" onClick={(e) => addNumberonSelected(2000)} >{companyDetails?.currency.split(",", 1)} 2000</button>
                </div>
              </div>
            </div>
            :
            // ""
            <div class="col-md-3  d-none d-md-flex flex-column">

              <div class="row flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit" >{companyDetails?.currency.split(",", 1)}  10 </button>
                </div>
              </div>
              <div class="row flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit"  >{companyDetails?.currency.split(",", 1)} 20</button>
                </div>
              </div>
              <div class="row  flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block Btn__lg btn-secondary btn-block-height" type="submit"  >{companyDetails?.currency.split(",", 1)} 50</button>
                </div>
              </div>
              <div class="row   flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn btn-block  Btn__lg btn-secondary btn-block-height" type="submit"  >{companyDetails?.currency.split(",", 1)} 100</button>
                </div>
              </div>
              <div class="row   flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit"  >{companyDetails?.currency.split(",", 1)} 200</button>
                </div>
              </div>
              <div class="row  flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" >{companyDetails?.currency.split(",", 1)} 500</button>
                </div>
              </div>
              <div class="row  flex-grow-1 mb-1">
                <div class="col-md-12">
                  <button class="btn  btn-block Btn__lg btn-secondary btn-block-height" type="submit" >{companyDetails?.currency.split(",", 1)} 2000</button>
                </div>
              </div>
            </div>



          }
<div style={{ overflow: 'hidden', height: '0', width: '0' }} >
<div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
    <div class="ticket font-face-gm" >

      {/* backgroundImage: `url(data:image/png;base64,${i.image})`, */}
      {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
        <div style={{ textAlign: 'center', justifyContent: 'center', textAlign: 'center', alignItems: 'center', margin: '0px' }}>
          <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '80px', width: '120px' }} />
        </div>
      }
      <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam}</p>
      <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1} {companyDetails?.addr2}</p>
      <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
      <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno}</p>
      {
        showHeaderSettings == true ?
           
          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TAX INVOICE</p>
          :
          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>BILL RECEIPT</p>
         
      }
      <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Bill No :  {localStorage.getItem('TerminalName')} - {paymentAmountData?.GetPrintArr?.length > 0 && paymentAmountData?.GetPrintArr[0].billno}</p>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {sliceItem == "T" ?
          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
          :
          (localStorage.getItem("tableno") == 'CT' ?
            // ""

            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
            // <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}></p>

            :
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Table No. {localStorage.getItem('tableno')}</p>

          )
        }
        <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Date :{datetime != null && datetime != undefined ? datetime : ""}</p>
      </div>
      {localStorage.getItem("tableno") == 'CT' ?
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Customer : {paymentAmountData?.GetPrintArr.length > 0 && paymentAmountData?.GetPrintArr[0].customer}</p>

          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
        </div>
        :
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>

          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
        </div>

      }
      {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>

    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
  </div> */}

      {
        localStorage.getItem("tableno") == 'CT' ?
          ""
          :
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Customer : {paymentAmountData?.GetPrintArr.length > 0 && paymentAmountData?.GetPrintArr[0].customer}</p>
          </div>
      }
      {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Customer : {paymentAmountData?.GetPrintArr.length > 0 && paymentAmountData?.GetPrintArr[0].customer}</p>
  </div> */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>

      </div>
      {
        tableHeaderSettings == '1' && billBodyContent == true ?
          <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

              {/* </tr> */}

            </div>
            <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
              {
                paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                paymentAmountData?.GetPrintArr?.map((item, index) =>

                (
                  <div>

                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                    </div>
                  </div>
                ))

              }

            </div>
          </div>
          :

          tableHeaderSettings == '1' && billBodyContent == false ?
            <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                  <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                </tr>

              </thead>
              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                {
                  paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                  paymentAmountData?.GetPrintArr?.map((item, index) =>

                  (
                    <tr  >
                      <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                      {/* {item?.TAX1 != '0.00' ? 
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
         : (
          item?.TAX2 != '0.00' ? 
          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
         :
         <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
         
         )
        } */}
                      <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                    </tr>
                  ))

                }

              </tbody>
            </table>
            :

            tableHeaderSettings == '2' && billBodyContent == true ?
              <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                  {/* </tr> */}

                </div>
                <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                  {
                    paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                    paymentAmountData?.GetPrintArr?.map((item, index) =>

                    (
                      <div>

                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                        </div>
                      </div>
                    ))

                  }

                </div>
              </div>
              :

              tableHeaderSettings == '2' && billBodyContent == false ?
                <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                      {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                      <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                    </tr>

                  </thead>
                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    {
                      paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                      paymentAmountData?.GetPrintArr?.map((item, index) =>

                      (
                        <tr  >
                          <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity).toFixed(2)}</td>

                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                          {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                          <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                        </tr>
                      ))

                    }

                  </tbody>
                </table>
                :
                tableHeaderSettings == '3' && billBodyContent == true ?
                <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width:  '25%' }}>PRODUCT</div>
                    <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', }}>QTY</div>
                    <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40%', textAlign: 'end' }}>TOTAL</div>


                  </div>
                  <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                    {
                      paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                      paymentAmountData?.GetPrintArr?.map((item, index) =>

                      (
                        <div>

                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}> </div>
                            <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                            <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '40%', wordBreak: 'break-all' }}>{item?.amount}</div>
                          </div>
                        </div>
                      ))

                    }

                  </div>
                </div>

                :
                tableHeaderSettings == '3' && billBodyContent == false ?
                  <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                        <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                        {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th> */}
                        {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                        <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                      </tr>

                    </thead>
                    <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      {
                        paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                        paymentAmountData?.GetPrintArr?.map((item, index) =>

                        (
                          <tr  >
                            <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                            {/* <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td> */}
                            {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                            <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.amount}</td>

                          </tr>
                        ))

                      }

                    </tbody>
                  </table>
                  :
                  tableHeaderSettings == '4' && billBodyContent == true ?

                    <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', }}>QTY</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px' }}>RATE</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>SST</div>

                        <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', textAlign: 'end' }}>TOTAL</div>

                        {/* </tr> */}

                      </div>
                      <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                        {
                          paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                          paymentAmountData?.GetPrintArr?.map((item, index) =>

                          (
                            <div>

                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                                <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px', wordBreak: 'break-all' }}>{item?.unitprice}</div>
                                {
                                  item?.TAX1 != '0.00' ?
                                    <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX1}</div>
                                    : (
                                      item?.TAX2 != '0.00' ?
                                        <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX2}</div>
                                        :
                                        item?.TAX3 != '0.00' ?
                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX3}</div>
                                          :
                                          '0.00'
                                    )
                                }
                                <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '50px', wordBreak: 'break-all' }}>{item?.amount}</div>
                              </div>
                            </div>
                          ))

                        }

                      </div>
                    </div>

                    :
                    tableHeaderSettings == '4' && billBodyContent == false ?
                      <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <thead>
                          <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            <th className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th>

                            <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                          </tr>

                        </thead>
                        <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          {
                            paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                            paymentAmountData?.GetPrintArr?.map((item, index) =>

                            (
                              <tr  >
                                <td className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', wordBreak: 'break-all' }}>{item?.ProductName}</td>
                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                {item?.TAX1 != '0.00' ?
                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
                                  : (
                                    item?.TAX2 != '0.00' ?
                                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
                                      :
                                      item?.TAX3 != '0.00' ?
                                        <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
                                        :
                                        '0.00'
                                  )
                                }
                                <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                              </tr>
                            ))

                          }

                        </tbody>
                      </table>

                      :
                      ''
      }

      <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Total Items :{paymentAmountData?.GetPrintArr?.length > 0 ? (paymentAmountData?.GetPrintArr?.length) : 0}</p>

        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>Total Qty:{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].qty ? parseFloat(cartItemsList[cartItemsList?.length - 1].qty) : 0}</p>

      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Sub Total :</p>

        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].subTotal ? cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2) : "0.00"}</p>

      </div>
      <>
        {showTaxDetails == true && paymentAmountData?.GetPrintArr != undefined && paymentAmountData?.GetPrintArr != null && paymentAmountData?.GetPrintArr?.length > 0 &&
          paymentAmountData?.GetPrintArr[0].TAX1pergrp != null && paymentAmountData?.GetPrintArr[0].TAX1pergrp != undefined && paymentAmountData?.GetPrintArr[0].TAX1pergrp?.map((i) => (
            i.tax1name === "GST" ?
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`CGST(${parseInt(i.tax1per) / 2}%)`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? (parseFloat(i.taxamt) / 2).toFixed(2)  : "0.00"}</p>

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`SGST(${parseInt(i.tax1per) / 2}%)`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? (parseFloat(i.taxamt) / 2).toFixed(2)  : "0.00"}</p>

                </div>
              </>
              :
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                  {`${i?.tax1name}${parseInt(i?.tax1per)}%`}
                </p>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                  {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? (parseFloat(i?.taxamt)).toFixed(2)  : "0.00"}</p>

              </div>
          ))}
      </>
      <>
        {showTaxDetails == true && paymentAmountData?.GetPrintArr != undefined && paymentAmountData?.GetPrintArr != null && paymentAmountData?.GetPrintArr?.length > 0 &&
          paymentAmountData?.GetPrintArr[0].TAX2pergrp != null && paymentAmountData?.GetPrintArr[0].TAX2pergrp != undefined && paymentAmountData?.GetPrintArr[0].TAX2pergrp?.map((i) => (
            i.tax2name === "GST" ?
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`CGST(${parseInt(i.tax2per) / 2}%)`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? (parseFloat(i.taxamt) / 2).toFixed(2)  : "0.00"}</p>

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`SGST(${parseInt(i.tax2per) / 2}%)`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? (parseFloat(i.taxamt) / 2).toFixed(2)  : "0.00"}</p>

                </div></>
              :
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                  {`${i?.tax2name}${parseInt(i?.tax2per)}%`}
                </p>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                  {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? (parseFloat(i?.taxamt)).toFixed(2)  : "0.00"}</p>

              </div>
          ))}
      </>
      <>
        {showTaxDetails == true && paymentAmountData?.GetPrintArr != undefined && paymentAmountData?.GetPrintArr != null && paymentAmountData?.GetPrintArr?.length > 0 &&
          paymentAmountData?.GetPrintArr[0].TAX3pergrp != null && paymentAmountData?.GetPrintArr[0].TAX3pergrp != undefined && paymentAmountData?.GetPrintArr[0].TAX3pergrp?.map((i) => (
            i.tax3name === "GST" ?
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`CGST(${parseInt(i.tax3per) / 2})%`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? (parseFloat(i.taxamt) / 2).toFixed(2) : "0.00"}</p>

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`SGST(${parseInt(i.tax3per) / 2})%`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? (parseFloat(i.taxamt) / 2).toFixed(2) : "0.00"}</p>

                </div></>
              :
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                  {`(${i?.tax3name}${parseInt(i?.tax3per)})%`}
                </p>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                  {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? (parseFloat(i?.taxamt)).toFixed(2) : "0.00"}</p>

              </div>
          ))}
      </>
      {/* {paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
    paymentAmountData?.GetPrintArr?.map((item, index) =>
    (item?.TAX1pergrp != null && item?.TAX1pergrp != undefined && item?.TAX1pergrp?.length > 0 && item?.TAX1pergrp?.map((i)=>(
      (i.tax1name==="GST" ?
      <>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
        {`CGST${parseInt(i.tax1per)/2}%`}
        </p>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
          {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt)/2 : "0.00"}</p>

      </div>
       <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

       <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                                   {`SGST${parseInt(i.tax1per)/2}%`}
                                   </p>
                                   <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                                     {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt)/2 : "0.00"}</p>
       
                                 </div></>
      
        :
        <>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
        {`${i?.tax1name}${parseInt(i?.tax1per)}%`}
        </p>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
          {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

      </div>
       </>

      
    )))))


    } */}
      {/* {paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
    paymentAmountData?.GetPrintArr?.map((item, index) =>
    (item?.TAX2pergrp != null && item?.TAX2pergrp != undefined && item?.TAX2pergrp?.length > 0 && item?.TAX2pergrp?.map((i)=>(
      (i.tax1name==="GST" ?
      <>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
        {`CGST${parseInt(i.tax1per)/2}%`}
        </p>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
          {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt)/2 : "0.00"}</p>

      </div>
       <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

       <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                                   {`SGST${parseInt(i.tax1per)/2}%`}
                                   </p>
                                   <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                                     {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt)/2 : "0.00"}</p>
       
                                 </div></>
      
        :
        <>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
        {`${i?.tax1name}${parseInt(i?.tax1per)}%`}
        </p>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
          {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

      </div>
       </>

      
    )))))
  } */}
      {/* // ( item?.TAX1pergrp.length > 0 && item.tax1name==="GST" ?
    
    //   <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

    //     <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{`GST${item.gstper}%`}</p>

    //     <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {item.gst.length > 0 && item.gst ? item.gst : "0.00"}</p>

    //   </div>
    // ) */}


      {/* {paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
    paymentAmountData?.GetPrintArr?.map((item, index) =>
    (item?.TAX3pergrp != null && item?.TAX3pergrp != undefined && item?.TAX3pergrp?.length > 0 && item?.TAX3pergrp?.map((i)=>(
      (i.tax1name==="GST" ?
      <>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
        {`CGST${parseInt(i.tax1per)/2}%`}
        </p>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
          {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt)/2 : "0.00"}</p>

      </div>
       <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

       <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                                   {`SGST${parseInt(i.tax1per)/2}%`}
                                   </p>
                                   <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                                     {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt)/2 : "0.00"}</p>
       
                                 </div></>
      
        :
        <>
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
        {`${i?.tax1name}${parseInt(i?.tax1per)}%`}
        </p>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
          {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

      </div>
       </>

      
    )))))


    } */}

      {/* {paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
    paymentAmountData?.GetPrintArr?.map((item, index) =>
    (item.vatper != null && item.vatper != undefined && item.vatper != '0.00' &&
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{`GST${item.vatper}%`}</p>

        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {item.vat.length > 0 && item.vat ? item.vat : "0.00"}</p>

      </div>
    )

    )}
  {paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
    paymentAmountData?.GetPrintArr?.map((item, index) =>
    (item.cessper != null && item.cessper != undefined && item.cessper != '0.00' &&
      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{`GST${item.cessper}%`}</p>

        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {item.cess.length > 0 && item.cess ? item.cess : "0.00"}</p>

      </div>
    )

    )} */}


      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Discount</p>
        {/* localStorage.getItem('discount') != null && */}
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {localStorage.getItem('discount') == '' ? '0.00' : localStorage.getItem('discount')}</p>

      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
        <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', color: 'black' }}>Total</p>

        <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {totalamount != null && totalamount != undefined ? totalamount.toFixed(2) : 0}</p>

      </div>

      {activeBillId.paymentMode == 'CASH' && showbillFooter == true ?
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse', borderTop: '1px dotted black' }}>
            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASH</p>

            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {numericValue != 0 && numericValue != null && numericValue != undefined ? parseFloat(numericValue).toFixed(2) : '0.00'}</p>

          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CHANGE</p>

            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {localStorage.getItem('balance') != null && localStorage.getItem('balance') != undefined && localStorage.getItem('balance') != 0 ? parseFloat(localStorage.getItem('balance')).toFixed(2)  : '0.00'}</p>

          </div></>
        : activeBillId.paymentMode == 'CARD' && showbillFooter == true ?
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse', borderTop: '1px dotted black' }}>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CARD</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {totalamount != null && totalamount != undefined ? totalamount.toFixed(2) : 0}</p>

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CHANGE</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {localStorage.getItem('balance') != null && localStorage.getItem('balance') != undefined ? localStorage.getItem('balance') : '0.00'}</p>

            </div></>

          : activeBillId.paymentMode == 'EWALLET' && showbillFooter == true ?
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse', borderTop: '1px dotted black' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>EWALLET {ewalletType && ewalletType}</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {totalamount != null && totalamount != undefined ? totalamount.toFixed(2) : 0}</p>

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CHANGE</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {localStorage.getItem('balance') != null && localStorage.getItem('balance') != undefined ? localStorage.getItem('balance') : '0.00'}</p>

              </div></>

            :
            showbillFooter == true && (activeBillId?.paymentMode === paymentMode.ONLINE_AC || activeBillId?.paymentMode === paymentMode.ONLINE_CARD || activeBillId?.paymentMode === paymentMode.ONLINE_COD) ?
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse', borderTop: '1px dotted black' }}>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{activeBillId?.paymentMode} {ewalletType && ewalletType}</p>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {totalamount != null && totalamount != undefined ? totalamount.toFixed(2) : 0}</p>

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CHANGE</p>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {localStorage.getItem('balance') != null && localStorage.getItem('balance') != undefined ? localStorage.getItem('balance') : '0.00'}</p>

                </div></>
              : ''}
      <div style={{ display: 'flex', borderTop: '1px dotted black', borderCollapse: 'collapse', justifyContent: 'center' }}>
        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No's :</p>

        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}> {paymentAmountDataKOT?.length > 0 ? paymentAmountDataKOT.map((i) => `${i.kotno},`) : ""}</p>

      </div>
      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderTop: '1px dotted black' }}>{companyDetails?.remarks1}</p>
      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.remarks2}</p>

      {/* <div style={{width:'10%'}}> */}
      {/* <img ref={inputRef} /> */}
      {/* <Barcode value='https://amepos.io/amepos-proassist'  width='1' height='25' /> */}
      {/* <svg  width="10px"/> */}
      <img ref={inputRef} style={{ height: '20%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />

      {/* <img ref={QRCodeRef} style={{ height: '50%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
   
    <QRCode value="hey" /> */}
      {/* </div> */}



    </div>
  </div>
 
</div>

        </div>
      </div>
      <div class="col-md-3 d-none d-md-flex flex-column">

        {localStorage.getItem('takenoMode').length > 0 && localStorage.getItem('takenoMode') == 0 ?
          activeBillId?.paymentMode === paymentMode.EWALLET ? <EWallet printStatement={printStatement} setEwalletType={setEwalletType} /> : <PaymentType />
          :
          activeBillId?.paymentMode === paymentMode.ONLINE_AC || activeBillId?.paymentMode === paymentMode.ONLINE_CARD || activeBillId?.paymentMode === paymentMode.ONLINE_COD ? <TakeAwayPayment printStatement={printStatement} setEwalletType={setEwalletType} /> : <PaymentType />

        }
        {/* {activeBillId?.paymentMode === paymentMode.EWALLET ? <EWallet printStatement={printStatement} setEwalletType={setEwalletType} /> : <PaymentType />} */}

        {/*<EWallet/>*/}
      </div>
      <DiscountPopUp show={modelDiscountPopUp} handleClose={CloseEditQuantity} animation={true}></DiscountPopUp>
    </div>


  );

}
