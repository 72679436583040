import React, { useEffect, useState } from 'react';
import { updateBill, addBill, addBillItem } from '../../reducers/billsSlice';
import { setActivePage, setActiveTab } from '../../reducers/homePageSlice';
import { fetchTakeways, selectAllTakeaways, updateTakeaway } from '../../reducers/takeAwaySlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents } from '../../utilities/Constants';
import { PageTitle } from '../Commons/PageTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { printBill } from '../../utilities/util';
import { selectAllTables } from '../../reducers/tablesSlice';
import { cartItems } from '../../reducers/CartSlice';
import { TakeAwayModal } from './TakeAwayModal';
import { ewalletModeData, takenoMode } from '../../reducers/ConstantSlice';
export function Takeaway() {
  let content;
  const dispatch = useDispatch()
  const allTakeaways = useSelector(selectAllTables)
  const [popupTakeaway, setPopupTakeaway] = useState(false);
  const [takeAwayData, setTakeAwayData] = useState('');
  const [onlineModeData, setOnlineModeData] = useState([]);

  console.log(allTakeaways, "allTakeaways");
  const takeAwaysStatus = useSelector((state) => state.tables?.statussss);
  const error = useSelector((state) => state.tables?.error)

  const handleCloseTakeawaypopup = () => { setPopupTakeaway(false) }
  const handlePrintDraft = (event, bill) => {
    // event.stopPropagation();
    // event.preventDefault();
    // printBill(bill);

  }








  useEffect(() => {
    if (takeAwaysStatus === 'idle') {
      dispatch(fetchTakeways())
      console.log("fetched");
    }
  }, [takeAwaysStatus, dispatch])



  const takeawayListPopupfunc = (i) => {
    console.log(i, "takeawayData");
    getEwalletModeDetails()
    setTakeAwayData(i)
    setPopupTakeaway(true)
  }

  const getEwalletModeDetails = () => {
    let dataToSend = {
      token: localStorage.getItem('token'),

      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_ewalletlist", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        console.log(responseJson, "kot");

        if (responseJson.ModePayArr[0].ModePay != 2) {
          var arr = [];
          // setEwalletModeData(responseJson)
          for (let i = 0; i < responseJson.ModePayArr.length; i++) {
            if (responseJson.ModePayArr[i].type == 'O') {
              arr.push({
                ModePay: responseJson.ModePayArr[i].ModePay,
                ewallet: responseJson.ModePayArr[i].ewallet,
                image: responseJson.ModePayArr[i].image,
                sno: responseJson.ModePayArr[i].sno,
                type: responseJson.ModePayArr[i].type

              })
            }
          }
          setOnlineModeData(arr)
        }

      })
      .catch((error) => {
        console.error(error);
      });

  }

  const checkcolor = (amount, sts, pay, draftflag, username, takeawaytype) => {
    localStorage.setItem('tableno', username);
    console.log(amount, sts, draftflag, username, "doooooooo");

    dispatch(cartItems());
    // alert(amount+sts)
    if (amount === 0 && sts === "0") {
      takeawayListPopupfunc(username)
      // setModalVisible(true);
    } else if (amount != "0" && sts === "0") {
      localStorage.setItem('takenoMode', pay)
      localStorage.setItem('takeawayOnlinePayment', takeawaytype)
      dispatch(takenoMode(pay));
      dispatch(setActivePage(pageComponents.BILLING));
      // Actions.billings();
    } else if (amount != "0" && sts === "1" && draftflag === "0") {
      localStorage.setItem('takenoMode', pay)
      localStorage.setItem('takeawayOnlinePayment', takeawaytype)
      dispatch(takenoMode(pay));
      dispatch(setActivePage(pageComponents.BILLING));
      // Actions.billings();
    } else if ((draftflag === "1" || draftflag === "2") && amount != "0" && sts === "1") {
      localStorage.setItem('takenoMode', pay)
      localStorage.setItem('takeawayOnlinePayment', takeawaytype)
      dispatch(takenoMode(pay));
      dispatch(setActivePage(pageComponents.BILLING));
      // Actions.billings();
    } else {
      // setModalVisible(true);

      takeawayListPopupfunc(username)
    }
  }

  // ; takeawayListPopupfunc(takeaway)



  if (takeAwaysStatus === 'loading') {
    content = <div className="col-md-2">Loading...</div>
  } else if (takeAwaysStatus === 'succeeded') {
    content = allTakeaways.length != 0 && allTakeaways[0].BusyTable != 2 && allTakeaways.map((takeaway, index) => (
      <>
        <div class="col-md-2 pl-1 pt-1 pr-1" style={{ flex: "0 0 20%", maxWidth: "25.666667%" }}>
          <div class="btn-block-height text-decoration-none" onClick={() => checkcolor(
            takeaway.amount,
            takeaway.printsts,
            takeaway.pay,
            takeaway.draftflag,
            takeaway.username,
            takeaway?.takeawaytype
          )}>

            <div class="  " style={{
              color: 'white', height: '28vh', marginTop: '5px',

              // <div class="card h-100"   style={{color:'white',
              backgroundColor: takeaway?.amount === "0" && takeaway?.printsts === "0"
                ? "#646464"
                : takeaway?.amount != "0" && takeaway?.printsts === "0"
                  ? "#f19100"
                  : takeaway?.amount != "0" &&
                    takeaway?.printsts === "1" &&
                    takeaway?.draftflag === "0"
                    ? "green"
                    : (takeaway?.draftflag === "1" ||
                      takeaway?.draftflag === "2") &&
                      takeaway?.amount != "0" &&
                      takeaway?.printsts === "1"
                      ? "#B7064D"
                      : "#646464",
            }}>
              <div class="d-flex justify-content-between">
                {/* <button class="rounded-circle p-2 shadow printerButton border-0 bg-white m-2"  onClick={(e)=>handlePrintDraft(e,takeaway.id)}><FontAwesomeIcon className={"printerIcon text-orange p-1"} icon={["fas", "print"]}></FontAwesomeIcon></button> */}
                {/* <div class="m-2 h2 display-5">{"T" + (index + 1)}</div>
            </div>
            <div class="d-flex flex-column align-text-bottom ">
              <span class="pl-2 lh-0"> Customer: CASH SALE</span>
              <span class="pl-2 lh-0">No of Items: 1</span>
              <span class="pl-2 lh-0">Order Taken by: AME</span>
              <span class="pl-2 lh-0">Bill Amount: Rs 450</span>
              <span class="pl-2 lh-0">In Time: 11:30 AM (101:06:34 hrs) </span>
      </div> */}
                <div class="m-2 h2 display-5">{takeaway?.username}</div>

              </div>
              <div class="d-flex flex-column">
                <span class="pl-2 lh-0"> Customer: {takeaway?.customernam}</span>
                <span class="pl-2 lh-0">No of Items: {takeaway?.departmentname}</span>
                <span class="pl-2 lh-0">Order Taken by: {takeaway?.ordertakenby}</span>
                <span class="pl-2 lh-0">Bill Amount: {takeaway?.amount}</span>
                <span class="pl-2 lh-0">Type: {takeaway?.takeawaytype}</span>
                {/* <span class="pl-2 lh-0">In Time: {takeaway?.intime} </span> */}
              </div>

            </div>


          </div>
          {/* </a> */}
        </div>
      </>

    ))
  } else if (takeAwaysStatus === 'failed') {
    content = <div className="col-md-12">{error}</div>
  }



  return (
    <>
      {/* <PageTitle titleHeader="TAKEAWAY" titleDescription="" /> */}
      <div class="row ml-4 mr-4 mb-4 d-flex flex-grow-1">
        {content}
      </div>
      {/* animation={true}  */}
      <TakeAwayModal show={popupTakeaway} onlineModeData={onlineModeData} handleClose={handleCloseTakeawaypopup} takeAwayData={takeAwayData} />

    </>

  );


}