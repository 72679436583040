import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Products.css';
import 'react-multi-carousel/lib/styles.css';
import { GetProducts } from './GetProducts'
import { GetItems } from './GetItems'
import { ChangePriceModal } from "./ChangePriceModal";
import { OpenPriceModal } from "./OpenPriceModal";
import { backToPreviousPage, selectActiveTab, setActivePage, setActiveTab } from '../../reducers/homePageSlice';
import { emptyCart, selectBillById } from '../../reducers/billsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, cashierTabs } from '../../utilities/Constants'
import { get } from 'jquery';
import ReactToPrint from 'react-to-print-advanced';
import { selectAllCart } from '../../reducers/CartSlice';
import { Addons } from './Addons';
import { KeyboardOn } from '../Keyboard/Keyboard';
import './Print.css'
import { fetchTables } from '../../reducers/tablesSlice';
import { useReactToPrint } from "react-to-print";
import './printData.css'
import { RefundBill } from '../RefundBill/RefundBill';
import { useBarcode } from 'react-barcodes';
import { selectAllRefund } from '../../reducers/refundSlice';

export function Products() {

  const [showKeyboard, setShowKeyBoard] = useState(false);
  // const [onlineDelivery, setOnlineDelivery] = useState(false);

  const onlineDelivery = useSelector((state) => state.constVar.showDelivery)
  const [kotData, setKotData] = useState();
  const [gstSubTotal, setGstSubTotal] = useState(0);
  const [gstTotal, setGstTotal] = useState(0);
  const [DiscountTotallastRecpt, setDiscountTotallastRecpt] = useState(0);

  const cartItemsListRefund = useSelector(selectAllRefund);
  const [showTaxDetails, setShowTaxDetails] = useState(true)
  const [billBodyContent, setBillBodyContent] = useState(false)
  const [showbillFooter, setShowbillFooter] = useState(true)
  const [qtyTotal, setQtyTotal] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [lastReceiptBillNo, setLastReceiptBillNo] = useState();
  const [lastReceiptCash, setLastReceiptCash] = useState();
  const [lastReceiptChange, setLastReceiptChange] = useState();
  const [lastReceiptKOTNos, setLastReceiptKOTNos] = useState();

  const [tableHeaderSettings, setTableHeaderSettings] = useState(3);
  const [showHeaderSettings, setShowHeaderSettings] = useState(false)
  const [reprintHide, setReprintHide] = useState(true);
  const [billNoScan, setBillNoScan] = useState("0");
  const LogoAme = localStorage.getItem("logo");
  const [loadingLastReceipt, setLoadingLastReceipt] = useState(false);
  const [textLastReceipt, setTextLastReceipt] = useState("old boring text");
  const onBeforeGetContentResolveLastReceipt = useRef(null);
  const onBeforeGetContentResolveKOT = useRef(null);
  const [loadingKOT, setLoadingKOT] = useState(false);
  const [textKOT, setTextKOT] = useState("old boring text");
  const [dateData, setDateData] = useState('');
  const [sliceItem, setSliceItem] = useState('')
  const pageStyle = `{ size: 2.5in 4in }`;
  const draftRef = useRef(null);
  const onlinOrderDraftRef = useRef(null);

  const onBeforeGetContentResolveOnline = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const [draftData, setDraftData] = useState()
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("old boring text");
  const [openProductModal, setOpenProductsModal] = useState(false);
  const { Box } = useSelector((state) => state.touch);
  const cartItemsList = useSelector(selectAllCart);
  const kotRef = useRef(null);
  const LastReceiptRef = useRef(null);
  const [companyDetails, setCompanyDetails] = useState();
  const [lastReceiptDetailsData, setLastReceiptDetailsData] = useState();
  const [showChangePrice, setShowChangePrice] = useState(false);
  const [showOpenPrice, setShowOpenPrice] = useState(false);
  const [showOtherOptions, setShowOtherOptions] = useState(true);
  const handleCloseChangePrice = () => setShowChangePrice(false);

  // const LogoAme = localStorage.getItem("logo");
  const tab = useSelector((state) => state.homePage.activeTab);
  console.log(tab, "page");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const date = new Date();
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  const handleClickOpenPrice = (e) => {
    setShowChangePrice(false);
    setShowOpenPrice(true);
  }

  const { inputRef } = useBarcode({
    value: billNoScan,
    options: {
      format: "CODE39",
      displayValue: true,
      // background: '#ffc0cb',

    }
  });

  // const date = new Date();
  // let datetime =
  // date.getFullYear() +
  // "-" +
  // (date.getMonth() + 1) +
  // "-" +
  // date.getDate() +

  // setDateData(datetime)

  const handleCloseOpenPrice = () => setShowOpenPrice(false);



  const dispatch = useDispatch();
  const activeBillId = useSelector((state) => state.bills.activeBillID)

  const showRefundfunc = useSelector((state) => state.constVar.showRefund)
  console.log(showRefundfunc, "showRefundfunc");


  const handleClickViewTables = (e) => {
    dispatch(setActivePage(pageComponents.VIEWTABLES));
    dispatch(setActiveTab(cashierTabs.DINE_IN));

  }

  const handleClickMergeTables = (e) => {
    if (sliceItem != null && sliceItem != undefined && sliceItem != 'T' && localStorage.getItem('tableno') != 'CT') {
      if (cartItemsList?.length > 0 && cartItemsList[0].kotno != undefined && cartItemsList[0].kotno != null && cartItemsList[0].kotno != '0' && tab === cashierTabs.DINE_IN && sliceItem != null && sliceItem != undefined && sliceItem != 'T') {

        dispatch(setActivePage(pageComponents.MERGETABLES));
      } else {
        alert("This is not a valid Table")
      }
    } else {
      alert("Please Select DineIn Table")
    }
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }

  const handleClickChangeTables = (e) => {
    if (sliceItem != null && sliceItem != undefined && sliceItem != 'T' && localStorage.getItem('tableno') != 'CT') {
      if (cartItemsList?.length > 0 && cartItemsList[0].kotno != undefined && cartItemsList[0].kotno != null && cartItemsList[0].kotno != '0' && tab === cashierTabs.DINE_IN && sliceItem != null && sliceItem != undefined && sliceItem != 'T') {

        dispatch(setActivePage(pageComponents.CHANGETABLES));
      } else {
        alert("This is not a valid Table")
      }
    } else {
      alert("Please Select DineIn Table")
    }
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }

  const handleClickReprint = (e) => {
    if (reprintHide == true) {

      dispatch(setActivePage(pageComponents.REPRINTBILL));
    }
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }
  // console.log(activeTab,"selectActiveTab");
  const handleClickSplitBill = (e) => {
    if (cartItemsList?.length > 0 && cartItemsList[0].kotno != undefined && cartItemsList[0].kotno != null && cartItemsList[0].kotno != '0' && tab === cashierTabs.DINE_IN && sliceItem != null && sliceItem != undefined && sliceItem != 'T') {

      dispatch(setActivePage(pageComponents.SPLITBILL));
    } else {
      alert("This is not a valid Table")
    }
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }

  const handleClickRefundBill = (e) => {
    dispatch(setActivePage(pageComponents.REFUNDBILL));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }

  const handleClickCustomer = (e) => {
    dispatch(setActivePage(pageComponents.CUSTOMERLIST));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }
  const handleClickDeliveryBoy = (e) => {
    dispatch(setActivePage(pageComponents.DELIVERYBOYLIST));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }



  const handleClickOtherOptions = (e) => {
    const divOtherOptions = document.getElementById("divOtherOptions");


    if (divOtherOptions.classList.contains("floating-button-expand")) {
      divOtherOptions.classList.remove("floating-button-expand");
    }
    else {
      divOtherOptions.classList.add("floating-button-expand");
    }

  }

  try {
    if (cartItemsList.length > 0 && cartItemsList[0].name != "yasar") {
      var billamnt = (
        (cartItemsList[cartItemsList.length - 1].subTotal) +
        (cartItemsList[cartItemsList.length - 1].gst)
      ).toFixed(2);
      var roundamnt = Math.round(billamnt);
    }

  } catch (error) {
    alert("Data not Found")
  }

  const handleClickChangePrice = (e) => {
    setShowChangePrice(true);

  }
  const printonlineOrderDraft = async () => {
    console.log("printonlineOrderDraft");
    await getdata()
    handlePrintOnlineOrder()
  }

  ///////////////////OnlineOrdersPrint///////////////////////////////

  const handleAfterPrintOnlineOrder = useCallback(() => {
    // goToKot()
    // dispatch(backToPreviousPage())
    // props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrintOnlineOrder = useCallback(() => {
    console.log("`onBeforePrint` called Online"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContentOnlineOrder = useCallback(() => {
    console.log("`onBeforeGetContent` called Online"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolveOnline.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text Online!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContentOnlineOrder = useCallback(() => {
    return onlinOrderDraftRef.current;
  }, [onlinOrderDraftRef.current]);

  const handlePrintOnlineOrder = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContentOnlineOrder,
    onBeforePrint: handleBeforePrintOnlineOrder,

    content: reactToPrintContentOnlineOrder,
    onAfterPrint: handleAfterPrintOnlineOrder,
    removeAfterPrint: true
  });
  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolveOnline.current === "function"
    ) {
      onBeforeGetContentResolveOnline.current();
    }
  }, [onBeforeGetContentResolveOnline.current, text]);





  ////////////////////////////////////////////////////////////////////


  const goToPayment = (texts) => {
    const date = new Date();

    // //POST json
    // const billamnt = (
    //   parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
    //   parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    // ).toFixed(2);
    // const roundamnt = Math.round(billamnt);
    // const num1 = Box;
    // const num2 = roundamnt;

    // // add two numbers
    // const sum = num1 - num2;

    let dataToSend = {
      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),
      openin: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_draftbill.php", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        if (responseJson.DraftBillArr.ItemsArray[0].itemsArray != 2) {
          const arr = [];
          var text = texts;
          getdata()
          setDraftData(responseJson)

          // text =
          //   text +
          //   "[L]<font size='normal' font-family='cambria' font-weight='bold'>TABLE:" +
          //   tableno +
          //   "</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>DATE:" +
          //   datetime +
          //   "</font>\n" +
          //   "[L]<font size='normal' font-family='cambria' font-weight='bold'>TERMINAL:" +
          //   terminalno +
          //   "</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>CASHIER:" +
          //   pinCreate[0].UserName +
          //   "</font>\n" +
          //   "[L]\n" +
          //   "[L]--------------------------------------------\n" +
          //   "[L]<font size='normal' font-family='cambria' font-weight='bold'>Product</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>Qty[R]Rate</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>Total</font>\n" +
          //   "[L]--------------------------------------------\n" +
          //   "[L]\n";

          for (
            let i = 0;
            i < responseJson.DraftBillArr.ItemsArray.length;
            i++
          ) {
            // text =
            //   text +
            //   "[L]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //   responseJson.DraftBillArr.ItemsArray[i].ProductName +
            //   "\n" +
            //   "</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //   responseJson.DraftBillArr.ItemsArray[i].Quantity.toString() +
            //   "</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //   responseJson.DraftBillArr.ItemsArray[i].unitprice.toString() +
            //   "</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //   responseJson.DraftBillArr.ItemsArray[i].amount.toString() +
            //   "</font>\n" +
            //   "[L]\n";
          }
          // text =
          //   text +
          //   "[L]--------------------------------------------\n" +
          //   "[L]<font size='normal' font-family='cambria' font-weight='bold'>Total Items:" +
          //   cartItemsList.length +
          //   "</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>Total Qty:" +
          //   cartItemsList[cartItemsList.length - 1].qty +
          //   "</font>\n" +
          //   "[L]--------------------------------------------\n" +
          //   "[L]<font size='normal' >SubTotal</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
          //   parseFloat(
          //     cartItemsList[cartItemsList.length - 1].subTotal
          //   ).toFixed(2) +
          //   "</font>\n" +
          //   "[L]<font size='normal'  >GST</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
          //   parseFloat(cartItemsList[cartItemsList.length - 1].gst).toFixed(2) +
          //   "</font>\n" +
          //   "[L]<b><font size='big' font-family='cambria' font-weight='bold'>Total</font></b>[R]<b><font size='big' font-family='cambria' font-weight='bold'>" +
          //   (
          //     parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
          //     parseFloat(cartItemsList[cartItemsList.length - 1].gst)
          //   ).toFixed(2) +
          //   "</font></b>\n" +
          //   "[L]--------------------------------------------\n" +
          //   "[C]<font size='normal' font-family='cambria' font-weight='bold'>Technology Partner amepos.in</font>\n";
          //printing(texts);
          // printing(text, responseJson.DraftBillArr.DraftBill.printernam);
          let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview.php";
          var itemss = [
            {
              link: link,
              tokenCreate: localStorage.getItem('token'),
            },
          ];

          handlePrint()
          dispatch(fetchTables(itemss));
          getClear();
          //  Actions.vieworders();


        } else {
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        alert("Data not Found")
      });
  };

  const getClear = () => {
    //POST json
    // let dataToSend = {
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      terminal: localStorage.getItem('TerminalName'),

      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),
    };
    // wanip: tokenCreate[0].Wanip,
    // dbusr: tokenCreate[0].DBUser,
    // dbpwd: tokenCreate[0].DBPwd,
    // dbnam: tokenCreate[0].DBname,
    // tabno: tableno,
    // terminal: terminalno,
    // };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable.php", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
            arr.push({
              clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
            });
          }

        } else {
          alert("Sorry");
        }
      })
      .catch((error) => {
        alert("Data not Found")
      });
  };




  // const handleOnBeforeGetContent = useCallback(() => {
  //   setLoading(true);
  //   setText("Loading new text...");

  //   goToPayment()
  //   return new Promise((resolve) => {
  //     onBeforeGetContentResolve.current = resolve;

  //     // setTimeout(() => {
  //     //   setLoading(false);
  //     //   setText("New, Updated Text!");
  //     //   resolve();
  //     // }, 2000);
  //   });
  // }, [setLoading, setText,goToPayment]);

  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  useEffect(() => {
    getdata()
    setSliceItem(localStorage.getItem("tableno").slice(0, 1))
    // handleClickLastReceipt()
  }, [])






  const Last = () => {

    getdata()
  };

  const getdata = () => {
    //POST json
    try {


      let dataToSend = {
        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,

        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token: localStorage.getItem('token'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl.php", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.clientdtlArr[0].clientdtl != 2) {
            setCompanyDetails(responseJson?.clientdtlArr[0])
            setTableHeaderSettings(responseJson.clientdtlArr[0].billformatdetails[1].status)
            if (responseJson.clientdtlArr[0].billformatdetails[4].status == '1') {
              setShowbillFooter(true)
            } else {
              setShowbillFooter(false)
            }
            if (responseJson.clientdtlArr[0].billformatdetails[0].status == '1') {
              setShowHeaderSettings(true)
            } else {
              setShowHeaderSettings(false)
            }
            if (responseJson.clientdtlArr[0].billformatdetails[3].status == '1') {

              setShowTaxDetails(true)
            } else {
              setShowTaxDetails(false)

            }
            if (responseJson.clientdtlArr[0].billformatdetails[2].status == '1') {

              setBillBodyContent(true)
            } else {
              setBillBodyContent(false)

            }

            // console.log(responseJson?.clientdtlArr[0],"responseJson?.clientdtlArr[0]");
          } else {
            alert("Sorry No CompanyDetails Data");
          }
        })
        .catch((error) => {
          alert("No CompanyDetails Found")
        });
    } catch (error) {
      console.log(error);
    }
  }


  const handleClickLastReceipt = () => {

    const date = new Date();
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    //POST json


    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),

      terminal: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_lastreceipt.php", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        if (responseJson.LastReceiptArr.ItemsArray[0].itemsArray != 2) {

          setBillNoScan(responseJson.LastReceiptArr.LastReceipt.billno)
          setLastReceiptDetailsData(responseJson)
          setLastReceiptBillNo(responseJson.LastReceiptArr.LastReceipt.billno)
          setLastReceiptCash(responseJson.LastReceiptArr.LastReceipt.cash)
          setLastReceiptChange(responseJson.LastReceiptArr.LastReceipt.change)
          if (responseJson.LastReceiptArr.ItemsArray.length != 0) {

            setLastReceiptKOTNos(responseJson.LastReceiptArr.ItemsArray[0].kotno)
            let subTotalamt = 0
            let gst = 0
            let qty = 0
            let amtt = 0
            let DISCOUNT = 0

            for (let i = 0; i < responseJson.LastReceiptArr.ItemsArray.length; i++) {

              DISCOUNT = parseFloat(DISCOUNT) + parseFloat(responseJson.LastReceiptArr.ItemsArray[i].discount);
              subTotalamt = parseFloat(subTotalamt) + parseFloat(responseJson.LastReceiptArr.ItemsArray[i].amount);

              gst = parseFloat(gst) + parseFloat(responseJson.LastReceiptArr.ItemsArray[i].gst);
              qty = parseFloat(qty) + parseFloat(responseJson.LastReceiptArr.ItemsArray[i].Quantity);
              // amtt = subTotalamt +gst;
              amtt = subTotalamt - parseFloat(responseJson.LastReceiptArr.LastReceipt.totaldiscount)

              setGstSubTotal(subTotalamt)
              setGstTotal(gst)
              setQtyTotal(qty)
            }

            setAmountTotal(amtt)
            setDiscountTotallastRecpt(responseJson.LastReceiptArr.LastReceipt.totaldiscount)
            console.log(subTotalamt + gst, amtt, parseFloat(responseJson.LastReceiptArr.LastReceipt.totaldiscount), " responseJson.LastReceiptArr.ItemsArray.length");
          }
          //       const obj = [...new Map(arryjs.map(item => [JSON.stringify(item), item])).values()];
          //  console.log(obj,"obj");

          //  setLastReceiptKOTNos(obj)
          handlePrintLastReceipt()
        } else {
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        alert("Data No Data Found")
      });

  }
  const handleClickAddons = () => {

    setOpenProductsModal(true)
  }

  const openRemarks = () => {
    setShowKeyBoard(true)
  }


  ///////////////////////////////


  const handleAfterPrint = useCallback(() => {
    // goToKot()
    dispatch(backToPreviousPage())
    // props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,

    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });
  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);



  ////////// KOT ///////////////////

  const goToKot = () => {
    let dataToSend = {
      token: localStorage.getItem('token'),

      tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot.php", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        console.log(responseJson, "kot");

        if (responseJson.PrintKotArr[0].PrintKot != 2) {
          setKotData(responseJson)

          let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview.php";
          var item = [
            {
              link: link,
              tokenCreate: localStorage.getItem('token'),
            },
          ];
          dispatch(fetchTables(item));
          getClear()
          // Actions.vieworders();
          handlePrintKOT()
        } else {
          //("Please check your email id or password");
          getClear()
          //  alert("KOT Already Printed");
          goToPayment()
          //  Actions.vieworders();
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      .catch((error) => {
        alert("Data No Data Found")
      });
  }





  console.log(gstSubTotal, "setGstSubTotal");



  const handleAfterPrintKOT = useCallback(() => {
    goToPayment()
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrintKOT = useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContentKOT = useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoadingKOT(true);
    setTextKOT("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolveKOT.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoadingKOT(false);
        setTextKOT("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoadingKOT, setTextKOT]);

  const reactToPrintContentKOT = useCallback(() => {
    return kotRef.current;
  }, [kotRef.current]);

  const handlePrintKOT = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContentKOT,
    onBeforePrint: handleBeforePrintKOT,

    content: reactToPrintContentKOT,
    onAfterPrint: handleAfterPrintKOT,
    removeAfterPrint: true
  });
  useEffect(() => {
    if (
      textKOT === "New, Updated Text!" &&
      typeof onBeforeGetContentResolveKOT.current === "function"
    ) {
      onBeforeGetContentResolveKOT.current();
    }
  }, [onBeforeGetContentResolveKOT.current, textKOT]);


  /////////// Last Receipt////////////////////



  const handleAfterPrintLastReceipt = useCallback(() => {

    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrintLastReceipt = useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContentLastReceipt = useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoadingLastReceipt(true);
    setTextLastReceipt("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolveLastReceipt.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoadingLastReceipt(false);
        setTextLastReceipt("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoadingLastReceipt, setTextLastReceipt]);

  const reactToPrintContentLastReceipt = useCallback(() => {
    return LastReceiptRef.current;
  }, [LastReceiptRef.current]);

  const handlePrintLastReceipt = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContentLastReceipt,
    onBeforePrint: handleBeforePrintLastReceipt,

    content: reactToPrintContentLastReceipt,
    onAfterPrint: handleAfterPrintLastReceipt,
    removeAfterPrint: true
  });
  useEffect(() => {
    if (
      textLastReceipt === "New, Updated Text!" &&
      typeof onBeforeGetContentResolveLastReceipt.current === "function"
    ) {
      onBeforeGetContentResolveLastReceipt.current();
    }
  }, [onBeforeGetContentResolveLastReceipt.current, textLastReceipt]);




  /////////////////////////////////

  const printStatement = async () => {
    // await goToPayment()
    // await handleClickLastReceipt();
    // await getDataUsingPost();
    if (localStorage.getItem('tableno') != "CT") {

      await goToKot()
    }
  }
  const lastReceiptStatement = async () => {
    // await goToPayment()
    // await handleClickLastReceipt();
    // await getDataUsingPost();
    getdata()
    await handleClickLastReceipt()
  }


  const changeOtherOptions = () => {
    setShowOtherOptions(!showOtherOptions)
  }

  console.log(showOtherOptions, "showOtherOptions");
  console.log(sliceItem, "sliceItem");

  return (
    <div class="row flex-grow-1">
      <div class="col-md-12 d-none d-md-flex flex-column">
        <div class="row border-bottom">

          <div class="col-md-12">
            <GetProducts />
          </div>

        </div>
        <div class="row flex-grow-1 tableFixHead scrollWidth" >
          <div class="col-md-12">
            {showKeyboard === true ? <div class="col-md-12">
              <KeyboardOn setShowKeyBoard={setShowKeyBoard} />
            </div> :
              (
                openProductModal == false ?
                  <GetItems /> :
                  <Addons setOpenProductsModal={setOpenProductsModal} />
                //  :
              )}



          </div>
        </div>
        {/* {showOtherOptions == true ? 
        <button onClick={() =>changeOtherOptions()}>Other Options</button>
        :

        <button onClick={() =>changeOtherOptions()}>Back</button>
      } */}
        {/* <div class="flex-fill">
           <button className="fontSize btn CUSTOMER__Color btn-block btn-block-height btn_large p4Padding" style={{backgroundColor:'646464!important '}} onClick={() => handleClickCustomer()}>
             Customer
           </button>
         </div>
         <div class="ml-1 flex-fill">
           <button className="fontSize btn CHANGETABLE__Color btn-block btn-block-height btn_large  p4Padding" style={{fontSize:"75% !important",background:'BAFE06'}} onClick={() => handleClickChangeTables()}>

             Change Table
           </button>
         </div>
         <div class="ml-1 flex-fill">
           <button className="fontSize btn MERGETABLE__Color btn-block btn_large btn-block-height p4Padding" style={{fontSize:"75% !important",background:'C8C8C8'}} onClick={() => handleClickMergeTables()}>
             Merge Table
           </button>

         </div>
         <div class="ml-1 flex-fill">
           <button className="fontSize btn ADDONS__Color btn-block btn_large btn-block-height p4Padding" style={{background:'F1140A'}} onClick={() => handleClickAddons()}>
             Addons 
           </button>

         </div>
         <div class="ml-1 flex-fill">
           <button className="fontSize btn REMARKS__Color btn-block btn_large btn-block-height p4Padding" style={{background:'2A7E2B'}} onClick={() => openRemarks()}>
             Remarks
           </button>

         </div>
         <div class="ml-1 flex-fill">
           <button className="fontSize btn REPRINT__Color btn-block btn_large btn-block-height p4Padding" style={{background:'C8C8C8'}} onClick={() => handleClickReprint()}>
             Reprint
           </button>

         </div>
         <div class="ml-1 flex-fill">
           <button className="fontSize btn Other_Options  btn-block btn_large btn-block-height p4Padding" style={{background:'C8C8C8'}}  onClick={() =>changeOtherOptions()}>
           Other Options
           </button>

         </div> */}

        {
          onlineDelivery == true ?
            <div class="" >
              <ul class="cards_overflow__products ">

                <li class="cards_item_1 margin_3 btn_height_items" >
                  <button className="btn CUSTOMER__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickDeliveryBoy()}>
                    Assign DeliveryBoy
                  </button>
                </li>
                <li class="cards_item_1 margin_3 btn_height_items" >
                  <button className="btn  DRAFTBILL__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => printonlineOrderDraft()}>

                    Print Draft
                  </button>
                </li>
                {
                  showRefundfunc ?
                    <>
                      <li class="cards_item_1 margin_3 btn_height_items" >
                        <button className="btn  LASTRECEIPT__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickRefundBill()}>

                          Refund Bill
                        </button>

                      </li>

                      <li class="cards_item_1 margin_3 btn_height_items" >
                        <button className="btn  ADDONS__Color btn__large btn-block shadow-sm btn_items_height" >
                          Delete Bill
                        </button>

                      </li>
                    </>
                    :
                    <>
                      <li class="cards_item_1 margin_3 btn_height_items" >
                        <button className="btn  LASTRECEIPT__Color2 btn__large btn-block shadow-sm btn_items_height" >

                          Last Receipt
                        </button>

                      </li>

                      <li class="cards_item_1 margin_3 btn_height_items" >
                        <button className="btn  ADDONS__Color2 btn__large btn-block shadow-sm btn_items_height" >
                          Addons
                        </button>

                      </li>
                    </>
                }
                {/* <li class="cards_item_1 margin_3 btn_height_items" >
                <button className="btn  LASTRECEIPT__Color2 btn__large btn-block shadow-sm btn_items_height" >

                  Last Receipt
                </button>

              </li>

              <li class="cards_item_1 margin_3 btn_height_items" >
                <button className="btn  ADDONS__Color2 btn__large btn-block shadow-sm btn_items_height" >
                  Addons
                </button>

              </li> */}
                <li class="cards_item_1 margin_3 btn_height_items" >
                  <button className="btn  REMARKS__Color2 btn__large btn-block shadow-sm btn_items_height" >
                    Remarks
                  </button>
                </li>

                <li class="cards_item_1 margin_3 btn_height_items" >
                  <button className="btn Other_Options2  btn__large btn-block shadow-sm btn_items_height" >
                    Other Options
                  </button>
                </li>

              </ul>

            </div>
            : (showOtherOptions == true ?
              <div class="" >
                <ul class="cards_overflow__products ">

                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn CUSTOMER__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickCustomer()}>
                      Customer
                    </button>
                  </li>

                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn  DRAFTBILL__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => printStatement()}>

                      Print Draft
                    </button>
                  </li>

                  {/* //  {
                // showOtherOptions == true  ? 
              //   <>
              //   <li class="cards_item_1 margin_3 btn_height_items" >
              //   <button className="btn  LASTRECEIPT__Color btn__large btn-block shadow-sm btn_items_height"   onClick={() =>handleClickRefundBill()}>

              //     Refund Bill
              //   </button>

              // </li>

              // <li class="cards_item_1 margin_3 btn_height_items" >
              //   <button className="btn  ADDONS__Color btn__large btn-block shadow-sm btn_items_height" >
              //     Delete Bill
              //   </button>

              // </li>
              //   </>
              // ""
              //   :
                <> */}
                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn  LASTRECEIPT__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => lastReceiptStatement()}>

                      Last Receipt
                    </button>

                  </li>

                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn  ADDONS__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickAddons()}>
                      Addons
                    </button>

                  </li>
                  {/* </>
              } */}
                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn  REMARKS__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => openRemarks()}>
                      Remarks
                    </button>
                  </li>

                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn Other_Options  btn__large btn-block shadow-sm btn_items_height" onClick={() => changeOtherOptions()}>
                      Other Options
                    </button>
                  </li>

                </ul>

              </div>
              :
              <div class="" >
                <ul class="cards_overflow__products ">
                  {/* <li class="cards_item_1 margin_3 btn_height_items" >
          <button className="btn CUSTOMER__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickCustomer()}>
             Customer
           </button>
           </li> */}
                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn MERGETABLE__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickMergeTables()}>
                      Merge Table
                    </button>
                  </li>
                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn CHANGETABLE__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickChangeTables()}>

                      Change Table
                    </button>
                  </li>

                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn REPRINT__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickReprint()}>
                      Reprint
                    </button>
                  </li>

                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn CHANGEPRICE__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickChangePrice()}>

                      Change Price
                    </button>
                  </li>
                  {/* <li class="cards_item_1 margin_3 btn_height_items" >
          <button className="btn REFUNDBILL__Color btn__large btn-block shadow-sm btn_items_height"  onClick={handleClickRefundBill}>
        
        Refund Bill
           </button>
           </li> */}


                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn SPLITTABLE__Color btn__large btn-block shadow-sm btn_items_height" onClick={() => handleClickSplitBill()}>
                      Split Table
                    </button>
                  </li>
                  <li class="cards_item_1 margin_3 btn_height_items" >
                    <button className="btn Other_Options  btn__large btn-block shadow-sm btn_items_height" onClick={() => changeOtherOptions()}>
                      BACK
                    </button>
                  </li>

                </ul>

              </div>
            )}
        {/* <div class="p-2 d-flex flex-row justify-content-between bg-gradient" > */}
        {/* <div class="ml-1 flex-fill">
           <button className="fontSize btn Other_Options btn-block btn_large btn-block-height p4Padding" style={{background:'C8C8C8'}}  onClick={() =>changeOtherOptions()}>
           BACK
           </button>

         </div>
       <div class="ml-1 flex-fill">
         <button className="fontSize btn SPLITTABLE__Color btn-block btn_large btn-block-height p4Padding" style={{background:'F59400'}} onClick={() => handleClickSplitBill()}>
           Split Table
         </button>
       </div>
       
       
       <div class="ml-1 flex-fill">
           <button className="fontSize btn DRAFTBILL__Color btn-block btn_large btn-block-height p4Padding" style={{background:'F1140A'}}  onClick={printStatement}>
        
     Print Draft
   </button>
   </div>
   <div class="ml-1 flex-fill">
           <button className="fontSize btn LASTRECEIPT__Color btn-block btn_large btn-block-height p4Padding" style={{background:'BAFE06!important'}}  onClick={lastReceiptStatement}>
        
     Last Receipt
   </button>
   </div>
   <div class="ml-1 flex-fill">
           <button className="fontSize btn REFUNDBILL__Color btn-block btn_large btn-block-height p4Padding" style={{background:'BAFE06!important'}}  onClick={handleClickRefundBill}>
        
     Refund Bill
   </button>
   </div>
   <div class="ml-1 flex-fill">
           <button className="fontSize btn CHANGEPRICE__Color btn-block btn_large btn-block-height p4Padding" style={{background:'BAFE06!important'}}  onClick={() => handleClickChangePrice()}>
        
     Change Price
   </button>
   </div> */}
        {/* <div class="ml-1 flex-fill">
         <button className="btn btn-secondary btn-block btn_large btn-block-height p4Padding" onClick={() => handleClickRefundBill()}>
         Print Draft
         </button>
       </div> */}
        <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
          <div class="print-container" ref={onlinOrderDraftRef} style={{ margin: "0", padding: "0" }}>
            <div class="ticket " >
              {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
                <div style={{ textAlign: 'center', justifyContent: 'center', textAlign: 'center', alignItems: 'center', margin: '0px' }}>
                  <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '80px', width: '120px' }} />
                </div>

              }
              <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1} {companyDetails?.addr2}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>(CUSTOMER COPY)</p>


              {/* {
                showHeaderSettings == true ?
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TAX INVOICE</p>
                  : */}
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PHONE ORDER RECEIPT</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PHONE ORDER NO : 60001</p>


              {/* } */}

              {/* <div style={{display:'flex',justifyContent:'space-between'}}>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

              {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>DATE: {dateData != null && dateData != undefined && dateData}</p> */}
              {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
            </div> */}

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>ORDERED DATE :{datetime != null && datetime != undefined ? datetime : ""}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>DELIVERY EXECUTIVE : JEEVA</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>DELIVERY DATE :{datetime != null && datetime != undefined ? datetime : ""}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>ADDRESS :{companyDetails?.addr1} {companyDetails?.addr2}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p> */}

                {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px'}}>CASHIER: {dateData != null && dateData != undefined && dateData}</p> */}

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
              </div>


              {
                tableHeaderSettings == '1' && billBodyContent == true ?
                  <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                      <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                      <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                      {/* </tr> */}

                    </div>
                    <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                      {
                        cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                        cartItemsList.map((item, index) =>

                        (
                          <div>

                            <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.productname}</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.totalqty)}</div>

                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                            </div>
                          </div>
                        ))

                      }

                    </div>
                  </div>
                  :

                  tableHeaderSettings == '1' && billBodyContent == false ?
                    <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                          <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                          <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                          {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                          <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                        </tr>

                      </thead>
                      <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        {
                          cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                          cartItemsList.map((item, index) =>

                          (
                            <tr  >
                              <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.productname}</td>
                              <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.totalqty)}</td>

                              <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                              {/* {item?.TAX1 != '0.00' ? 
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
         : (
          item?.TAX2 != '0.00' ? 
          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
         :
         <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
         
         )
        } */}
                              <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                            </tr>
                          ))

                        }

                      </tbody>
                    </table>
                    :

                    tableHeaderSettings == '2' && billBodyContent == true ?
                      <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                          <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                          <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                          {/* </tr> */}

                        </div>
                        <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                          {
                            cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                            cartItemsList.map((item, index) =>

                            (
                              <div>

                                <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.productname}</div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.totalqty).toFixed(2)}</div>

                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                                </div>
                              </div>
                            ))

                          }

                        </div>
                      </div>
                      :

                      tableHeaderSettings == '2' && billBodyContent == false ?
                        <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                              <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                              <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                              <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                              {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                              <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                            </tr>

                          </thead>
                          <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            {
                              cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                              cartItemsList.map((item, index) =>

                              (
                                <tr  >
                                  <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.productname}</td>
                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.totalqty).toFixed(2)}</td>

                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                  {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                                  <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                                </tr>
                              ))

                            }

                          </tbody>
                        </table>
                        :
                        tableHeaderSettings == '3' && billBodyContent == true ?
                          <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}>PRODUCT</div>
                              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', }}>QTY</div>
                              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40%', textAlign: 'end' }}>TOTAL</div>


                            </div>
                            <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                              {
                                cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                                cartItemsList.map((item, index) =>

                                (
                                  <div>

                                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.productname}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}> </div>
                                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', wordBreak: 'break-all' }}>{parseInt(item?.totalqty).toFixed(2)}</div>

                                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '40%', wordBreak: 'break-all' }}>{item?.amount}</div>
                                    </div>
                                  </div>
                                ))

                              }

                            </div>
                          </div>

                          :
                          tableHeaderSettings == '3' && billBodyContent == false ?
                            <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                              <thead>
                                <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                  <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th> */}
                                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                                  <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                                </tr>

                              </thead>
                              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                {
                                  cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                                  cartItemsList.map((item, index) =>

                                  (
                                    <tr  >
                                      <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.productname}</td>
                                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.totalqty)}</td>

                                      {/* <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td> */}
                                      {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                                      <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.amount}</td>

                                    </tr>
                                  ))

                                }

                              </tbody>
                            </table>
                            :
                            tableHeaderSettings == '4' && billBodyContent == true ?

                              <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', }}>QTY</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px' }}>RATE</div>
                                  {/* <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>SST</div> */}

                                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', textAlign: 'end' }}>TOTAL</div>

                                  {/* </tr> */}

                                </div>
                                <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                                  {
                                    cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                                    cartItemsList.map((item, index) =>

                                    (
                                      <div>

                                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.productname}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{parseInt(item?.totalqty)}</div>

                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px', wordBreak: 'break-all' }}>{item?.unitprice}</div>
                                          {/* {
                                            item?.TAX1 != '0.00' ?
                                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX1}</div>
                                              : (
                                                item?.TAX2 != '0.00' ?
                                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX2}</div>
                                                  :
                                                  item?.TAX3 != '0.00' ?
                                                    <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX3}</div>
                                                    :
                                                    '0.00'
                                              )
                                          } */}
                                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '50px', wordBreak: 'break-all' }}>{item?.amount}</div>
                                        </div>
                                      </div>
                                    ))

                                  }

                                </div>
                              </div>

                              :
                              tableHeaderSettings == '4' && billBodyContent == false ?
                                <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                  <thead>
                                    <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                      <th className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                                      {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                                      <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                                    </tr>

                                  </thead>
                                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                    {
                                      cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                                      cartItemsList.map((item, index) =>

                                      (
                                        <tr  >
                                          <td className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', wordBreak: 'break-all' }}>{item?.productname}</td>
                                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.totalqty)}</td>

                                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                          {/* {item?.TAX1 != '0.00' ?
                                            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
                                            : (
                                              item?.TAX2 != '0.00' ?
                                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
                                                :
                                                item?.TAX3 != '0.00' ?
                                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
                                                  :
                                                  '0.00'
                                            )
                                          } */}
                                          <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                                        </tr>
                                      ))

                                    }

                                  </tbody>
                                </table>

                                :
                                ''
              }


              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Total Items :{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].qty ? (cartItemsList?.length) : 0}</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>Total Qty:{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].qty ? parseFloat(cartItemsList[cartItemsList?.length - 1].qty) : 0}</p>

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Sub Total :</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].subTotal ? parseFloat(cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2)) : "0.00"}</p>

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST</p>

                {showTaxDetails == true ?
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].gst ? parseFloat(cartItemsList[cartItemsList?.length - 1].gst.toFixed(2)) : "0.00"}</p>
                  :
                  ""

                }

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', color: 'black' }}>Total</p>

                <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', marginRight: '4px', color: 'black' }}> {roundamnt != null && roundamnt != undefined ? roundamnt.toFixed(2) : '0.00'}</p>

              </div>
              {/* <div style={{ display: 'flex', borderTop: '1px dotted black', borderCollapse: 'collapse', justifyContent: 'center' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No's :</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{draftData?.DraftBillArr?.DraftBill?.kotno}</p>

              </div> */}
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.remarks1}</p>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.remarks2}</p>


            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
          <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
            <div class="ticket " >
              {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
                <div style={{ textAlign: 'center', justifyContent: 'center', textAlign: 'center', alignItems: 'center', margin: '0px' }}>
                  <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '80px', width: '120px' }} />
                </div>
              }
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{draftData?.DraftBillArr?.DraftBill?.companynam.length > 0 && draftData?.DraftBillArr?.DraftBill?.companynam}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}> {draftData?.DraftBillArr?.DraftBill?.addr1.length > 0 && draftData?.DraftBillArr?.DraftBill?.addr1}{draftData?.DraftBillArr?.DraftBill?.addr2.length > 0 && draftData?.DraftBillArr?.DraftBill?.addr2}</p>
              {/* { companyDetails?.length > 0 &&  companyDetails?.addr1}{ companyDetails?.length > 0 &&  companyDetails?.addr2}</p> */}
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}> PH NO:{draftData?.DraftBillArr?.DraftBill?.addr3.length > 0 && draftData?.DraftBillArr?.DraftBill?.addr3}</p>
              {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Email:{ draftData?.DraftBillArr?.DraftBill?.addr3.length > 0 &&  raftData?.DraftBillArr?.DraftBill?.addr3}{ companyDetails?.length > 0 &&  companyDetails?.addr4}</p> */}
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{draftData?.DraftBillArr?.DraftBill?.gstno.length > 0 && draftData?.DraftBillArr?.DraftBill?.gstno}</p>
              {
                showHeaderSettings == true ?
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TAX INVOICE</p>
                  :
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>DRAFT RECEIPT</p>


              }

              {/* <div style={{display:'flex',justifyContent:'space-between'}}>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

              {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>DATE: {dateData != null && dateData != undefined && dateData}</p> */}
              {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
            </div> */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {sliceItem == "T" ?
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
                  :
                  (localStorage.getItem("tableno") == 'CT' ?
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CounterSale No. {localStorage.getItem('tableno')}</p>

                    :
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TABLE No. {localStorage.getItem('tableno')}</p>

                  )
                }
                {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Date :{datetime != null && datetime != undefined ? datetime : ""}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p> */}

                {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px'}}>CASHIER: {dateData != null && dateData != undefined && dateData}</p> */}

              </div>


              {
                tableHeaderSettings == '1' && billBodyContent == true ?
                  <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                      <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                      <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                      {/* </tr> */}

                    </div>
                    <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                      {
                        draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                        draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                        (
                          <div>

                            <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                            </div>
                          </div>
                        ))

                      }

                    </div>
                  </div>
                  :

                  tableHeaderSettings == '1' && billBodyContent == false ?
                    <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                          <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                          <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                          {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                          <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                        </tr>

                      </thead>
                      <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        {
                          draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                          draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                          (
                            <tr  >
                              <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                              <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                              <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                              {/* {item?.TAX1 != '0.00' ? 
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
         : (
          item?.TAX2 != '0.00' ? 
          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
         :
         <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
         
         )
        } */}
                              <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                            </tr>
                          ))

                        }

                      </tbody>
                    </table>
                    :

                    tableHeaderSettings == '2' && billBodyContent == true ?
                      <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                          <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                          <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                          {/* </tr> */}

                        </div>
                        <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                          {
                            draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                            draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                            (
                              <div>

                                <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                                </div>
                              </div>
                            ))

                          }

                        </div>
                      </div>
                      :

                      tableHeaderSettings == '2' && billBodyContent == false ?
                        <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                              <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                              <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                              <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                              {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                              <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                            </tr>

                          </thead>
                          <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            {
                              draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                              draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                              (
                                <tr  >
                                  <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity).toFixed(2)}</td>

                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                  {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                                  <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                                </tr>
                              ))

                            }

                          </tbody>
                        </table>
                        :
                        tableHeaderSettings == '3' && billBodyContent == true ?
                          <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}>PRODUCT</div>
                              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', }}>QTY</div>
                              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40%', textAlign: 'end' }}>TOTAL</div>


                            </div>
                            <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                              {
                                draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                                draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                                (
                                  <div>

                                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}> </div>
                                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '40%', wordBreak: 'break-all' }}>{item?.amount}</div>
                                    </div>
                                  </div>
                                ))

                              }

                            </div>
                          </div>

                          :
                          tableHeaderSettings == '3' && billBodyContent == false ?
                            <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                              <thead>
                                <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                  <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th> */}
                                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                                  <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                                </tr>

                              </thead>
                              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                {
                                  draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                                  draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                                  (
                                    <tr  >
                                      <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                      {/* <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td> */}
                                      {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                                      <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.amount}</td>

                                    </tr>
                                  ))

                                }

                              </tbody>
                            </table>
                            :
                            tableHeaderSettings == '4' && billBodyContent == true ?

                              <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', }}>QTY</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px' }}>RATE</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>SST</div>

                                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', textAlign: 'end' }}>TOTAL</div>

                                  {/* </tr> */}

                                </div>
                                <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                                  {
                                    draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                                    draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                                    (
                                      <div>

                                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px', wordBreak: 'break-all' }}>{item?.unitprice}</div>
                                          {
                                            item?.TAX1 != '0.00' ?
                                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX1}</div>
                                              : (
                                                item?.TAX2 != '0.00' ?
                                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX2}</div>
                                                  :
                                                  item?.TAX3 != '0.00' ?
                                                    <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX3}</div>
                                                    :
                                                    '0.00'
                                              )
                                          }
                                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '50px', wordBreak: 'break-all' }}>{item?.amount}</div>
                                        </div>
                                      </div>
                                    ))

                                  }

                                </div>
                              </div>

                              :
                              tableHeaderSettings == '4' && billBodyContent == false ?
                                <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                  <thead>
                                    <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                      <th className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th>

                                      <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                                    </tr>

                                  </thead>
                                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                    {
                                      draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length > 0 &&

                                      draftData?.DraftBillArr?.ItemsArray.map((item, index) =>
                                      (
                                        <tr  >
                                          <td className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', wordBreak: 'break-all' }}>{item?.ProductName}</td>
                                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                          {item?.TAX1 != '0.00' ?
                                            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
                                            : (
                                              item?.TAX2 != '0.00' ?
                                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
                                                :
                                                item?.TAX3 != '0.00' ?
                                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
                                                  :
                                                  '0.00'
                                            )
                                          }
                                          <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                                        </tr>
                                      ))

                                    }

                                  </tbody>
                                </table>

                                :
                                ''
              }


              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Total Items :{draftData?.DraftBillArr?.ItemsArray?.length > 0 ? (draftData?.DraftBillArr?.ItemsArray?.length) : 0}</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>Total Qty:{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].qty ? parseFloat(cartItemsList[cartItemsList?.length - 1].qty) : 0}</p>

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Sub Total :</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency?.split(",", 1)} {cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].subTotal ? cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2) : "0.00"}</p>

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST</p>

                {showTaxDetails == true ?
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].gst ? cartItemsList[cartItemsList?.length - 1].gst.toFixed(2) : "0.00"}</p>
                  :
                  ""

                }

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', color: 'black' }}>Total</p>

                <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {roundamnt != null && roundamnt != undefined ? parseFloat(roundamnt).toFixed(2) : "0.00"}</p>

              </div>
              <div style={{ display: 'flex', borderTop: '1px dotted black', borderCollapse: 'collapse', justifyContent: 'center' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No's :</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{draftData?.DraftBillArr?.DraftBill?.kotno}</p>

              </div>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.remarks1}</p>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.remarks2}</p>


            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
          <div class="print-container" ref={kotRef} style={{ margin: "0", padding: "0" }}>
            <div class="ticket " >
              {sliceItem == "T" ?
                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
                :
                (localStorage.getItem("tableno") == 'CT' ?
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CounterSale No. {localStorage.getItem('tableno')}</p>

                  :
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Table No. {localStorage.getItem('tableno')}</p>

                )
              }
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>



              <table className="centered" style={{ borderCollapse: 'collapse' }}>

                <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  {
                    kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length > 0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                    kotData?.PrintKotArr[0].ItemsArray.map((item, index) =>

                    (
                      <tr  >
                        <td className='description' style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                        <td className="quantity  " style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>


                      </tr>
                    ))

                  }

                </tbody>
              </table>
              <p className="centered" style={{ fontSize: '12px', borderTop: '1px dotted black', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{"<<< AME>>>"}</p>


            </div>
          </div>
        </div>



        {/* //////////////////////////////////////////////// */}




        {/* /////////////////////////////////////////////////////// */}


        <ChangePriceModal show={showChangePrice}
          animation={true} handleClose={handleCloseChangePrice} handleOpenPriceModal={handleClickOpenPrice}>

        </ChangePriceModal>
        <OpenPriceModal show={showOpenPrice}
          animation={true} handleClose={handleCloseOpenPrice}  >

        </OpenPriceModal>
        <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
          <div class="print-container" ref={LastReceiptRef} style={{ margin: "0", padding: "0" }}>
            <div class="ticket " >
              {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
                <div style={{ textAlign: 'center', justifyContent: 'center', textAlign: 'center', alignItems: 'center', margin: '0px' }}>
                  <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '80px', width: '120px' }} />
                </div>
              }
              <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam != undefined && companyDetails?.companynam != null && companyDetails?.companynam}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1 != undefined && companyDetails?.addr1 != null && companyDetails?.addr1}, {companyDetails?.addr2 != undefined && companyDetails?.addr2 != null && companyDetails?.addr2}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3 != undefined && companyDetails?.addr3 != null && companyDetails?.addr3}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Email:{companyDetails?.addr4 != undefined && companyDetails?.addr4 != null && companyDetails?.addr4}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno != undefined && companyDetails?.gstno != null && companyDetails?.gstno}</p>
              {
                showHeaderSettings == true ?

                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TAX INVOICE</p>
                  :
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>LAST RECEIPT</p>

              }
              <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Bill No : {localStorage.getItem('TerminalName')} - {lastReceiptBillNo != null && lastReceiptBillNo != undefined ? lastReceiptBillNo : ""}</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {sliceItem == "T" ?
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
                  :
                  (localStorage.getItem("tableno") == 'CT' ?

                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt?.terminal}</p>

                    :
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Table No. {localStorage.getItem('tableno')}</p>

                  )
                }
                <p className="centered" style={{ fontSize: '9px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Date :{datetime != null && datetime != undefined ? datetime : ""}</p>
              </div>
              {
                localStorage.getItem("tableno") == 'CT' ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Customer : {lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt?.customer}</p>


                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
                  </div>
                  :
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt?.terminal}</p>

                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
                  </div>

              }
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>

    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
  </div> */}

              {
                localStorage.getItem("tableno") == 'CT' ?
                  ""
                  :
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Customer : {lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt?.customer}</p>

                  </div>
              }
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.length > 0 && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt?.terminal}</p>


                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
              </div> */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

              </div>
              {
                tableHeaderSettings == '1' && billBodyContent == true ?
                  <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                      <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                      <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                      {/* </tr> */}

                    </div>
                    <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>

                      {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                        lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>
                        (
                          <div>

                            <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                            </div>
                          </div>
                        ))

                      }

                    </div>
                  </div>
                  :

                  tableHeaderSettings == '1' && billBodyContent == false ?
                    <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                          <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                          <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                          {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                          <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                        </tr>

                      </thead>
                      <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>

                        {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                          lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>
                          (
                            <tr  >
                              <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                              <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                              <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                              {/* {item?.TAX1 != '0.00' ? 
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
         : (
          item?.TAX2 != '0.00' ? 
          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
         :
         <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
         
         )
        } */}
                              <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                            </tr>
                          ))

                        }

                      </tbody>
                    </table>
                    :

                    tableHeaderSettings == '2' && billBodyContent == true ?
                      <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                          <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                          <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                          {/* </tr> */}

                        </div>
                        <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>

                          {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                            lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>
                            (
                              <div>

                                <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                                </div>
                              </div>
                            ))

                          }

                        </div>
                      </div>
                      :

                      tableHeaderSettings == '2' && billBodyContent == false ?
                        <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                              <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                              <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                              <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                              {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                              <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                            </tr>

                          </thead>
                          <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>

                            {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                              lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>
                              (
                                <tr  >
                                  <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity).toFixed(2)}</td>

                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                  {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                                  <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                                </tr>
                              ))

                            }

                          </tbody>
                        </table>
                        :
                        tableHeaderSettings == '3' && billBodyContent == true ?
                          <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}>PRODUCT</div>
                              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', }}>QTY</div>
                              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40%', textAlign: 'end' }}>TOTAL</div>


                            </div>
                            <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>

                              {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                                lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>
                                (
                                  <div>

                                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}> </div>
                                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '40%', wordBreak: 'break-all' }}>{item?.amount}</div>
                                    </div>
                                  </div>
                                ))

                              }

                            </div>
                          </div>

                          :
                          tableHeaderSettings == '3' && billBodyContent == false ?
                            <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                              <thead>
                                <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                  <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th> */}
                                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                                  <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                                </tr>

                              </thead>
                              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>

                                {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                                  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>
                                  (
                                    <tr  >
                                      <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                      {/* <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td> */}
                                      {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                                      <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.amount}</td>

                                    </tr>
                                  ))

                                }

                              </tbody>
                            </table>
                            :
                            tableHeaderSettings == '4' && billBodyContent == true ?

                              <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', }}>QTY</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px' }}>RATE</div>
                                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>SST</div>

                                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', textAlign: 'end' }}>TOTAL</div>

                                  {/* </tr> */}

                                </div>
                                <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>

                                  {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                                    lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>
                                    (
                                      <div>

                                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px', wordBreak: 'break-all' }}>{item?.unitprice}</div>
                                          {
                                            item?.TAX1 != '0.00' ?
                                              <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX1}</div>
                                              : (
                                                item?.TAX2 != '0.00' ?
                                                  <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX2}</div>
                                                  :
                                                  item?.TAX3 != '0.00' ?
                                                    <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX3}</div>
                                                    :
                                                    '0.00'
                                              )
                                          }
                                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '50px', wordBreak: 'break-all' }}>{item?.amount}</div>
                                        </div>
                                      </div>
                                    ))

                                  }

                                </div>
                              </div>

                              :
                              tableHeaderSettings == '4' && billBodyContent == false ?
                                <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                  <thead>
                                    <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                                      <th className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th>

                                      <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                                    </tr>

                                  </thead>
                                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>

                                    {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                                      lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>

                                      (
                                        <tr  >
                                          <td className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', wordBreak: 'break-all' }}>{item?.ProductName}</td>
                                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                          {item?.TAX1 != '0.00' ?
                                            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
                                            : (
                                              item?.TAX2 != '0.00' ?
                                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
                                                :
                                                item?.TAX3 != '0.00' ?
                                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
                                                  :
                                                  '0.00'
                                            )
                                          }
                                          <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                                        </tr>
                                      ))

                                    }

                                  </tbody>
                                </table>

                                :
                                ''
              }
              {/* <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                    <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                    <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>

                    <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                  </tr>

                </thead>
                <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length > 0 &&

                    lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.map((item, index) =>

                    (
                      <tr  >
                        <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                        <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                        <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                        <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                      </tr>
                    ))

                  }

                </tbody>
              </table> */}
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Total Items :{lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.length > 0 ? (lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.length) : 0}</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>Total Qty:{qtyTotal != 0 ? qtyTotal : "0.00"}</p>

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Sub Total :</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                  {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.length > 0 && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt.subtotal != null && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt.subtotal != undefined ? lastReceiptDetailsData?.LastReceiptArr?.LastReceipt?.subtotal : "0.00"}</p>

              </div>


              {showTaxDetails == true && lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.length > 0 &&
                lastReceiptDetailsData?.LastReceiptArr[0].TAX1pergrp != null && lastReceiptDetailsData?.LastReceiptArr[0].TAX1pergrp != undefined && lastReceiptDetailsData?.LastReceiptArr[0].TAX1pergrp?.map((i) => (
                  i.tax1name === "GST" ?
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                          {`CGST${parseInt(i.tax1per) / 2}%`}
                        </p>
                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                          {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt) / 2 : "0.00"}</p>

                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                          {`SGST${parseInt(i.tax1per) / 2}%`}
                        </p>
                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                          {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) / 2 : "0.00"}</p>

                      </div></>
                    :
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                        {`${i?.tax1name}${parseInt(i?.tax1per)}%`}
                      </p>
                      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                        {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                    </div>
                ))}

              {showTaxDetails == true && lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.length > 0 &&
                lastReceiptDetailsData?.LastReceiptArr[0].TAX2pergrp != null && lastReceiptDetailsData?.LastReceiptArr[0].TAX2pergrp != undefined && lastReceiptDetailsData?.LastReceiptArr[0].TAX2pergrp?.map((i) => (
                  i.tax2name === "GST" ?
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                          {`CGST${parseInt(i.tax2per) / 2}%`}
                        </p>
                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                          {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt) / 2 : "0.00"}</p>

                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                          {`SGST${parseInt(i.tax2per) / 2}%`}
                        </p>
                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                          {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) / 2 : "0.00"}</p>

                      </div></>
                    :
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                        {`${i?.tax2name}${parseInt(i?.tax2per)}%`}
                      </p>
                      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                        {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                    </div>
                ))}

              {showTaxDetails == true && lastReceiptDetailsData?.LastReceiptArr !== undefined && lastReceiptDetailsData?.LastReceiptArr !== null && lastReceiptDetailsData?.LastReceiptArr?.length > 0 &&
                lastReceiptDetailsData?.LastReceiptArr[0].TAX3pergrp != null && lastReceiptDetailsData?.LastReceiptArr[0].TAX3pergrp != undefined && lastReceiptDetailsData?.LastReceiptArr[0].TAX3pergrp?.map((i) => (
                  i.tax3name === "GST" ?
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                          {`CGST${parseInt(i.tax3per) / 2}%`}
                        </p>
                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                          {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt) / 2 : "0.00"}</p>

                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                          {`SGST${parseInt(i.tax3per) / 2}%`}
                        </p>
                        <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                          {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) / 2 : "0.00"}</p>

                      </div></>
                    :
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                        {`${i?.tax3name}${parseInt(i?.tax3per)}%`}
                      </p>
                      <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                        {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                    </div>
                ))}
              {/* <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
               <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Sub Total :</p>
               
               <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} { gstSubTotal != 0  ? gstSubTotal.toFixed(2) : "0.00" }</p>

            </div> */}
              {/* <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
               <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST</p>
               
               <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} { gstTotal != 0 ? gstTotal.toFixed(2) : "0.00" }</p>
        
            </div> */}
              {/* {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== undefined &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== null &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length >0 &&
                lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.map((item, index) => 
                (item.gstper != null && item.gstper != undefined && item.gstper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.gstper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.gst.length > 0  &&  item.gst ? item.gst : "0.00"  }</p>
              
             </div>
                )
               
               )} */}
              {/* {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== undefined &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== null &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length >0 &&
                lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.map((item, index) => 
                (item.vatper != null && item.vatper != undefined && item.vatper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.vatper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.vat.length > 0  &&  item.vat ? item.vat : "0.00"  }</p>
              
             </div>
                )
               
               )} */}
              {/* {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== undefined &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== null &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length >0 &&
                lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.map((item, index) => 
                (item.cessper != null && item.cessper != undefined && item.cessper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.cessper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.cess.length > 0  &&  item.cess ? item.cess : "0.00"  }</p>
              
             </div>
                )
               
               )} */}
              {/* {lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== undefined &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray !== null &&  lastReceiptDetailsData?.LastReceiptArr?.ItemsArray.length >0 &&
                lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.map((item, index) => 
                (item.cessper != null && item.cessper != undefined && item.cessper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.cessper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.cess.length > 0  &&  item.cess ? item.cess : "0.00"  }</p>
              
             </div>
                )
               
               )} */}
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>DISCOUNT</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {DiscountTotallastRecpt != null && DiscountTotallastRecpt != undefined && DiscountTotallastRecpt != 0 ? DiscountTotallastRecpt : "0.00"}</p>
                {/* .toFixed(2) */}

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', color: 'black' }}>Total</p>

                <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {amountTotal != undefined && amountTotal != null && amountTotal != 0 ? Math.round(amountTotal).toFixed(2) : "0.00"}</p>

              </div>
              {showbillFooter == true ?
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse', borderTop: '1px dotted black' }}>
                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{lastReceiptDetailsData?.LastReceiptArr?.ItemsArray?.length > 0 && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt.paymode != null && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt.paymode != undefined && lastReceiptDetailsData?.LastReceiptArr?.LastReceipt?.paymode}</p>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {lastReceiptCash != 0 && lastReceiptCash != null && lastReceiptCash != undefined ? lastReceiptCash : "0.00"}</p>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
                    <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Change</p>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}> {companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.currency.split(",", 1)} {lastReceiptChange != 0 && lastReceiptChange != null && lastReceiptChange != undefined ? lastReceiptChange : "0.00"}</p>

                  </div>
                </>
                :
                ""
              }

              {/* ,justifyContent:'space-evenly' */}
              <div style={{ display: 'flex', borderTop: '1px dotted black', borderCollapse: 'collapse', justifyContent: 'center' }}>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No's :</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{lastReceiptKOTNos != null && lastReceiptKOTNos != undefined ? lastReceiptKOTNos : "0.00"}</p>

              </div>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.length > 0 && companyDetails != undefined && companyDetails != null && companyDetails?.remarks1}</p>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.remarks2}</p>
              <img ref={inputRef} style={{ height: '20%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />


            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </div>

  );

}

