import {
    createSlice,
    createEntityAdapter,
    createSelector,
    createAsyncThunk,
    } from "@reduxjs/toolkit";
    
    // const apolloFetch = createApolloFetch({ uri });
    // const variables = {
    // "count": 5
    // };
    const query = `query ProductList($search:String!){ #pass the search string
    products(first: 100, filter: {search:$search },channel:"default-channel") {
    edges {
    node {
    name #item name
    id #item Id
    variants {
    name #item's variant name[sub-item name]
    id #variant Id
    }
    }
    }
    }
    }
    `;
    
    const searchQuery = `query ProductList($search:String!){ #pass the search string
    products(first: 100, filter: {search:$search }) {
    edges {
    node {
    name #item name
    id #item Id
    variants {
    name #item's variant name[sub-item name]
    id #variant Id
    }
    }
    }
    }
    }
    `;
    
    const searchAdapter = createEntityAdapter();
    const initialState = searchAdapter.getInitialState({
    status: "idle",
    error: null,
    itemssearch: null,
    });
    
    export const searchItems = createAsyncThunk("csearch/items", async (item) => {
    // const response = await client.headers( {Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5MzA5MDgsIm93bmVyIjoic2FsZW9yIiwiZXhwIjoxNjI2OTMxMjA4LCJ0b2tlbiI6Iko4MFRBMEE5RjBmTyIsImVtYWlsIjoib25saW5lYWNjb3VudHNAYW1lcG9zLmluIiwidHlwZSI6ImFjY2VzcyIsInVzZXJfaWQiOiJWWE5sY2pveCIsImlzX3N0YWZmIjp0cnVlfQ.x_0NziiFpPnVkD87fopROrjt4NhelI38jvHdmT2wWJs'}).query({ query: PRODUCTS, variables: { "category" : "Q2F0ZWdvcnk6Nw==" } });
    // console.log("data items list",response.data);
    // return response.data;
    console.log("====================================");
    console.log(item);
    console.log("===================yas=================");
    //alert(id);
    //debugger;
    // const response = () => {
    // //POST json
    let dataToSend = {
        token:localStorage.getItem('token'),
    search_customer: item.item[0].st,
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const response = await fetch(
    "https://superadmin.amepos.io/TabPosApiNew/tabpos_searchcustomer",
    {
    method: "POST",
    headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
    }
    );
    return response.json();
    });
    
    export const customersearchSlice = createSlice({
    name: "csearch",
    initialState,
    reducers: {
    searchTerm: (state, action) => {
    console.log("dararayd,s,msmsl", action.payload);
    
    state.searchTerm = action.payload;
    },
    
    //--------------------------
    searchAllItemsRemove(state, action) {
    // const products = state.entities[action.payload.id]
    
    searchAdapter.removeAll(state);
    },
    //=======================
    },
    
    extraReducers: {
    [searchItems.pending]: (state, action) => {
    state.status = "loading";
    },
    [searchItems.fulfilled]: (state, action) => {
    state.status = "succeeded";
    state.itemssearch = action.payload;
    
    searchAdapter.removeAll(state);
    console.log("===================PRODUCT=================");
    console.log(action.payload);
    console.log("====================================");
    
    const CustomerArr = action.payload.CustomerArr;
    const arr = [];
    
    for (let i = 0; i < CustomerArr.length; i++) {
    arr.push({
    id: i.toString(),
    id: i.toString(),
    custid: CustomerArr[i].custid,
    custnam: CustomerArr[i].custnam,
    phno: CustomerArr[i].phno,
    dob: CustomerArr[i].dob,
    addr1: CustomerArr[i].addr1,
    addr2: CustomerArr[i].addr2,
    addr3: CustomerArr[i].addr3,
    addr4: CustomerArr[i].addr4,
    addr5: CustomerArr[i].addr5,
    email: CustomerArr[i].email,
    Customer: CustomerArr[i].Customer
    });
    }
    console.log("====================================");
    console.log(arr);
    console.log("====================================");
    arr.map((node) => searchAdapter.addOne(state, node));
    },
    [searchItems.rejected]: (state, action) => {
    state.status = "failed";
    state.error = action.error.message;
    },
    },
    });
    
    export const { searchAllItemsRemove } = customersearchSlice.actions;
    
    export default customersearchSlice.reducer;
    export const {
    selectAll: selectSearchItems,
    selectEntities: select,
    } = searchAdapter.getSelectors((state) => state.csearch);
    
