



/* eslint-disable no-use-before-define */
import React, {useRef, useState,useEffect } from 'react';
import {TextField} from '@mui/material';

// import {  TextField, Select } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Autocomplete } from '@material-ui/lab';
import{ Autocomplete,  createFilterOptions } from '@mui/material';
// @mui/material/Autocomplete
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/DropDownMenu';
import { makeStyles } from '@material-ui/core/styles';
import { client } from '../../utilities/client';
import { gql } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'reactstrap';
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { useDispatch, useSelector } from 'react-redux';
import './SearchProducts.css';
import { searchAllItemsRemoveAfterSearch, searchItems } from '../../reducers/searchItemsSlice';
import { searchAllItemsRemove } from '../../reducers/itemsSlice';
import CancelKotadmin from './CancelKotadmin';
import { selectSearchItems } from '../../reducers/searchItemsSlice';
import ShowChangePrice from './ShowChangePrice';
import { showHoldSaleButton } from '../../reducers/ConstantSlice';
import styled from "styled-components";


// const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    flex: 1,
    height: "100%",
    padding: 0
  },
  popupIndicator: {
    flex: 1,
    height: "100%",
    padding: 0
  },
  root: {
    flex: 1,
    height: "100%",
    padding: 0
  },
  // .css-1027a8t-MuiAutocomplete-root .MuiOutlinedInput-root
}));


function useKey(key,cb){
  const callbackRef = useRef(cb);
  useEffect(()=>{
    callbackRef.current = cb;
  })
  useEffect(()=>{
    function handle(event){
      if(event.code === key){
        callbackRef.current(event)
      }
    }
    document.addEventListener("keypress",handle);
    return () =>document.removeEventListener("keypress",handle)
  },[key])
}

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 1.5rem;
  }
`;


export default function SearchProducts() {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const classes = useStyles();
  const [value, setValue] =  React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [emptyCartName, setEmptyCartName] = React.useState("");
  const searchData = useSelector(selectSearchItems)
  const cartItemsList = useSelector(selectAllCart);
  const [adminPanel, setAdminPanel] = useState(false);
  const [inputValueSearch, setInputValueSearch] = React.useState("");
  
  const [qtyPlucCode,setQtyPlucCode] = useState(false)
  const [changePriceAdminModal,setChangePriceAdminModal] = useState(false)
  const [cancelKotData,setCancelKotData] = useState([])
  const dispatch = useDispatch();
   React.useEffect(() => {
    let active = true;
    setOptions(searchData)
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await client
      // .query({query:SEARCHPRODUCTS,variables: { "count": 10}});
    
      // const products = await response.data.products.edges;
      
      // if (active) {
        // const optionsProducts= products.map((node) => node);
        // console.log(optionsProducts);
       
      // }
      ;
    })();

    return () => {
      active = false;
    };
  }, [loading,searchData]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  const CloseEditQuantity = () => setChangePriceAdminModal(false);
  const addLineItem = (plucod)=>{
    var hsnoconst = 0;
    var tablevalue = ''
    
    if (localStorage.getItem('tableno') == 'CT') {
      hsnoconst = localStorage.getItem('hsno')
      tablevalue = `CT-${localStorage.getItem('TerminalName')}`
    } else {
      hsnoconst = 0
      tablevalue = localStorage.getItem('tableno')
    }
    const date = new Date();
    const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
    
    
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
      
      hsno:hsnoconst,
    tqty: "1",
    itemid: plucod,
    tabno: tablevalue,
    tym: datetime,
    ordusr:  localStorage.getItem('UserID'),
    openin: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_itemdetails", {
    method: "POST",
    body: formBody,
    headers: {
    //Header Defination
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    })
    .then((response) => response.json()
    // console.log(response,"response")
    // 
    )
    //If response is in json then in success
    .then((responseJson) => {
    console.log(responseJson,"responseJson");
    const item = [
    {
    tableno: localStorage.getItem('tableno'),
    // tokenCreate: tokenCreate,
    terminalno: localStorage.getItem('TerminalName'),
    },
    ];
    dispatch(cartItems(item));
    const arr = [];
    
    if (responseJson.ProductDetailsArr[0].Product != 2) {
    for (let i = 0; i < responseJson.ProductDetailsArr.length; i++) {
    arr.push({
    id: i.toString(),
    productdetails: responseJson.ProductDetailsArr[i].ProductDetails,
    });
    }
    // console.log(searc);
    searchProducts('')
    setOptions([])
    setValue({})
    setInputValueSearch('')
    // setproduct(arr);
    } else {
    alert("Sorry No Data");
    //navigation.replace('DrawerNavigationRoutes')
    }
    })
    //If response is not in json then in error
    .catch((error) => {
    // alert("alert Data not found getItems alert");
    });
    
    
    
    
    
    }

  const emptyCartData = (sno) => {
    
    if( cartItemsList[0].name != "yasar" &&  cartItemsList[0].kotno == "0" ){
      
      var hsnoconst = 0;
      var tablevalue = ''
      if (localStorage.getItem('tableno') == 'CT') {
        hsnoconst = localStorage.getItem('hsno')
        tablevalue = `CT-${localStorage.getItem('TerminalName')}`
      } else {
        hsnoconst = 0
        tablevalue = localStorage.getItem('tableno')
      }
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
      tabno:  tablevalue,
      
      terminal:localStorage.getItem('TerminalName'),
      hsno:hsnoconst,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_emptycart", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        const item = [
          {
              tableno: localStorage.getItem('tableno'),
          },
        ];
        
      dispatch({type:"discountPrecentage",payload:`0.00`});
        dispatch(cartItems(item));
        if (responseJson.EmptyCartArr[0].EmptyCart != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.EmptyCartArr.length; i++) {
            arr.push({
              emt: responseJson.EmptyCartArr[i].EmptyCart,
            });
          }

          if (responseJson.EmptyCartArr[0].cancelkot.length > 0) {

            setCancelKotData(responseJson.EmptyCartArr[0].cancelkot)
          }
          console.log("HIII");
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          // alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  }
  else if( cartItemsList[0].name != "yasar"){

    setAdminPanel(true)
    setEmptyCartName("EMPTY")
  }
}
  
  const CloseAdminPanel = () => setAdminPanel(false);

  const searchProducts = (text) => {
    setSearchTerm(text)
    if (text.length > 0) {
    const st = text;
    setSearchTerm(st)
    dispatch(searchAllItemsRemove());
    var item=[{
    token:localStorage.getItem('token'),
    
    st:text}]
    dispatch(searchItems({item}));
   
    
    }else{
      
      dispatch(searchAllItemsRemoveAfterSearch());
    }
  
    }

    console.log(value?.productname,inputValueSearch,"inputValueSearch");
    console.log(options,"options");
    // const dummyFunc = (i) =>{
    //   console.log(i,"skjnsnk");
    // }


    const changeSearchItem = (i,m) =>{
      console.log(i,m,"skjnsnk");
      if(m != null && m != undefined){

      setValue(m)
      }
      // setSearchValue(i)
      // setItemValue(i.node.variants[0].channelListings[0].price.amount)
    }
    function handleEnter(){
      console.log("Enter key is pressed");
      addItemsToCart()
  
    }
    const addItemsToCart = () =>{
      addItemThroughCheckPrice(value)
    }

    const addItemThroughCheckPrice = (i) =>{

      // dispatch(productId(sno.sno))
      // dispatch(pricelevelID(sno.pricelevel))
      // dispatch(usellpValueId(sno.usellp))

      // dispatch(itemIdPlucode(sno.plucod))
      // localStorage.setItem('sellp',"");
      // of(
        console.log(parseInt(i.sellp),"isellp");
      //   localStorage.getItem('sellp'))
        console.log(typeof(localStorage.getItem('sellp')),"sellp");
      // if(localStorage.getItem('sellp') != null || localStorage.getItem('sellp') != ''){

      //   console.log(localStorage.getItem('sellp'),"sellp");
      // }else{
        if(localStorage.getItem("tableno") == "CT"){
          if(localStorage.getItem('sellp') == '' || localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == undefined){
            if(parseInt(i.sellp) == '0'){
            
              setChangePriceAdminModal(true)
              setQtyPlucCode(i.plucod)
            }else{
              addLineItem(i.plucod)
            }
    
          }else{
            setChangePriceAdminModal(true)
            setQtyPlucCode(i.plucod)
          }
          dispatch(showHoldSaleButton(true))
        }else{
          if(localStorage.getItem('sellp') == '' || localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == undefined){
            
            console.log((localStorage.getItem('sellp')),"sellp");
          if(parseInt(i.sellp) == '0'){
            
            setChangePriceAdminModal(true)
            setQtyPlucCode(i.plucod)
          }else{
            addLineItem(i.plucod)
          }
         
          } 
          // else if(localStorage.getItem('sellp') == '1'){
  
          //   console.log((localStorage.getItem('sellp')),"sellp");
          //   if(i.sellp == '0.00'){
            
          //     setChangePriceAdminModal(true)
          //     setQtyPlucCode(i.plucod)
          //   }else{
          //     addLineItem(i.plucod)
          //   }
          // }
           else if(localStorage.getItem('sellp') == '1'){
  
            console.log((localStorage.getItem('sellp')),"sellp1");
  
            if(i.sellp1 == '0.00'){
            
              setChangePriceAdminModal(true)
              setQtyPlucCode(i.plucod)
            }else{
              addLineItem(i.plucod)
            }
          }
          else if(localStorage.getItem('sellp') == '2'){
            console.log((localStorage.getItem('sellp')),"sellp2");
            if(i.sellp2 == '0.00'){
            
              setChangePriceAdminModal(true)
              setQtyPlucCode(i.plucod)
            }else{
              addLineItem(i.plucod)
            }
          }
          else if(localStorage.getItem('sellp') == '3'){
            console.log((localStorage.getItem('sellp')),"sellp3");
            if(i.sellp3 == '0.00'){
            
              setChangePriceAdminModal(true)
              setQtyPlucCode(i.plucod)
            }else{
              addLineItem(i.plucod)
            }
          }else if(localStorage.getItem('sellp') == '4'){
            console.log((localStorage.getItem('sellp')),"sellp4");
            if(i.sellp4 == '0.00'){
            
              setChangePriceAdminModal(true)
              setQtyPlucCode(i.plucod)
            }else{
              addLineItem(i.plucod)
            }
          }else if(localStorage.getItem('sellp') == '5'){
            console.log((localStorage.getItem('sellp')),"sellp5");
  
            if(i.sellp5 == '0.00'){
            
              setChangePriceAdminModal(true)
              setQtyPlucCode(i.plucod)
            }else{
              addLineItem(i.plucod)
            }
          }
          
          
          else{
            setChangePriceAdminModal(true)
            setQtyPlucCode(i.plucod)
          }
        }
        
    }
    

    useKey("Enter",handleEnter)
  return (
    <div style={{display:'flex',height: "8vh"}}>
      <div className='col-md-9'>
        
      <div class="has-search mt-2">
      {/* <div class=" has-search mt-1"> */}
        {/* <span class="form-control-feedback"><FontAwesomeIcon icon='search' /></span> */}
          {/* <input type="text" className="Form__Control" placeholder="Search Products" style={{outline:'0'}}  onChange={(e) => searchProducts(e.target.value)} ></input> */}
          {/* </div> */}
      {/* <input type="text" class="form-control  rounded-0" /> */}
          

     
      <Autocomplete
      className={classes}
      
      sx={{ height: `calc(2.5em + 0.75rem + 2px)`,overflow:'hidden',padding:'0px',borderRadius:'5px' }}
       onEnter
      //  value={value?.productname != null && value?.productname != undefined ? value?.productname : '--'} 
       options={options}
      loading={loading}
      ListboxProps={{ style: { maxHeight: "5rem" }, position: "bottom-start" ,padding:'0px'}}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      inputValue={inputValueSearch != null && inputValueSearch != undefined && inputValueSearch != 'undefined' ? inputValueSearch : ''}
      onInputChange={(e, options) => {
        if(options != null && options != undefined){

          setInputValueSearch(options)
          console.log(e?.target.value,options,"e.changevalue1");
        }
        console.log(e?.target.value,options,"e.changevalue");
          searchProducts( options)
      }}
      onChange={(e, options, reason) => {
        
        // setInputValueSearch(options?.productname)
        // setInputValueSearch(options)
        // console.log(e?.target.value,options,"e.changevalue2");
        changeSearchItem(e?.target.value, options)}
      }
      
      getOptionSelected={(option, value) => option?.productname == value?.productname}
      getOptionLabel={(option) => option?.productname}
      filterSelectedOptions
      freeSolo
      renderInput={(params) => (
      <div class="">
         <span class="form-control-feedback mt-2"><FontAwesomeIcon icon='search' /></span>
        <TextField 
        {...params}    
        // label="items"
        // margin="normal"
        // style={{padding:'0px',height:'10px',borderBottom:'0px'}} 
        placeholder="Search Product"
        variant="standard"
        // InputProps={{
        //   ...params.InputProps,
        //   endAdornment: (
        //     <React.Fragment style={{padding:'0px'}}>
        //     {loading ? <CircularProgress color="inherit" size={20} /> : null}
        //       {params.InputProps.endAdornment}
        //     </React.Fragment>
        //   ),
        // }}
          // error={Boolean(errors?.autocomplete)}
          // helperText={errors?.autocomplete?.message}
        />
      
      </div>
      )}
      // freeSolo
     
      // renderInput={params => (
        // <div class="has-search mt-2">
        // <span class="form-control-feedback mt-2"><FontAwesomeIcon icon='search' /></span>
        // <TextField {...params}   label="Search Products" variant="outlined" 
        //  InputProps={{
        //     ...params.InputProps,
        //     endAdornment: (
        //       <React.Fragment>
        //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
        //         {params.InputProps.endAdornment}
        //       </React.Fragment>
        //     ),
        //   }}
          // /> 
            // </div>
      // )}
    />


    </div>
    </div>
    <div className='col-md-3 mt-2'>

    <button className='fontSize__Empty btn fontSize_Empty' style={{width:'100%',height:'calc(2.5em + 0.75rem + 2px)',backgroundColor:'rgb(185 7 7)',color:'white',fontWeight:'bold',borderRadius:'5px',fontSize:'65% !important;'}} onClick={()=>emptyCartData()}>Empty Cart</button>
</div>
<CancelKotadmin show={adminPanel} handleClose={CloseAdminPanel} cancelKotData={cancelKotData} cartName={emptyCartName}/>
<ShowChangePrice show={changePriceAdminModal} handleClose={CloseEditQuantity} qtyPlucCode={qtyPlucCode} animation={true}></ShowChangePrice>
  
    </div>
  );
}
 