export const pageComponents = {
  BILLING: 'BILLING',
  VIEWTABLES: 'VIEWTABLES',
  PAYMENT: 'PAYMENT',
  CLOSINGSALES: 'CLOSINGSALES',
  SPLITPAYMENT: 'SPLITPAYMENT',
  SPLITBILL: 'SPLITBILL',
  MERGETABLES: 'MERGETABLES',
  CHANGETABLES: 'CHANGETABLES',
  BUSYORDERS: 'BUSYORDERS',
  ONLINEORDERS: 'ONLINEORDERS',
  REPRINTBILL:'REPRINTBILL',
  REFUNDBILL:'REFUNDBILL',
  CUSTOMERLIST:'CUSTOMERLIST',
  ADDCUSTOMER:'ADDCUSTOMER',
  EDITCUSTOMER:'EDITCUSTOMER',
  SUPPLIERPAYOUTLIST:'SUPPLIERPAYOUTLIST',
  ADDSUPPLIER:'ADDSUPPLIER',
  EXPENSEPAYOUTLIST:'EXPENSEPAYOUTLIST',
  ADDEXPENSE:'ADDEXPENSE',
  ADDEMPLOYEE:'ADDEMPLOYEE',
  FLOATINGCASH:'FLOATINGCASH',
  EMPLOYEEATTENDANCE:'EMPLOYEEATTENDANCE',
  EMPLOYEECHECKIN:'EMPLOYEECHECKIN',
  DELIVERYBOYLIST:'DELIVERYBOYLIST',
  EDITDELIVERYBOY:'EDITDELIVERYBOY',
  ADDDELIVERYBOY:'ADDDELIVERYBOY'
}

export const cashierTabs = {
  DINE_IN: 'DINEIN',
  TAKE_AWAY: 'TAKEAWAY',
  COUNTER_SALE: 'COUNTERSALE',
  BUSY_ORDERS: 'BUSYORDERS',
  ONLINE_ORDERS: 'ONLINEORDERS'
}

export const posType = {
  RESTAURANT: 'RESTAURANT',
  RETAIL: 'RETAIL'
}

export const ClosingSalesType = {
  WITH_DENOMINATION: 'WITH_DENOMINATION',
  WITHOUT_DENOMINATION: 'WITHOUT_DENOMINATION'
}
export const paymentMode = {
  CASH:'CASH',
  CARD:'CARD',
  CREDIT:'CREDIT',
  SPLIT:"SPLIT",
  EWALLET:'EWALLET',
  ONLINE_AC:'ONLINE_AC',
  ONLINE_CARD:'ONLINE_CARD',
  ONLINE_COD:'ONLINE_COD',
  COMPLEMENTRY:'COMPLEMENTRY'
  
}

 export const OnlineOrderTypes = {
    UNCONFIRMED_ORDERS: 'UNCONFIRMED_ORDERS',
    CONFIRMED_ORDERS:'CONFIRMED_ORDERS',
    TO_DELIVERY_PICKUP:'TO_DELIVERY_PICKUP',
    OUT_FOR_DELIVERY:'OUT_FOR_DELIVERY',
    DELIVERED:'DELIVERED',
    Ready_To_Delivery:'Ready_To_Delivery'
    };