import React, { Component } from 'react';
import { ItemsGrid } from './ItemsGrid';
import { Products } from './Products';
import './Billing.css';
import { useSelector } from 'react-redux';
import { selectAllRefund } from '../../reducers/refundSlice';
import { RefundGrid } from './RefundGrid';


// export class Billing extends Component {
 
export const Billing = (props) => {

const refundBill = useSelector(selectAllRefund)
    console.log(refundBill,"refundBil");
// const cartItemsList = useSelector(selectAllCart);
    return (

      <div class="row flex-grow-1" style={{margin:'0px'}}>
        <div class="col-12 col-sm-4 d-none d-sm-flex flex-column " style={{padding:'0px'}}>
          {refundBill.length > 0 && refundBill[0].name != "yasar" ? 
          < RefundGrid/> 
          :
          <ItemsGrid /> 
        
        }
          {/* <ItemsGrid />  */}
        </div>
        <div class="col col-sm-8 d-none d-sm-flex flex-column border-left">
          <Products />
        </div>

      </div>



    );
}
