import React, { useRef, useState, useEffect } from 'react';
import './ItemsGrid.css';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateBill, updateQty, addBill, addBillItem, selectBillById, selectItem, addRemarks } from '../../reducers/billsSlice';
import { setActivePage } from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, paymentMode } from '../../utilities/Constants'
import { AddOnModal } from "./AddOnModal";
import SearchProducts from "./SearchProducts"
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { fetchItems } from '../../reducers/addonSlice';
import { addonRemarksShow, itemIdPlucode, paymentModeStatus, pricelevelID, productId, showHoldSaleButton, usellpValueId } from '../../reducers/ConstantSlice';
import { fetchTables } from '../../reducers/tablesSlice';
import { useReactToPrint } from "react-to-print";
import QtyChangeModal from './QtyChangeModal';
import { Button } from 'reactstrap';
import Keypad from '../../assets/images/keypad.png'
import { Image } from 'react-bootstrap';
import { Calc } from '../Commons/Calc';
import CancelKotadmin from './CancelKotadmin';

export const ItemsGrid = () => {

  const dispatch = useDispatch();

  const { discountPrecentage } = useSelector((state) => state.touch);
  const addonRemarksShowData = useSelector((state) => state.constVar.addonRemarksShow)
  const { showHoldSaleButtonData } = useSelector((state) => state.constVar.showHoldSaleButton)

  // console.log(showHoldSaleButtonData,"showHoldSaleButton");

  const usellpValueIdData = useSelector((state) => state.constVar.usellpValueId)
  const productIdData = useSelector((state) => state.constVar.productId)
  const productIDVALUE = useSelector((state) => state.constVar.itemIdPlucode)
  const pricelevelIDValue = useSelector((state) => state.constVar.pricelevelID)
  let varNoOfItems = 0;
  let varNoOfQty = 0;
  let varSubTotal = 0;
  let varTotal = 0;
  let curRow = 0;
  const cartItemsList = useSelector(selectAllCart);
  // console.log(cartItemsList,"cartItemsListinDine");
  try {
    var billamnt = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
      parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    ).toFixed(2);

    var billamnt2 = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal)
    ).toFixed(2);

    var roundamnt2 = Math.round(billamnt2);

    var roundamnt = Math.round(billamnt);
  } catch (error) {
    alert(error)
  }

  const date = new Date();
  const datetime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();


  const activeBillId = useSelector((state) => state.bills.activeBillID)
  const bill = useSelector((state) => selectBillById(state, activeBillId))
  const billingItems = bill === undefined ? undefined : bill.lineitems;
  const [showAddOns, setShowAddOns] = useState(false);
  const [qtyPlucCode, setQtyPlucCode] = useState();
  const [colorchangeData, setColorchangeData] = useState();
  const [notepad, setNotePad] = useState(false);
  const [adminPanel, setAdminPanel] = useState(false);

  const [sliceItem, setSliceItem] = useState('')
  const [emptyCartName, setEmptyCartName] = useState("")
  const [cancelKotData, setCancelKotData] = useState("")
  const [holdSaleList, setHoldSaleList] = useState([])
  const [numericValue, setNumericValue] = useState("");
  const [modelQtyPopUp, setModelQtyPopUp] = useState(false)
  const draftRef = React.useRef(null);
  const [resumeSaleNumber, setResumeSaleNumber] = useState(0)
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const onBeforeGetContentResolve = React.useRef(null);
  // const cartItemsList = useSelector(selectAllCart);
  const [noOfItems, setnoOfItems] = useState(0);
  const [noOfQty, setnoOfQty] = useState(0);
  const [subTotal, setsubTotal] = useState(0);
  const [gst, setgst] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [kotData, setKotData] = useState();
  // try {
  //   var billamnt = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
  //     parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
  //     parseFloat(cartItemsList[cartItemsList.length - 1].gst)
  //   ).toFixed(2);
  //   var roundamnt = Math.round(billamnt);
  // } catch (error) {
  //   alert("Data not Found")
  // }

  const CloseAddOns = () => setShowAddOns(false);
  const ShowAddOns = () => {

    setShowAddOns(true);
  }
  const incrementCart = (quantiy, sno, pricelevel, kot, usellp) => {
    console.log(kot, "kot");
    var hsnoconst = 0;
    var tablevalue = ''
    if (localStorage.getItem('tableno') == 'CT') {
      hsnoconst = localStorage.getItem('hsno')
      tablevalue = `CT-${localStorage.getItem('TerminalName')}`
    } else {
      hsnoconst = 0
      tablevalue = localStorage.getItem('tableno')
    }
    let dataToSend = {
      //   wanip: tokenCreate[0].Wanip,
      //   dbusr: tokenCreate[0].DBUser,
      //   dbpwd: tokenCreate[0].DBPwd,
      //   dbnam: tokenCreate[0].DBname,

      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      sno: sno,
      tabno: tablevalue,
      ordusr: localStorage.getItem('UserID'),
      terminal: localStorage.getItem('TerminalName'),
      rateid: pricelevel,
      tqty: quantiy,
      usellp: usellp,

      hsno: hsnoconst,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_modifycartitem", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        const item = [
          {
            tableno: localStorage.getItem('tableno'),

          },
        ];
        dispatch(cartItems(item));

        if (responseJson.ModifyCartArr[0].ModifyCart != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.ModifyCartArr.length; i++) {
            arr.push({
              Sno: responseJson.ModifyCartArr[i].sno,
              Amount: responseJson.ModifyCartArr[i].amount,
              SubTotal: responseJson.ModifyCartArr[i].subtotal,
              GST: responseJson.ModifyCartArr[i].totgst,
              Total: responseJson.ModifyCartArr[i].total,
            });
          }

        } else {
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        alert("Data not Found")
      });
  };

  const handlePayment = event => {
    dispatch(setActivePage(pageComponents.PAYMENT));
    dispatch({ type: "totalamount", payload: roundamnt })
    dispatch({ type: "subtotalamount", payload: roundamnt2 })
    dispatch({ type: "ADD_TOUCH", payload: 0 })
    dispatch({ type: "balance", payload: 0 })
    dispatch({ type: "dueamount", payload: 0 })
    dispatch(paymentModeStatus({ paymentMode: paymentMode.CASH }));
    //dispatch(setActivePage(pageComponents.SPLITPAYMENT));//TESTING
    //dispatch(setActivePage(pageComponents.SPLITBILL));//TESTING
    //dispatch(setActivePage(pageComponents.MERGETABLES));//TESTING
    event.preventDefault();
  }

  let content;
  // const dispatch = useDispatch();

  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }

  const handleRowClick = (event, payload) => {
    event.preventDefault();
    dispatch(selectItem(payload));

  }

  const handleEditItem = (event, payload) => {
    event.preventDefault();
    dispatch(selectItem(payload));
    ShowAddOns();

  }
  const handleQtyUpdate = (event, payload) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(updateQty(payload));

  }

  const AddRemarksandAddon = (sno) => {
    console.log(sno, "snosno");
    if (sno.sno === colorchangeData) {
      setColorchangeData()
    } else {

      dispatch(addonRemarksShow(true))
      setColorchangeData(sno.sno)
      dispatch(productId(sno.sno))
      dispatch(pricelevelID(sno.pricelevel))
      dispatch(usellpValueId(sno.usellp))

      dispatch(itemIdPlucode(sno.plucod))
      getAddon(sno)
    }
  }

  var getAddon = (sno) => {

    dispatch({ type: "usellp", payload: sno.usellp });
    dispatch({ type: "pricelevel", payload: sno.pricelevel });
    dispatch({ type: "sno", payload: sno.sno });
    dispatch({ type: "addonshow", payload: false });
    dispatch({ type: "remarkshow", payload: true });

    var item = [
      {
        sno: sno.sno,

      },
    ];
    dispatch(fetchItems(item));

  };

  const keydownHandler = (evt) => {

    var itemGrid = document.getElementById('tblItemGrid');
    var tbody = itemGrid.getElementsByTagName('tbody')[0];
    var trows = tbody.getElementsByTagName('tr');

    // store these so we won't have to keep recalculating
    var numRows = trows.length;

    // return the old cell to normal
    for (var t = 0; t < numRows; ++t) {
      var trow = trows[t];

      if (trow.className == "highlight") {

        curRow = t;
        break;
      }

    }
    tbody.getElementsByTagName('tr')[curRow].className = '';

    // increment/decrement the position of the current cell
    // depending on the key pressed
    if (evt.keyCode == 38 && curRow > 0) // up
      curRow--;
    else if (evt.keyCode == 40 && curRow < numRows - 1) // down
      curRow++;

    // update the new cell
    tbody.getElementsByTagName('tr')[curRow].className = 'highlight';
    // tbody.getElementsByTagName('tr')[curRow].click() ;
  }



  const deleteCart = (sno, kot) => {
    console.log(kot, typeof (kot), "kot");
    if (kot == "0") {
      var hsnoconst = 0;
      var tablevalue = '';
      if (localStorage.getItem('tableno') == 'CT') {
        hsnoconst = localStorage.getItem('hsno')
        tablevalue = `CT-${localStorage.getItem('TerminalName')}`
      } else {
        hsnoconst = 0
        tablevalue = localStorage.getItem('tableno')
      }

      let dataToSend = {
        hsno: hsnoconst,
        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token: localStorage.getItem('token'),
        sno: sno,
        tabno: tablevalue,
        ordusr: localStorage.getItem('UserID'),

        terminal: localStorage.getItem("TerminalName")
        //   wanip: tokenCreate[0].Wanip,
        //   dbusr: tokenCreate[0].DBUser,
        //   dbpwd: tokenCreate[0].DBPwd,
        //   dbnam: tokenCreate[0].DBname,
        //   sno: sno,
        //   ordusr: pinCreate[0].UserID,
        //   tabno: tableno,
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_deletecart", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          const item = [
            {
              tableno: localStorage.getItem('tableno'),
            },
          ];
          dispatch(cartItems(item));
          if (responseJson.DeleteCartArr[0].DeleteCart != 2) {

            const arr = [];
            for (let i = 0; i < responseJson.DeleteCartArr.length; i++) {
              arr.push({
                delete: responseJson.DeleteCartArr[i].DeleteCart,
              });
            }
            if (responseJson.DeleteCartArr[0].cancelkot.length > 0) {
              setCancelKotData(sno)
            }

          } else {
            alert("Sorry No Data");
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          alert("Data not Found")
        });
    } else {
      setCancelKotData(sno)
      setAdminPanel(true)
      setEmptyCartName("DELETE")
    }
  }

  const decrementCart = (quantiy, sno, pricelevel, usellp) => {
    if (quantiy === 0) {
      deleteCart(sno);
    } else {
      
      var tablevalue = '';
     
  
      var hsnoconst = 0;
      if (localStorage.getItem('tableno') == 'CT') {
        hsnoconst = localStorage.getItem('hsno')
        tablevalue = `CT-${localStorage.getItem('TerminalName')}`
      } else {
        hsnoconst = 0
        tablevalue = localStorage.getItem('tableno')
      }
      console.log(tablevalue, "tablevalue");
      let dataToSend = {

        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,
        // sno: sno,
        // ordusr: pinCreate[0].UserID,
        // tabno: tableno,
        // terminal: terminalno,
        // rateid: pricelevel,
        // tqty: quantiy,

        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token: localStorage.getItem('token'),
        sno: sno,
        tabno: tablevalue,
        ordusr: localStorage.getItem('UserID'),
        terminal: localStorage.getItem('TerminalName'),
        rateid: pricelevel,
        tqty: quantiy,
        usellp: usellp,
        hsno: hsnoconst,
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_modifycartitem", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          const item = [
            {
              tableno: localStorage.getItem('tableno'),
            },
          ];
          dispatch(cartItems(item));

          if (responseJson.ModifyCartArr[0].ModifyCart != 2) {
            const arr = [];
            for (let i = 0; i < responseJson.ModifyCartArr.length; i++) {
              arr.push({
                Sno: responseJson.ModifyCartArr[i].sno,
                Amount: responseJson.ModifyCartArr[i].amount,
                SubTotal: responseJson.ModifyCartArr[i].subtotal,
                GST: responseJson.ModifyCartArr[i].totgst,
                Total: responseJson.ModifyCartArr[i].total,
              });
            }

          } else {
            alert("Sorry No Data");
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          alert("Data not Found")
        });
    }
  };
  ///////////////// SEND KOT /////////////////////////////
  const addItemsPad = () => {
    if (numericValue != null && numericValue != undefined && numericValue != '' && numericValue.length > 0) {

      const date = new Date();
      const datetime =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

        var tablevalue = '';
        if (localStorage.getItem('tableno') == 'CT') {
          // hsnoconst = localStorage.getItem('hsno')
    
          // var string = localStorage.getItem('TerminalName')
          // string.replace(/[A-Za-z]/g, "").replace(/[^\d.-]/g, '');
    
          // var ctvalue = string.replace(/[A-Za-z]/g, "")
          // console.log(ctvalue, "string");
    
          tablevalue = `CT-${localStorage.getItem('TerminalName')}`
        } else {
          // hsnoconst = 0
    
          tablevalue = localStorage.getItem('tableno')
        }
    
        console.log(tablevalue, "tablevalue");

      let dataToSend = {
        
        tabno: tablevalue,
        token: localStorage.getItem('token'),
        sno: productIdData,
        rateid: pricelevelIDValue,
        tqty: numericValue,
        terminal: localStorage.getItem('TerminalName'),
        ordusr: localStorage.getItem('UserID'),
        usellp: usellpValueIdData,

        hsno: localStorage.getItem('hsno'),
        // token:localStorage.getItem('token'),

        // tqty: numericValue,
        // itemid: productIDVALUE,
        // tabno: localStorage.getItem('tableno'),
        // tym: datetime,
        // ordusr:  localStorage.getItem('UserID'),
        // openin: localStorage.getItem('TerminalName'),
        // openprice:
        // openprice:
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_modifycartitem.php", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          console.log(responseJson, "addcarttt");
          const item = [
            {
              tableno: localStorage.getItem('tableno'),
              // tokenCreate: tokenCreate,
              terminalno: localStorage.getItem('TerminalName'),
            },
          ];
          dispatch(cartItems(item));
          const arr = [];

          if (responseJson.ModifyCartArr[0].ModifyCart != 2) {
            for (let i = 0; i < responseJson.ModifyCartArr.length; i++) {
              arr.push({
                id: i.toString(),
                productdetails: responseJson.ModifyCartArr[i].ModifyCart,
              });
            }
            // handleClose();

            changetoNotepad()
            clearAll();
            // setproduct(arr);
            console.log("HIII");
          } else {
            console.log("Please check your email id or password");
            alert("Sorry No Data");
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });





    } else {

      const date = new Date();
      const datetime =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();


        var tablevalue = '';
        if (localStorage.getItem('tableno') == 'CT') {
          // hsnoconst = localStorage.getItem('hsno')
    
          // var string = localStorage.getItem('TerminalName')
          // string.replace(/[A-Za-z]/g, "").replace(/[^\d.-]/g, '');
    
          // var ctvalue = string.replace(/[A-Za-z]/g, "")
          // console.log(ctvalue, "string");
    
          tablevalue = `CT-${localStorage.getItem('TerminalName')}`
        } else {
          // hsnoconst = 0
    
          tablevalue = localStorage.getItem('tableno')
        }
    
        console.log(tablevalue, "tablevalue");

      let dataToSend = {
        token: localStorage.getItem('token'),

        hsno: localStorage.getItem('hsno'),
        tqty: 0,
        itemid: productIDVALUE,
        tabno: tablevalue,
        tym: datetime,
        ordusr: localStorage.getItem('UserID'),
        openin: localStorage.getItem('TerminalName'),
        openprice: usellpValueIdData
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_itemdetails", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          console.log(responseJson, "addcarttt");
          const item = [
            {
              tableno: localStorage.getItem('tableno'),
              // tokenCreate: tokenCreate,
              terminalno: localStorage.getItem('TerminalName'),
            },
          ];
          dispatch(cartItems(item));
          const arr = [];

          if (responseJson.ProductDetailsArr[0].Product != 2) {
            for (let i = 0; i < responseJson.ProductDetailsArr.length; i++) {
              arr.push({
                id: i.toString(),
                productdetails: responseJson.ProductDetailsArr[i].ProductDetails,
              });
            }
            // handleClose();

            changetoNotepad()
            clearAll();
            // setproduct(arr);
            console.log("HIII");
          } else {
            console.log("Please check your email id or password");
            alert("Sorry No Data");
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });





    }

  }
  // const addLineItem = ()=>

  const goToKot = () => {
    let dataToSend = {
      token: localStorage.getItem('token'),

      tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {

        if (responseJson.PrintKotArr[0].PrintKot != 2) {
          setKotData(responseJson)

          let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
          var item = [
            {
              link: link,
              tokenCreate: localStorage.getItem('token'),
            },
          ];
          dispatch(fetchTables(item));
          getClear()
          // Actions.vieworders();
          handlePrint()
        } else {
          getClear()
          if (cartItemsList.length > 0 && cartItemsList[0].name != 'yasar') {

            alert("KOT Already Printed");
          }
          else {
            // ""
          }
          //  Actions.vieworders();
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      .catch((error) => {
        alert("Data not Found")
      });
  }

  const getClear = () => {
    //POST json
    let dataToSend = {
      token: localStorage.getItem('token'),

      tabno: localStorage.getItem('tableno'),
      terminal: localStorage.getItem('TerminalName'),

    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
            arr.push({
              clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
            });
          }

        } else {
          alert("Sorry No data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        alert("Data not Found")
      });
  };


  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,

    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });
  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }

  }, [onBeforeGetContentResolve.current, text]);

  // if(localStorage.getItem('LicenseLeft').length > 0){

  //   alert(`${localStorage.getItem('LicenseLeft')}`)
  // }

  const sendKOT = async () => {
    await goToKot();

  }

  useEffect(() => {
    // getdata()
    setSliceItem(localStorage.getItem("tableno").slice(0, 1))
    // handleClickLastReceipt()
  }, [])


  /////////////////////////////////////////////
  const setModelQtyPopUpFunc = (i) => {
    setQtyPlucCode(i)
    setModelQtyPopUp(true)
  }

  const alertCustomer = () => {

    alert("Please Select Customer Name")
    // if(localStorage.getItem('CustomerName') ==null){

  }
  // )
  const alertToADDItems = () => {
    alert("Please add Items to Cart")
  }


  const CloseAdminPanel = () => setAdminPanel(false);

  const CloseEditQuantity = () => setModelQtyPopUp(false);

  const changetoNotepad = () => {
    setNotePad(!notepad)

  }


  const holdSalefunc = () => {
    try {
      if (cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT") {
        // const goToKot = () =>{
        let dataToSend = {
          token: localStorage.getItem('token'),
          terminal: localStorage.getItem('TerminalName'),
          // tabno: localStorage.getItem('tableno'),
        };
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_putholdsale", {
          method: "POST",
          body: formBody,
          headers: {
            //Header Defination
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        })
          .then((response) => response.json())
          //If response is in json then in success
          .then((responseJson) => {
            console.log(responseJson, "responseJson");
            if (responseJson.HoldSaleArr[0].HoldSale != 2) {
              //           setKotData(responseJson)

              // getClear()
              //   // Actions.vieworders();
              //   handlePrint()
              getHoldSalesList()
              // dispatch(cartItems());
              // alert("Added to HoldSale")
            }
          })
          .catch((error) => {
            // alert("Data not Found")
          });
        // }
      }


    } catch (error) {
      // alert(error)
    }
  }

  const getHoldSalesList = () => {
    // if(cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT" ){
    // const goToKot = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.HoldSaleListArr[0].HoldSale != 2) {
          //           setKotData(responseJson)
          // setHoldSaleList(responseJson.HoldSaleListArr)

          setHoldSaleList(responseJson.HoldSaleListArr)
          // getClear()
          //   // Actions.vieworders();
          //   handlePrint()
          // getHoldSalesList()
          dispatch(cartItems());
          alert("Added to HoldSale")
        }
      })
      .catch((error) => {
        // alert("Data not Found")
      });
    // }
    // }
  }
  const ResumeholdSalefunc = () => {
    // console.log(cartItemsList,"dndjon");
    try {
      if (cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT") {
        // const goToKot = () =>{
        let dataToSend = {
          token: localStorage.getItem('token'),
          terminal: localStorage.getItem('TerminalName'),
          // tabno: localStorage.getItem('tableno'),
        };
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_putholdsale", {
          method: "POST",
          body: formBody,
          headers: {
            //Header Defination
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        })
          .then((response) => response.json())
          //If response is in json then in success
          .then((responseJson) => {
            console.log(responseJson, "responseJson");
            if (responseJson.HoldSaleArr[0].HoldSale != 2) {
              //           setKotData(responseJson)

              // getClear()
              //   // Actions.vieworders();
              //   handlePrint()
              getResumeHoldSalesList()
              // dispatch(cartItems());
              // alert("Added to HoldSale")
            }
          })
          .catch((error) => {
            // alert("Data not Found")
          });
        // }
      }
      else if (cartItemsList[0].name == 'yasar') {
        getResumeSalesfunc()
      }


    } catch (error) {
      // alert(error)
    }
  }

  const ResumeholdSalefuncforPrevious = () => {
    // console.log(cartItemsList,"dndjon");
    try {
      if (cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT") {
        // const goToKot = () =>{
        let dataToSend = {
          token: localStorage.getItem('token'),
          terminal: localStorage.getItem('TerminalName'),
          // tabno: localStorage.getItem('tableno'),
        };
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_putholdsale", {
          method: "POST",
          body: formBody,
          headers: {
            //Header Defination
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
        })
          .then((response) => response.json())
          //If response is in json then in success
          .then((responseJson) => {
            console.log(responseJson, "responseJson");
            if (responseJson.HoldSaleArr[0].HoldSale != 2) {
              //           setKotData(responseJson)

              // getClear()
              //   // Actions.vieworders();
              //   handlePrint()
              getResumeHoldSalesListPrevious()
              // dispatch(cartItems());
              // alert("Added to HoldSale")
            }
          })
          .catch((error) => {
            // alert("Data not Found")
          });
        // }
      }
      else if (cartItemsList[0].name == 'yasar') {
        getResumeSalesPreviousfunc()
      }


    } catch (error) {
      // alert(error)
    }
  }
  const getResumeHoldSalesListPrevious = () => {
    // if(cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT" ){
    // const goToKot = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.HoldSaleListArr[0].HoldSale != 2) {
          //           setKotData(responseJson)
          // setHoldSaleList(responseJson.HoldSaleListArr)
          getResumeSalesPreviousfunc()
          // resumeSaleNumber
          // getClear()
          //   // Actions.vieworders();
          //   handlePrint()
          // getHoldSalesList()
          // dispatch(cartItems());
          // alert("Added to HoldSale")
        }
      })
      .catch((error) => {
        // alert("Data not Found")
      });
    // }
    // }
  }

  const getResumeHoldSalesList = () => {
    // if(cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT" ){
    // const goToKot = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.HoldSaleListArr[0].HoldSale != 2) {
          //           setKotData(responseJson)
          setHoldSaleList(responseJson.HoldSaleListArr)
          getResumeSalesfunc()
          // resumeSaleNumber
          // getClear()
          //   // Actions.vieworders();
          //   handlePrint()
          // getHoldSalesList()
          // dispatch(cartItems());
          // alert("Added to HoldSale")
        }
      })
      .catch((error) => {
        // alert("Data not Found")
      });
    // }
    // }
  }
  const getResumeSalesfunc = () => {
    if (holdSaleList.length > resumeSaleNumber) {
      // if(cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT" ){
      // const goToKot = () =>{
      let dataToSend = {
        token: localStorage.getItem('token'),
        hsno: holdSaleList[resumeSaleNumber].hsno,
        terminal: localStorage.getItem('TerminalName'),
        // tabno: localStorage.getItem('tableno'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_resumeholdsale", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          console.log(responseJson, "responseJson");
          if (responseJson.ResumeSaleArr[0].ResumeSale != 2) {
            //           setKotData(responseJson)
            setHoldSaleList(responseJson.HoldSaleListArr)
            // setResumeSaleNumber(resumeSaleNumber+1)
            // getClear()
            //   // Actions.vieworders();
            //   handlePrint()
            // getHoldSalesList()
            dispatch(cartItems());
            // alert("Added to HoldSale")

          }
        })
        .catch((error) => {
          // alert("Data not Found")
        });
      // }
      // }
    } else {
      setResumeSaleNumber(0)
      dispatch(cartItems());
    }
  }

  const getResumeSalesPreviousfunc = () => {
    if (holdSaleList.length > resumeSaleNumber) {
      // if(cartItemsList.length > 0 && cartItemsList[0].name != 'yasar' && localStorage.getItem('TerminalName') != null && localStorage.getItem('TerminalName') != undefined && localStorage.getItem('tableno') == "CT" ){
      // const goToKot = () =>{
      let dataToSend = {
        token: localStorage.getItem('token'),
        hsno: holdSaleList[resumeSaleNumber].hsno,
        terminal: localStorage.getItem('TerminalName'),
        // tabno: localStorage.getItem('tableno'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_resumeholdsale", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          console.log(responseJson, "responseJson");
          if (responseJson.ResumeSaleArr[0].ResumeSale != 2) {
            //           setKotData(responseJson)
            // setHoldSaleList(responseJson.HoldSaleListArr)
            setResumeSaleNumber(resumeSaleNumber - 1)
            // getClear()
            //   // Actions.vieworders();
            //   handlePrint()
            // getHoldSalesList()
            dispatch(cartItems());
            // alert("Added to HoldSale")

          }
        })
        .catch((error) => {
          // alert("Data not Found")
        });
      // }
      // }
    } else {
      setResumeSaleNumber(0)
      dispatch(cartItems());
    }
  }

  const getPreviousResumeData = () => {
    if (resumeSaleNumber >= 0) {
      ResumeholdSalefuncforPrevious()
      // console.log(holdSaleList[resumeSaleNumber].hsno,"sckhibdjn");
    }
  }


  console.log(holdSaleList, cartItemsList, "holdSaleList");
  console.log(resumeSaleNumber, "resumeSaleNumber");

  const putHolSaleFunc = () => {
    // const goToKot = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_putholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.HoldSaleArr[0].HoldSale != 2) {
          getHoldSaleFunc()

          alert("Added to HoldSale")
        }
      })

  }

  const getHoldSaleFunc = () => {

    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.HoldSaleListArr[0].HoldSale != 2) {

          setHoldSaleList(responseJson?.HoldSaleListArr)
          setResumeSaleNumber(responseJson?.HoldSaleListArr?.length)
          dispatch(cartItems());
        }
      })

  }

  const putResumeOnHold = () => {

    // const goToKot = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_putholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.HoldSaleArr[0].HoldSale != 2) {

        }
      })


  }

  const getResumeOnHold = () => {

    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.HoldSaleListArr[0].HoldSale != 2) {
          setHoldSaleList(responseJson.HoldSaleListArr)
          // setHoldSaleList(responseJson.HoldSaleListArr)
          // setResumeSaleNumber(responseJson.HoldSaleListArr.length)
          // dispatch(cartItems());
          // alert("Added to HoldSale")
        }
      })


  }

  const holdfunc = () => {
    if (holdSaleList.length > 0 && resumeSaleNumber === holdSaleList.length) {
      emptyCartData()
      getHoldSaleFunc()
    } else if (cartItemsList.length > 0 && cartItemsList[0].name != "yasar") {

      // setResumeSaleNumber(resumeSaleNumber+1)
      putHolSaleFunc()
    } else {
      alert("Hold Sale Cannot be Empty")
    }

    dispatch(showHoldSaleButton(true))
  }
  const resumeSaleFunc = async () => {

    console.log(holdSaleList, resumeSaleNumber, holdSaleList[resumeSaleNumber - 1].hsno, "holdSaleList[resumeSaleNumber");
    // await putResumeOnHold();

    let dataToSend = {
      token: localStorage.getItem('token'),
      hsno: holdSaleList[resumeSaleNumber - 1].hsno,
      terminal: localStorage.getItem('TerminalName'),
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_resumeholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.ResumeSaleArr[0].ResumeSale != 2) {
          //           setKotData(responseJson)
          // setHoldSaleList(responseJson.HoldSaleListArr)
          // setResumeSaleNumber(resumeSaleNumber-1)
          // getClear()
          //   // Actions.vieworders();
          //   handlePrint()
          // getHoldSalesList()
          dispatch(cartItems());
          // alert("Added to HoldSale")
          if (holdSaleList[resumeSaleNumber - 1].hsno == 'CT') {

            localStorage.setItem("hsno", '0')
          } else {

            localStorage.setItem("hsno", holdSaleList[resumeSaleNumber - 1].hsno)
          }
        }
      })
  }
  // const previousResumeFunc = async () => {
  //   setResumeSaleNumber(resumeSaleNumber - 1)
  //   await putResumeOnHold();
  //   await  getResumeOnHold() 
  //   resumeSaleFunc();

  // }


  ///////////////////////////////


  const previousResumeFunc = async () => {
    if (
      holdSaleList?.length > 0 &&
      holdSaleList[resumeSaleNumber - 1].hsno == "CT"
    ) {
      await setResumeSaleNumber(resumeSaleNumber - 2);
      await resumeSaleFunc(resumeSaleNumber - 2);
    } else {
      await setResumeSaleNumber(resumeSaleNumber - 1);
      // await putResumeOnHold();
      // await getResumeOnHold()
      await resumeSaleFunc(resumeSaleNumber - 1);
    }
  };





  ///////////////////////////////////



  const nextResumeFunc = async () => {
    console.log(resumeSaleNumber, "resumeSaleNumber2");
    if (resumeSaleNumber == holdSaleList.length) {
      emptyCartData()
      localStorage.setItem("hsno", '0')
    } else {
      setResumeSaleNumber(resumeSaleNumber + 1)
      // await putResumeOnHold();
      // await  getResumeOnHold() 
      nextFunc()
      if (holdSaleList[resumeSaleNumber].hsno == 'CT') {

        localStorage.setItem("hsno", '0')
      } else {

        localStorage.setItem("hsno", holdSaleList[resumeSaleNumber].hsno)
      }

    }

  }

  const emptyCartData = (sno) => {


    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),

      terminal:localStorage.getItem('TerminalName'),
      hsno: '',

    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_emptycart", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        const item = [
          {
            tableno: localStorage.getItem('tableno'),
          },
        ];

        dispatch({ type: "discountPrecentage", payload: `0.00` });
        dispatch(cartItems(item));
        if (responseJson.EmptyCartArr[0].EmptyCart != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.EmptyCartArr.length; i++) {
            arr.push({
              emt: responseJson.EmptyCartArr[i].EmptyCart,
            });
          }

          // if (responseJson.EmptyCartArr[0].cancelkot.length > 0) {

          // setCancelKotData(responseJson.EmptyCartArr[0].cancelkot)
          // }
          console.log("HIII");
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          // alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
    //If response is not in json then in error

  }

  const alertSendKot = () => {
    alert("Counter Sale Cannot be Printed")
  }


  const nextFunc = async () => {
    // setResumeSaleNumber(resumeSaleNumber+1)
    console.log(holdSaleList, resumeSaleNumber - 1, "holdSaleList[resumeSaleNumber-1].hsno");
    // if(resumeSaleNumber == 0){
    let dataToSend = {
      token: localStorage.getItem('token'),
      // hsno:holdSaleList[resumeSaleNumber-1].hsno
      hsno: holdSaleList[resumeSaleNumber].hsno,
      terminal: localStorage.getItem('TerminalName'),
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_resumeholdsale", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        if (responseJson.ResumeSaleArr[0].ResumeSale != 2) {
          //           setKotData(responseJson)
          // setHoldSaleList(responseJson.HoldSaleListArr)
          // setResumeSaleNumber(resumeSaleNumber+1)
          // getClear()
          //   // Actions.vieworders();
          //   handlePrint()
          // getHoldSalesList()
          dispatch(cartItems());
          // alert("Added to HoldSale")

        }
      })
    // }


  }
  return (

    <div class="row flex-grow-1" style={{ margin: '0px' }}>
      <div class="col-md-12 d-none d-md-flex flex-column" style={{ padding: '0px' }}>
        <div class="row" style={{ margin: '0px' }}>
          <div class="col-md-12" style={{ padding: '0px' }}>
            {/* <SearchItems /> */}
            <SearchProducts />
          </div>
        </div>
        <div class="row flex-grow-1 tableFixHead" style={{ margin: '0px' }}>
          <div class="col-md-12 " style={{ padding: '0px' }}>
            <table class="table table-list-search" tabIndex={0} id="tblItemGrid" style={{ margin: '0px' }}>
              <thead style={{ height: '30px' }}>
                <tr className="no-top-border">
                  <th className="no-top-border h6fontSize2 text-center" style={{ padding: '0px', width: '10%' }}><b>S.No</b></th>
                  <th className="no-top-border h6fontSize2 text-center" style={{ padding: '0px', width: '30%' }}><b>ITEMS</b></th>
                  <th className="no-top-border h6fontSize2 text-center" style={{ padding: '0px', width: '25%' }}><b>QTY</b></th>
                  {/* text-center */}
                  <th className="no-top-border h6fontSize2 text-center" style={{ padding: '0px', width: '25%' }}><b>TOTAL</b></th>
                  <th className="no-top-border h6fontSize2 text-center" style={{ padding: '0px', width: '10%' }}><b></b></th>

                </tr>
              </thead>
              <tbody>
                {
                  cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                  cartItemsList.map((item, index) =>

                  (
                    // onClick={(e) => handleRowClick(e, { id: item.id, billid: activeBillId })} 
                    <tr className={item.isselected ? "highlight" : ""} >
                      <td class="text-center padding2">{index + 1}</td>
                      {/* onClick={(e) => handleEditItem(e, { id: item.id, billid: activeBillId })} */}
                      {colorchangeData === item.sno ?
                        <td className="text-center padding2 colorChange" style={{ backgroundColor: 'violet' }} onClick={() => AddRemarksandAddon(item)}>
                          <div class="h6fontSize" style={{ wordBreak: 'break-word' }}>{item?.productname}</div>
                          <div><small class="text-muted">{item?.remarks}</small></div>
                          {/* <div><small class="text-muted">{item?.addon}</small></div> */}
                          <div>{item?.addon != null && item?.addon != undefined && item?.addon?.length > 0 && item?.addon.map((i) => <div style={{ fontSize: '55%', color: 'gray' }} >{i.addonnam} <br /></div>)}</div>

                          {/* Qty: */}
                        </td>
                        :
                        <td class="text-center padding2" onClick={() => AddRemarksandAddon(item)}>
                          <div class="h6fontSize" style={{ wordBreak: 'break-word' }}>{item?.productname}</div>
                          <div><small class="text-muted">{item?.remarks}</small></div>
                          {/* {item?.addon}  */}
                          <div>{item?.addon != null && item?.addon != undefined && item?.addon?.length > 0 && item?.addon.map((i) => <div style={{ fontSize: '55%', color: 'gray' }}  >{i.addonnam} <br /></div>)}</div>
                          {/* Qty: */}
                        </td>}
                      {item.kotno == "0" && item.kotno.length > 0 ?
                        <td class="text-center padding2" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <div style={{ width: '25%' }}>
                            {item.totalqty > 1 && <button className="btn btn-danger button_small" style={{ width: '100%' }} onClick={(e) => decrementCart(parseInt(item.totalqty) - 1, item.sno, item.pricelevel, item.usellp)}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}
                            {item.totalqty <= 1 && <button className="btn btn-danger button_small" style={{ backgroundColor: '#460d13', border: '#460d13', width: '100%' }}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}

                          </div>
                          <div style={{ width: '50%', wordBreak: 'break-all' }}>
                            <span class="text-danger    " onClick={() => setModelQtyPopUpFunc(item.plucod)} style={{ width: '100%', wordBreak: 'break-all' }}>{parseInt(item?.totalqty)}   </span>
                            {/* paddingLeft paddingRight
paddingLeft paddingRight */}
                          </div>
                          <div style={{ width: '25%' }}>
                            <button className="btn btn-success btn-sm" onClick={(e) => incrementCart(parseInt(item.totalqty) + 1, item.sno, item.pricelevel, item.kotno, item.usellp)} ><FontAwesomeIcon icon="plus"></FontAwesomeIcon></button>

                          </div>



                        </td>
                        :
                        <td class="text-center padding2" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <div style={{ width: '25%' }}>
                            {item.totalqty > 1 && <button className="btn btn-danger button_small" style={{ width: '100%' }}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}
                            {item.totalqty <= 1 && <button className="btn btn-danger button_small" style={{ backgroundColor: '#460d13', border: '#460d13', width: '100%' }}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}

                          </div>
                          <div style={{ width: '50%', wordBreak: 'break-all' }}>
                            <span class="text-danger    " style={{ width: '100%', wordBreak: 'break-all' }}>{parseInt(item?.totalqty)}   </span>

                          </div>

                          <div style={{ width: '25%' }}>
                            <button className="btn btn-success btn-sm" ><FontAwesomeIcon icon="plus"></FontAwesomeIcon></button>

                          </div>
                        </td>
                      }

                      <td class="text-center padding2" style={{ wordBreak: 'break-all' }}>{Math.round((parseFloat(item?.amount)))}</td>

                      <td class="text-center padding2">

                        <button className="btn btn-danger btn-sm" onClick={() => deleteCart(item.sno, item.kotno)}><FontAwesomeIcon icon="trash"></FontAwesomeIcon></button>
                      </td>
                    </tr>
                  ))

                }

              </tbody>
            </table>
          </div>
        </div>
        {/* m-2 */}
        <div class="row " style={{ margin: '0px' }}>

          <div class="col-md-12" style={{ padding: '0px' }}>
            {/* mb-2 */}
            {/* card */}
            <div class=" shadow  square-edges border-0" >
              <div class="Card__Body " style={{ display: 'flex' }}>
                <table id="summaryDisplay" class="table mb-0">

                  <tbody class="border-0 ">

                    <tr class="pb-2 ">
                      <td style={{ display: 'flex', justifyContent: 'center' }}>{localStorage.getItem("sellpName")}</td>
                    </tr>

                    <tr class="pb-2 ">
                      <td>No Of Items</td>
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].qty ? (cartItemsList?.length) : 0}</td>
                      {/* <td rowSpan="5" className="mx-auto">
                                                <h4 class="text-center">
                                                    Normal Price
                                            </h4>
                                                <h1 class=" text-danger text-center">
                                                    Total</h1>
                                                <h1 class=" text-danger text-center"> */}
                      {/* {cartItemsList?.length > 0 && parseFloat(cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2))} */}
                      {/* {roundamnt != null && roundamnt != undefined ? roundamnt.toFixed(2) : '0.00'}
                                                </h1>
                                            </td> */}
                    </tr>
                    <tr class="pb-2">

                      <td>No Of Qty</td>
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].qty ? parseFloat(cartItemsList[cartItemsList?.length - 1].qty) : 0}</td>

                    </tr>
                    <tr class="pb-2">

                      <td>Sub Total</td>
                      {/* <td>{subTotal.toFixed(2)}</td> */}
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].subTotal ? parseFloat(cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2)) : "0.00"}</td>

                    </tr>
                    <tr class="pb-2">

                      <td>Discount</td>
                      {/* <td>{gst.toFixed(2)}</td>
 */}
                      <td>{discountPrecentage != null && discountPrecentage != undefined && discountPrecentage ? parseInt(discountPrecentage).toFixed(2) : "0.00"}</td>

                    </tr>
                    <tr class="pb-2">

                      <td>TAX</td>
                      {/* <td>{gst.toFixed(2)}</td>
                                             */}
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].gst ? parseFloat(cartItemsList[cartItemsList?.length - 1].gst.toFixed(2)) : "0.00"}</td>

                    </tr>
                    <tr>
                      <td class=" text-danger " style={{ fontSize: '120%', fontWeight: 'bold' }}>
                        {/* <h1 class=" text-danger text-center"> */}
                        Total
                        {/* </h1> */}
                      </td> <td class=" text-danger">
                        {/* <h1 class=" text-danger text-center">           */}
                        {roundamnt != null && roundamnt != undefined ? roundamnt.toFixed(2) : '0.00'}
                        {/* </h1> */}
                      </td>
                    </tr>
                    {/* <tr class="pb-4">

                                            <td>Disc</td>
                                            <td>{discount.toFixed(2)}</td>
                                        </tr> */}
                  </tbody>
                </table>
                <table id="summaryDisplay" class="table mb-0">
                  {/* {notepad === false ?
                   
                    :
                    <Calc/> */}
                  <>
                    <tbody class="border-0 ">
                      <tr class="pb-2 ">
                        <td style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                          {/* {resumeSaleNumber == 0 ?
                                           <Button style={{
                                            padding: "0px 12% 0px 12%",
                                            color: "black",
                                            background:'#8ba741',
                                            fontWeight: "bold",
                                            // onClick={()=>holdSalefunc()}
                                            fontSize: "180%"}}>
                                              {"<"}</Button>
                                              :
                                              <Button className='CHANGETABLE__Color' style={{
                                                padding: "0px 12% 0px 12%",
                                                color: "black",
                                                fontWeight: "bold",
                                                // onClick={()=>holdSalefunc()}
                                                // onClick={()=>getPreviousResumeData()} 
                                                fontSize: "180%"}} onClick={()=>resumeSaleFunc()} >
                                                  {"<"}</Button>  
                                        
                                        } */}
                          {resumeSaleNumber == 0 ?
                            <Button style={{
                              padding: "0px 12% 0px 12%",
                              color: "black",
                              background: '#8ba741',
                              fontWeight: "bold",
                              // onClick={()=>holdSalefunc()}
                              fontSize: "180%"
                            }}>
                              {"<"}</Button>
                            //  <button>Nothing</button>
                            :
                            //  <button onClick={()=>previousResumeFunc()}>Previous</button>
                            <Button className='CHANGETABLE__Color' style={{
                              padding: "0px 12% 0px 12%",
                              color: "black",
                              fontWeight: "bold",
                              // onClick={()=>holdSalefunc()}
                              // onClick={()=>getPreviousResumeData()} 
                              fontSize: "180%"
                            }} onClick={() => previousResumeFunc()} >
                              {"<"}</Button>
                          }

                          {/* <Button className='CHANGETABLE__Color' style={{
                                              padding: "0px 12% 0px 12%",
                                              color: "black",
                                              fontWeight: "bold",
                                              fontSize: "180%"}}  onClick={()=>holdSalefunc()}>
                                                {"<"}</Button> */}
                          {
                          }
                          {localStorage.getItem('tableno') == "CT" ?
                            holdSaleList?.length == 0 || holdSaleList?.length <= resumeSaleNumber || showHoldSaleButtonData == true ?
                              //  <button onClick={()=>holdfunc()}>Hold</button>
                              // CHANGETABLE__Color
                              <Button className='MERGETABLE__Color' style={{
                                padding: "0px 12% 0px 12%",
                                backgroundColor: "pink !important",
                                color: "white",
                                fontWeight: "bold",
                                // color
                                // onClick={()=> ResumeholdSalefunc()}
                                // onClick={()=>resumefunc()}
                                fontSize: "180%"
                              }} onClick={() => holdfunc()} >
                                Hold
                                {/* {" >"} */}
                              </Button>
                              :
                              //  <button onClick={()=>nextResumeFunc()}>Next</button>

                              <Button className='CHANGETABLE__Color' style={{
                                padding: "0px 12% 0px 12%",
                                backgroundColor: "#BAFE06 !important",
                                color: "black",
                                fontWeight: "bold",
                                // onClick={()=> ResumeholdSalefunc()}
                                // onClick={()=>resumefunc()}
                                fontSize: "180%"
                              }}
                                onClick={() => nextResumeFunc()} >
                                {/* {" >"} */}
                                Next
                              </Button>
                            :
                            holdSaleList?.length == 0 || holdSaleList?.length <= resumeSaleNumber || showHoldSaleButtonData == true ?
                              //  <button onClick={()=>holdfunc()}>Hold</button>

                              <Button className='CHANGETABLE__Color' style={{
                                padding: "0px 12% 0px 12%",
                                backgroundColor: "#BAFE06 !important",
                                color: "black",
                                fontWeight: "bold",
                                // onClick={()=> ResumeholdSalefunc()}
                                // onClick={()=>resumefunc()}
                                fontSize: "180%"
                              }}
                              //  onClick={()=>holdfunc()} 
                              >
                                {/* Hold */}
                                {">"}
                              </Button>
                              :
                              //  <button onClick={()=>nextResumeFunc()}>Next</button>

                              <Button className='CHANGETABLE__Color' style={{
                                padding: "0px 12% 0px 12%",
                                backgroundColor: "#BAFE06 !important",
                                color: "black",
                                fontWeight: "bold",
                                // onClick={()=> ResumeholdSalefunc()}
                                // onClick={()=>resumefunc()}
                                fontSize: "180%"
                              }}
                              //  onClick={()=>nextResumeFunc()} 
                              >
                                {" >"}
                                {/* Next */}
                              </Button>
                          }
                          {/* <Button className='CHANGETABLE__Color' style={{
                                              padding:  "0px 12% 0px 12%",
                                              backgroundColor: "#BAFE06 !important",
                                              color: "black",
                                              fontWeight: "bold",
                                              // onClick={()=> ResumeholdSalefunc()}
                                              // onClick={()=>resumefunc()}
                                              fontSize: "180%"}}  >{">"}</Button> */}
                        </td>
                      </tr>
                      {/* <tr class="pb-2 ">
                        <td style={{ display: 'flex', justifyContent: 'center' }}><Button className='btn-orange' style={{

                          fontWeight: "bold", width: '25%',
                          fontSize: "150%"
                        }}  >
                          <Image src={Keypad} width="90%" onClick={() => changetoNotepad()} />
                        </Button></td>
                      </tr> */}
                    </tbody>
                    <div class="row text-center flex-wrap w-100" style={{ margin: '0px' }}>

                      <div class=" w-100">
                        {/* padding__5 form-control-lg*/}
                        <input class="text-center  square-edges inputHeightCalc  inputCardNumber border" type="text" value={numericValue} />
                      </div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

                      <div class="w-100"></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

                      <div class="w-100"></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

                      <div class="w-100"></div>
                      <div class="col-4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
                      <div class="col-4 border bg-success text-light" onClick={() => addItemsPad()}><strong>Enter</strong></div>

                    </div>
                  </>
                  {/* } */}

                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // : (localStorage.getItem('CustomerName') == null && localStorage.getItem('CustomerName') == undefined && cartItemsList.length > 0   && cartItemsList[0].name !== 'yasar' ? 
                    // <div class="col-sm-6 p-1">
                    // <button className="btn Btn__large p4Padding btn-block  btn-block-height" style={{marginTop:'5px',backgroundColor:'#242618 ',color:'white ',fontWeight:'bold ',border:'#242618 '}} onClick={()=>alertCustomer()}>
                    //    Payment 
                    // </button>
                    // </div> */}
        {/* &&  localStorage.getItem('CustomerName') != null && localStorage.getItem('CustomerName') != undefined  */}
        <div class="row " style={{ margin: '5px' }}>
          <div class="col-sm-6 p-1" style={{ marginBottom: '2px' }}>
            {localStorage.getItem('tableno') != undefined && localStorage.getItem('tableno') != null && localStorage.getItem('tableno') != "CT" ?
              <button className="btn  Btn__large p4Padding btn-block  btn-block-height btn-beforeSendKot" onClick={() => sendKOT()}>
                {/* style={{marginTop:'5px',backgroundColor:'#f19100',color:'#fff'}} */}

                Send KOT
              </button>
              : <button className="btn Btn__large btn-sendkotafter p4Padding btn-block  btn-block-height"
                // ,backgroundColor:'#285656' 
                style={{ marginTop: '5px', color: '#fff', fontWeight: 'bold' }} onClick={() => alertSendKot()}>

                Send KOT
              </button>

            }
            {/* <button className="btn btn-infogreen Btn__large p4Padding btn-block  btn-block-height" style={{marginTop:'5px'}} onClick = {()=>sendKOT()}>
                             
                                Send KOT
                        </button> */}
          </div>
          <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
            <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
              <div class="ticket " >
                {sliceItem == "T" ?
                  <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
                  :
                  (localStorage.getItem("tableno") == 'CT' ?
                    <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>CounterSale No. {localStorage.getItem('tableno')}</p>

                    :
                    <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Table No. {localStorage.getItem('tableno')}</p>

                  )
                }

                {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}

                {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>DATE: {dateData != null && dateData != undefined && dateData}</p> */}
                {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
               </div> */}
                {/* <div style={{display:'flex',justifyContent:'space-between'}}> */}
                {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p> */}

                {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px'}}>CASHIER: {dateData != null && dateData != undefined && dateData}</p> */}

                {/* </div> */}

                <table className="centered" style={{ borderCollapse: 'collapse' }}>
                  {/* <thead> */}
                  {/* <tr style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                                    <th className='description'  style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>Product</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>QTY</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>RATE</th>

                                    <th className="price" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>TOTAL</th>

                                </tr> */}
                  {/* <tr>
                        <th class="quantity">Q.</th>s
                        <th class="description">Description</th>
                        <th class="price">$$</th>
                    </tr> */}
                  {/* </thead> */}
                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    {
                      kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length > 0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                      kotData?.PrintKotArr[0].ItemsArray.map((item, index) =>

                      (
                        <tr  >
                          <td className='description' >

                            <div><small style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black', fontWeight: 'bold' }}>{item?.ProductName}</small></div>
                            {/* {item?.addon.length > 0 &&  */}
                            { item?.addon.length > 0 && 
                              <div><small style={{ fontSize: '8px', borderCollapse: 'collapse', color: 'black' }}>ADDON'S :
                              {item?.addon.length > 0 &&
                              item?.addon.map((i) => `${i.addonnam} ,` )} 
                            </small>
                            </div>
                            }
                            {item?.Remarks != '' && item?.Remarks != null && item?.Remarks != undefined &&
                              <div>
                                <small style={{ fontSize: '9px', borderCollapse: 'collapse', color: 'black' }}>
                                  REMARKS: {item?.Remarks}
                                </small>
                              </div>

                            }
                          </td>

                          <td className="quantity  " style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                          {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}

                        </tr>
                      ))

                    }

                  </tbody>
                </table>
                {/* <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Total Items :{paymentAmountData?.GetPrintArr?.length > 0  ?(paymentAmountData?.GetPrintArr?.length) :0  }</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>Total Qty:{cartItemsList?.length > 0 && cartItemsList[cartItemsList?.length - 1].qty ?parseFloat(cartItemsList[cartItemsList?.length - 1].qty):0  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Sub Total :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{cartItemsList?.length > 0 && cartItemsList[cartItemsList?.length - 1].subTotal ?cartItemsList[cartItemsList?.length - 1].subTotal:0.00  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{cartItemsList?.length > 0 && cartItemsList[cartItemsList?.length - 1].gst ? cartItemsList[cartItemsList?.length - 1].gst:0.00  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',color:'black'}}>Total</p>
                  
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',marginRight:'4px',color:'black'}}>{roundamnt != null && roundamnt != undefined ? roundamnt :0 }</p>
           
               </div>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black' ,borderTop:'1px dotted black'}}>{companyDetails?.remarks1}</p>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.remarks2}</p>
               */}
                <p className="centered" style={{ fontSize: '10px', borderTop: '1px dotted black', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{"<<< AME>>>"}</p>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{datetime}</p>


              </div>
            </div>
          </div>

          {cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].name !== 'yasar' ?
            <div class="col-sm-6 p-1" style={{ marginBottom: '2px' }}>
              {/* florogreen */}

              <button className="btn btn-paymentbtn Btn__large p4Padding btn-block  btn-block-height" style={{ marginTop: '5px' }} onClick={handlePayment}>
                Payment
              </button>

            </div>

            :
            <div class="col-sm-6 p-1" style={{ marginBottom: '2px' }}>
              {/* style={{marginTop:'5px',backgroundColor:'rgb(170 175 140) ',color:'white ',fontWeight:'bold ',border:'rgb(170 175 140) '}}  */}
              <button className="btn Btn__large p4Padding btn-block  btn-block-height btn-beforePayment" onClick={() => alertToADDItems()}>
                Payment
              </button>

            </div>

          }


        </div>
        <AddOnModal show={showAddOns}
          animation={true} handleClose={CloseAddOns} >

        </AddOnModal>

      </div>
      {/* <QtyChangeModal show={modelQtyPopUp} handleClose={CloseEditQuantity} qtyPlucCode={qtyPlucCode} animation={true}></QtyChangeModal> */}
      <CancelKotadmin sliceItem={sliceItem} show={adminPanel} handleClose={CloseAdminPanel} cancelKotData={cancelKotData} cartName={emptyCartName} />
    </div>



  );
}
