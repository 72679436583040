import React, { Component } from 'react';
import { selectAllRefund } from '../../reducers/refundSlice';
import { RefundPaymentSummary } from '../refundPayment/PaymentSummary';
import { PaymentMode } from './PaymentMode';
import { PaymentSummary } from './PaymentSummary';

import { useSelector, useDispatch } from 'react-redux'
import { RefundPaymentMode } from '../refundPayment/refundPaymentMode';


export function Payment () {
  // static displayName = Payment.name;

  const refundBill = useSelector(selectAllRefund)
  const [state, setstate] = React.useState("");
  // render() {
    return (
      <div class="row flex-grow-1">
        <div class="col-12 col-sm-4 d-none d-sm-flex flex-column ">

        {refundBill.length > 0 && refundBill[0].name != "yasar" ? 
          <RefundPaymentSummary setstate={setstate}/>
          :
          <PaymentSummary setstate={setstate}/>
    }
        </div>
        <div class="col col-sm-8   d-none d-sm-flex flex-column border-left">
        {refundBill.length > 0 && refundBill[0].name != "yasar" ?
          <RefundPaymentMode state={state} setstate={setstate} />
          :
          <PaymentMode state={state} setstate={setstate}/>
    }
        </div>

      </div>




    );
  // }
}
