import { gql, useQuery } from '@apollo/client';
import Carousel from 'react-multi-carousel';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux';
import './GetProducts.css'
import "react-multi-carousel/lib/styles.css";
import React, { useState } from 'react';
import { fetchItems, searchAllItemsRemove } from '../../reducers/itemsSlice';

// const PRODUCTS = gql`
// query{
//   categories(level:0,first:10){
//     edges{
//       node{
//         id
//         name
//       }
//     }
//   }
// }
// `;

export function GetProducts(props) {


  const [showSubCategories, setShowSubCategories] = useState(false)
  const { tokenCreate } = useSelector((state) => state.user);
  const [department, setdepartment] = useState([])
  const [departmentid, setdepartmentId] = useState('')

  const [subCategoriesData, setSubCategoriesData] = useState([])
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button type="button" class="carousel_arrow carousel_arrow-left" onClick={() => onClick()} > <FontAwesomeIcon icon="angle-left"></FontAwesomeIcon></button>;
  };


  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button type="button" class="carousel_arrow carousel_arrow-right" onClick={() => onClick()} ><FontAwesomeIcon icon="angle-right"></FontAwesomeIcon></button>;
  };

  // const CustomLeftArrow = ({ onClick, ...rest }) => {
  //   const {
  //     onMove,
  //     carouselState: { currentSlide, deviceType }
  //   } = rest;
  //   // onMove means if dragging or swiping in progress.
  //   return <button type="button" class="carousel_arrow carousel_arrow-left" onClick={() => onClick()} > <FontAwesomeIcon icon="angle-left"></FontAwesomeIcon></button>;
  // };

  const dispatch = useDispatch();


  // const { data, loading, error } = useQuery(PRODUCTS);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error</p>;
  var handleClickProducts = (i) => {
    console.log(i,"nowdid");
    if (i.deptmenu != null && i.deptmenu == 1) {

      let dataToSend = {
        Wanip: localStorage.getItem('Wanip'),
        DBname: localStorage.getItem('DBname'),
        DBPwd: localStorage.getItem('DBPwd'),
        DBUser: localStorage.getItem('DBUser'),
        token: localStorage.getItem('token'),
        terminal: localStorage.getItem('TerminalName'),
      }
      dispatch({
        type: "addonshow",
        payload: true
      })
      // dispatch({
      // type:"remarkshow",
      // payload:false
      // })
      // if(tableno===null){
      // alert("please Select DineIn or TakeAway or CS")
      // return false
      // }
      var item = [
        {
          id: i.deptid,
          catid:''
          // tokenCreate: dataToSend,
        },
      ];
      
      dispatch(searchAllItemsRemove(i.deptid));
      dispatch(fetchItems(item));
      // dispatch(department({ token, id }));
      //alert(id)
      //POST json\

    } else if (i.deptmenu != null && i.deptmenu == 2) {
      setShowSubCategories(true)
      setSubCategoriesData(i.categories)
      setdepartmentId(i.deptid)
    }
  }

  const handleGetCategoryItems = (i) =>{
    

    let dataToSend = {
      Wanip: localStorage.getItem('Wanip'),
      DBname: localStorage.getItem('DBname'),
      DBPwd: localStorage.getItem('DBPwd'),
      DBUser: localStorage.getItem('DBUser'),
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
    }
    dispatch({
      type: "addonshow",
      payload: true
    })
    // dispatch({
    // type:"remarkshow",
    // payload:false
    // })
    // if(tableno===null){
    // alert("please Select DineIn or TakeAway or CS")
    // return false
    // }
    var item = [
      {
        id: departmentid,
        catid:i.catid
        // tokenCreate: dataToSend,
      },
    ];
    dispatch(searchAllItemsRemove(departmentid));
    dispatch(fetchItems(item));
    // setShowSubCategories(false)
    // dispatch(department({ token, id }));
    //alert(id)
    //POST json\

 
  }

  // var handleClickProducts  =  (product)=>{
  //   dispatch(fetchItems(product));
  // };

  React.useEffect(() => {
    let dataToSend = {
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getdepartment", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        if (responseJson.DepartmentArr[0].Department != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.DepartmentArr.length; i++) {
            arr.push({
              departmentname: responseJson.DepartmentArr[i].DepartmentName,
              deptid: responseJson.DepartmentArr[i].Deptid,
              deptmenu: responseJson.DepartmentArr[i].deptmenu,
              categories: responseJson.DepartmentArr[i].categories,
            });
          }

          setdepartment(arr);
        } else {
          // setErrortext("Please check your email id or password");
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
      });

  }, [])










  return (

    // <Carousel responsive={responsive}  className={"m-2"} customRightArrow={<></>} customLeftArrow={<></>} 
    // id={node.deptid} onClick={()=>handleClickProducts(node.deptid)}
    // >
    <>
      {showSubCategories == true ?
        <Carousel keyBoardControl={true} minimumTouchDrag={10} arrows={true} shouldResetAutoplay={false} autoPlay={false} slidesToSlide={4} responsive={responsive} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} className={"margin2"}>
          {subCategoriesData.length > 0 && subCategoriesData[0].Category != 2 &&  subCategoriesData?.map((node, index) => (
            <div className="margin2 btn_height_product " >
              <button className="btn btn-orange  btn__large btn-block shadow-sm btn_height_product" id={node.catid} onClick={() => handleGetCategoryItems(node)}>
                {node.CategoryName}
              </button>
            </div>
          ))}
          <div className="margin2 btn_height_product " >
              <button className="btn btn-infored  btn__large btn-block shadow-sm btn_height_product" onClick={() => setShowSubCategories(false)}>
                Back
              </button>
            </div>
        </Carousel>
        :
        <Carousel keyBoardControl={true} minimumTouchDrag={10} arrows={true} shouldResetAutoplay={false} autoPlay={false} slidesToSlide={4} responsive={responsive} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} className={"margin2"}>
          {department?.map((node, index) => (
            <div className="margin2 btn_height_product " >
              {/* .deptid */}
              <button className="btn btn-orange  btn__large btn-block shadow-sm btn_height_product" id={node.deptid} onClick={() => handleClickProducts(node)}>
                {node.departmentname}
              </button>
            </div>
          ))}
        </Carousel>
      }
    </>


  );
}
export default GetProducts;
