import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';



const PRODUCTS = gql`
query GetItems($count:Int)
{
products(first: $count) {
  edges {
    node {
      id
      name
      description
    }
  }
}
}
`;
 

const onlineOrdersAdapter = createEntityAdapter();
const initialState = onlineOrdersAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchOnlineOrders = createAsyncThunk('onlineOrders/fetchOnlineOrders', async (onlineOrderTypes) => {
 
  const response = await client
    .query({ query: PRODUCTS, variables: { "count": onlineOrderTypes } });

  return response.data;

})

export const onlineOrdersSlice = createSlice({
  name: 'onlineorders',
  initialState,
  reducers: {
    updateTable(state, action) {
     
    },
    mergeTables(state, action) {
     
    },
     
  },
  extraReducers: {
    [fetchOnlineOrders.pending]: (state, action) => {

      state.status = 'loading'
    },
    [fetchOnlineOrders.fulfilled]: (state, action) => {

      state.status = 'succeeded'
      // Add any fetched posts to the array
      
      console.log(action.payload);
      onlineOrdersAdapter.removeAll(state);
      action.payload.products.edges.map(({node}) => (
          
        onlineOrdersAdapter.addOne(state, node)
        
      ))
      //state.tables = state.tables.concat(action.payload)
    },
    [fetchOnlineOrders.rejected]: (state, action) => {

      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {  updateTable,mergeTables} = onlineOrdersSlice.actions;

export default onlineOrdersSlice.reducer;
export const { selectAll: selectAllOnlineOrders } = onlineOrdersAdapter.getSelectors((state) => state.onlineorders);


//export const selectAllBillItems = (state) => state.items.billItems;
