import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
// import { BillingItem } from '../Models/BillingItem';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';



// const apolloFetch = createApolloFetch({ uri });
// const variables = {
// "count": 5
// };
const query = `query GetCart($table:String,$search:String){
#Pass table name and search field as "ORDER_PLACED"
busyorders(first: 10,filter:{table:$table,status:$search}) {
totalCount
edges {
node {
id #ID of the Busy Order
status #Status of the Busy Order
table {
id
name # Table details of the Busy Order
}
order { # Order details of the Busy Order
metadata{
key
value
}
number #Order number
id #Order Id
status #Order status
created #Order Taken time
lines {
id #orderline id
productName #item name
variantName #itemvariant name [sub-item]
variant{
id
}
quantity #quantity of the orderline
totalPrice{
net{
amount #total price of the orderline
}
}
}
total {
net {
amount #Total amount of the order
}
tax{
amount #Tax amount of the order
}
}
}
}
}
}
}


`;


const cartAdapter = createEntityAdapter();
const initialState = cartAdapter.getInitialState({
    status: 'idle',
    error: null,
    productList: null

})

export const cartItems = createAsyncThunk("cart/cartItems", async (item) => {
    var tablevalue = ''
    var orderno = ''
    var token = ''

    console.log("============wORKING++++++++++++++++",item);
    if (item != undefined && item[0]?.orderno?.length > 0) {

        console.log("============wORKING++++++++++++++++");
        tablevalue = ''
        orderno = item[0].orderno
        token= "61b32535f2c80"
    }
    else if (localStorage.getItem('tableno') == 'CT') {
        console.log("============wORKING1++++++++++++++++");
        //   hsnoconst = localStorage.getItem('hsno')
        tablevalue = `CT-${localStorage.getItem('TerminalName')}`
        orderno = ''
        token=localStorage.getItem('token')
    } else {
        //   hsnoconst = 0
        tablevalue = localStorage.getItem('tableno')
        orderno = ''
        token=localStorage.getItem('token')

        console.log("============wORKING2++++++++++++++++");
    }

   
    // console.log(item, item[0].orderno.length, "onlineorder");
    let dataToSend = {
        // wanip: item[0].tokenCreate[0].Wanip,
        // dbusr: item[0].tokenCreate[0].DBUser,
        // dbpwd: item[0].tokenCreate[0].DBPwd,
        // dbnam: item[0].tokenCreate[0].DBname,

        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token: token,
        tabno: tablevalue,
        openin: localStorage.getItem('TerminalName'),
        orderno: orderno
    };
    let formBody = [];
    for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const response = await fetch(
        "https://superadmin.amepos.io/TabPosApiNew/tabpos_getcart",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: formBody,
        }
    );

    // console.log("data list-----------------",response.json());
    return response.json();
});





// const response = await client.headers( {Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5MzA5MDgsIm93bmVyIjoic2FsZW9yIiwiZXhwIjoxNjI2OTMxMjA4LCJ0b2tlbiI6Iko4MFRBMEE5RjBmTyIsImVtYWlsIjoib25saW5lYWNjb3VudHNAYW1lcG9zLmluIiwidHlwZSI6ImFjY2VzcyIsInVzZXJfaWQiOiJWWE5sY2pveCIsImlzX3N0YWZmIjp0cnVlfQ.x_0NziiFpPnVkD87fopROrjt4NhelI38jvHdmT2wWJs'}).query({ query: PRODUCTS, variables: { "category" : "Q2F0ZWdvcnk6Nw==" } });
// console.log("data items list",response.data);
// return response.data;




export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        searchTerm: (state, action) => {

            state.searchTerm = action.payload;
        }

    },
    extraReducers: {
        [cartItems.pending]: (state, action) => {

            state.status = 'loading'
        },
        [cartItems.fulfilled]: (state, action) => {

            state.status = 'succeeded'
            state.productList = action.payload;

            cartAdapter.removeAll(state);
            console.log('====================================');
            console.log(action.payload);
            console.log('====================================');
            const GetCartArr = action.payload.GetCartArr;
            const arr = [];


            var qty = 0;
            var subTotal = 0;
            var gst = 0;
            var dis = 0;
            var amtt = 0;
            console.log(GetCartArr.length);
            if (action.payload.GetCartArr[0].GetCart != 2) {
                if (GetCartArr.length != 0) {
                    for (let i = 0; i < GetCartArr.length; i++) {
                        subTotal = parseFloat(subTotal) + parseFloat(GetCartArr[i].SellPrice);
                        gst = parseFloat(gst) + parseFloat(GetCartArr[i].GST);
                        qty = parseFloat(qty) + parseFloat(GetCartArr[i].TotalQty);
                        amtt = parseFloat(amtt) + parseFloat(GetCartArr[i].Amount);
                        try {
                            if (GetCartArr[i].addon.length != 0) {

                                console.log(GetCartArr[i].kotno.length, "GetCartArr[i].kotno");
                                arr.push({
                                    id: i.toString(),
                                    sno: GetCartArr[i].sno,
                                    lineno: GetCartArr[i].lineno,
                                    name: "anand",
                                    plucod: GetCartArr[i].plucod,
                                    productname: GetCartArr[i].ProductName,
                                    totalqty: GetCartArr[i].TotalQty,
                                    sellprice: GetCartArr[i].SellPrice,
                                    usellp: GetCartArr[i].usellp,
                                    gst: GetCartArr[i].GST,
                                    amount: GetCartArr[i].Amount,
                                    remarks: GetCartArr[i].Remarks,
                                    pricelevel: GetCartArr[i].PriceLevelID,
                                    kotno: GetCartArr[i].kotno,
                                    preqty: GetCartArr[i].prepqty,
                                    printsts: GetCartArr[i].printsts,
                                    addon: GetCartArr[i].addon,
                                    subTotal: subTotal,
                                    gst: gst,
                                    qty: qty,
                                    amtt: amtt,
                                });
                            } else {

                                arr.push({
                                    id: i.toString(),
                                    sno: GetCartArr[i].sno,
                                    lineno: GetCartArr[i].lineno,
                                    name: "anand",
                                    plucod: GetCartArr[i].plucod,
                                    productname: GetCartArr[i].ProductName,
                                    totalqty: GetCartArr[i].TotalQty,
                                    sellprice: GetCartArr[i].SellPrice,
                                    usellp: GetCartArr[i].usellp,
                                    gst: GetCartArr[i].GST,
                                    amount: GetCartArr[i].Amount,
                                    remarks: GetCartArr[i].Remarks,
                                    pricelevel: GetCartArr[i].PriceLevelID,
                                    kotno: GetCartArr[i].kotno,
                                    preqty: GetCartArr[i].prepqty,
                                    printsts: GetCartArr[i].printsts,
                                    addon: "",
                                    subTotal: subTotal,
                                    gst: gst,
                                    qty: qty,
                                    amtt: amtt,
                                });
                            }
                        } catch (error) {
                            console.log(error);
                        }

                    }


                    arr.map((node) => cartAdapter.addOne(state, node));

                    // console.log("ddddddddddddddddddd",action.payload.data.products.edges);
                    // cartAdapter.upsertMany(state, action.payload.data.products.edges);


                } else {
                    const arr = [
                        {
                            id: 0,
                            name: "yasar",
                            amount: 0,
                            subTotal: 0,
                            gst: 0,
                        },
                    ];

                    arr.map((node) => cartAdapter.addOne(state, node));
                }
            } else {
                const arr = [
                    {
                        id: 0,
                        name: "yasar",
                        amount: 0,
                        subTotal: 0,
                        gst: 0,
                    },
                ];

                arr.map((node) => cartAdapter.addOne(state, node));
            }

        },
        [cartItems.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});



export default cartSlice.reducer;
export const { selectAll: selectAllCart, selectEntities: select } = cartAdapter.getSelectors((state) => state.cart);

//

// export const selectItemsForSearch = createSelector(
// [selectAllItems, (state, searchTerm) => searchTerm],
// (items, searchTerm) => items.filter((item) => item.name === searchTerm)
// )
// export const selectItemsForSearch = (state) => state.items.filter((item) => item.name.includes(searchTerm));