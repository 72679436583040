import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../utilities/fontawesome';
import { pageComponents, paymentMode } from '../../utilities/Constants'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './QtyChangeModal.css';
import { setActivePage } from '../../reducers/homePageSlice';
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { Button, Modal, } from "react-bootstrap";
// import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { apiURI } from '../../utilities/client';
import { useReactToPrint } from "react-to-print";
const CancelKotadmin = (props) => {

  const [cancelKotNumber, setCancelKotNumber] = useState([])
  const [cancelKotData, setCancelKotData] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const dispatch = useDispatch();
  const draftRef = React.useRef(null);
  const [numericValue, setNumericValue] = useState("");
  const cartItemsList = useSelector(selectAllCart);
  const onBeforeGetContentResolve = React.useRef(null);
  // const TableName = useSelector(state =>state.table.tablename)
  // const TableStatus = useSelector(state =>state.table.tableStatus)
  const date = new Date();
  const datetime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }


  const clearAll = () => {

    setNumericValue("");
  }
  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }


  const sendCancelKOT = async () => {
    // await goToKot();
    handlePrint();
  }


  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    dispatch(setActivePage(pageComponents.VIEWTABLES));

    handleClose()
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);


  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,

    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }

  }, [onBeforeGetContentResolve.current, text]);

  const handleClose = props.handleClose;


  const deleteCart = (sno, kot) => {
    // console.log(kot,typeof(kot),"kot");
    // if(kot == "0"){
      var tablevalue = '';
    var hsnoconst = 0;
    if (localStorage.getItem('tableno') == 'CT') {
      hsnoconst = localStorage.getItem('hsno')
      tablevalue = `CT-${localStorage.getItem('TerminalName')}`
    } else {
      hsnoconst = 0
      tablevalue = localStorage.getItem('tableno')
    }

    let dataToSend = {

      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      sno: props.cancelKotData,
      tabno: localStorage.getItem('tableno'),
      ordusr: localStorage.getItem('UserID'),
      hsno: hsnoconst,
      terminal: localStorage.getItem("TerminalName")
      //   wanip: tokenCreate[0].Wanip,
      //   dbusr: tokenCreate[0].DBUser,
      //   dbpwd: tokenCreate[0].DBPwd,
      //   dbnam: tokenCreate[0].DBname,
      //   sno: sno,
      //   ordusr: pinCreate[0].UserID,
      //   tabno: tableno,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_deletecart", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        const item = [
          {
            tableno: localStorage.getItem('tableno'),
          },
        ];
        dispatch(cartItems(item));
        if (responseJson.DeleteCartArr[0].DeleteCart != 2) {

          const arryjs = [];
          const map = "";
          const arr = [];
          for (let i = 0; i < responseJson.DeleteCartArr[0].cancelkot.length; i++) {


            arryjs.push({

              kotno: responseJson.DeleteCartArr[0].cancelkot[i].kotno
            })
          }
          // arr.push({
          //   delete: responseJson.DeleteCartArr[i].DeleteCart,
          // });

          // const uniqueNames = arryjs.filter((val,id,array) => array.indexOf(val) == id);
          if (responseJson.DeleteCartArr[0].cancelkot.length > 0) {
            const obj = [...new Map(arryjs.map(item => [JSON.stringify(item), item])).values()];
            console.log(obj, "obj");

            setCancelKotNumber(obj)
            setCancelKotData(responseJson.DeleteCartArr[0].cancelkot)
            sendCancelKOT()

            // setCancelKotNumber()

          }

        } else {
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        // alert("Data not Found")
      });
  }
  // } 

  const secureUnlock = () => {

    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      password: numericValue,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_adminlogin", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson, "emptyCart");
        const item = [
          {
            tableno: localStorage.getItem('tableno'),
          },
        ];
        // dispatch(cartItems(item));
        if (responseJson.AdminLoginArr[0].AdminLogin != 2) {
          // adminScreen
          // setAdminScreen(true)
          Securepin()

        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry Wrong Pin");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  }


  const Securepin = () => {
    console.log("1234", props.cartName);
    if (props.cartName == "DELETE") {
      deleteCart()
      // Securepin()
    }
    else if (props.cartName == "EMPTY") {
      // console.log("1234");
      emptyCartfunc()
    }
    else {

    }
  }

  const emptyCartfunc = () => {
    var hsnoconst = 0;
    
    var tablevalue = '';
    if (localStorage.getItem('tableno') == 'CT') {
      hsnoconst = localStorage.getItem('hsno')
      tablevalue = `CT-${localStorage.getItem('TerminalName')}`
    } else {
      hsnoconst = 0
      tablevalue = localStorage.getItem('tableno')
    }


    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),

      terminal:localStorage.getItem('TerminalName'),
      hsno: hsnoconst,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_emptycart", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson, "emptyCart");
        const item = [
          {
            tableno: localStorage.getItem('tableno'),
          },
        ];
        dispatch(cartItems(item));
        if (responseJson.EmptyCartArr[0].EmptyCart != 2) {
          const arr = [];
          const arryjs = [];
          const map = "";
          if (responseJson.EmptyCartArr[0].cancelkot.length > 0) {
            for (let i = 0; i < responseJson.EmptyCartArr[0].cancelkot.length; i++) {
              arr.push({
                kotno: responseJson.EmptyCartArr[0].cancelkot[i].oldkotno,
                ProductName: responseJson.EmptyCartArr[0].cancelkot[i].ProductName,

                Remarks: responseJson.EmptyCartArr[0].cancelkot[i].Remarks,
                Quantity: responseJson.EmptyCartArr[0].cancelkot[i].Quantity,

              });
              // };

              // for (let i = 0; i < responseJson.EmptyCartArr[0].cancelkot.length; i++) {
              arryjs.push({

                kotno: responseJson.EmptyCartArr[0].cancelkot[i].oldkotno
              })
            }
            console.log(arryjs, "arryjs");

            // const existingLineItem = arryjs.filter(a => a.kotno === newLineitem.kotno)
            const obj = [...new Map(arryjs.map(item => [JSON.stringify(item), item])).values()];
            console.log(obj, "obj");
            // const uniqueNames = arryjs.filter((val,id,array) => array[val].kotno == id.kotno);
            if (responseJson.EmptyCartArr[0].cancelkot.length > 0) {

              setCancelKotData(responseJson.EmptyCartArr[0].cancelkot)
              setCancelKotNumber(obj)
            }

            // arryjs
            // setCancelKotNumber(uniqueNames)
            // console.log(uniqueNames,"uniqueNames");
            sendCancelKOT()
          }
          console.log("HIII");
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });

  }

  // console.log(cancelKotNumber,"cancelKotNumber");
  return (
    <Modal
      show={props.show}
      animation={props.animation}
      size="md" className="shadow-xl border ">
      <Modal.Header className="border-0">
        <Modal.Title className="w-100">
          <div class="d-flex" style={{ justifyContent: "center", fontWeight: 'bold' }}>
            ADMIN
            {/* <div class="ml-auto "><button class="btn-circle" onClick={() => handleClose()}>X</button></div> */}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 border-0">

        {/* <div class=" "> */}

        {/* <div class="d-flex flex-row flex-wrap pl-2 pr-3 "> */}

        {/* <input type="text" class="btn-block  square-edges splitBillQuantityInput"></input> */}
        {/* </div> */}
        {/* </div> */}
        <div class="ml-4 ">

          <div class="row text-center margin__bottom flex-wrap w-100">
            <div class=" w-100">
              <input class="text-center form-control-lg square-edges padding_5 inputCardNumber border" type="password" value={numericValue} />
            </div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(7)}
            ><strong>7</strong> </div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(8)}
            ><strong>8</strong></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(9)}
            ><strong>9</strong></div>

            <div class="w-100"></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(4)}
            ><strong>4</strong></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(5)}
            ><strong>5</strong></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(6)}
            ><strong>6</strong></div>

            <div class="w-100"></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(1)}
            ><strong>1</strong></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(2)}
            ><strong>2</strong></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(3)}
            ><strong>3</strong></div>

            <div class="w-100"></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
            <div class="col-4 padding_4 border calcButtons"
              onClick={() => addNumber(0)}
            ><strong>0</strong></div>
            <div class="col-4 padding_4 border " onClick={() => deleteNumber()}><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>

          </div>


        </div>
        {/* <div class="w-100 mb-4" style={{display:'flex'}}>
 
              <Button className="btn-lg btn btn-florogreen btn-block  padding_4"
               onClick={()=>addLineItem()}
               >Enter</Button>
              <Button className="btn-lg btn btn-secondary btn-block  padding_4"
               onClick={()=>addLineItem()}
               >Cancel</Button>
          </div> */}
        <div class="w-100 " style={{ display: 'flex' }}>
          {loading == true ?

            <Button className="btn-lg btn btn-secondary  padding_4"

              style={{ display: 'block', width: '95%', marginRight: '3%' }} >LOADING ...</Button>
            :
            <Button className="btn-lg btn btn-secondary  padding_4"

              style={{ display: 'block', width: '95%', marginRight: '3%' }}
              // style={{display:'block',width:'95%'}}
              onClick={() => handleClose()}
            >Cancel</Button>

          }

          <Button className="btn-lg btn btn-florogreen padding_4"
            onClick={() => secureUnlock()}
            style={{ display: 'block', width: '95%' }}
          //  style={{display:'block',width:'95%',marginRight:'3%'}}
          >
            {/* btn-block  */}
            {/* <img src="/images/logout.png" alt="" class="headerMenu" style={{marginRight:'10%',color:'black'}} ></img> */}
            Secure Unlock</Button>
          {/* <Button className="btn-lg btn btn-florogreen btn-block  padding_4"
               onClick={()=>handleClose()}
               >Cancel</Button>
            
          </div>
          <div class="w-100 mb-4">
 
              <Button className="btn-lg btn btn-florogreen btn-block  padding_4"
               onClick={()=>PinPage()}
               >Pin Page</Button>
               
            */}

        </div>
      </Modal.Body>


      <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
        <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
          <div class="ticket " >
            <h3 className="centered" style={{ fontSize: '17px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Cancel Order </h3>

            {props.sliceItem == "T" ?
              <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
              :
              (localStorage.getItem("tableno") == 'CT' ?
                <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>CounterSale No. {localStorage.getItem('tableno')}</p>

                :
                <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Table No. {localStorage.getItem('tableno')}</p>

              )
            }


            <table className="centered" style={{ borderCollapse: 'collapse' }}>

              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                {
                  cancelKotData !== undefined && cancelKotData !== null && cancelKotData?.length > 0 &&

                  cancelKotData?.map((item, index) =>

                  (
                    <tr  >
                      <td className='description' >

                        <div><small style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black', fontWeight: 'bold' }}>{item?.ProductName}</small></div>
                        {/* {item?.addon.length > 0 && item?.addon.map((i)=>
                                    <div><small  style={{fontSize:'9px',borderCollapse:'collapse',color:'black'}}>ADDON'S :{i.addonnam}
                                    </small></div>
                                  )} */}
                        {item?.Remarks != '' && item?.Remarks != null && item?.Remarks != undefined &&
                          <div><small style={{ fontSize: '9px', borderCollapse: 'collapse', color: 'black' }}>REMARKS: {item?.Remarks}</small></div>
                        }
                      </td>

                      <td className="quantity  " style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                      {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}

                    </tr>
                  ))

                }

              </tbody>
            </table>
            <p className="centered" style={{ fontSize: '10px', borderTop: '1px dotted black', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Old KOT No. {cancelKotNumber.length > 0 ? cancelKotNumber.map((i) => `${i.kotno},`) : ""}</p>

            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{"<<< AME>>>"}</p>

            {/* <p className="centered" style={{fontSize:'12px',borderTop:'1px dotted black' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{"<<< AME>>>"}</p> */}
            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{datetime}</p>


          </div>
        </div>
      </div>
      {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

          <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
        </Modal.Footer> */}
    </Modal>
  )
}

export default CancelKotadmin
