import {
    createSlice,
    createEntityAdapter,
    createSelector,
    createAsyncThunk,
    } from "@reduxjs/toolkit";
    
    const userAdapter = createEntityAdapter();
    const initialState = userAdapter.getInitialState({
    status: "idle",
    error: null,
    tokenCreate: null,
    pinCreate: null,
    terminalCreate: null,
    isLoggedIn: false,
    });
    
    // export const updateLoginStatus = createAsyncThunk('tables/User', async () => {
    
    // const response = await client
    // .query({ query: PRODUCTS, variables: { "count": 20 } });
    
    // return response.data;
    
    // })
    
    export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
    loginUser(state, action) {
    state.tokenCreate = action.payload;
    
    state.isLoggedIn = true;
    },
    logoutUser(state, action) {
    console.log("HII");
    // state.tokenCreate = null;
    state.pinCreate = [{ ShopLogin: 2 }];
    state.terminalCreate = null;
    // state.isLoggedIn = false
    },
    pinUser(state, action) {
    state.pinCreate = action.payload;
    },
    terminalUser(state, action) {
    state.terminalCreate = action.payload;
    },
    },
    });
    
    export const {
    loginUser,
    logoutUser,
    pinUser,
    terminalUser,
    } = userSlice.actions;
    
    export default userSlice.reducer;