import React, { Component } from 'react';

export class MenuButton extends React.Component {
    render(){
      return (
         
 
 <img src="/images/menu.png" alt="" class="headerMenu" onClick={this.props.toggle} ></img>
  
      )
     }   
 }
   