import React, { useState, useEffect } from 'react';
import { Button, Modal, } from "react-bootstrap";
import { setActivePage, updatePage } from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, ClosingSalesType } from '../../utilities/Constants'
import { fetchNewOrders } from '../../reducers/onlineOrderUnconfirmedSlice';
import { fetchNewConfirmedOrders } from '../../reducers/onlineOrderCofirmed';
import { cartItems } from '../../reducers/CartSlice';
import { showDelivery } from '../../reducers/ConstantSlice';

export function AssignDeliveryModal(props) {


  const dispatch = useDispatch();


  const confirmUnComingOrderFunc = (i) =>{
    try{
      

      let dataToSend = {
        token: '61b32535f2c80',
        ordno:props.newOrdersData.ordno
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_foodready", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          console.log(responseJson, "addcarttt");
          changePage()
    // props.setOnlineOrders(OnlineOrderTypes.CONFIRMED_ORDERS)
      dispatch(fetchNewConfirmedOrders())
      // handleClose()

        })
        

    }catch(error){
      alert(`${error} in onlineOrderModalConfirm`)
    }
  }

  const AsignDelivery = ( ) =>{
    // changePage()
    const item = [
      {
        tableno: '',

        // tokenCreate: tokenCreate,
        token:localStorage.getItem('token'),
        orderno:props.newOrdersData.ordno,
        openin: localStorage.getItem('TerminalName'),
      },
    ];
    dispatch(cartItems(item));


    
    dispatch(setActivePage(pageComponents.BILLING));
    dispatch(showDelivery(true))
    // 61b32535f2c80
  }

  const handleClose = props.CloseModal;
  const changePage = props.changePage;

  console.log(props.newOrdersData,"newOrdersDatass");
  return (
    <>
      <Modal
        show={props.show}
        animation={props.animation}
        size="xl" className="shadow-lg border ">
        <Modal.Header className="">
          <Modal.Title className="w-100">
            <div class="d-flex">
              <div class="p-1">
              <div >New Orders : { props.newOrdersData.channelname != null || props.newOrdersData.channelname != undefined ?  props.newOrdersData.channelname : "Own App"} </div>
             
              </div>
               <div class="ml-auto p-1"> <div>Payment Mode :{ props.newOrdersData.paymode != null && props.newOrdersData.paymode != undefined ?  props.newOrdersData.paymode : 'UnPaid'} </div>
             
              {/* <div style={{padding:'5px',background:'green',color:'white',borderRadius:'5px'}}>
                 Pending
              </div> */}
                 </div>
                 {/* <button class="btn-circle" onClick={() => handleClose()}>X</button> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 border-0 modal-body-closing">

          <div class="row">
            <div class="col-md-6">
              <p class="display-4" style={{fontSize:'2rem',marginLeft:'10px'}}>Order No:{ props.newOrdersData.ordno != null && props.newOrdersData.ordno != undefined &&  props.newOrdersData.ordno}</p>
              {/* <p style={{marginLeft:'10px',fontWeight:'600'}}>

              Delivery Boy:
              
              <select class="display-4" style={{fontSize:'1rem',marginLeft:'10px'}}  >
              className="form-control btn-block-height square-edges"
              onChange={(e)=>setTwoWallet(e.target.value)}
                      <option style={{fontSize:'13px'}}>Select a Delivery Boy</option>
                      <option style={{fontSize:'13px'}} value="Anand">Anand</option>
                      <option style={{fontSize:'13px'}} value="Priyanga">Priyanga</option>
                      <option style={{fontSize:'13px'}} value="Jeevan">Jeevan</option>
                    </select>
                    </p> */}
              <p  style={{marginLeft:'10px',fontWeight:'600'}}>Delivery Address</p>
              <p  style={{marginLeft:'10px'}}>{ props.newOrdersData.address != null && props.newOrdersData.address != undefined &&  props.newOrdersData.address}</p>

              <p  style={{marginLeft:'10px',fontWeight:'600'}}><span> Ordered Date And Time : </span><span> { props.newOrdersData.orddate != null && props.newOrdersData.orddate != undefined &&  props.newOrdersData.orddate}</span></p>
            
              <p  style={{marginLeft:'10px',fontWeight:'600'}}><span> Delivery Date And Time : </span><span> { props.newOrdersData.deldate != null && props.newOrdersData.deldate != undefined &&  props.newOrdersData.deldate}</span></p>
              {/* <p  style={{marginLeft:'10px'}}> { props.newOrdersData.deldate != null && props.newOrdersData.deldate != undefined &&  props.newOrdersData.deldate}</p> */}
              
              <h5  style={{marginLeft:'10px',fontWeight:'600'}}><span> Total Bill Amount :</span><span> { props.newOrdersData.ordertotal != null && props.newOrdersData.ordertotal != undefined &&  props.newOrdersData.ordertotal}</span></h5>
              <p  style={{marginLeft:'10px'}}></p>
              
             
              {/* <p  style={{marginLeft:'10px'}}>  Assigned  Delivery Boy</p>
              <p  style={{marginLeft:'10px'}}> Iron Man</p> */}
              {/* <p>IDLI - 5</p>
              <p>DOSAI - 8</p>

              <p>INR 100.00</p> */}
              {/* <button class="btn btn-infogreen">View Bill</button> */}
            </div>
            <div class="col-md-6">
              <h5 style={{fontWeight:'600'}}>Order Details</h5>
              <table>
                <thead>
                    <tr>
                      <th style={{textAlign:'center' ,width:'10%'}}>{"Sno"}</th>
                      <th style={{textAlign:'center',width:'40%'}}>{"Product Name"}</th>
                      <th style={{textAlign:'center',width:'30%'}}>{"Remarks"}</th>
                      <th style={{textAlign:'center',width:'20%'}}>{"Quantity"}</th>
                      {/* <th style={{textAlign:'center'}}>{"Amount"}</th> */}

                      </tr>
                </thead>
                  <tbody>
              { props.newOrdersData?.itemsarray?.length > 0  && props.newOrdersData?.itemsarray?.map((i,index)=>(
               
                    <tr>
                      <th style={{textAlign:'center',width:'10%'}}>{index+1}</th>
                      <td style={{textAlign:'center',width:'40%',wordBreak:'break-all'}}>{i?.prodnam}</td>
                      <td style={{textAlign:'center',width:'30%',wordBreak:'break-all'}}>{i?.remarks}</td>
                      <td style={{textAlign:'center',width:'20%'}}>{i?.quantity}</td>
                      {/* <td style={{textAlign:'center'}}>{i.amount}</td> */}
                    </tr>
                // <p>{i.prodnam} - {i.amount}</p>
              ))  }
              
              </tbody>
                </table>
            </div>
            
          </div>
          {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',marginBottom:'15px'}}>
                <button style={{padding:'10px' , borderRadius:'5px',background:'orange',color:'white',border:'none',fontWeight:'500'}} onClick={() => handleClose()}>Back</button>
                
                <button style={{padding:'10px' , borderRadius:'5px',background:'gray',color:'white',border:'none',fontWeight:'500'}}>Cancel Order</button>
                
                <button style={{padding:'10px' , borderRadius:'5px',background:'#1ee81e',color:'white',border:'none',fontWeight:'500'}}>Confirm Order</button>
              </div> */}

              
             <ul class="cards_overflow__products " style={{justifyContent:'space-evenly'}}>
    <li class="cards_item_1 margin_3 btn_height_items" >
          <button className="btn btn__large btn-block shadow-sm btn_items_height"
            style={{ background:'orange',color:'white',border:'none',fontWeight:'500'}}
            onClick={() => handleClose()}
          >
          Back
          </button>
          </li>
          {/* <li class="cards_item_1 margin_3 btn_height_items" >
          <button className="btn  btn__large btn-block shadow-sm btn_items_height"
          style={{background:'gray',color:'white',border:'none',fontWeight:'500'}} >
          Cancel Order
          </button>
          </li> */}
          <li class="cards_item_1 margin_3 btn_height_items" >
          { props.newOrdersData.channelname != null || props.newOrdersData.channelname != undefined ?
            //  confirmUnComingOrderFunc
           "":<button className="btn  btn__large btn-block shadow-sm btn_items_height"
           style={{background:'#1ee81e',color:'white',border:'none',fontWeight:'500'}} onClick={()=>AsignDelivery()}>
          Assign Delivery Boy
          </button>}
          </li>
          </ul>

        </Modal.Body>
        {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

        <div>
          <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
        </div>
      </Modal.Footer> */}
      </Modal>
    </>
  );

}