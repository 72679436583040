import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavItem, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../utilities/fontawesome';
import { setActivePage, setActiveTab } from '../reducers/homePageSlice';
import { Menu } from "./Menu/Menu";
import { MenuButton } from "./Menu/MenuButton";
import { Backdrop } from "./Menu/Backdrop";
import { connect } from "react-redux"
import { pageComponents, cashierTabs } from '../utilities/Constants';
import './NavMenu.css';
import { cartItems } from '../reducers/CartSlice';
import ModalPopLogout from './Modal';

import { Minimize } from 'react-feather';
import { showRefund, showDelivery } from '../reducers/ConstantSlice';
const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}


export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      date: new Date(),
      show: false,
      showRefund: false,
      showDelivery: false
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      60000
    );
    // submit
    // alert(`Your License Will Expire in ${localStorage.getItem('LicenseLeft')}`)
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }



  state = { drawerOpen: false }
  // state = { admin: true }

  drawerToggleClickHandler = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen,

    })
  }
  backdropClickHandler = () => {
    this.setState({
      drawerOpen: false
    })
  }

  formatTime = (date) => {
    var d = this.state.date.getDate()
    var m = months[this.state.date.getMonth()]
    var y = this.state.date.getFullYear()

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return d + ' ' + m + ', ' + y + ' ' + strTime;
  }


  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };


  SignOut = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("Name");
    localStorage.removeItem("isStaff");
    localStorage.removeItem("locked");
    localStorage.removeItem("UserID");
    localStorage.removeItem("tableno");
    localStorage.removeItem("TerminalName");

    localStorage.removeItem("UserName");
    localStorage.removeItem("token");
    localStorage.removeItem("Login");

    window.location.href = `${process.env.PUBLIC_URL}/login`
    //toast.success("Logged out!");
    //  setTimeout(() => {
    //   props.history.push(`${process.env.PUBLIC_URL}/`);
    // }, 200);
  }



  setTabNameasDineIn = () => {

    localStorage.setItem('whichTab', "DineIn")
  }
  setTabNameasTakeaway = () => {

    localStorage.setItem('whichTab', "TakeAway")
  }
  removeCustomerName = () => {

    if (localStorage.getItem('CustomerName') != null && localStorage.getItem('CustomerName') != undefined) {

      localStorage.removeItem('Customerid')
      localStorage.removeItem('phoneNumber')
      localStorage.removeItem('CustomerName')
      // this.props.setActiveTab(cashierTabs.COUNTER_SALE); 
      // this.props.setActivePage(pageComponents.BILLING);
      // this.setTeminalNameToCs()

      window.location.href = `${process.env.PUBLIC_URL}/`
    }
    else {
    }
  }

  setTeminalNameToCs = () => {
    localStorage.setItem('whichTab', "CounterSale")
    localStorage.setItem("tableno", "CT")

    const item = [
      {
        tableno: "CT",
        terminalno: localStorage.getItem('TerminalName'),
      },
    ];
    this.props.cartItems(item)
    this.getClear()
    // dispatch(cartItems(item));
  }

  getClear = () => {
    //POST json
    // let dataToSend = {
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      terminal: localStorage.getItem('TerminalName'),

      token: localStorage.getItem('token'),
      tabno: localStorage.getItem('tableno'),
    };
    // wanip: tokenCreate[0].Wanip,
    // dbusr: tokenCreate[0].DBUser,
    // dbpwd: tokenCreate[0].DBPwd,
    // dbnam: tokenCreate[0].DBname,
    // tabno: tableno,
    // terminal: terminalno,
    // };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
            arr.push({
              clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
            });
          }

        } else {
          alert("Sorry");
        }
      })
      .catch((error) => {
        alert("data not found")
      });
  };

  goFull = () => {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  changeRefundButton = () => {
    // console.log("actionking");
    // this.props.showRefund(!this.state.showRefund)
    // this.props.showDelivery(!this.state.showDelivery)

    // this.setState({ showRefund: !this.state.showRefund })
    // this.setState({ showDelivery: !this.state.showDelivery })

  }

  render() {

    let backdrop;
    if (this.state.drawerOpen) {
      backdrop = <Backdrop close={this.backdropClickHandler} />;
    }

    return (
      <nav class="navbar navbar-expand-lg navbar-dark  bg-amedark Navbarheight" style={{ padding: '0px' }}>
        {/* 0.5rem 0rem 0.5rem 1rem */}
        <div class="navbar-brand" onClick={() => { this.changeRefundButton() }}>
          <img src="/images/logo-header.png" alt="" class="imgHeaderLogo" ></img>
          <p style={{ color: 'white', fontSize: '60%', marginBottom: '0px', marginLeft: '2px' }}>{this.formatTime(this.state.date)}</p>
        </div>


        {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> */}

        {/* <div class="nav nav-tabs nav-fill align-text-bottom"  id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Home</a>
            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</a>
            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</a>
            <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">About</a>
          </div> */}

        <div class="collapse navbar-collapse" id="navbarSupportedContent" >
          <div class="nav nav-tabs nav-fill tabbar " id="nav-tab" role="tablist">
            <button class={this.props.activeTab === cashierTabs.COUNTER_SALE ? "nav-item nav-link  tab  activeTab" : "nav-item nav-link  tab  "}
              onClick={() => {
                this.props.setActiveTab(cashierTabs.COUNTER_SALE);
                this.props.setActivePage(pageComponents.BILLING);
                this.setTeminalNameToCs();
                this.props.discountPrecentage();
                this.props.dueamount()
                this.props.subtotalamount();
                this.props.totalamount()
                localStorage.setItem('takenoMode', '0');
              }} style={{ fontSize: '85%' }}>Counter Sale</button>

            <button class={this.props.activeTab === cashierTabs.TAKE_AWAY ? "nav-item nav-link  tab  activeTab" : "nav-item nav-link  tab  "} onClick={() => {
              this.props.setActiveTab(cashierTabs.TAKE_AWAY); this.props.setActivePage(pageComponents.VIEWTABLES); this.getClear();

              this.props.discountPrecentage();
              this.props.dueamount()
              this.props.subtotalamount();
              this.props.totalamount()
              localStorage.setItem('takenoMode', '0');
            }} style={{ fontSize: '85%' }}>Takeaway</button>

            <button class={this.props.activeTab === cashierTabs.DINE_IN ? "nav-item nav-link  tab  activeTab" : "nav-item nav-link  tab  "} onClick={() => {
              this.props.setActiveTab(cashierTabs.DINE_IN); this.props.setActivePage(pageComponents.VIEWTABLES); this.getClear();
              localStorage.setItem('takenoMode', '0');
              this.props.discountPrecentage();
              this.props.dueamount()
              this.props.subtotalamount();
              this.props.totalamount()
            }} style={{ fontSize: '85%' }} >Dine In</button>

            <button class={this.props.activeTab === cashierTabs.BUSY_ORDERS ? "nav-item nav-link  tab  activeTab" : "nav-item nav-link  tab  "} onClick={() => {
              this.props.setActiveTab(cashierTabs.BUSY_ORDERS); this.props.setActivePage(pageComponents.VIEWTABLES); this.getClear()
              localStorage.setItem('takenoMode', '0');
              this.props.discountPrecentage();
              this.props.dueamount()
              this.props.subtotalamount();
              this.props.totalamount()
            }} style={{ fontSize: '85%' }}>Busy Orders</button>

            <button class={this.props.activeTab === cashierTabs.ONLINE_ORDERS ? "nav-item nav-link  tab  activeTab" : "nav-item nav-link  tab  "} onClick={() => {
              this.props.setActiveTab(cashierTabs.ONLINE_ORDERS); this.props.setActivePage(pageComponents.ONLINEORDERS);
              localStorage.setItem('takenoMode', '0');
              this.props.discountPrecentage();
              this.props.dueamount()
              this.props.subtotalamount();
              this.props.totalamount()
            }} style={{ fontSize: '85%' }} >Online Orders</button>

          </div>
          <ul class="nav nav-tabs mr-auto">

          </ul>
          <div class="form-inline my-2 my-lg-0 ">
            <ul class="navbar-nav mr-auto" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

              {/* <li class="nav-item active">
                <span class="display-3 text-white">|</span>
              </li> */}
              <li class="nav-item active "
                // style={{width:'20%'}}
                style={{ padding: '0px', width: '10vw' }}
              >
                {/* <li class="nav-item active mt-2"> */}
                {/* pl-3 */}
                {/* pt-3 mt-2 */}
                <span class="nav-link " style={{ fontSize: '90%', color: 'white', padding: '0px' }} >

                  {/* <span class="" style={{display:'block',fontSize:'90%',color:'white''}}> */}
                  {localStorage.getItem('CompanyName') ? localStorage.getItem('CompanyName') : ''}
                </span>
              </li>
              {/* <li class="nav-item active">
                <span class="display-3 text-white">|</span>
              </li> */}

              {localStorage.getItem('CustomerName')?.length > 0 ?
                //  mt-2
                <li class="nav-item active"
                  style={{ padding: '0px', textAlign: 'center', width: '15vw' }}
                //  style={{height:'100%',border:'3px solid white'}}
                // marginLeft:'20px',marginRight:'20px',
                // style={{width:'40%'}}
                >
                  {/* pl-3 */}
                  {/* nav-link clock h2 pt-3 */}
                  <span class="" style={{ display: 'block', fontSize: '80%', color: 'white', }} onClick={() => { this.removeCustomerName() }}>
                    Cashier - {localStorage.getItem('UserName')} |  Customer - {localStorage.getItem('CustomerName')}

                    {/* {this.formatTime(this.state.date)} */}
                  </span>
                  <span style={{ fontSize: '80%', color: 'white' }}>
                    Terminal - {localStorage.getItem('TerminalName')} | TableNo - {localStorage.getItem('tableno')}
                  </span>
                  {/* 80/ */}
                  {/* <span style={{ display: 'block', fontSize: '75%', color: 'white', }} onClick={() => { this.removeCustomerName() }}> */}
                  {/* Cashier - {localStorage.getItem('UserName')} |  Customer - {localStorage.getItem('CustomerName')}  */}
                  {/* </span> */}
                  {/* paddingTop: '2px'
 paddingTop: '2px' */}

                </li>
                // mt-2
                : <li class="nav-item active "
                  style={{ padding: '0px', textAlign: 'center', width: '15vw' }}
                // marginLeft:'20px',marginRight:'20px'
                // style={{width:'40%'}}
                //  style={{height:'100%',border:'3px solid white'}}
                >
                  {/* pl-3 */}
                  {/* nav-link clock h2 pt-3 */}
                  {/* paddingTop: '2px'  */}
                  <span class="" style={{ display: 'block', fontSize: '80%', color: 'white', }} onClick={() => { this.removeCustomerName() }}>
                    Cashier - {localStorage.getItem('UserName')}

                    {/* {this.formatTime(this.state.date)} */}
                  </span>
                  <span style={{ fontSize: '80%', color: 'white' }}>
                    Terminal - {localStorage.getItem('TerminalName')} | TableNo - {localStorage.getItem('tableno')}
                  </span>
                  {/* <span class="" style={{ display: 'block', fontSize: '88%', color: 'white',}}> */}
                  {/* {this.formatTime(this.state.date)} */}
                  {/* Cashier - {localStorage.getItem('cashier')} "Praveen" */}
                  {/* Bill No : {localStorage.getItem('TerminalName')} - {localStorage.getItem('billNo')} */}
                  {/* </span> */}

                  {/* paddingTop: '2px'  */}
                  {/* <span class="" style={{ display: 'block',  fontSize: '75%', color: 'white',}}> */}
                  {/* {this.formatTime(this.state.date)} */}
                  {/* Cashier - {localStorage.getItem('cashier')} "Praveen" */}
                  {/* Cashier - {localStorage.getItem('UserName')} */}
                  {/* </span> */}

                </li>
              }
              {/* <li class="nav-item active ">
                pl-3
                <span class="display-3 text-white">|</span>
              </li> */}
              {/* mt-2 */}
              <li class="nav-item active "

                style={{ padding: '0px', width: '8vw' }}
              //  style={{height:'100%',border:'3px solid white'}}
              // style={{width:'32%'}}
              >
                {/* > */}
                {/* pl-3 */}
                {/* nav-link clock h2 pt-3 */}
                {/* paddingTop: '2px' */}
                <span class="" style={{ display: 'block', fontSize: '88%', color: 'white', padding: '0px' }}>
                  {/* {this.formatTime(this.state.date)} */}
                  {/* Cashier - {localStorage.getItem('cashier')} "Praveen" */}
                  Bill No - {localStorage.getItem('TerminalName')} - {localStorage.getItem('billNo')}
                </span>

              </li>
              {/* <li class="nav-item active ">
                pl-3
                <span class="display-3 text-white">|</span>
              </li> */}

              <li class="nav-item active "
                style={{ marginRight: '2px', marginLeft: '2px', padding: '0px' }}>
                {/* pl-3 */}
                {/* style={{ marginTop: '20%' }} */}
                {/* <a class="nav-link" href="#" onClick={()=>this.SignOut()}> */}
                <a class="nav-link" href="#" onClick={this.showModal}>
                  <img src="/images/logout.png" alt="" class="headerMenu"  ></img>
                </a>
              </li>
              {/* <li class="nav-item active " >
                pl-3
                <span class="display-3 text-white">|</span>
              </li> */}
              <li class="nav-item active "
                style={{ marginRight: '2px', marginLeft: '2px', padding: '0px' }}>
                {/* style={{ marginTop: '20%' }} */}
                {/* pl-3 */}
                <a class="nav-link" href="#" onClick={this.drawerToggleClickHandler}>
                  <img src="/images/menu.png" alt="" class="headerMenu"  ></img>
                </a>
                {/* <MenuButton toggle={this.drawerToggleClickHandler} /> */}
              </li>
              <li class="nav-item active "
                style={{ marginRight: '2px', marginLeft: '2px', padding: '0px' }}>
                {/* style={{ marginTop: '20%' }} */}
                {/* pl-3 */}
                <a class="nav-link" href="#" onClick={this.goFull}>
                  <Minimize />
                  {/* <img src="/images/menu.png" alt="" class="headerMenu"  ></img> */}
                </a>
                {/* <MenuButton toggle={this.drawerToggleClickHandler} /> */}
              </li>
            </ul>
            {/* {this.state.admin === true ?  */}
            <Menu show={this.state.drawerOpen} close={this.backdropClickHandler} />
            {/* :
          ""
          } */}

            {backdrop}


          </div>
          <ModalPopLogout show={this.state.show} handleClose={this.hideModal} animation={true}>

            <p>Modal</p>
          </ModalPopLogout>
        </div>
      </nav>


      // <header>


      //   <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
      //     <Container>

      //       <NavbarBrand tag={Link} to="/">{this.displayName}</NavbarBrand>
      //       <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
      //         <ul className="navbar-nav flex-grow">
      //           <NavItem>
      //             <NavLink tag={Link} className="btn btn-primary square-edgesd-default" to="/counter">
      //               <FontAwesomeIcon icon="coffee" inverse size="2x"/>
      //               <br />
      //               Counter
      //               </NavLink>
      //           </NavItem>
      //           <NavItem>
      //             <NavLink tag={Link} className="btn btn-primary square-edgesd-default" to="/">Take away</NavLink>
      //           </NavItem>
      //           <NavItem>
      //             <NavLink tag={Link} className="btn btn-primary square-edgesd-default" to="/fetch-data">Dine In</NavLink>
      //           </NavItem>
      //           <NavItem>
      //             <NavLink tag={Link} className="btn btn-primary square-edgesd-default" to="/fetch-data">Online</NavLink>
      //           </NavItem>
      //           <NavItem>
      //             <NavLink tag={Link} className="btn btn-primary square-edgesd-default" to="/fetch-data">Busy Orders</NavLink>
      //           </NavItem>
      //           <NavItem>
      //             <NavLink tag={Link} className="btn btn-primary square-edgesd-default" to="/fetch-data">Settle Payment</NavLink>
      //           </NavItem>
      //         </ul>
      //       </Collapse>
      //     </Container>
      //   </Navbar>
      // </header>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.homePage.activePage,
  activeTab: state.homePage.activeTab,

});

const mapDispatchToProps = (dispatch) => {
  return {
    setActivePage: (ActivePage) => dispatch(setActivePage(ActivePage)),
    setActiveTab: (ActiveTab) => dispatch(setActiveTab(ActiveTab)),
    cartItems: (item) => dispatch(cartItems(item)),
    showRefund: (item) => dispatch(showRefund(item)),
    showDelivery: (item) => dispatch(showDelivery(item)),
    discountPrecentage: () => dispatch({ type: "discountPrecentage", payload: '' }),
    totalamount: () => dispatch({ type: "totalamount", payload: 0 }),
    subtotalamount: () => dispatch({ type: "subtotalamount", payload: 0 }),
    dueamount: () => dispatch({ type: "dueamount", payload: 0 }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu)

