import React, { useEffect, useState } from 'react';
import { Button, Modal, } from "react-bootstrap";

import { useSelector, useDispatch } from 'react-redux';
import { Calc } from "../Commons/Calc";
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { expenseItems } from '../../reducers/expenseSlice';
import { useReactToPrint } from "react-to-print";
import { cartItems } from '../../reducers/CartSlice';
import { pageComponents } from '../../utilities/Constants';
import { setActivePage } from '../../reducers/homePageSlice';

export function ExpensePayoutModal(props) {
  
  const dispatch = useDispatch();
  const [numericValue, setNumericValue] = useState("");
  const [employeeListData, setEmployeeListData] = useState();
  const [expenseList, setExpenseList] = useState();
  const [reasonName, setResonName] = useState();
  const [transactionDate, setTransactionDate] = useState();
  const [narationValue, setNarationValue] = useState();
  const [selectedEmpName, setSelectedEmpName] = useState();
  
  const [companyDetails, setCompanyDetails] = useState();
  const [loading, setLoading] = React.useState(false);
  
  console.log(loading,"loading please Wait");
  const [text, setText] = React.useState("old boring text");
  const onBeforeGetContentResolve = React.useRef(null);
  const draftRef = React.useRef(null);
  var reasonSplitArrayselectedEmpName =  selectedEmpName !== undefined  && selectedEmpName.length > 0 && selectedEmpName.split(',')
  var reasonSplitArray =  reasonName !== undefined  && reasonName.length > 0 && reasonName.split(',')
  const date = new Date();
    console.log(date, "anandgreenangel");
    
    const futureDate = date.getDate() ;
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();

  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }


console.log(reasonSplitArrayselectedEmpName,"reasonName");
  const handleClose = props.handleClose;


  const EmployeeList = () => {
    //POST jsonconst date = new Date();
    
    
    let dataToSend = {
      token:localStorage.getItem('token')
      
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getemployees.php", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        
        if (responseJson.EmployeeArr[0].Employee != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.EmployeeArr.length; i++) {
            arr.push({
              atype: responseJson.EmployeeArr[i].empname,
            });
          }
          setEmployeeListData(responseJson)
        } else {
         
          alert("Sorry No Data");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const ExpenseList = () => {
    //POST jsonconst date = new Date();
    
    
    let dataToSend = {
      token:localStorage.getItem('token')
      
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_expensedtl.php", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        
        if (responseJson.ExpenseTypeArr[0].ExpenseType != 2) {
          const arr = [];
          setExpenseList(responseJson)
          for (let i = 0; i < responseJson.ExpenseTypeArr.length; i++) {
            arr.push({
              atype: responseJson.ExpenseTypeArr[i].ExpenseType,
            });
          }
        } else {
         
          alert("Sorry No Data");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

 
  useEffect(()=>{
    EmployeeList()
    ExpenseList()
    getdata()
  },[])

console.log(selectedEmpName,"selectedEmpName");
  const newExpensePayoutFunc = () => {
    //POST jsonconst date = new Date();
  
    var num = parseFloat(numericValue)
    let dataToSend = {
      token:localStorage.getItem('token'),
      expid:reasonSplitArray[0],
      paydte:datetime,
      payusr:reasonSplitArrayselectedEmpName[0],
      amount:num.toFixed(2),
      naration:narationValue,
      term:localStorage.getItem('TerminalName'),
      trandte:transactionDate
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_expenseentry.php", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        const item = [
          {
            tokenCreate: localStorage.getItem('token'),
          },
        ];
        dispatch(expenseItems(item));
        handlePrint()
        if (responseJson.ExpenseTypeArr[0].ExpenseType != 2) {
          const arr = [];
          
          for (let i = 0; i < responseJson.ExpenseTypeArr.length; i++) {
            arr.push({
              atype: responseJson.ExpenseTypeArr[i].ExpenseType,
            });
          }
         
         

        } else {
         
          alert("Sorry No Data");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

 
  const handleAfterPrint = React.useCallback(() => {
      
    setNumericValue("");
    dispatch({type:"discountPrecentage",payload:''});
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  //  alert("Floating Cash added")
   handleClose()
   
   dispatch(setActivePage(pageComponents.BILLING));
   dispatch(cartItems())
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {

    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading ...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 


    
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    
    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

    React.useEffect(() => {
      if (
        text === "New, Updated Text!" &&
        typeof onBeforeGetContentResolve.current === "function"
      ) {
        onBeforeGetContentResolve.current();
      }
    }, [onBeforeGetContentResolve.current, text]);
  
    const getdata = () => {
      //POST json
      let dataToSend = {
        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,
    
        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token:localStorage.getItem('token'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl.php", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          console.log(responseJson);
          if (responseJson.clientdtlArr[0].clientdtl != 2) {
            setCompanyDetails(responseJson.clientdtlArr[0])
            // var text =
            //   "[C]<b><font size='big' font-family='cambria' font-weight='bold'>   " +
            //   responseJson.clientdtlArr[0].companynam +
            //   "</font></b>\n" +
            //   "[C]<font size='normal' font-family='cambria' font-weight='bold'>    " +
            //   responseJson.clientdtlArr[0].addr1 +
            //   ", " +
            //   responseJson.clientdtlArr[0].addr2 +
            //   "</font>\n" +
            //   "[C]<font size='normal' font-family='cambria' font-weight='bold'>    PH NO: " +
            //   responseJson.clientdtlArr[0].addr3 +
            //   "</font>\n" +
            //   "[C]<font size='normal' font-family='cambria' font-weight='bold'>    GST NO: " +
            //   responseJson.clientdtlArr[0].gstno +
            //   "</font>\n" +
            //   "[C]<b><font size='normal' font-family='cambria' font-weight='bold'>    LAST RECEIPT</font></b>\n";
            // goToReceipt(text);
    
            // console.log("====================================");
            // console.log(text);
            // console.log("====================================",responseJson);
            // return text;
          } else {
            // setErrortext("Please check your email id or password");
            console.log("Please check your email id or password");
            alert("Sorry No Data");
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });
    };
  return (
    <Modal
      show={props.show}
      animation={props.animation}
      size="md" className="shadow-xl border ">
      {/* <Modal.Header className="border-0"  style={{padding:'5px !important'}}>
        <Modal.Title className="w-100">
          <div class="d-flex">
            <div class="p-2"></div>
            <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
          </div>
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className=" border-0">

        <div class=" ">

          <div class="d-flex flex-row flex-wrap pb-2 pl-2 pr-3 ">
            
           <select class="form-control btn-block-height square-edges p-3  " onChange={(e)=>setSelectedEmpName(e.target.value)}>
  <option value="selected">Select Employee Name</option>
  {employeeListData?.EmployeeArr.length > 0 && employeeListData?.EmployeeArr.map((i)=>(

<option value={[i.userid,i.empname]}>{i.empname}</option>
  ))}
  {/* <option value="2">Two</option>
  <option value="3">Three</option> */}
</select>
          </div>
          <div class="pt-2 pl-2 pr-3 " style={{display:'flex',flexDirection:'row'}}>
            {/* <span>Transaction Date</span> */}
          <input type="date" class="form-control btn-block-height square-edges" defaultValue={defaultValue} style={{padding: "12px!important"}} aria-placeholder='Transaction Date' onChange={(e)=>setTransactionDate(e.target.value)}></input>
       </div>
          <div class="d-flex flex-row flex-wrap pt-2 pl-2 pr-3 ">

          <select class="form-control btn-block-height square-edges p-3" onChange={(e)=>setResonName(e.target.value)}>
            
<option value="selected">Select Payout Type</option>
          {expenseList?.ExpenseTypeArr.length > 0 && expenseList?.ExpenseTypeArr.map((i)=>(

          

<option value={[i.ExpenseId,i.ExpenseName]}>{i.ExpenseName}</option>
          ))}
  {/* <option value="2">Two</option>
  <option value="3">Three</option> */}
</select>
          </div>
        </div>
        <div class=" w-100 pt-2 pl-2 pr-3 ">
              <input className="text-center form-control-lg square-edges btn-block-height inputCardNumber border" type="text" placeholder='Naration' defaultValue={narationValue} onChange={(e)=>setNarationValue(e.target.value)}  style={{marginTop:'2px !important'}}/>
            </div>
        <div class="ml-4 ">
        {/* my-4 */}
          <div class="row text-center flex-wrap w-100">
          
            <div class=" w-100 ">
              <input className="text-center form-control-lg square-edges p-4 inputCardNumber border" type="text" value={numericValue} />
            </div>
            {/* style={{    padding: "10px!important"}}  */}
            <div class="col-4 p-4 border calcButtons"onClick={() => addNumber(7)}><strong>7</strong> </div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
            <div class="col-4 p-4 border "  onClick={() => deleteNumber()}><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>

          </div>


        </div>
        {/* mb-4  */}
        <div class="w-100 pt-2 pl-2 pr-3">

          <Button className="btn-lg btn btn-florogreen btn-block  p-4" onClick={()=>newExpensePayoutFunc()}>Enter</Button>

        </div>
        <div class="w-100 pt-2 pl-2 pr-3">
{loading == true ?

<Button className="btn-lg btn btn-secondary btn-block  p-4" >LOADING ...</Button>
:

<Button className="btn-lg btn btn-secondary btn-block  p-4" onClick={() => handleClose()}>CLOSE</Button>
}

</div>
      </Modal.Body>
      <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " >
               <p className="centered" style={{fontSize:'14px' ,borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>{companyDetails?.companynam}</p>
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.addr1}{companyDetails?.addr2}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>PH NO:{companyDetails?.addr3}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST No:{companyDetails?.gstno}</p>
               <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>EXPENSE PAYOUT RECEIPT</p>
                
                
               {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div> */}
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                 
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Date :{datetime != null && datetime != undefined ? datetime :""}</p>
               </div>
               <table className="centered" style={{borderCollapse:'collapse'}}>
               <thead>
                                <tr style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                                    <th className='totalCash'  style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>Employee Name</th>
                                    <th className="Amount" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>Total({companyDetails?.currency.split(",",1)})</th>
                                    {/* <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>RATE</th>

                                    <th className="price" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>TOTAL</th> */}

                                </tr>
                                 
                            </thead>
                           
                            <tbody  style={{borderTop:'1px dotted black',borderBottom:'1px dotted black',borderCollapse:'collapse'}}>
                            <tr>
                                       <td className="Amount  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{reasonSplitArrayselectedEmpName !== undefined && reasonSplitArrayselectedEmpName.length > 0 && reasonSplitArrayselectedEmpName[1]} ({reasonSplitArray !== undefined && reasonSplitArray.length > 0 && reasonSplitArray[1]})</td>


                                       <td className="Amount  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{companyDetails?.currency.split(",",1)} {numericValue != null && numericValue != undefined ? <>{numericValue}</> : null}</td>


                                           
                                           {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}
                           
                                       </tr>
                                      
                                 

                            </tbody>
                        </table> 
               
             
                       

                    </div>
                </div>
                
           </div>
      {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

          <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
        </Modal.Footer> */}
    </Modal>
  );
}
