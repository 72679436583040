import React, { useState } from 'react';
import './EditTable.css';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux'
import { backToPreviousPage } from '../../reducers/homePageSlice';

// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';
import { fetchTables } from '../../reducers/tablesSlice';


export function EditTable(props) {

  const dispatch = useDispatch();
  const [numericValue, setNumericValue] = useState("");
  const page = useSelector((state) => state.homePage.activePage);

   
    
   

  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }

  const getChange = () => {
    //POST json
    if (localStorage.getItem('tableno') === "CT") {
    alert("please Select DineIn Tables");
    return false;
    }
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
    old_tabno: localStorage.getItem('tableno'),
    new_tabno: numericValue,
    openin: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_changetable", {
    method: "POST",
    body: formBody,
    headers: {
    //Header Defination
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
    //alert(JSON.stringify(responseJson));
    console.log(responseJson);
    
    // dispatch(tableno(numericValue));
    let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
    var item = [
    {
    link: link,
    },
    ];
    dispatch(fetchTables(item));
    getClear();
    
    // Actions.vieworders()
    if (responseJson.ChangeTable[0].ChangeTable != 2) {
    const arr = [];
    for (let i = 0; i < responseJson.ChangeTable.length; i++) {
    arr.push({
    changetable: responseJson.ChangeTable[i].ChangeTable,
    });
    }
    
    console.log("HIII");
    } else {
    alert("Sorry!Cannot Choose Busy Tables");
    //navigation.replace('DrawerNavigationRoutes')
    }
    })
    //If response is not in json then in error
    .catch((error) => {
    //alert(JSON.stringify(error));
    console.error(error);
    });
    };
    const getClear = () => {
    //POST json
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
      tabno:  localStorage.getItem('tableno'),
      terminal: localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
    method: "POST",
    body: formBody,
    headers: {
    //Header Defination
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
    //alert(JSON.stringify(responseJson));
    console.log(responseJson);
    if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
    const arr = [];
    for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
    arr.push({
    clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
    });
    }
    
    console.log("HIII");
    } else {
    alert("Sorry No Data");
    //navigation.replace('DrawerNavigationRoutes')
    }
    })
    //If response is not in json then in error
    .catch((error) => {
    //alert(JSON.stringify(error));
    console.error(error);
    });
    };
    



  return (
    <div class="row">
      <div class="col-4">
        <PageTitle titleHeader={page === pageComponents.MERGETABLES ? "Merge Table" : "Change Table"} titleDescription="" >
        {page === pageComponents.MERGETABLES ? <h4>2 from </h4> : <><h4>Changing from </h4><h4>2 to </h4></>}
          
          {/* <div>Change Table </div> */}
          </PageTitle>
      </div>
      <div class="col-4 mt-5">
        <div class="row mt-5">
          <div class="col-12  d-none d-sm-flex flex-column ">

            <div class="row w-60  ">
              <div class="btn-group-vertical ml-4  " role="group"  >

                <div class="row text-center  flex-wrap w-100">
                  <div class=" col-10 pl-0 pr-0">
                    <input class="text-center form-control-lg square-edges p-5  inputCardNumber border" type="text" value={numericValue} />
                  </div>
                  <div class=" col-2">
                    &nbsp;
                </div>
                  <div class="w-100"></div>
                  <div class="col-2 p-4 border calcButtons col-offset-1" onClick={() => addNumber(7)}><strong>7</strong> </div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('A')}><strong>A</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('E')}><strong>E</strong></div>
                  <div class="w-100"></div>
                  <div class="col-2 p-4 border calcButtons col-offset-1" onClick={() => addNumber(4)}><strong>4</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('B')}><strong>B</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('F')}><strong>F</strong></div>

                  <div class="w-100"></div>
                  <div class="col-2 p-4 border calcButtons col-offset-1" onClick={() => addNumber(1)}><strong>1</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('C')}><strong>C</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('S')}><strong>S</strong></div>

                  <div class="w-100"></div>
                  <div class="col-2 p-4 border calcButtons col-offset-1" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => deleteNumber()}><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('D')}><strong>D</strong></div>
                  <div class="col-2 p-4 border calcButtons" onClick={() => addNumber('U')}><strong>U</strong></div>
                  <div class="w-100"></div>
                  <div class=" col-10 mt-3 pl-0 pr-0">
                    <button class="btn   btn-block btn-lg btn-florogreen p-4" onClick={ () => getChange()}>{page === pageComponents.MERGETABLES ? "Confirm Merge Bill" : "Confirm Change Bill"}</button>
                  </div>
                  <div class=" col-2">
                    &nbsp;
                </div>
                  <div class="w-100"></div>
                  <div class=" col-10 mt-3 pl-0 pr-0">
                    <button class="btn   btn-block btn-lg btn-florogreen p-4" onClick={() => dispatch(backToPreviousPage())}>Cancel</button>
                  </div>
                  <div class=" col-2">
                    &nbsp;
                </div>
                </div>





              </div>
            </div>

          </div>


        </div>
      </div>
    </div>


  );

}
