import React, { useEffect,useState } from 'react';
import './OnlineOrders.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOnlineOrders, selectAllOnlineOrders} from '../../reducers/onlineOrdersSlice';
import { PageTitle } from '../Commons/PageTitle';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UnConfirmedAndConfirmedOrders } from './UnConfirmedAndConfirmedOrders';
import { ToDeliveryOrders } from './ToDeliveryOrders';
import { OutForDeliveryAndDeliveredOrders } from './OutForDeliveryAndDeliveredOrders';
import { OnlineOrderTypes } from '../../utilities/Constants'
import { ConfirmedOrders } from './confirmedOrders';
import { ReadyToDelivery } from './readyToDelivery';

export function OnlineOrders(props) {

 

  const [onlineOrders, setOnlineOrders] = useState(OnlineOrderTypes.UNCONFIRMED_ORDERS);

  let content;

   if (onlineOrders == OnlineOrderTypes.CONFIRMED_ORDERS) {
    content =  <ConfirmedOrders OrderType={OnlineOrderTypes.CONFIRMED_ORDERS} setOnlineOrders={setOnlineOrders}/>
  } else if (onlineOrders == OnlineOrderTypes.UNCONFIRMED_ORDERS) {
    content =  <UnConfirmedAndConfirmedOrders OrderType={OnlineOrderTypes.UNCONFIRMED_ORDERS} setOnlineOrders={setOnlineOrders}/>
  } else if (onlineOrders == OnlineOrderTypes.TO_DELIVERY_PICKUP) {
    content =  <ToDeliveryOrders OrderType={OnlineOrderTypes.TO_DELIVERY_PICKUP} setOnlineOrders={setOnlineOrders}/>
  } else if (onlineOrders == OnlineOrderTypes.OUT_FOR_DELIVERY) {
    content =  <OutForDeliveryAndDeliveredOrders OrderType={OnlineOrderTypes.OUT_FOR_DELIVERY} setOnlineOrders={setOnlineOrders}/>
   } else if (onlineOrders == OnlineOrderTypes.Ready_To_Delivery) {
    content =  <ReadyToDelivery OrderType={OnlineOrderTypes.Ready_To_Delivery} setOnlineOrders={setOnlineOrders}/>
  }
  // else if (onlineOrders == OnlineOrderTypes.DELIVERED) {
  //   content =  <ToDeliveryOrders OrderType={OnlineOrderTypes.DELIVERED} setOnlineOrders={setOnlineOrders}/>
  // }
  else{
    content =  <UnConfirmedAndConfirmedOrders OrderType={OnlineOrderTypes.UNCONFIRMED_ORDERS} setOnlineOrders={setOnlineOrders}/>
  }

  return (
    <>
      <div class="row mt-3 ml-1 mb-2 mr-1">
        <div class="col-md-2" style={{flex:'1 0 16.666667%',maxWidth:'20.666667%'}}>
          <button type="button" onClick={()=>setOnlineOrders(OnlineOrderTypes.UNCONFIRMED_ORDERS)} class={onlineOrders == OnlineOrderTypes.UNCONFIRMED_ORDERS?"btn btn-orange-active btn-lg btn-block p-3": "btn btn-orange btn-lg btn-block p-3"} style={{fontSize:'100%'}}>New Orders</button>
        </div>
        <div class="col-md-2" style={{flex:'1 0 16.666667%',maxWidth:'20.666667%'}}>
          <button type="button" onClick={()=>setOnlineOrders(OnlineOrderTypes.CONFIRMED_ORDERS)}  class={onlineOrders == OnlineOrderTypes.CONFIRMED_ORDERS?"btn btn-orange-active btn-lg btn-block p-3": "btn btn-orange btn-lg btn-block p-3"} style={{fontSize:'100%'}}>Running Orders</button>
        </div>
        <div class="col-md-2" style={{flex:'1 0 16.666667%',maxWidth:'20.666667%'}}>
          <button type="button" onClick={()=>setOnlineOrders(OnlineOrderTypes.Ready_To_Delivery)}  class={onlineOrders == OnlineOrderTypes.Ready_To_Delivery ?"btn btn-orange-active btn-lg btn-block p-3": "btn btn-orange btn-lg btn-block p-3"} style={{fontSize:'100%'}}>Ready For Delivery</button>
        </div>
        <div class="col-md-2" style={{flex:'1 0 16.666667%',maxWidth:'20.666667%'}}>
          <button type="button" onClick={()=>setOnlineOrders(OnlineOrderTypes.TO_DELIVERY_PICKUP)}  class={onlineOrders == OnlineOrderTypes.TO_DELIVERY_PICKUP?"btn btn-orange-active btn-lg btn-block p-3": "btn btn-orange btn-lg btn-block p-3"} style={{fontSize:'100%'}}>Completed Orders</button>
        </div>
        <div class="col-md-2" style={{flex:'1 0 16.666667%',maxWidth:'20.666667%'}}>
          <button type="button" onClick={()=>setOnlineOrders(OnlineOrderTypes.OUT_FOR_DELIVERY)}  class={onlineOrders == OnlineOrderTypes.OUT_FOR_DELIVERY?"btn btn-orange-active btn-lg btn-block p-3": "btn btn-orange btn-lg btn-block p-3"} style={{fontSize:'100%'}}>Declined Orders</button>
        </div>
        {/* <div class="col-md-2">
          <button type="button" onClick={()=>setOnlineOrders(OnlineOrderTypes.DELIVERED)}  class={onlineOrders == OnlineOrderTypes.DELIVERED?"btn btn-orange-active btn-lg btn-block p-3": "btn btn-orange btn-lg btn-block p-3"} style={{fontSize:'100%'}}>Delivered</button>
        </div> */}
        
      </div>
      {
        content
      }
    </>


  );

}
