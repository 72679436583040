import React, { useEffect, useState } from 'react';
import './OnlineOrders.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOnlineOrders, selectAllOnlineOrders } from '../../reducers/onlineOrdersSlice';
import { PageTitle } from '../Commons/PageTitle';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OnlineOrderTypes } from '../../utilities/Constants'
import { OnlineOrderModal } from './OnlineOrderModal'
import { CheckCircle } from 'react-feather';
// import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
// import { fetchNewOrders, selectAllOnlineNewOrders, } from '../../reducers/onlineOrderUnconfirmedSlice';
// import { fetchNewConfirmedOrders, selectAllOnlineNewconfirmedOrders } from '../../reducers/onlineOrderCofirmed';
import { fetchNewOrders } from '../../reducers/onlineOrderUnconfirmedSlice';
import { fetchNewDeliveryOrders, selectAllOnlinenewDeliveryOrders } from '../../reducers/DeliveryOrdersSlice';
import { AssignDeliveryModal } from './AssignDeliverBoyModal';
export function ReadyToDelivery(props) {

  const dispatch = useDispatch()
  const allOnlineOrders = useSelector(selectAllOnlinenewDeliveryOrders)
  let content;
  // const onlineOrdersStatus = useSelector((state) => state.onlineorders.status)
  const onlineOrdersStatus = useSelector((state) => state.newDeliveryOrders.status)
  // const allupcomingOrders = useSelector((state)=>state.onlineorders.selectAllOnlineNewOrders)
  console.log(allOnlineOrders, "allOnlineOrders");
  const error = useSelector((state) => state.newDeliveryOrders.error)
  const orderType = props.OrderType;
  const [showOnlineModal, setShowOnlineModal] = useState(false);
  const [newOrdersData, setNewOrdersData] = useState([]);

  const [count, setCount] = useState(0);
    const handleClickEditOrder = (i) => {
      console.log(i,"Acknowledged");
      setShowOnlineModal(true);
      setNewOrdersData(i)
      // confirmUnComingOrderFunc(i)
    }


  const confirmUnComingOrderFunc = (i) => {
    try {


      let dataToSend = {
        token: '61b32535f2c80',
        ordno: i.ordno
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_foodready", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          console.log(responseJson, "addcarttt");

          props.setOnlineOrders(OnlineOrderTypes.CONFIRMED_ORDERS)
          dispatch(fetchNewDeliveryOrders())
          // handleClose()

        })


    } catch (error) {
      alert(`${error} in onlineOrderModalConfirm`)
    }
  }
 const changePage = () =>{
    
    props.setOnlineOrders(OnlineOrderTypes.TO_DELIVERY_PICKUP)
  }
  const handleOnlineModal = () => setShowOnlineModal(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCount(count + 1);
      dispatch(fetchNewDeliveryOrders())
    }, 3000);

    return () => clearTimeout(timeout);
  }, [fetchNewDeliveryOrders, dispatch]);
  console.log(count, "apple");
  //   useEffect(() => {
  //     // debugger;
  //     if (onlineOrdersStatus === 'idle') {
  //       dispatch(fetchNewConfirmedOrders())
  //     }
  //   }, [onlineOrdersStatus, dispatch])

  //   console.log();

  if (onlineOrdersStatus === 'loading') {
    content = <div className="col-md-2">Loading...</div>
  } else if (onlineOrdersStatus === 'succeeded') {
    content =
      allOnlineOrders.map((onlineOrder, index) => (

        // <tr>


          <div class="col-md-2 pl-1 pt-1 pr-1" style={{ flex: "0 0 20%", maxWidth: "25.666667%" }}
          >
            {/* onClick={() => getDataUsingPost(table.username)}  */}
            {/* onClick={() => handleClickEditOrder(onlineOrder)} */}
            <div class="btn-block-height-onlineOrders text-decoration-none"  onClick={() => handleClickEditOrder(onlineOrder)}>
              {/* '#a8e51f'  rgb(100, 100, 100) */}
              <div class=" h-100 " style={{ background: "rgb(25 227 216)", color: 'black', borderRadius: '5px' }} >
                <div class="d-flex justify-content-between">
                  {/* <button class="rounded-circle p-2 printerButton shadow border-0 bg-white m-2" onClick={(e) => handlePrintDraft(e, table)}><FontAwesomeIcon className={"printerIcon p-1"} icon={["fas", "print"]}></FontAwesomeIcon></button> */}
                  
                  <div class="m-2 h2 display-5">{onlineOrder?.ordno != null && onlineOrder?.ordno != undefined && onlineOrder?.ordno}</div>
                </div>
                <div class="d-flex flex-column">
                {/* <span class="pl-2 lh-0" style={{ margin: '2px', fontSize: '14px', fontWeight: '600' }}> Order No: {onlineOrder?.ordno != null && onlineOrder?.ordno != undefined && onlineOrder?.ordno}</span> */}
                  
                  <span class="pl-2 lh-0" style={{ margin: '2px', fontSize: '14px', fontWeight: '600' }}> Customer: {onlineOrder?.customername != null && onlineOrder?.customername != undefined && onlineOrder?.customername}</span>
                  <span class="pl-2 lh-0" style={{ margin: '2px', fontSize: '14px', fontWeight: '600' }}>Channel: {(onlineOrder?.channelname != null || onlineOrder?.channelname != undefined) ? onlineOrder?.channelname : "Own App"}</span>
                  <span class="pl-2 lh-0" style={{ margin: '2px', fontSize: '14px', fontWeight: '600' }}>Order Date: {onlineOrder?.orddate != null && onlineOrder?.orddate != undefined && onlineOrder?.orddate}</span>
                  <span class="pl-2 lh-0" style={{ margin: '2px', fontSize: '14px', fontWeight: '600' }}>Order Total: {onlineOrder?.ordertotal != null && onlineOrder?.ordertotal != undefined && onlineOrder?.ordertotal}</span>
                  {/* <span class="pl-2 lh-0" style={{ margin: '2px', fontSize: '14px', fontWeight: '600' }}>Payment Mode: {onlineOrder?.paymode != null && onlineOrder?.paymode != undefined ? onlineOrder?.paymode : "Pending"}</span> */}
                  
                  {/* <span class="pl-2 lh-0">In Time: {table?.intime} </span> */}
                </div>


              </div>
            </div>
          </div>


          /* </tr> <th class="align-middle " scope="row" style={{textAlign:'center'}}>{onlineOrder?.ordno != null && onlineOrder?.ordno != undefined &&    onlineOrder?.ordno}</th>
      <th class="align-middle " scope="row" style={{textAlign:'center'}}>{(onlineOrder?.channelname != null || onlineOrder?.channelname != undefined) ?    onlineOrder?.channelname : "Own App"}</th>
      <td class="align-middle" style={{textAlign:'center'}}>{onlineOrder?.customername != null && onlineOrder?.customername != undefined &&  onlineOrder?.customername}</td>
      <td class="align-middle" style={{textAlign:'center'}}><span class="badge rounded-pill bg-success text-white p-2">{onlineOrder?.orddate != null && onlineOrder?.orddate != undefined &&onlineOrder?.orddate}</span></td>
      <td class="align-middle" style={{textAlign:'center'}}>{onlineOrder?.ordertotal != null && onlineOrder?.ordertotal != undefined &&onlineOrder?.ordertotal}</td>
      <td class="align-middle">{"onlineOrder.id"}</td>
      <td class="align-middle">{"@mdo"}</td>
      <td class="align-middle"><span class="badge rounded-pill bg-success text-white p-2">{"8639589241"}</span></td>
      <td class="align-middle" style={{textAlign:'center'}}><span class="badge rounded-pill bg-success text-white p-2">{onlineOrder?.deldate != null && onlineOrder?.deldate != undefined &&onlineOrder?.deldate}</span></td>
      <td class="align-middle"><span class="badge rounded-pill bg-success text-white p-2">{"Assigned to Delivery Exe"}</span></td>
      <td class="align-middle" style={{textAlign:'center'}}><span class="">{onlineOrder?.status != null && onlineOrder?.status != undefined &&onlineOrder?.status}</span></td>
      <td class="align-middle p-2" >
        <div style={{justifyContent:'center',alignItems:'center',display:'flex'}}>

        {onlineOrder?.channelname != null ? 
        ''
        onClick={()=>handleClickEditOrder(onlineOrder)}
        :<button class=" p-2  shadow border-0 "  style={{background:'green',color:'white',borderRadius:'5px'}} >Assign Delivery Boy
        <FontAwesomeIcon className={"gridDeleteIcon  text-white pt-1"}  icon="fas fa-badge-check" />
        <CheckCircle  className={" text-white pt-1"} style={{width:'100%',height:'100%'}}/>
        <FontAwesomeIcon className={"gridDeleteIcon  text-white pt-1"} icon={["fas", "badge"]}></FontAwesomeIcon>
          <img src="/images/VIEW-BN-IC.png" alt="" class="gridViewButton"></img>
          </button> 
        }
        &nbsp;&nbsp;&nbsp;
      <button class="rounded-circle p-2 gridDeleteButton shadow border-0 bg-danger " 
      onClick={()=>handleClickEditOrder()} /
      ><FontAwesomeIcon className={"gridDeleteIcon  text-white pt-1"} icon={["fas", "trash"]}></FontAwesomeIcon></button>
           
        </div>  
      </td> */

        // </tbody>
      ))
  } else if (onlineOrdersStatus === 'failed') {
    content = <div className="col-md-12">{error}</div>
  }


  return (
    <>
      <PageTitle titleHeader={"Ready For Delivery"} titleDescription="" />

      {/* <PageTitle titleHeader={orderType == OnlineOrderTypes.CONFIRMED_ORDERS? "Confirmed Orders":"UnConfirmed Orders"} titleDescription="" /> */}
      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">
        {/* border shadow  */}
          <div class="row w-100 flex-grow-1 tableFixHead">
            {/* <table class="table ametable " > */}
            {/* <thead style={{ borderBottom: '2px solid' }}>
                <tr>
                  <th scope="col" style={{ textAlign: 'center' }}>Order No</th>

                  <th scope="col" style={{ textAlign: 'center' }}>Channel</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Customer</th>
                  <th scope="col">Name</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Ordered Time</th>
                  <th scope="col">Phone</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Amount</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Delivery Time</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Status</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Action</th>
                </tr>
              </thead> */}
            {/* <tbody> */}

            {/* <tbody> */}

            <div class="row ml-4 mr-4 mb-4 d-flex flex-grow-1">
              {content}
            </div>
            {/* </tbody> */}

            {/* </tbody> */}
            {/* </table> */}
            {/* <OnlineOrderModal   show={showOnlineModal}
              animation={true} CloseModal={handleOnlineModal} newOrdersData={newOrdersData}/> */}
              <AssignDeliveryModal   show={showOnlineModal}
              animation={true} CloseModal={handleOnlineModal} newOrdersData={newOrdersData} changePage={changePage}/>
          </div>

        </div>


      </div>
    </>


  );

}
