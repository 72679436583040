import React, { useState } from 'react';
import './Menu.css';
import  {setActivePage,updatePage} from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents } from '../../utilities/Constants'
import {ClosingSalesModal} from '../../components/ClosingSales/ClosingSalesModal'
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitle } from '../Commons/PageTitle';
import { Button } from 'react-bootstrap';
import { Modal, } from "react-bootstrap";

export function Menu(props)  {

  const dispatch = useDispatch();

  const [numericValue, setNumericValue] = useState("");
  const [showClosingSales, setShowClosingSales] = useState(false);
  const [adminScreen, setAdminScreen] = useState(false);
  
  const CloseClosingSales = () => {
    setShowClosingSales(false);
    props.close();
  }
  const ShowClosingSales = () => {
 
    setShowClosingSales(true);
  }

   
  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }
  const cashbody = () =>{
  
  }
  const secureUnlock = () =>{
     
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
      password:  numericValue,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_adminlogin", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson,"emptyCart");
        const item = [
          {
              tableno: localStorage.getItem('tableno'),
          },
        ];
        // dispatch(cartItems(item));
        if (responseJson.AdminLoginArr[0].AdminLogin != 2) {
          // adminScreen
          
    setNumericValue("");
          setAdminScreen(true)
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry Wrong Pin");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  }

  const backtolock = () =>{
    props.close()
    setAdminScreen(false)
  }
 

  const  handleMenuClick = (e,Page)=>{
        
       dispatch(setActivePage(Page));
       props.close();
       setAdminScreen(false)
  }
 
    let drawerClasses = 'side-drawer'
    if (props.show) {
      drawerClasses = 'side-drawer open'
    }
    return (

      // <Modal
      //   show={props.show}
      //   onHide={props.close}
      //   backdrop="false"
      //   keyboard={false}
      //   animation='true'
      // >
        
        <Modal
        show={props.show}
        animation={props.animation}
        size="md" className="shadow-xl border ">
{/* <Modal.Body></Modal.Body> */}
      {props.show == true ?
    <div>
    {adminScreen == false ?
  <div style={{margin:'10px'}}>
    <div  style={{justifyContent: 'center',display:'flex'}}>
    <PageTitle titleHeader="ADMIN" >
        {/* <h4>ADMIN </h4> */}
      </PageTitle>
      </div>
    
      
  <div class="text-center my-4 flex-wrap w-100" style={{display:'flex',padding:'2px'}}>
    <div class=" w-100">
      <input class="text-center form-control-lg square-edges padding__5 inputCardNumber border" type="password" value={numericValue} />
    </div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

    <div class="w-100"></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

    <div class="w-100"></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

    <div class="w-100"></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
    <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
    <div class="col-4 padding__4 border "onClick={() => deleteNumber(0)} ><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>
    
  </div>
  <div style={{display:'flex'}}>
  <Button className="btn-lg btn btn-secondary "
          //  onClick={()=>Securepin()}
           style={{display:'block',width:'95%',marginRight:'3%',padding:'10px'}} onClick={props.close}
           >Cancel</Button>
    <Button className="btn-lg btn btn-florogreen "
          //  onClick={()=>Securepin()}
           style={{display:'block',width:'95%',marginRight:'3%',padding:'10px'}} onClick={()=>secureUnlock()}
           >Secure Unlock</Button>
  </div>
    </div>
    :
<div  className='side-drawer'>
<div class="row">
      <div class="col marginfont">
      <img src="/images/left-arrow.png" alt=""  onClick={backtolock} class="closeMenu"></img>
    
      </div>
    </div>
    <ul className="" id="menu">
      <li><a class="btn btn-lg" href="#"  onClick={(e) => handleMenuClick(e,pageComponents.FLOATINGCASH)}><h4>Floating Cash</h4></a></li>
      {/* <li><a class="btn btn-lg"  href="#" onClick={(e) => handleMenuClick(e,pageComponents.SUPPLIERPAYOUTLIST)}><h4>Supplier Payout</h4></a></li> */}
      <li><a class="btn btn-lg"   href="#" onClick={(e) => handleMenuClick(e,pageComponents.EXPENSEPAYOUTLIST)}><h4>Expense Payout</h4></a></li>
      {/* <li><a class="btn btn-lg" href="#"><h4>Employee Payout</h4></a></li> */}
      <li><a class="btn btn-lg"  href="#" onClick={(e) => ShowClosingSales()}><h4>Closing Sales</h4></a></li>
      {/* <li><a class="btn btn-lg"  mr-5  ml-5  href="#" onClick={(e) => handleMenuClick(e,pageComponents.EMPLOYEEATTENDANCE)}><h4>Employee Attendance</h4></a></li> */}
      <li><a class="btn btn-lg"   href="#"><h4>Open Drawer</h4></a></li>
    </ul>
    <div class="row">
      <div class="col marginfont">
      <button type="button" class="btn-lg btn btn-secondary marginLeft  marginRight p-4 " onClick={(e) => handleMenuClick(e,pageComponents.BILLING)}>Back to Cashier</button>
      </div>
    </div>
</div>
  
  }
    
     
    <ClosingSalesModal show={showClosingSales}
                animation={true} CloseClosingSales={CloseClosingSales}  >

            </ClosingSalesModal>
  </div>
  :''  
    }
      
      </Modal>
      
    )
 

}





