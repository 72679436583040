import React, { useState } from 'react';
import './RefundBill.css';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calc } from '../Commons/Calc';
import { backToPreviousPage } from '../../reducers/homePageSlice';
import { pageComponents } from '../../utilities/Constants';
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'

// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';
import { cartItems } from '../../reducers/CartSlice';
import { refundItems ,updateCart} from '../../reducers/refundSlice';


export function RefundBill(props) {

  const draftRef = React.useRef(null);
  const dispatch = useDispatch();
  const [numericValue, setNumericValue] = useState("");
  const page = useSelector((state) => state.homePage.activePage);
  const [refundData, setRefundData] = useState([]);
  const [kotData, setKotData] = useState([]);

  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }


  const refundFunc = async () =>{
    await getDeleteBillDetails()

  }

const  deletefunc = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
      billno:numericValue,
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_deletebill", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          // alert(JSON.stringify(responseJson));
          console.log(responseJson,"kot");
          
          if (responseJson.DeleteBillArr[0].DeleteBill != 2) {
            // getDeleteBillDetails()

            alert('Bill is Deleted')
    
          }
    
    
          
        })
        .catch((error) => {
          console.error(error);
        });
  
}

const  getDeleteBillDetails = () =>{
  
  const date = new Date();
  console.log(date, "anandgreenangel");
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() ;
  console.log(datetime, date, "anandangelcart");

  const item = {
    numericValue:numericValue,
    datetime:datetime,
   refund:"refund"
  }

  dispatch(refundItems(item))

  dispatch(backToPreviousPage())
}
// const  getDeleteBillDetails = () =>{
  
//   const date = new Date();
// console.log(date, "anandgreenangel");
// const datetime =
//   date.getFullYear() +
//   "-" +
//   (date.getMonth() + 1) +
//   "-" +
//   date.getDate() ;
// console.log(datetime, date, "anandangelcart");
//   let dataToSend = {
//     token: localStorage.getItem('token'),
//     currentdate:datetime,
//     terminal: localStorage.getItem('TerminalName'),
//     billno:numericValue,

//     // tabno: localStorage.getItem('tableno'),
//   };
//   let formBody = [];
//     for (let key in dataToSend) {
//       let encodedKey = encodeURIComponent(key);
//       let encodedValue = encodeURIComponent(dataToSend[key]);
//       formBody.push(encodedKey + "=" + encodedValue);
//     }
//     formBody = formBody.join("&");
//     fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getbilldetails", {
//       method: "POST",
//       body: formBody,
//       headers: {
//         //Header Defination
//         "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
//       },
//     })
//     .then((response) => response.json())
//       //If response is in json then in success
//       .then((responseJson) => {
//         // alert(JSON.stringify(responseJson));
//         console.log(responseJson,"kot");
        
//         if (responseJson.BillDetailsArr[0].BillDetails != 2) {
//           const arr = [];
   
//           const arryjs = [];
//           setRefundData(responseJson.BillDetailsArr)

//           // deletefunc()
//           // if()
//           for (let i = 0; i < responseJson.BillDetailsArr[0].items.length; i++) {
//             arryjs.push({
                
//               kotno: responseJson.GetPrintArr[i].kotno
//             })
            
//            }
//            const obj = [...new Map(arryjs.map(item => [JSON.stringify(item), item])).values()];
//    console.log(obj,"obj");
//            setKotData(obj)
//         }else{
//           alert(responseJson.BillDetailsArr[0].message)
//         }
  
  
        
//       })
//       .catch((error) => {
//         console.error(error);
//       });

// }

console.log(refundData,"refundData");
  return (
    <div class="row">
      <div class="col-4">
        <PageTitle titleHeader="Refund Bill/Delete Bill" titleDescription="" >
       
           
          </PageTitle>
      </div>
      <div class="col-4 mt-5">
        <div class="row mt-5">
          <div class="col-12  d-none d-sm-flex flex-column ">

            <div class="row w-60  ">
            <div class="btn-group-vertical  " role="group"  >

<div class="row text-center my-4 flex-wrap w-100">
  <div class=" w-100">
    <input class="text-center form-control-lg square-edges padding__5 inputCardNumber border" type="text" value={numericValue} />
  </div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

  <div class="w-100"></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

  <div class="w-100"></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

  <div class="w-100"></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
  <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
  <div class="col-4 padding__4 border bg-success text-light" onClick={()=>refundFunc()}><strong>Enter</strong></div>
   
</div>





</div>
            </div>

          </div>


        </div>
      </div>
      <div class="col-4 mt-5 text-right pr-5">
      <Button className="btn-lg btn btn-florogreen w-50 p-4"  onClick={() => dispatch(backToPreviousPage())}>Back</Button>
      </div>


      


    </div>


  );

}
