import {
    createSlice,
    createEntityAdapter,
    createSelector,
    createAsyncThunk,
    } from "@reduxjs/toolkit";
    // import { BillingItem } from "../Models/BillingItem";
    import { client } from "../utilities/client";
    import { gql } from "@apollo/client";
    import { useSelector, useDispatch } from "react-redux";
    import React from "react";
    
    const itemsAdapter = createEntityAdapter();
    const initialState = itemsAdapter.getInitialState({
    status: "idle",
    error: null,
    searchTerm: "",
    });
    
    export const fetchItems = createAsyncThunk("addon/fetchItems", async (item) => {
   
    let dataToSend = {
    token:localStorage.getItem('token'),
    sno: item[0].sno,
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    const response = await fetch(
    "https://superadmin.amepos.io/TabPosApiNew/tabpos_getaddon",
    {
    method: "POST",
    headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
    }
    );
    
    // console.log("data list-----------------",response.json());
    return response.json();
    });
    
    export const searchProducts = createAsyncThunk(
    "items/searchProducts",
    async (searchTerm) => {
    const response = await client.query({
  
    variables: { count: searchTerm },
    });
    return response.data.products;
    }
    );
    
    export const addonSlice = createSlice({
    name: "addon",
    initialState,
    reducers: {
    searchTerm: (state, action) => {
    state.searchTerm = action.payload;
    },
    },
    extraReducers: {
    [fetchItems.pending]: (state, action) => {
    state.status = "loading";
    },
    [fetchItems.fulfilled]: (state, action) => {
    state.status = "succeeded";
    console.log(action.payload);
    itemsAdapter.removeAll(state);
    // console.log("====================================");
    // console.log(action.payload,"hiiiiiiiiiiiiiii");
    // console.log("====================================");
    
    
    const AddOnArr = action.payload.AddOnArr;
    const arr = [];
    for (let i = 0; i < AddOnArr.length; i++) {
    
    arr.push({
    id: i.toString(),
    getaddon: AddOnArr[i].AddOn,
    sno: AddOnArr[i].sno,
    maxallowed:AddOnArr[i].maxallowed,
    minallowed:AddOnArr[i].minallowed,
    masterid:AddOnArr[i].masterid,
    addonid: AddOnArr[i].addonid,
    addnam: AddOnArr[i].addonnam,
    pronam: AddOnArr[i].prodnam,
    masteraddon:AddOnArr[i].masteraddon,
    AddonArray:AddOnArr[i].AddonArray
    });
    }
    // console.log('====================================');
    // console.log(arr,"arrrrrrrrrrr");
    // console.log('====================================');
    arr.map((node) => itemsAdapter.addOne(state, node));
    },
    [searchProducts.rejected]: (state, action) => {
    state.status = "failed";
    state.error = action.error.message;
    },
    [searchProducts.pending]: (state, action) => {
    state.status = "loading";
    },
    [searchProducts.fulfilled]: (state, action) => {
    state.status = "succeeded";
    console.log(action.payload);
    itemsAdapter.removeAll(state);
    action.payload.edges.map(({ node }) => itemsAdapter.addOne(state, node));
    },
    [searchProducts.rejected]: (state, action) => {
    state.status = "failed";
    state.error = action.error.message;
    },
    },
    });
    
    export const { searchTerm } = addonSlice.actions;
    
    export default addonSlice.reducer;
    export const { selectAll: selectAllItems } = itemsAdapter.getSelectors(
    (state) => state.addon
    );
    
    // export const selectItemsForSearch = createSelector(
    // [selectAllItems, (state, searchTerm) => searchTerm],
    // (items, searchTerm) => items.filter((item) => item.name === searchTerm)
    // )
    // export const selectItemsForSearch = (state) => state.items.filter((item) => item.name.includes(searchTerm));