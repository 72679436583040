import React, { useEffect,useState } from 'react';
import './OnlineOrders.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOnlineOrders, selectAllOnlineOrders} from '../../reducers/onlineOrdersSlice';
import { PageTitle } from '../Commons/PageTitle';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OnlineOrderTypes } from '../../utilities/Constants'
import { OnlineOrderModal } from './OnlineOrderModal'
import {   CheckCircle} from 'react-feather';
// import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
// import { fetchNewOrders, selectAllOnlineNewOrders, } from '../../reducers/onlineOrderUnconfirmedSlice';
import { fetchNewConfirmedOrders, selectAllOnlineNewconfirmedOrders } from '../../reducers/onlineOrderCofirmed';
import { fetchNewOrders } from '../../reducers/onlineOrderUnconfirmedSlice';
import { RunninngOrderModal } from './runningOrderModal';
export function ConfirmedOrders(props) {

  const dispatch = useDispatch()
  const allOnlineOrders = useSelector(selectAllOnlineNewconfirmedOrders)
  let content;
  // const onlineOrdersStatus = useSelector((state) => state.onlineorders.status)
  const onlineOrdersStatus = useSelector((state) => state.newconfirmedOrders.status)
  // const allupcomingOrders = useSelector((state)=>state.onlineorders.selectAllOnlineNewOrders)
  console.log(allOnlineOrders,"allOnlineOrders");
  const error = useSelector((state) => state.newconfirmedOrders.error)
  const orderType=props.OrderType;
  const [showOnlineModal, setShowOnlineModal] = useState(false);
  const [newOrdersData, setNewOrdersData] = useState([]);
  
  const [count, setCount] = useState(0);
  const handleClickEditOrder = (i) => {
    console.log(i,"Acknowledged");
    setShowOnlineModal(true);
    setNewOrdersData(i)
    // confirmUnComingOrderFunc(i)
  }
  
  const changePage = () =>{
    
    props.setOnlineOrders(OnlineOrderTypes.CONFIRMED_ORDERS)
  }
 
  const handleOnlineModal = () => setShowOnlineModal(false);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
       setCount(count+1);
       dispatch(fetchNewConfirmedOrders())
     }, 3000);
 
    return () => clearTimeout(timeout);
   },[fetchNewConfirmedOrders,count,dispatch]);
   console.log(count,"apple");
//   useEffect(() => {
//     // debugger;
//     if (onlineOrdersStatus === 'idle') {
//       dispatch(fetchNewConfirmedOrders())
//     }
//   }, [onlineOrdersStatus, dispatch])

//   console.log();

  if (onlineOrdersStatus === 'loading') {
    content = <div className="col-md-2">Loading...</div>
  } else if (onlineOrdersStatus === 'succeeded') {
    content = 
    allOnlineOrders.map((onlineOrder, index) => (
      <div class="col-md-2 pl-1 pt-1 pr-1" style={{flex: "0 0 20%",maxWidth: "25.666667%"}}
      >
        {/* onClick={() => getDataUsingPost(table.username)}  */}
        <div class="btn-block-height-onlineOrders text-decoration-none" onClick={()=>handleClickEditOrder(onlineOrder)}>
        {/* '#a8e51f'  rgb(100, 100, 100) card */}
          <div class=" h-100 " style={{background:"rgb(25 227 216)",color:'black',borderRadius:'5px'}} >
            <div class="d-flex justify-content-between">
              {/* <button class="rounded-circle p-2 printerButton shadow border-0 bg-white m-2" onClick={(e) => handlePrintDraft(e, table)}><FontAwesomeIcon className={"printerIcon p-1"} icon={["fas", "print"]}></FontAwesomeIcon></button> */}
              <div class="m-2 h2 display-5">{onlineOrder?.ordno != null && onlineOrder?.ordno != undefined &&    onlineOrder?.ordno}</div>
            </div>
            <div class="d-flex flex-column">
              <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}> Customer: {onlineOrder?.customername != null && onlineOrder?.customername != undefined &&  onlineOrder?.customername}</span>
              <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Channel: {(onlineOrder?.channelname != null || onlineOrder?.channelname != undefined) ?    onlineOrder?.channelname : "Own App"}</span>
              <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Order Date: {onlineOrder?.orddate != null && onlineOrder?.orddate != undefined &&onlineOrder?.orddate}</span>
              <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Order Total: {onlineOrder?.ordertotal != null && onlineOrder?.ordertotal != undefined &&onlineOrder?.ordertotal}</span>
              {/* <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Payment Mode: {onlineOrder?.paymode != null && onlineOrder?.paymode != undefined ? onlineOrder?.paymode: "Pending"}</span> */}
           {/* <span class="pl-2 lh-0">In Time: {table?.intime} </span> */}
            </div>
  
  
          </div>
        </div>
      </div>
      ))
  } else if (onlineOrdersStatus === 'failed') {
    content = <div className="col-md-12">{error}</div>
  }


  return (
    <>
      <PageTitle titleHeader={"Running Orders"} titleDescription="" />
     
      {/* <PageTitle titleHeader={orderType == OnlineOrderTypes.CONFIRMED_ORDERS? "Confirmed Orders":"UnConfirmed Orders"} titleDescription="" /> */}
      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">
        {/* border shadow  */}
          <div class="row w-100 flex-grow-1 tableFixHead">
     
            
      <div class="row ml-4 mr-4 mb-4 d-flex flex-grow-1">
      {content}
      </div>
            <RunninngOrderModal   show={showOnlineModal}
              animation={true} CloseModal={handleOnlineModal} newOrdersData={newOrdersData} changePage={changePage}/>
          </div>

        </div>


      </div>
    </>


  );

}
