import React, { useState, useEffect } from 'react';

import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { customerItems, customerSlice, searchAllItemsRemove, selectAllCustomer } from '../../reducers/customerSlice';
import { customerItemData } from '../../reducers/ConstantSlice';
import { backToPreviousPage, selectActivePage, setActivePage, setActiveTab } from '../../reducers/homePageSlice';
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';
import { searchItems, selectSearchItems } from '../../reducers/customersearchSlice';


import { Table } from 'antd';
import 'antd/dist/antd.css';
import "./style.css"
import { deliveryBoyItems, selectAllDeliveryBoy } from '../../reducers/deliveryBoySlice';


export function DeliveryBoyList(props) {


    const dispatch = useDispatch();
  
  
    const item = useSelector(selectSearchItems);
    const [searchTerm, setSearchTerm] = React.useState("");
    const customerItemsList = useSelector(selectAllDeliveryBoy);
    let content;
    const tokenCreate = localStorage.getItem('token')
    // const item = useSelector(selectSearchItems);
    const customersStatus = useSelector((state) => state.customers.status)
    // const error = useSelector((state) => state.customers.error)
    // const allCustomers = useSelector(selectAllCustomers)
    const handleClickAddCustomer = (e) => {
      dispatch(setActivePage(pageComponents.ADDDELIVERYBOY));
      //dispatch(setActiveTab(cashierTabs.DINE_IN));
  
    }
    const backtoCashier = (e) => {
      dispatch(setActivePage(pageComponents.BILLING));
      //dispatch(setActiveTab(cashierTabs.DINE_IN));
  
    }
    const editcustomer = (custnam,
      phno,
      dob,
      email,
      addr1,
      addr2,
      addr3,
      addr4,
      addr5,
      custid) => {
      console.log('====================================');
      console.log(custnam, "qqqqqqqqqqqqqqqqqq");
      console.log('====================================');
      const item = [
        custnam,
        phno,
        dob,
        email,
        addr1,
        addr2,
        addr3,
        addr4,
        addr5,
        custid,
      ];
      //  Actions.EditCustomer({"data": item});
      // dispatch(( }));
      dispatch(customerItemData(item));
      dispatch(setActivePage(pageComponents.EDITDELIVERYBOY));
  
    };
  
    const sendCustomerDatafunc = (name, phone, id) => {
      console.log(name, phone, id);
      localStorage.setItem('CustomerName', name);
      localStorage.setItem('Customerid', id);
  
      localStorage.setItem('phoneNumber', phone);
      backtoCashier();
    }
  
  
    const searchProducts = (text) => {
      // setSearchTerm(text);
      // if (text.length > 0) {
      const st = text;
      // console.log("ffghgfdxcvhhgfc",st);
      setSearchTerm(st);
      dispatch(searchAllItemsRemove());
      var item = [
        {
  
          st: text,
        },
      ];
      dispatch(searchItems({ item }));
  
  
      // }
      // else{
  
      // }
    };
    console.log(customerItemsList, "customerItemsList");
    const columns = [
  
      {
        title: 'Name',
        // dataIndex: 'custnam',
  
        fixed: 'left',
        align: 'center',
        render: (text, record) => (
  
          <h6 onClick={() => sendCustomerDatafunc(text.custnam, text.phno, text.custid)} style={{ height: '20px' }}>{text?.custnam}</h6>
        ),
        // sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'Mobile',
        // dataIndex: 'phno',
        align: 'center',
        fixed: 'left',
        render: (text, record) => (
  
          <h6 class="align-middle" onClick={() => sendCustomerDatafunc(text.custnam, text.phno, text.custid)} style={{ height: '20px' }}>{text?.phno}</h6>
        ),
        // sorter: (a, b) => a.phno.length - b.phno.length,
      },
  
      {
        title: 'Email',
        // dataIndex: 'email',
        align: 'center',
        fixed: 'right',
        render: (text, record) => (
  
          <h6 class="align-middle" onClick={() => sendCustomerDatafunc(text.custnam, text.phno, text.custid)} style={{ height: '20px' }}>{text?.email}</h6>
        ),
        // sorter: (a, b) => a.phno.length - b.phno.length,
      },
  
      {
        title: 'Action',
        align: 'center',
        fixed: 'right',
        // dataIndex: 'email',
        render: (text, record) => (
          // <td class="align-middle" style={{ height: '20px' }}>
          <button class="btn btn-florogreen btn-lg rounded-3 align-middle" onClick={() => {
            editcustomer(
              text.custnam, text.phno, text.dob, text.email, text.addr1, text.addr2, text.addr3, text.addr4, text.addr5, text.custid
            );
          }}><FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon></button>
          // <td class="align-middle" onClick={()=>sendCustomerDatafunc(text.custnam,text.phno,text.custid)} style={{height:'20px'}}>{text?.email}</td>
        ),
        // sorter: (a, b) => a.phno.length - b.phno.length,
      },
  
    ]
  
  
    if (customerItemsList.length > 0 && customerItemsList[0].Customer != 2) {
      content = customerItemsList.length > 0 && customerItemsList.map((node, index) => (
        <tr style={{ height: '20px' }}>
  
          <th class="align-middle " scope="row" style={{ height: '20px' }}>{index + 1}</th>
  
          <td class="align-middle" onClick={() => sendCustomerDatafunc(node.custnam, node.phno, node.custid)} style={{ height: '20px' }}>{node.custnam}</td>
          <td class="align-middle" onClick={() => sendCustomerDatafunc(node.custnam, node.phno, node.custid)} style={{ height: '20px' }}>{node.phno}</td>
          {/* <td class="align-middle" onClick={()=>sendCustomerDatafunc(node.custnam,node.phno,node.custid)}>{node.dob}</td> */}
          <td class="align-middle" onClick={() => sendCustomerDatafunc(node.custnam, node.phno, node.custid)} style={{ height: '20px' }}>{node.email}</td>
          {/* <td class="align-middle" onClick={()=>sendCustomerDatafunc(node.custnam,node.phno,node.custid)}>{node.addr1} {node.addr2} {node.addr3} {node.addr4} {node.addr5}
          </td> */}
          <td class="align-middle" style={{ height: '20px' }}><button class="btn btn-florogreen btn-lg rounded-3" onClick={() => {
            editcustomer(
              node.custnam, node.phno, node.dob, node.email, node.addr1, node.addr2, node.addr3, node.addr4, node.addr5, node.custid
            );
          }}><FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon></button></td>
        </tr>
      ))
    }
    else if (item.length > 0 && item[0].Customer != 2) {
      content = item.length > 0 && item.map((node, index) => (
        <tr>
  
          <th class="align-middle" scope="row" style={{ height: '20px' }}>{index + 1}</th>
          <td class="align-middle" onClick={() => sendCustomerDatafunc(node.custnam, node.phno, node.custid)} style={{ height: '20px' }}>{node.custnam}</td>
          <td class="align-middle" onClick={() => sendCustomerDatafunc(node.custnam, node.phno, node.custid)} style={{ height: '20px' }}>{node.phno}</td>
          {/* <td class="align-middle" onClick={()=>sendCustomerDatafunc(node.custnam,node.phno,node.custid)} style={{height:'20px'}}>{node.dob}</td> */}
          <td class="align-middle" onClick={() => sendCustomerDatafunc(node.custnam, node.phno, node.custid)} style={{ height: '20px' }}>{node.email}</td>
          {/* <td class="align-middle" onClick={()=>sendCustomerDatafunc(node.custnam,node.phno,node.custid)} style={{height:'20px'}}>{node.addr1} {node.addr2} {node.addr3} {node.addr4} {node.addr5}
          </td> */}
          <td class="align-middle"><button class="btn  btn-lg btn-secondary rounded-3" onClick={() => {
            editcustomer(
              node.custnam, node.phno, node.dob, node.email, node.addr1, node.addr2, node.addr3, node.addr4, node.addr5, node.custid
            );
          }} style={{ height: '20px' }}><FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon></button></td>
        </tr>
      ))
    }
  
    //  else if (customersStatus === 'failed') {
    //   content = <div className="col-md-12">{error}</div>
  
    // const dispatchFunc = () =>{
    //   dispatch(customerSlice());
    // }
  
    // React.useEffect(() => {
    //   dispatchFunc()
  
    // }, []);
    // useEffect(()=>{
    //   dispatch(customerSlice());
    // },[ dispatch])
  
  
    // useEffect(() => {
    //   if (customersStatus === 'idle') {
    //     dispatch(customerSlice());
    //   }
    // }, [customersStatus, dispatch])
    React.useEffect(() => {
      console.log("====================================");
      console.log("anand", "hiiiiiiiiiiii");
      console.log("====================================");
  
      const item = [
        {
  
          tokenCreate: localStorage.getItem('token'),
        },
      ];
      dispatch(deliveryBoyItems(item));
      //dispatch(cartItems(tokenCreate));
    }, []);
  
  
    return (
      <>
        <div class="row mt-3 ">
          <div class="col-md-2">
            <PageTitle titleHeader="Delivery Boy"  >
              <h3 style={{ display: 'none' }}>Delivery Boy List</h3>
            </PageTitle>
          </div>
          {/* pt-5 */}
          <div class="col-md-4 pt-2">
            <div class=" has-search mt-1">
              <span class="form-control-feedback"><FontAwesomeIcon icon='search' /></span>
              <input type="text" class="form-control inputnonorder-gray rounded-0" placeholder="Search Delivery Boy" onChange={(e) => searchProducts(e.target.value)} ></input>
            </div>
          </div>
  
          <div class="col-md-2">
  
          </div>
          {/* <div class="col-md-2"> */}
          <div class="col-md-2 pr-4">
  
            <button type="button" class="btn btn-secondary btn-lg btn-block p-2" onClick={() => backtoCashier()}>Back</button>
          </div>
          {/* </div> */}
          <div class="col-md-2 pr-4">
            <button type="button" class="btn btn-florogreen btn-lg btn-block p-2" onClick={() => handleClickAddCustomer()}>Add DeliveryBoy</button>
          </div>
        </div>
        <div class="row flex-grow-1 ml-1 mr-1 mb-5 mt-3" >
          <div class="col-12  d-none d-sm-flex flex-column merge-outer">
  
            <div class="row w-100 border shadow flex-grow-1 tableFixHead">
              <div className="table-responsive">
                {customerItemsList?.length > 0 && customerItemsList[0].Customer != 2 ?
                  <Table className="table-striped"
                    // pagination= { {total : customerItemsList.length,
                    // showTotal : (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    // showSizeChanger : true,onShowSizeChange: onShowSizeChange ,itemRender : itemRender 
                    // } }
                    style={{ overflowX: 'auto', textAlign: 'center' }}
                    // style={{ overflowX: 'auto' }}
                    columns={columns}
                    pagination={false}
                    // bordered
                    // bordered
                    dataSource={customerItemsList}
                    rowKey={record => record.id}
                  // onChange={console.log("change")}
                  />
                  :
                  (item.length > 0 && item[0].Customer != 2) ?
                    <Table className="table-striped"
                      // pagination= { {total : customerItemsList.length,
                      // showTotal : (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      // showSizeChanger : true,onShowSizeChange: onShowSizeChange ,itemRender : itemRender 
                      // } }
                      style={{ overflowX: 'auto', textAlign: 'center' }}
                      // style={{ overflowX: 'auto' }}
                      columns={columns}
                      pagination={false}
                      // bordered
                      // bordered
                      dataSource={item}
                      rowKey={record => record.id}
                    // onChange={console.log("change")}
                    />
  
                    : ''
                }
  
              </div>
            </div>
  
          </div>
  
  
        </div>
  
  
  
      </>
  
  
    );
  
  }
  