import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
// import { BillingItem } from '../Models/BillingItem';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';



// const apolloFetch = createApolloFetch({ uri });
// const variables = {
// "count": 5
// };
const query = `query GetCart($table:String,$search:String){
#Pass table name and search field as "ORDER_PLACED"
busyorders(first: 10,filter:{table:$table,status:$search}) {
totalCount
edges {
node {
id #ID of the Busy Order
status #Status of the Busy Order
table {
id
name # Table details of the Busy Order
}
order { # Order details of the Busy Order
metadata{
key
value
}
number #Order number
id #Order Id
status #Order status
created #Order Taken time
lines {
id #orderline id
productName #item name
variantName #itemvariant name [sub-item]
variant{
id
}
quantity #quantity of the orderline
totalPrice{
net{
amount #total price of the orderline
}
}
}
total {
net {
amount #Total amount of the order
}
tax{
amount #Tax amount of the order
}
}
}
}
}
}
}


`;


const refundAdapter = createEntityAdapter();
const initialState = refundAdapter.getInitialState({
    status: 'idle',
    error: null,
    productList: null,
    somename: 0,
    balance: 0,
    billno: ''

})

export const refundItems = createAsyncThunk("refund/refundItems", async (item) => {


    let dataToSend = {
        token: localStorage.getItem('token'),
        // tabno: localStorage.getItem('tableno'),
        // openin: localStorage.getItem('TerminalName'),

        terminal: localStorage.getItem('TerminalName'),
        currentdate: item.datetime,

        billno: item.numericValue,


        // tabno: localStorage.getItem('tableno'),
    };




    let formBody = [];
    for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const response = await fetch(
        "https://superadmin.amepos.io/TabPosApiNew/tabpos_getbilldetails",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: formBody,
        }
    );

    return response.json();


});








export const refundSlice = createSlice({
    name: 'refund',
    initialState,
    reducers: {
        searchTerm: (state, action) => {

            state.searchTerm = action.payload;
        },
        updateCart: (state, action) => {
            refundAdapter.removeAll(state);
            const arr = []
            var amount = 0;
            var usellp = 0;
            var subtotal = 0;
            var totalQty = 0;
            var totalgst = 0;
            var totalItem = 0;
            var arr2 = action.payload;
            var amtt = 0;
            console.log(localStorage.getItem('sellp'), "action.payload.amount");

            for (let i = 0; i < action.payload.length; i++) {
                totalItem = action.payload.length;
                totalQty = totalQty + parseInt(action.payload[i].mainqty);
                totalgst = totalgst + action.payload[i].gst;
                amtt = amtt + parseFloat(action.payload[i].amount);
                subtotal = subtotal + (parseFloat(action.payload[i].amount) - parseFloat(action.payload[i].gst))
                console.log(action.payload[i].amount, "gstamount");
            }
            // for (let i = 0; i < action.payload.length; i++) {
            //     console.log(action.payload[i].amount,action.payload[i].gstType,action.payload[i].edit,localStorage.getItem('sellp'),parseInt(action.payload[i].sellp),parseInt(action.payload[i].qty),parseInt(action.payload[i].GSTper),"all.amount");

            // if(action.payload[i].gstType == 1 && action.payload[i].edit == "yes" &&( localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == '')){
            //     arr2[i].amount = parseInt(action.payload[i].sellp) * parseInt(action.payload[i].qty)
            // }else if(action.payload[i].gstType == 1 && localStorage.getItem('sellp') == 1 && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = parseInt(action.payload[i].sellp1) * parseInt(action.payload[i].qty)
            // }else if(action.payload[i].gstType == 1 && localStorage.getItem('sellp') == 2  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = parseInt(action.payload[i].sellp2) * parseInt(action.payload[i].qty)
            // }else if(action.payload[i].gstType == 1 && localStorage.getItem('sellp') == 3  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = parseInt(action.payload[i].sellp3) * parseInt(action.payload[i].qty)
            // }else if(action.payload[i].gstType == 1 && localStorage.getItem('sellp') == 4  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = parseInt(action.payload[i].sellp4) * parseInt(action.payload[i].qty)
            // }else if(action.payload[i].gstType == 1 && localStorage.getItem('sellp') == 5  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = parseInt(action.payload[i].sellp5) * parseInt(action.payload[i].qty)
            // }else if(action.payload[i].gstType == 0   && action.payload[i].edit == "yes" &&( localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == '')){
            //     arr2[i].amount = ((parseInt(action.payload[i].sellp )+ (parseInt(action.payload[i].sellp) * parseInt(action.payload[i].GSTper) * 0.01) )*(parseInt(action.payload[i].qty)))

            // }else if(action.payload[i].gstType == 0 && localStorage.getItem('sellp') == 1  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = ((parseInt(action.payload[i].sellp1 )+ (parseInt(action.payload[i].sellp1) * parseInt(action.payload[i].GSTper) * 0.01) )*(parseInt(action.payload[i].qty)))

            // }else if(action.payload[i].gstType == 0 && localStorage.getItem('sellp') == 2  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = ((parseInt(action.payload[i].sellp2 )+ (parseInt(action.payload[i].sellp2) * parseInt(action.payload[i].GSTper) * 0.01) )*(parseInt(action.payload[i].qty)))

            // }else if(action.payload[i].gstType == 0 && localStorage.getItem('sellp') == 3  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = ((parseInt(action.payload[i].sellp3 )+ (parseInt(action.payload[i].sellp3) * parseInt(action.payload[i].GSTper) * 0.01) )*(parseInt(action.payload[i].qty)))

            // }else if(action.payload[i].gstType == 0 && localStorage.getItem('sellp') == 4  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = ((parseInt(action.payload[i].sellp4 )+ (parseInt(action.payload[i].sellp4) * parseInt(action.payload[i].GSTper) * 0.01) )*(parseInt(action.payload[i].qty)))

            // }else if(action.payload[i].gstType == 0 && localStorage.getItem('sellp') == 5  && action.payload[i].edit  == "yes"){
            //     arr2[i].amount = ((parseInt(action.payload[i].sellp5 )+ (parseInt(action.payload[i].sellp5) * parseInt(action.payload[i].GSTper) * 0.01) )*(parseInt(action.payload[i].qty)))

            // }
            // else{
            //     arr2[i].amount = action.payload[i].amount
            //     console.log(amount,action.payload[i].amount,"actionamount");
            // }

            // }
            console.log(arr2, action.payload, totalgst, totalQty, totalItem, "amountamount");

            for (let i = 0; i < action.payload.length; i++) {


                arr.push({
                    id: i.toString(),
                    lineno: (i + 1).toString(),
                    // sno: GetCartArr[i].sno,
                    amtpaid: action.payload[i].amtpaid,
                    balance: action.payload[i].balance,
                    edit: action.payload[i].edit,
                    lineno: action.payload[i].lineno,
                    name: "anand",
                    plucod: action.payload[i].plucod,
                    prodname: action.payload[i].prodname,
                    // totalqty: action.payload[i].totalqty,
                    // sellprice: GetCartArr[i].SellPrice,
                    // usellp: GetCartArr[i].usellp,
                    mainqty: action.payload[i].mainqty,
                    discount: 0.00,
                    gst: action.payload[i].gst,
                    GSTper: action.payload[i].GSTper,
                    amount: action.payload[i].amount,
                    remarks: action.payload[i].remarks,
                    pricelevel: action.payload[i].pricelevel,
                    kotno: action.payload[i].kotno,
                    gstType: action.payload[i].gstType,
                    // preqty: GetCartArr[i].prepqty,
                    // printsts: GetCartArr[i].printsts,
                    addon: action.payload[i].addon,

                    subTotal: subtotal,
                    // gst: action.payload[i].gst,
                    qty: action.payload[i].qty,
                    amtt: amtt,
                    totalgst: totalgst,
                    totalqty: totalQty,
                    totalItem: totalItem,
                    unitprice: action.payload[i].unitprice,
                    uqty: action.payload[i].mainqty,
                    uom: 'UNIT',
                    tqty: totalQty,
                    costp: '',
                    sellp: action.payload[i].unitprice,
                    disc: '',
                    kotno: '',
                    gstper: action.payload[i].GSTper,
                    rateid: action.payload[i].rateid,
                    catid: action.payload[i].catid,
                    suplid: '',
                    remarks: '',
                    typ: '',
                    vatvalue: '',
                    vatper: ''

                });
            }
            console.log(arr, "action.payload");
            // state.productList=action.payload;


            arr.map((node) => (

                refundAdapter.addOne(state, node)

            ))
            // console.log(action.payload,"action.payload2");
        }

    },
    extraReducers: {
        [refundItems.pending]: (state, action) => {

            state.status = 'loading'
        },
        [refundItems.fulfilled]: (state, action) => {

            state.status = 'succeeded'
            state.productList = action.payload;
            refundAdapter.removeAll(state);
            console.log('====================================');
            console.log(action.payload, "cartreud");
            console.log('====================================action.payload');


            const GetCartArr = action.payload.BillDetailsArr;
            const arr = [];
            state.somename = GetCartArr[0].amtpaid;
            state.balance = GetCartArr[0].balance;
            state.billno = GetCartArr[0].billno;
            var totalItem = 0;
            var qty = 0;
            var subTotal = 0;
            var gst = 0;
            var dis = 0;
            var amtt = 0;
            console.log(GetCartArr.length, "GetCartArr");
            console.log('====================================GetCartArr.length');
            if (action.payload.BillDetailsArr[0].BillDetails != 2) {
                console.log('====================================action.payload.BillDetailsArr[0].BillDetails');
                if (GetCartArr.length != 0) {
                    console.log('====================================GetCartArr.length');
                    for (let i = 0; i < GetCartArr[0].items?.length; i++) {
                        console.log('====================================GetCartArr[0].items?.length');
                        // subTotal = parseFloat(subTotal) + parseFloat( GetCartArr[0].items[i].SellPrice);
                        gst = parseFloat(gst) + parseFloat(GetCartArr[0].items[i].gst);
                        qty = parseFloat(qty) + parseFloat(GetCartArr[0].items[i].totalquantity);
                        amtt = parseFloat(amtt) + parseFloat(GetCartArr[0].items[i].amount);
                        subTotal = subTotal + parseFloat(GetCartArr[0].items[i].amount) - parseFloat(GetCartArr[0].items[i].gst);

                        totalItem = GetCartArr[0].items.length;
                        try {
                            if (GetCartArr[0].items[i].addon.length != 0) {

                                console.log('====================================addon',);
                                // console.log(GetCartArr[i].kotno.length,"GetCartArr[i].kotno");



                                arr.push({
                                    id: i.toString(),
                                    // sno: GetCartArr[i].sno,
                                    lineno: GetCartArr[0].items[i].lineno,
                                    name: "anand",
                                    plucod: GetCartArr[0].items[i].plucod,
                                    prodname: GetCartArr[0].items[i].ProductName,
                                    // totalqty: GetCartArr[0].items[i].totalquantity,
                                    // sellprice: GetCartArr[i].SellPrice,
                                    // usellp: GetCartArr[i].usellp,
                                    amtpaid: GetCartArr[0].amtpaid,
                                    balance: GetCartArr[0].balance,
                                    gst: parseFloat(GetCartArr[0].items[i].gst),
                                    discount: GetCartArr[0].discount,
                                    GSTper: GetCartArr[0].items[i].gstper,
                                    gstType: GetCartArr[0].gstType,
                                    amount: GetCartArr[0].items[i].amount,
                                    remarks: GetCartArr[0].items[i].remarks,
                                    pricelevel: GetCartArr[0].items[i].PriceLevelid,
                                    kotno: GetCartArr[0].items[i].kotno,
                                    billno: GetCartArr[0].billno,
                                    // preqty: GetCartArr[i].prepqty,
                                    usellp: GetCartArr[0].items[i].unitprice,
                                    // printsts: GetCartArr[i].printsts,
                                    addon: GetCartArr[0].items[i].addon,
                                    subTotal: subTotal,
                                    totalgst: gst,
                                    qty: qty,
                                    totalqty: qty,
                                    amtt: amtt,
                                    mainqty: GetCartArr[0].items[i].totalquantity,
                                    edit: "no",
                                    totalItem: totalItem,
                                    unitprice: GetCartArr[0].items[i].unitprice
                                });
                            } else {

                                arr.push({
                                    id: i.toString(),
                                    // sno: GetCartArr[i].sno,rateid
                                    lineno: GetCartArr[0].items[i].lineno,
                                    amtpaid: GetCartArr[0].amtpaid,
                                    name: "anand",
                                    plucod: GetCartArr[0].items[i].plucod,
                                    prodname: GetCartArr[0].items[i].ProductName,
                                    // totalqty: GetCartArr[0].items[i].totalquantity,
                                    usellp: GetCartArr[0].items[i].usellp,
                                    billno: GetCartArr[0].billno,
                                    balance: GetCartArr[0].balance,
                                    // sellprice: GetCartArr[i].SellPrice,
                                    // usellp: GetCartArr[i].usellp,
                                    gst: parseFloat(GetCartArr[0].items[i].gst),
                                    amount: GetCartArr[0].items[i].amount,
                                    remarks: GetCartArr[0].items[i].remarks,
                                    pricelevel: GetCartArr[0].items[i].PriceLevelid,
                                    kotno: GetCartArr[0].items[i].kotno,
                                    GSTper: GetCartArr[0].items[i].GSTper,
                                    discount: GetCartArr[0].discount,
                                    // preqty: GetCartArr[i].prepqty,
                                    // printsts: GetCartArr[i].printsts,
                                    addon: "",
                                    mainqty: GetCartArr[0].items[i].totalquantity,
                                    subTotal: subTotal,
                                    totalgst: gst,
                                    totalqty: qty,
                                    qty: qty,
                                    amtt: amtt,
                                    edit: "no",
                                    totalItem: totalItem,
                                    unitprice: GetCartArr[0].items[i].unitprice

                                });
                            }
                        } catch (error) {
                            console.log(error);
                        }

                    }


                    arr.map((node) => refundAdapter.addOne(state, node));

                    // console.log("ddddddddddddddddddd",action.payload.data.products.edges);
                    // refundAdapter.upsertMany(state, action.payload.data.products.edges);


                } else {
                    const arr = [
                        {
                            id: 0,
                            name: "yasar",
                            amount: 0,
                            subTotal: 0,
                            gst: 0,
                        },
                    ];

                    arr.map((node) => refundAdapter.addOne(state, node));
                }
            } else {
                const arr = [
                    {
                        id: 0,
                        name: "yasar",
                        amount: 0,
                        subTotal: 0,
                        gst: 0,
                    },
                ];

                arr.map((node) => refundAdapter.addOne(state, node));
            }


        },
        [refundItems.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});
export const { updateCart } = refundSlice.actions;



export default refundSlice.reducer;
export const { selectAll: selectAllRefund, selectEntities: select } = refundAdapter.getSelectors((state) => state.refund);

//

// export const selectItemsForSearch = createSelector(
// [selectAllItems, (state, searchTerm) => searchTerm],
// (items, searchTerm) => items.filter((item) => item.name === searchTerm)
// )
// export const selectItemsForSearch = (state) => state.items.filter((item) => item.name.includes(searchTerm));