import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import '../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Splash extends Component {




    render() {
        return (
            <div class="container-fluid  d-none d-sm-flex flex-column h-100 text-center" >
                <img src="images/logo-ss.png" class="w-25 text-center"></img>
                www.amepos.io
            </div>


        );
    }
}