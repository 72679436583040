import React, { useEffect, useState } from 'react';
import {  Modal, } from "react-bootstrap";
import { useSelector, useDispatch  } from 'react-redux';
import { cartItems } from '../../reducers/CartSlice';
import { setActivePage } from '../../reducers/homePageSlice';
import { pageComponents } from '../../utilities/Constants';
export function TakeAwayModal(props) {
    const dispatch = useDispatch()
    // const onlineModeData = useSelector((state) => state.constVar.onlineModeData)
    const handleClose = props.handleClose;

    

    const getDataUsingPost = (takeaway,sno,name) => {
      console.log(takeaway,"takeaway");
    localStorage.setItem('takenoMode',sno)
    localStorage.setItem('takeawayOnlinePayment',name)
      //POST json
      let dataToSend = {
        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
  
      terminal: localStorage.getItem('TerminalName'),
  
      token:localStorage.getItem('token'),
      tabno: takeaway,
      };
      let formBody = [];
      for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_checkbusytable", {
      method: "POST",
      body: formBody,
      headers: {
      //Header Defination
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
      //alert(JSON.stringify(responseJson));
      console.log(responseJson,"takeawwaycolde");
      if (responseJson.CheckBusyArr[0].CheckBusy === 3) {
        handleClickTakeawaymodalClose(takeaway)
        } else {
          handleClickTakeawaymodalClose(takeaway)
        // alert("Terminal "+responseJson.CheckBusyArr[0].Openin+" Work in this table")
        }
      // if (responseJson.CheckBusyArr[0].CheckBusy != 2) {
      // const arr = [];
      // for (let i = 0; i < responseJson.CheckBusyArr.length; i++) {
      // arr.push({
      // chkbusy: responseJson.CheckBusyArr[i].CheckBusy,
      // });
      // }
      
      // console.log("HIII");
      // } else {
      // alert("Sorry No Data");
      //navigation.replace('DrawerNavigationRoutes')
      // }
      })
      //If response is not in json then in error
      .catch((error) => {
      //alert(JSON.stringify(error));
      console.error(error);
      });
  // }
      };
    
  const handleClickTakeawaymodalClose = (takeaway) => {
    // dispatch(addBill({ id: 1, customername: "chandru", lineitems: [] }));

    localStorage.setItem('tableno', takeaway);
    
    console.log(takeaway, "anand");
    const item = [
      {
        tableno: takeaway,
        terminalno: localStorage.getItem('TerminalName'),
      },
    ];
    dispatch(cartItems(item));
    dispatch(setActivePage(pageComponents.BILLING));
    handleClose()
    // dispatch(setActivePage(pageComponents.BILLING));
  }

  return (
    <>
      <Modal
    show={props.show}
    // animation={props.animation}
    size="md" className="shadow-lg border ">
    <Modal.Header className="">
      <Modal.Title className="w-100">
        <div class="d-flex">
          {/* <div class="p-2">Change Price</div> */}
          <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
        </div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0 m-0 border-0 modal-body-changeprice">
    <div class="pl-3 pt-3 mb-4">
              {/* onClick={()=>{changeToNormalPrice()}} */}
              {/* onClick={getDataUsingPost(props.takeAwayData)} */}
              {/* href="#"  */}
          <div  onClick={()=>getDataUsingPost(props.takeAwayData,'0','')}   class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{"Normal Price"}</h3></div>
        </div>
     {props.onlineModeData?.length > 0 && props.onlineModeData?.map((i)=>(
          <div class="pl-3 pt-3 mb-4">
              {/* onClick={()=>{changeToNormalPrice()}} */}
              {/* onClick={getDataUsingPost(props.takeAwayData)} */}
              {/* href="#"  */}
          <div  onClick={()=>getDataUsingPost(props.takeAwayData,i.sno,i.ewallet)}   class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{i.ewallet}</h3></div>
        </div>
     ))}
      {/* <div class="pl-3 pt-3 mb-4">
        <a onClick={()=>{changeToNormalPrice()}}  href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">Normal Price</h3></a>
      </div>
    
    
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp1nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp1nam != null && companyDetails?.sellp1nam != undefined && companyDetails?.sellp1nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp2nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp2nam != null && companyDetails?.sellp2nam != undefined && companyDetails?.sellp2nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp3nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp3nam != null && companyDetails?.sellp3nam != undefined && companyDetails?.sellp3nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp4nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp4nam != null && companyDetails?.sellp4nam != undefined && companyDetails?.sellp4nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp5nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp5nam != null && companyDetails?.sellp5nam != undefined && companyDetails?.sellp5nam}</h3></a>

      </div> */}

    </Modal.Body>
    {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

        <div>
          <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
        </div>
      </Modal.Footer> */}
  </Modal>
    </>
  );

}