import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { cartItems } from "../../reducers/CartSlice";
import { apiURI } from "../../utilities/client";

import "./style.css";

export function KeyboardOn(props) {
  const productSno = useSelector((state) => state.constVar.productId)
  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
//   const tableid = useSelector(state => state.table.tablename)

//   const addonId = useSelector(state =>state.table.addonIdProductName)
  const dispatch = useDispatch();
  const onChange = input => {
    setInput(input);
    console.log("Input changed", input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = event => {
    const input = event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };
  

  const addRemarksfunc = () => {
    //POST json
    
    console.log('====================================');
    // console.log(sno,name,"nameeeeeeee");
    console.log('====================================');
    let dataToSend = {
    token: localStorage.getItem("token"),
    
    sno: productSno,
    remarks: input,
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_addremarks", {
    method: "POST",
    body: formBody,
    headers: {
    //Header Defination
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
    //alert(JSON.stringify(responseJson));
    console.log(responseJson);
    if (responseJson.AddRemarksArr[0].AddRemarks != 2) {
    const arr = [];
    for (let i = 0; i < responseJson.AddRemarksArr.length; i++) {
    arr.push({
    addremarks: responseJson.AddRemarksArr[i].AddRemarks,
    });
    }
    const item = [
      {
      tableno: localStorage.getItem('tableno'),
      tokenCreate: localStorage.getItem('token'),
      terminalno: localStorage.getItem('TerminalName'),
      },
      ];
      dispatch(cartItems(item));
    console.log("HIII");
    props.setShowKeyBoard(false)
    } else {
    // setErrortext("Please check your email id or password");
    console.log("Please check your email id or password");
    alert("Sorry No Data");
    //navigation.replace('DrawerNavigationRoutes')
    }
    })
    //If response is not in json then in error
    .catch((error) => {
    //alert(JSON.stringify(error));
    console.error(error);
    });
    };

  return (
    <div className="App">
      <input
        value={input}
        placeholder={"Tap on the virtual keyboard to start"}
        onChange={onChangeInput}
      />
      <Keyboard
        keyboardRef={r => (keyboard.current = r)}
        layoutName={layout}
        onChange={onChange}
        onKeyPress={onKeyPress}
        style={{height:'80px'}}
      />
      <div className="m-2 btn-block-height-products "  style={{display:'flex'}}>
      <button className="btn btn-orange btn-lg "style={{width:'80%',margin:'10px'}} onClick={()=>addRemarksfunc()}>
      Enter</button>
      <button className="btn btn-lg "style={{width:'80%',margin:'10px',background:'gray',color:'white',fontWeight:'bold'}} onClick={()=>props.setShowKeyBoard(false)}>Back</button>
      </div>
    </div>
  );
}
