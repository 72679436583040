
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { selectAllItems,selectItemsForSearch, } from '../../reducers/addonSlice';
import { cartItems } from '../../reducers/CartSlice';
import './GetItems.css'


export function Addons(props) {
  const [showChildAddons,setShowChildAddons] = useState(false)
  const [showChildAddonsData,setShowChildAddonsData] = useState(false)
  
  
  const dispatch = useDispatch();

  const {pricelevel} =useSelector((state)=>state.touch)
  const {usellp} =useSelector((state)=>state.touch)
  
  const items = useSelector(selectAllItems);
  console.log(items,"items")


    const Addaddon = (sno, addonid) => {
      var tablevalue = ''
      if (localStorage.getItem('tableno') == 'CT') {
      //   hsnoconst = localStorage.getItem('hsno')
        tablevalue = `CT-${localStorage.getItem('TerminalName')}`
      } else {
      //   hsnoconst = 0
      tablevalue = localStorage.getItem('tableno')
      }
        let dataToSend = {
          
        sno: sno,
        addonid: addonid,
        usellp:usellp,
        token: localStorage.getItem('token'),
        rateid:pricelevel,
        tabno:tablevalue,
        ordusr:localStorage.getItem('UserID'),
        terminal:localStorage.getItem('TerminalName'),
        };
        let formBody = [];
        for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_addonadd", {
        method: "POST",
        body: formBody,
        headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        })
        .then((response) => response.json())
        .then((responseJson) => {
        if (responseJson.AddaddonArr[0].Addaddon != 2) {
        const arr = [];
        for (let i = 0; i < responseJson.AddaddonArr.length; i++) {
        arr.push({
        addon: responseJson.AddaddonArr[i].Addaddon,
        });
        };
        dispatch(cartItems())
        // props.setOpenProductsModal(false)
        } else {
        alert("Sorry No Data");
        
        }
        })
        .catch((error) => {
        });
        };


    
 
 const  close = () =>{
    props.setOpenProductsModal(false)
  }

  const changeMasterToChild = (i) =>{
    console.log(i,"sjjj");
    setShowChildAddonsData(i)
    setShowChildAddons(true)
  }

  return (
    <>
    { showChildAddons == false ?
      <ul class="cards_overflow ">
         { 
       items?.length > 0 && items[0].getaddon != 2 && items.map((node) => (
     
     <li class="cards_item_1 margin_2 btn_height_items" >
        
          <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1" onClick = {()=>changeMasterToChild(node)}>
                   
          {node.masteraddon}
                 
               </button>
    
      </li>
))}
    
    <li class="cards_item_1 margin_2 btn_height_items" >
      <button class="btn btn-block btn__large btn-infored btn_items_height margin-1" onClick = {()=>close()}>
        Back       
      </button>
    </li>

    
  </ul>
  :
  <ul class="cards_overflow ">
     { 
    showChildAddonsData?.AddonArray?.length > 0 && showChildAddonsData.AddonArray[0].AddOn != 2 && showChildAddonsData.AddonArray.map((node) => (
  
  <li class="cards_item_1 margin_2 btn_height_items" >
     
       <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1" onClick = {()=>Addaddon(showChildAddonsData.sno, node.addonid)}>
                
       {node.addonnam}
              
            </button>
 
   </li>

))}
 <li class="cards_item_1 margin_2 btn_height_items" >
   <button class="btn btn-block btn__large btn-infored btn_items_height margin-1" onClick = {()=>close()}>
     Back       
   </button>
 </li>

 
</ul>
  }
  
  </>
  );
}

