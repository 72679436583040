import React from 'react';
import { Button, Modal, } from "react-bootstrap";

 
export function AlertModal(props) {

 
   
  const handleClose = props.handleClose;
  
  return (
  
      <Modal
    show={props.show}
    animation={props.animation}
    size="md" className="shadow-lg border ">
    <Modal.Header className="">
      <Modal.Title className="w-100">
        <div class="d-flex">
          <div class="p-2">{props.Title}</div>
          <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
        </div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0 m-0 border-0 text-center p-4 h4">
     
      {props.Message}
      

    </Modal.Body>
     <Modal.Footer className="py-1 d-flex justify-content-center border-0">

        <div>
          <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5"  onClick={() => handleClose()}>OK</Button>
        </div>
      </Modal.Footer>  
  </Modal>
   
  );

}