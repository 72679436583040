import { gql, useQuery } from '@apollo/client';
import './ClosingSales.css'

export function Denomination({setone,settwo,setfive,settwohnsnd, setten, settwenty,setfifty, sethnrd, settwohnrd, setfivehnrd}) {

    return (
        <div class="row ml-4">
            <div class="col">
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5 pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block " style={{marginTop:'10px'}}>1</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => setone(e.target.value)} /></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  " style={{marginTop:'10px'}}>2</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => settwo(e.target.value)}/></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  "  style={{marginTop:'10px'}}>5</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85"  onChange={(e) => setfive(e.target.value)}  style={{marginTop:'10px'}}/></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  "  style={{marginTop:'10px'}}>10</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange ={(e) => setten(e.target.value)} style={{marginTop:'0px'}}/></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  " style={{marginTop:'10px'}}>20</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => settwenty(e.target.value)} style={{marginTop:'0px'}}/></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  " style={{marginTop:'10px'}}>50</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => setfifty(e.target.value)} style={{marginTop:'0px'}}/></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  " style={{marginTop:'10px'}}>100</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => sethnrd(e.target.value)} style={{marginTop:'0px'}}/></div>
                        </div>
                    </div>
                </div>
               

            
            {/* <div class="col"> */}
            <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  " style={{marginTop:'10px'}}>200</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => settwohnrd(e.target.value)} style={{marginTop:'0px'}}/></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  " style={{marginTop:'10px'}}>500</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => setfivehnrd(e.target.value)} style={{marginTop:'0px'}}/></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col ">
                        {/* p-2 */}
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn button__large btn-infogreen btn-block  " style={{marginTop:'10px'}}>2000</button></div>
                            <div class="pl-5 pr-5 pt-2" style={{marginTop:'10px'}}>X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-85" onChange={(e) => settwohnsnd(e.target.value)} style={{marginTop:'0px'}}/></div>
                        </div>
                    </div>
                </div>
                {/* <div class="row">
                    <div class="col ">
                    // p-2
                        <div class="d-flex  justify-content-between pl-5 pr-5">
                            <div class="w-50"><button class="btn btn-lg btn-infogreen btn-block  ">5000</button></div>
                            <div class="pl-5 pr-5 pt-2">X</div>
                            <div class="w-50"><input type="text" class="form-control square-edges h-100" /></div>
                        </div>
                    </div>
                </div> */}
            {/* </div> */}
            </div>
        </div>

    );
}
