import React, { useEffect,useState } from 'react';
import './OnlineOrders.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOnlineOrders, selectAllOnlineOrders} from '../../reducers/onlineOrdersSlice';
import { PageTitle } from '../Commons/PageTitle';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OnlineOrderTypes } from '../../utilities/Constants'
import { OnlineOrderModal } from './OnlineOrderModal'
import {   CheckCircle} from 'react-feather';
// import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
import { fetchNewOrders, selectAllOnlineNewOrders, } from '../../reducers/onlineOrderUnconfirmedSlice';
export function UnConfirmedAndConfirmedOrders(props) {
  
  const dispatch = useDispatch()
  const allOnlineOrders = useSelector(selectAllOnlineNewOrders)
  let content;
  // const onlineOrdersStatus = useSelector((state) => state.onlineorders.status)
  const onlineOrdersStatus = useSelector((state) => state.newOrders.status)
  // const allupcomingOrders = useSelector((state)=>state.onlineorders.selectAllOnlineNewOrders)
  console.log(allOnlineOrders,"allOnlineOrders");
  const error = useSelector((state) => state.newOrders.error)
  const orderType=props.OrderType;
  const [showOnlineModal, setShowOnlineModal] = useState(false);
  const [newOrdersData, setNewOrdersData] = useState([]);
  
  const handleClickEditOrder = (i) => {
    
    setShowOnlineModal(true);
    setNewOrdersData(i)

  }
 
  const handleOnlineModal = () => {
    setShowOnlineModal(false)
  };

  const changePage = () =>{
    
    props.setOnlineOrders(OnlineOrderTypes.Ready_To_Delivery)
  }
  
  useEffect(() => {
    // debugger;
    if (onlineOrdersStatus === 'idle') {
      dispatch(fetchNewOrders())
    }
    // onlineOrdersStatus, dispatch
  }, [])

  // console.log();

  if (onlineOrdersStatus === 'loading') {
    content = <div className="col-md-2">Loading...</div>
  } else if (onlineOrdersStatus === 'succeeded') {
    content = 
    allOnlineOrders.map((onlineOrder, index) => (
      
      allOnlineOrders.map((onlineOrder, index) => (
        <div class="col-md-2 pl-1 pt-1 pr-1" style={{flex: "0 0 20%",maxWidth: "25.666667%"}}
        >
          {/* onClick={() => getDataUsingPost(table.username)}  */}
          <div class="btn-block-height-onlineOrders text-decoration-none" onClick={()=>handleClickEditOrder(onlineOrder)}>
          {/* '#a8e51f'  rgb(100, 100, 100) card */}
            <div class=" h-100 " style={{background:"rgb(25 227 216)",color:'black',borderRadius:'5px'}} >
              <div class="d-flex justify-content-between">
                {/* <button class="rounded-circle p-2 printerButton shadow border-0 bg-white m-2" onClick={(e) => handlePrintDraft(e, table)}><FontAwesomeIcon className={"printerIcon p-1"} icon={["fas", "print"]}></FontAwesomeIcon></button> */}
                <div class="m-2 h2 display-5">{onlineOrder?.ordno != null && onlineOrder?.ordno != undefined &&    onlineOrder?.ordno}</div>
              </div>
              <div class="d-flex flex-column">
                <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}> Customer: {onlineOrder?.customername != null && onlineOrder?.customername != undefined &&  onlineOrder?.customername}</span>
                <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Channel: {(onlineOrder?.channelname != null || onlineOrder?.channelname != undefined) ?    onlineOrder?.channelname : "Own App"}</span>
                <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Order Date: {onlineOrder?.orddate != null && onlineOrder?.orddate != undefined &&onlineOrder?.orddate}</span>
                <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Order Total: {onlineOrder?.ordertotal != null && onlineOrder?.ordertotal != undefined &&onlineOrder?.ordertotal}</span>
                {/* <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Payment Mode: {onlineOrder?.paymode != null && onlineOrder?.paymode != undefined ? onlineOrder?.paymode: "Pending"}</span> */}
             {/* <span class="pl-2 lh-0">In Time: {table?.intime} </span> */}
              </div>
    
    
            </div>
          </div>
        </div>
        ))
    ))
  } else if (onlineOrdersStatus === 'failed') {
    content = <div className="col-md-12">{error}</div>
  }


  return (
    <>
      <PageTitle titleHeader={"New Orders"} titleDescription="" />
     
      {/* <PageTitle titleHeader={orderType == OnlineOrderTypes.CONFIRMED_ORDERS? "Confirmed Orders":"UnConfirmed Orders"} titleDescription="" /> */}
      {/* <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer"> */}
        <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">
          {/* <div class="row w-100 border shadow flex-grow-1 tableFixHead"> */}
            {/* <table class="table ametable " >
              <thead style={{borderBottom:'2px solid'}}>
                <tr>
                  <th scope="col" style={{textAlign:'center'}}>Order No</th>

                  <th scope="col" style={{textAlign:'center'}}>Channel</th>
                  <th scope="col" style={{textAlign:'center'}}>Customer</th>
                  <th scope="col">Name</th>
                  <th scope="col" style={{textAlign:'center'}}>Ordered Time</th>
                  <th scope="col">Phone</th>
                  <th scope="col" style={{textAlign:'center'}}>Amount</th>
                  <th scope="col" style={{textAlign:'center'}}>Delivery Time</th>
                  <th scope="col" style={{textAlign:'center'}}>Action</th>
                </tr>
              </thead>
              <tbody>
              {content}
               
                
              </tbody>
            </table> */}
            <div class="row w-100 flex-grow-1 tableFixHead">
     
            
     <div class="row ml-4 mr-4 mb-4 d-flex flex-grow-1">
     {content}
     </div>
            <OnlineOrderModal   show={showOnlineModal}
              animation={true} CloseModal={handleOnlineModal} newOrdersData={newOrdersData} changePage={changePage}/>
          </div>

        </div>


      </div>
    </>


  );

}
