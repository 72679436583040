import { gql, useQuery } from '@apollo/client';

export function PageTitle(props) {


    return (
       <div class="row ml-1 pl-3 mt-3">
           <div class="pl-1">
           <h2>{props.titleHeader}&nbsp;{props.subTitle && <span class="h4 text-secondary">{props.subTitle}</span>}</h2>
           <span class="">{props.children}</span>
           </div>
           
       </div>

    );
}
 
 