




import React, { useEffect,useState } from 'react';
import './OnlineOrders.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOnlineOrders, selectAllOnlineOrders} from '../../reducers/onlineOrdersSlice';
import { PageTitle } from '../Commons/PageTitle';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OnlineOrderTypes } from '../../utilities/Constants'
import { OnlineOrderModal } from './OnlineOrderModal'
import {   CheckCircle} from 'react-feather';
// import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
import { fetchNewOrders, selectAllOnlineNewOrders, } from '../../reducers/onlineOrderUnconfirmedSlice';
import { fetchNewConfirmedOrders, selectAllOnlineNewconfirmedOrders } from '../../reducers/onlineOrderCofirmed';
import { ShowOrderDetailsModal } from './showOrderDetailspopup';
import { fetchNewCompletedOrders, selectAllOnlinenewCompletedOrders } from '../../reducers/completedOnlineOrderSlice';
import { AssignDeliveryModal } from './AssignDeliverBoyModal';
import { cartItems } from '../../reducers/CartSlice';


export function ToDeliveryOrders(props) {
  
  const dispatch = useDispatch()
  const allOnlineOrders = useSelector(selectAllOnlinenewCompletedOrders)
  let content;
  // const onlineOrdersStatus = useSelector((state) => state.onlineorders.status)
  // const onlineOrdersStatus = useSelector((state) => state.newconfirmedOrders.status)
  // const allupcomingOrders = useSelector((state)=>state.onlineorders.selectAllOnlineNewOrders)
  console.log(allOnlineOrders,"allOnlineOrders");
  const onlineOrdersStatus = useSelector((state) => state.newCompletedOrders.status)
  const error = useSelector((state) => state.newCompletedOrders.error)
  const orderType=props.OrderType;
  const [showOnlineModal, setShowOnlineModal] = useState(false);
  const [newOrdersData, setNewOrdersData] = useState([]);
  
  const handleClickEditOrder = (i) => {
    
    setShowOnlineModal(true);
    setNewOrdersData(i)

  }
  const changePage = () =>{
    
    // props.setOnlineOrders(OnlineOrderTypes.Ready_To_Delivery)
  }
  const handleOnlineModal = () => {
    setShowOnlineModal(false)
    // props.setOnlineOrders(OnlineOrderTypes.CONFIRMED_ORDERS)
  };
  
  useEffect(() => {
    // debugger;
    if (onlineOrdersStatus === 'idle') {
      dispatch(fetchNewCompletedOrders())
    }
    // onlineOrdersStatus, dispatch
  }, [])

  // console.log();

  if (onlineOrdersStatus === 'loading') {
    content = <div className="col-md-2">Loading...</div>
  } else if (onlineOrdersStatus === 'succeeded') {
    content = allOnlineOrders?.length > 0 ?
    allOnlineOrders.map((onlineOrder, index) => (
    <div class="col-md-2 pl-1 pt-1 pr-1" style={{flex: "0 0 20%",maxWidth: "25.666667%"}}
    >
      {/* onClick={() => getDataUsingPost(table.username)}  */}
      {/* onClick={()=>handleClickEditOrder(onlineOrder)} */}
      <div class="btn-block-height-onlineOrders text-decoration-none">
      {/* '#a8e51f'  rgb(100, 100, 100) card */}
        <div class=" h-100 " style={{background:"rgb(25 227 216)",color:'black',borderRadius:'5px'}} >
          <div class="d-flex justify-content-between">
            {/* <button class="rounded-circle p-2 printerButton shadow border-0 bg-white m-2" onClick={(e) => handlePrintDraft(e, table)}><FontAwesomeIcon className={"printerIcon p-1"} icon={["fas", "print"]}></FontAwesomeIcon></button> */}
            <div class="m-2 h2 display-5">{onlineOrder?.ordno != null && onlineOrder?.ordno != undefined &&    onlineOrder?.ordno}</div>
          </div>
          <div class="d-flex flex-column">
            <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}> Customer: {onlineOrder?.customername != null && onlineOrder?.customername != undefined &&  onlineOrder?.customername}</span>
            <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Channel: {(onlineOrder?.channelname != null || onlineOrder?.channelname != undefined) ?    onlineOrder?.channelname : "Own App"}</span>
            <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Order Date: {onlineOrder?.orddate != null && onlineOrder?.orddate != undefined &&onlineOrder?.orddate}</span>
            <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Order Total: {onlineOrder?.ordertotal != null && onlineOrder?.ordertotal != undefined &&onlineOrder?.ordertotal}</span>
            {/* <span class="pl-2 lh-0" style={{margin:'2px',fontSize:'14px',fontWeight:'600'}}>Payment Mode: {onlineOrder?.paymode != null && onlineOrder?.paymode != undefined ? onlineOrder?.paymode: "Pending"}</span> */}
           {/* <span class="pl-2 lh-0">In Time: {table?.intime} </span> */}
          </div>


        </div>
      </div>
    </div>
    ))
    :
    "Currently No Orders"
    // allOnlineOrders.map((onlineOrder, index) => (
      
    //   <tbody>
    //   <tr>
    //   <th class="align-middle " scope="row" style={{textAlign:'center'}}>{onlineOrder?.ordno != null && onlineOrder?.ordno != undefined &&    onlineOrder?.ordno}</th>
    //   <th class="align-middle " scope="row" style={{textAlign:'center'}}>{(onlineOrder?.channelname != null || onlineOrder?.channelname != undefined) ?    onlineOrder?.channelname : "Own App"}</th>
    //   <td class="align-middle" style={{textAlign:'center'}}>{onlineOrder?.customername != null && onlineOrder?.customername != undefined &&  onlineOrder?.customername}</td>
    //   <td class="align-middle" style={{textAlign:'center'}}><span class="badge rounded-pill bg-success text-white p-2">{onlineOrder?.orddate != null && onlineOrder?.orddate != undefined &&onlineOrder?.orddate}</span></td>
    //   <td class="align-middle" style={{textAlign:'center'}}>{onlineOrder?.ordertotal != null && onlineOrder?.ordertotal != undefined &&onlineOrder?.ordertotal}</td>
    //   {/* <td class="align-middle">{"onlineOrder.id"}</td> */}
    //   {/* <td class="align-middle">{"@mdo"}</td> */}
    //   {/* <td class="align-middle"><span class="badge rounded-pill bg-success text-white p-2">{"8639589241"}</span></td> */}
    //   <td class="align-middle" style={{textAlign:'center'}}><span class="badge rounded-pill bg-success text-white p-2">{onlineOrder?.deldate != null && onlineOrder?.deldate != undefined &&onlineOrder?.deldate}</span></td>
    //   {/* <td class="align-middle"><span class="badge rounded-pill bg-success text-white p-2">{"Assigned to Delivery Exe"}</span></td> */}
     
    // </tr>
    
    // </tbody>
    // ))
  } else if (onlineOrdersStatus === 'failed') {
    content = <div className="col-md-12">{error}</div>
  }


  return (
    <>
      <PageTitle titleHeader={"Completed Orders"} titleDescription="" />
     
      {/* <PageTitle titleHeader={orderType == OnlineOrderTypes.CONFIRMED_ORDERS? "Confirmed Orders":"UnConfirmed Orders"} titleDescription="" /> */}
      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">
        {/* border shadow  */}
          <div class="row w-100 flex-grow-1 tableFixHead">
            {/* <table class="table ametable " > */}
              {/* <thead style={{borderBottom:'2px solid'}}>
                <tr>
                  <th scope="col" style={{textAlign:'center'}}>Order No</th>

                  <th scope="col" style={{textAlign:'center'}}>Channel</th>
                  <th scope="col" style={{textAlign:'center'}}>Customer</th>
                  <th scope="col">Name</th>
                  <th scope="col" style={{textAlign:'center'}}>Ordered Time</th>
                  <th scope="col">Phone</th>
                  <th scope="col" style={{textAlign:'center'}}>Amount</th>
                  <th scope="col" style={{textAlign:'center'}}>Delivered at</th>
                  <th scope="col" style={{textAlign:'center'}}>Action</th>
                </tr>
              </thead> */}
              {/* <tbody> */}
              
      <div class="row ml-4 mr-4 mb-4 d-flex flex-grow-1">
              {content}
               
              {/* {content} */}
              </div>
              
              {/* </tbody> */}
            {/* </table> */}
            <AssignDeliveryModal   show={showOnlineModal}
              animation={true} CloseModal={handleOnlineModal} newOrdersData={newOrdersData} changePage={changePage}/>
          </div>

        </div>


      </div>
    </>


  );

}
