import React, { useState } from 'react';
import { Button, Modal, } from "react-bootstrap";

import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function OpenPriceModal(props) {
  const [numericValue, setNumericValue] = useState("");


  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }



  const handleClose = props.handleClose;

  return (
    <Modal
      show={props.show}
      animation={props.animation}
      size="md" className="shadow-xl border ">
      <Modal.Header className="border-0">
        <Modal.Title className="w-100">
          <div class="d-flex">
            <div class="p-2">Open Price
            </div>
            <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 border-0">

        
        <div class="ml-4 ">

          <div class="row text-center my-4 flex-wrap w-100">
            <div class=" w-100">
              <input class="text-center form-control-lg square-edges p-5 inputCardNumber border" type="text" value={numericValue} />
            </div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
            <div class="col-4 p-4 border font-weight-bolder" ><strong>.</strong></div>

          </div>


        </div>
        <div class="w-100 mb-4">

          <Button className="btn-lg btn btn-florogreen btn-block  p-4">Enter</Button>

        </div>
      </Modal.Body>
      {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

          <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
        </Modal.Footer> */}
    </Modal>
  );
}
