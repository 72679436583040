import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';



const PRODUCTS = gql`
  query {
    busyorders(first: 100, filter: { search: "DINEIN" }) {
      edges {
        node {
          id
          table {
            name
          }
          order {
            id
          }
          status
          mode
        }
      }
    }
  }

`;
 

const busyOrdersAdapter = createEntityAdapter();
const initialState = busyOrdersAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchBusyOrders = createAsyncThunk('busyOrders/fetchBusyOrders', async () => {
 
  const response = await client
    .query({ query: PRODUCTS, variables: { } });

  return response.data;

})

export const busyOrdersSlice = createSlice({
  name: 'busyorders',
  initialState,
  reducers: {
     
     
  },
  extraReducers: {
    [fetchBusyOrders.pending]: (state, action) => {

      state.status = 'loading'
    },
    [fetchBusyOrders.fulfilled]: (state, action) => {

      state.status = 'succeeded'
      // Add any fetched posts to the array
      
      console.log(action.payload);
      busyOrdersAdapter.removeAll(state);
      action.payload.busyorders.edges.map(({node}) => (
          
        busyOrdersAdapter.addOne(state, node)
        
      ))
      //state.tables = state.tables.concat(action.payload)
    },
    [fetchBusyOrders.rejected]: (state, action) => {

      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

// export const {  updateTable,mergeTables} = tablesSlice.actions;

export default busyOrdersSlice.reducer;
export const { selectAll: selectBusyOrders } = busyOrdersAdapter.getSelectors((state) => state.busyorders);


//export const selectAllBillItems = (state) => state.items.billItems;
