import React, {useRef, useEffect, useState } from 'react';
import { setActivePage, setActiveTab } from '../../reducers/homePageSlice';
import { updateBill, selectBillById } from '../../reducers/billsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, paymentMode } from '../../utilities/Constants'
import { ewalletModeData, onlineModeData, paymentModeStatus } from '../../reducers/ConstantSlice';

import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { fetchTables } from '../../reducers/tablesSlice';

import { useReactToPrint } from "react-to-print";


export function PaymentType(props) {
 
  const date = new Date();
  
  console.log(date, "anandgreenangel");
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  console.log(datetime, date, "anandangelcart");
  const [sliceItem,setSliceItem] = useState('')
  
  const LogoAme = localStorage.getItem("logo");
  // const [ewalletModeData,setEwalletModeData] = useState();
  const [paymentAmountDataKOT,setPaymentAmountDataKOT] = useState();
  const [paymentAmountData,setPaymentAmountData] = useState();
  const [denominations,setDenominations] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const onBeforeGetContentResolve = React.useRef(null);
  const draftRef = React.useRef(null);
  const onBeforeGetContentResolveKOT = React.useRef(null);
  const kotRef = useRef(null);
  const [loadingKOT, setLoadingKOT] = React.useState(false);
  const [textKOT, setTextKOT] = React.useState("old boring text");
  const [kotData, setKotData] = useState();
  const cartItemsList = useSelector(selectAllCart);
  const { discountPrecentage } = useSelector((state) => state.touch);
  const {balance,dueamount} =useSelector((state)=>state.touch)
  const dispatch = useDispatch();
  const activeBillId = useSelector((state) => state.bills.activeBillID)
  console.log(activeBillId,"activeBillId");
  const handleClickSplit = (e) => {
    getEwalletModeDetails();
    dispatch(setActivePage(pageComponents.SPLITPAYMENT));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  }
  const handleClickCard = (e) => {
    // console.log(activeBillId,"activeBillId");
    dispatch(paymentModeStatus({paymentMode: paymentMode.CARD}));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  
  }




  const handleClickCredit = (e) => {
    if(localStorage.getItem("CustomerName") != null && localStorage.getItem("CustomerName") != undefined){
      console.log(localStorage.getItem("CustomerName"),"localStorage.getItemCustomerName",dueamount);
    // console.log(activeBillId,"activeBillId");
    dispatch(paymentModeStatus({paymentMode: paymentMode.CREDIT}));
    printStatement()
    //dispatch(setActiveTab(cashierTabs.DINE_IN));
    }else{
      alert("Please Select Valid Customer")
    }
  }

  const getdata = () => {
    //POST json
    let dataToSend = {
      // wanip: tokenCreate[0].Wanip,
      // dbusr: tokenCreate[0].DBUser,
      // dbpwd: tokenCreate[0].DBPwd,
      // dbnam: tokenCreate[0].DBname,

      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.clientdtlArr[0].clientdtl != 2) {
          setCompanyDetails(responseJson.clientdtlArr[0])
          
          localStorage.setItem('billNo',responseJson.clientdtlArr[0].billno);
          if(responseJson.clientdtlArr[0].denominations != undefined && responseJson.clientdtlArr[0].denominations != null && responseJson.clientdtlArr[0].denominations.length > 0){
            var itemDenomination  = responseJson.clientdtlArr[0].denominations.split(",")
            setDenominations(itemDenomination)
          }
        
        } else {
          console.log("Please check your email id or password");
          alert("Sorry No Data");
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  const printStatement = async (i,amount) => {
    // await goToPayment()
    // await handleClickLastReceipt();
      // await getDataUsingPost();

      console.log(i,amount,"i,amount");
      if(localStorage.getItem('tableno') != "CT"){

        await goToKot(i,amount)
      }else{

        // console.log( numericValue,totalamount,Box,"numericValue1");
        // console.log("Please check your email id or password");
        // getClear()
        var  discount = "";
        var payno = '';
        var nameonCardData = '';
        var balanceData = '';
        var cardno = '';
        var customerId = localStorage.getItem("Customerid");
        var balance22 = 0;
        var totalPaid = '';
        
        if(discountPrecentage != null && discountPrecentage != undefined){
          discount = discountPrecentage
        }else{
          discount = '0.00'
        }
        payno = '1';
      
      localStorage.setItem('payno','3')
      localStorage.setItem('balance',"0.00")
      localStorage.setItem('totalamount',dueamount)
      localStorage.setItem('totalPaid',dueamount)
      
      localStorage.setItem('discount',discount)
      
      localStorage.setItem('custId',customerId)
       goToPayment()
       
      }
  }

  const goToKot = (i,amount) =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
     
      tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          // alert(JSON.stringify(responseJson));
          console.log(responseJson,"kot");
          
          if (responseJson.PrintKotArr[0].PrintKot != 2) {
                    setKotData(responseJson)
                  
            let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
            var item = [
              {
                link: link,
                tokenCreate: localStorage.getItem('token'),
              },
            ];
            var  discount = "";
            var payno = '3';
            var nameonCardData = '';
            var balanceData = '';
            var cardno = '';
            var customerId = localStorage.getItem("Customerid");
            var balance22 = 0;
            
            var totalPaid =  '';
            if(discountPrecentage != null && discountPrecentage != undefined){
              discount = discountPrecentage
            }else{
              discount = '0.00'
            }
               


            localStorage.setItem('totalPaid',dueamount)
            console.log(balance,i,'balance');
            localStorage.setItem('payno','3')
            localStorage.setItem('balance',"0.00")
            localStorage.setItem('totalamount',dueamount)
            localStorage.setItem('discount',discount)
            dispatch(fetchTables(item));
            localStorage.setItem('custId',customerId)
            handlePrintKOT();
                  
                  } 
          else{
            
            console.log("Please check your email id or password");
            // getClear()
            var  discount = "";
            var payno = '';
            var nameonCardData = '';
            var balanceData = '';
            var cardno = '';
            var customerId = localStorage.getItem("Customerid");
            var totalPaid = '';
            var balance22 = 0;
            
            if(discountPrecentage != null && discountPrecentage != undefined){
              discount = discountPrecentage
            }else{
              discount = '0.00'
            }
          localStorage.setItem('totalPaid',dueamount)
          console.log(balance,i,'balance');
          localStorage.setItem('payno',"3")
          localStorage.setItem('balance',"0.00")
          localStorage.setItem('totalamount',dueamount)
          localStorage.setItem('discount',discount)
          
          localStorage.setItem('custId',customerId)
           goToPayment()


           
  // getClear();
          }
        })
        .catch((error) => {
          console.error(error);
        });
  }


  const handleAfterPrintKOT = React.useCallback(() => {
    goToPayment()
     console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);
  
  const handleBeforePrintKOT = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);
  
  const handleOnBeforeGetContentKOT = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoadingKOT(true);
    setTextKOT("Loading new text...");
  
    return new Promise((resolve) => {
      onBeforeGetContentResolveKOT.current = resolve;
  
      setTimeout(() => {
        // goToPayment();
        setLoadingKOT(false);
        setTextKOT("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoadingKOT, setTextKOT]);
  
  const reactToPrintContentKOT = React.useCallback(() => {
    return kotRef.current;
  }, [kotRef.current]);
  
  const handlePrintKOT = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 
  
  
    
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContentKOT,
    onBeforePrint: handleBeforePrintKOT,
    
    content: reactToPrintContentKOT,
    onAfterPrint: handleAfterPrintKOT,
    removeAfterPrint: true
  });
  React.useEffect(() => {
    if (
      textKOT === "New, Updated Text!" &&
      typeof onBeforeGetContentResolveKOT.current === "function"
    ) {
      onBeforeGetContentResolveKOT.current();
    }
  }, [onBeforeGetContentResolveKOT.current, textKOT]);
  const handleAfterPrint = React.useCallback(() => {
      
    dispatch({type:"discountPrecentage",payload:''});
    console.log("`onAfterPrint` called"); 
     localStorage.removeItem('balance');
     localStorage.removeItem('totalamount');
     localStorage.removeItem('payno');
     localStorage.removeItem('discount');
     localStorage.removeItem('totalPaid');
     localStorage.removeItem('CustomerName');
     localStorage.removeItem('Customerid');
     localStorage.removeItem('custId');
     dispatch(setActivePage(pageComponents.BILLING));
     dispatch(cartItems())
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
    
    // dispatch(setActivePage(pageComponents.BILLING));
    // dispatch(selectActiveTab(cashierTabs.COUNTER_SALE))
    // (setActiveTab(cashierTabs.COUNTER_SALE))
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 


    
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    
    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });
  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);




  const goToPayment = () => {
    const date = new Date();
    
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    console.log(datetime, date, "anandangelcart");
    //POST json
    const billamnt = cartItemsList.length > 0 && cartItemsList[0].name !="yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
      parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    ).toFixed(2);
    const roundamnt = Math.round(billamnt);
    var gross =
      roundamnt - parseFloat(cartItemsList[cartItemsList.length - 1].gst);
    const round=roundamnt-billamnt
    const num2 = roundamnt;
    console.log(roundamnt, "rrrr");
    // add two numbers
    var payno = '';
    var nameonCard = '';
    var cardno = '';
    var totalamountcard = '';
    var totalamountcash = '';
    var amtpaid = localStorage.getItem('totalPaid');
    // if(activeBillId.paymentMode == 'CASH'){
    //   payno = '1';
    //   nameonCard = '';
    //   totalamountcash = localStorage.getItem('totalPaid')
    //   amtpaid = localStorage.getItem('totalPaid')
    // }else if(activeBillId.paymentMode == 'CARD'){
    //   payno = '2';
      
    //   cardno = localStorage.getItem('cardno');
    //   nameonCard = localStorage.getItem('nameonCard');
    //   totalamountcard = localStorage.getItem('totalamount')
    //   amtpaid = localStorage.getItem('totalamount')
    // }else if(activeBillId.paymentMode == 'EWALLET'){
    //   payno = '4'
    //   nameonCard = '';
    //   cardno= '';
    //   totalamountcash = localStorage.getItem('totalamount')
    //   amtpaid = localStorage.getItem('totalamount')
    // }
   
    // HOSTING_ENV="local"
    let dataToSend = {
      token:localStorage.getItem('token'),
      tabno:localStorage.getItem('tableno'),
      custid: localStorage.getItem("custId"), //or name
      orddte: datetime,
      payusr: localStorage.getItem('UserID'),
      openin:  localStorage.getItem('TerminalName'),
      paymode: localStorage.getItem('payno'),
      // paymode:"12",
      billamt:  localStorage.getItem('totalamount'),
      round: round,
      paidamt: amtpaid,
      // paidamt:200,
      // nameoncard: localStorage.getItem('nameonCard') ,//or emptystring
      // cardno:localStorage.getItem('cardNumber'),
      // card: totalamountcard,
      // card:12,
      // cash:totalamountcash,
      balance:localStorage.getItem('balance'),
      gst:parseFloat(cartItemsList[cartItemsList.length - 1].gst).toFixed(2),
      cess:0,
      gross:gross,
      discount:localStorage.getItem('discount'),
      vat:0
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printbill", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson); 
        getdata()
 if (responseJson.GetPrintArr[0].GetPrint != 2 && responseJson.GetPrintArr[0].Printtyp != 0 ) {
   const arr = [];
   
   const arryjs = [];
   setPaymentAmountData(responseJson)

   for (let i = 0; i < responseJson.GetPrintArr.length; i++) {
    arryjs.push({
                
      kotno: responseJson.GetPrintArr[i].kotno
    })
   }
   const obj = [...new Map(arryjs.map(item => [JSON.stringify(item), item])).values()];
   console.log(obj,"obj");

   setPaymentAmountDataKOT(obj)
  //  setCancelKotNumber(obj)
  //  printing(text, responseJson.GetPrintArr[0].ipaddress);
   console.log("HIII");
   let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
   var item = [
     {
       link: link,
      //  tokenCreate: tokenCreate,
     },
   ];
   
handlePrint()
   dispatch(fetchTables(item));
  //  getClear()
 } else {
   console.log("Please check your email id or password");
   alert("Sorry No Data");
 }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClickCash = (e) => {
    console.log("?");
    // dispatch(updateBill({
    //   id: activeBillId,
    //   changes: {
    //     paymentMode: paymentMode.CASH,

    //   },
      dispatch(paymentModeStatus({
        // id: activeBillId,
        // changes: {
          paymentMode: paymentMode.CASH,
  
        // },
      }));
    // }));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  }

  const handleClickEwallet = (e) => {
    // dispatch(updateBill({
    //   id: activeBillId,
    //   changes: {
    //     paymentMode: paymentMode.EWALLET,

    //   },
    // }));
    getEwalletModeDetails();

    dispatch(paymentModeStatus({
      // id: activeBillId,
      // changes: {
        paymentMode: paymentMode.EWALLET,

      // },
    }));

    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  }

  const getEwalletModeDetails = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
     
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_ewalletlist", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          // alert(JSON.stringify(responseJson));
          console.log(responseJson,"kot");
          
          if (responseJson.ModePayArr[0].ModePay != 2) {
            var arr = [];
              // setEwalletModeData(responseJson)
                  for (let i = 0; i < responseJson.ModePayArr.length; i++) {
                    if(responseJson.ModePayArr[i].type == 'E'){
                      // const objectURL = URL.createObjectURL(responseJson.ModePayArr[0].image)

                      // console.log(objectURL,"objectURL");
                      // URL.revokeObjectURL()
                      // let url = URL.createObjectURL(responseJson.ModePayArr[0].image)
                      // console.log(url,"url");
                      // let buff = ''
                      // buff = new Buffer(`${responseJson.ModePayArr[i].image}`, 'base64');
                      // let base64ToStringNew = buff.toString('ascii');
                      // console.log(base64ToStringNew,"base64ToStringNew");
                      // var decodedString = atob(encodedString)
                      // decodeBase64(responseJson.ModePayArr[i].image)
                      arr.push({
                        ModePay:responseJson.ModePayArr[i].ModePay,
                        ewallet:responseJson.ModePayArr[i].ewallet,
                        image:responseJson.ModePayArr[i].image,

                        type:responseJson.ModePayArr[i].type

                      })
                    }
                  }
              dispatch(ewalletModeData(arr))
              console.log(arr,"arrWalletType");
            } 
         
        })
        .catch((error) => {
          console.error(error);
        });
  
  }
  
  const decodeBase64 = (data) => {
    console.log(Buffer.from(data, 'base64').toString('ascii'),"ddddd");
    return Buffer.from(data, 'base64').toString('ascii');
  }
 const handleClickOnlineAct = (e) => {
    // dispatch(updateBill({
    //   id: activeBillId,
    //   changes: {
    //     paymentMode: paymentMode.EWALLET,

    //   },
    // }));
    getonlineModeDetails();

    dispatch(paymentModeStatus({
      // id: activeBillId,
      // changes: {
        paymentMode: paymentMode.ONLINE_AC,

      // },
    }));

    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  }
  const handleClickOnlineCOD = (e) => {
    // dispatch(updateBill({
    //   id: activeBillId,
    //   changes: {
    //     paymentMode: paymentMode.EWALLET,

    //   },
    // }));
    getonlineModeDetails();

    dispatch(paymentModeStatus({
      // id: activeBillId,
      // changes: {
        paymentMode: paymentMode.ONLINE_COD,

      // },
    }));

    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  }
  const handleClickOnlineCard = (e) => {
    // dispatch(updateBill({
    //   id: activeBillId,
    //   changes: {
    //     paymentMode: paymentMode.EWALLET,

    //   },
    // }));
    getonlineModeDetails();

    dispatch(paymentModeStatus({
      // id: activeBillId,
      // changes: {
        paymentMode: paymentMode.ONLINE_CARD,

      // },
    }));

    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  }

  const getonlineModeDetails = () =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
     
      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_ewalletlist", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          // alert(JSON.stringify(responseJson));
          console.log(responseJson,"kot");
          
          if (responseJson.ModePayArr[0].ModePay != 2) {
            var arr = [];
              // setEwalletModeData(responseJson)
                  for (let i = 0; i < responseJson.ModePayArr.length; i++) {
                    if(responseJson.ModePayArr[i].type == 'O'){
                      // const objectURL = URL.createObjectURL(responseJson.ModePayArr[0].image)

                      // console.log(objectURL,"objectURL");
                      // URL.revokeObjectURL()
                      // let url = URL.createObjectURL(responseJson.ModePayArr[0].image)
                      // console.log(url,"url");
                      // let buff = ''
                      // buff = new Buffer(`${responseJson.ModePayArr[i].image}`, 'base64');
                      // let base64ToStringNew = buff.toString('ascii');
                      // console.log(base64ToStringNew,"base64ToStringNew");
                      // var decodedString = atob(encodedString)
                      // decodeBase64(responseJson.ModePayArr[i].image)
                      arr.push({
                        ModePay:responseJson.ModePayArr[i].ModePay,
                        ewallet:responseJson.ModePayArr[i].ewallet,
                        image:responseJson.ModePayArr[i].image,
                        sno:responseJson.ModePayArr[i].sno,
                        type:responseJson.ModePayArr[i].type

                      })
                    }
                  }
              dispatch(onlineModeData(arr))
              console.log(arr,"arrWalletType");
            } 
         
        })
        .catch((error) => {
          console.error(error);
        });
  
  }
// export function PaymentType(props) {
 
//   const dispatch = useDispatch();
//   const activeBillId = useSelector((state) => state.bills.activeBillID)
//   const handleClickSplit = (e) => {
//     dispatch(setActivePage(pageComponents.SPLITPAYMENT));
//     //dispatch(setActiveTab(cashierTabs.DINE_IN));
//   }
//   const handleClickCard = (e) => {
//     dispatch(updateBill({
//       id: activeBillId,
//       changes: {
//         paymentMode: paymentMode.CARD,

//       },
//     }));
//     //dispatch(setActiveTab(cashierTabs.DINE_IN));
//   }

//   const handleClickCash = (e) => {
//     dispatch(updateBill({
//       id: activeBillId,
//       changes: {
//         paymentMode: paymentMode.CASH,

//       },
//     }));
//     //dispatch(setActiveTab(cashierTabs.DINE_IN));
//   }

//   const handleClickEwallet = (e) => {
//     dispatch(updateBill({
//       id: activeBillId,
//       changes: {
//         paymentMode: paymentMode.EWALLET,

//       },
//     }));
//     //dispatch(setActiveTab(cashierTabs.DINE_IN));
//   }
  
//     return (

//       <div class="d-none d-md-flex flex-column flex-grow-1 mt-2">

//         <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickCash()}>Cash</button>
//           </div>
//         </div>
//         <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickCard()}>Card</button>
//           </div>
//         </div>
//         {/* <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height">Credit</button>
//           </div>
//         </div> */}
//         {/* <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickSplit()}>Split</button>
//           </div>
//         </div> */}
//         <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickEwallet()}>E - Wallet</button>
//           </div>
//         </div>
//         {/* <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Online A/C</button>
//           </div>
//         </div>*/}
//         <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Vouchers</button>
//           </div>
//         </div> 
//         <div class="row flex-grow-1 m-1" >
//           <div class="col-md-12">
//             <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Complementary</button>
//           </div>
//         </div>
//       </div>
//     );
   

// }
  
    return (
      <>
      {localStorage.getItem('takenoMode').length > 0 && localStorage.getItem('takenoMode') == 0 ? 
      <div class="d-none d-md-flex flex-column flex-grow-1 mt-2">

        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickCash()}>Cash</button>
          </div>
        </div>
        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickCard()}>Card</button>
          </div>
        </div>
        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickCredit()}> Credit</button>
          </div>
        </div>
        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickSplit()}>Split</button>
          </div>
        </div>
        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickEwallet()}>E - Wallet</button>
          </div>
        </div>
        {/* <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Online A/C</button>
          </div>
        </div> */}
        {/* <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Voucher</button>
          </div>
        </div> */}
        {/* <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Back</button>
          </div>
        </div> */}
        <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={kotRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " > 
            {sliceItem == "T" ?
             <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TakeAway No. {localStorage.getItem('tableno')}</p>
            :
            (localStorage.getItem("tableno") == 'CT' ? 
           <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CounterSale No. {localStorage.getItem('tableno')}</p>
           
            :
           <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Table No. {localStorage.getItem('tableno')}</p>
           
            )  
        }
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

             
                        <table className="centered" style={{borderCollapse:'collapse'}}>
                           
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                  kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length >0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                                  kotData?.PrintKotArr[0].ItemsArray.map((item, index) => 
                                       
                                       (
                                       <tr  >
                                           <td className='description'   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                           <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                           
                                           {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}
                           
                                       </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
            <p className="centered" style={{fontSize:'12px',borderTop:'1px dotted black' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{"<<< AME>>>"}</p>
              

                    </div>
                </div>
                </div>




{/* ///////////////////// */}
<div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " >
           {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
                      <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '50%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />

                    }
                    <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1}{companyDetails?.addr2}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>BILL RECEIPT</p>
                    <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Bill No :  {localStorage.getItem('TerminalName')} - {paymentAmountData?.GetPrintArr?.length > 0 && paymentAmountData?.GetPrintArr[0].billno}</p>

                <div style={{display:'flex',justifyContent:'space-between'}}>
                {sliceItem == "T" ?
              <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TakeAway No. {localStorage.getItem('tableno')}</p>
            :
            (localStorage.getItem("tableno") == 'CT' ? 
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CounterSale No. {localStorage.getItem('tableno')}</p>
           
            :
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Table No. {localStorage.getItem('tableno')}</p>
           
            )  
        }
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Date :{datetime != null && datetime != undefined ? datetime :""}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Customer : {paymentAmountData?.GetPrintArr.length > 0 && paymentAmountData?.GetPrintArr[0].customer}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  
               </div>
             
                        <table className="centered" style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            <thead>
                                <tr style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                                    <th className='description'  style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>Product</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>QTY</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>RATE</th>

                                    <th className="price" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>TOTAL</th>

                                </tr>
                                 
                            </thead>
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                   paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 && 

                                   paymentAmountData?.GetPrintArr?.map((item, index) => 
                                       
                                       (
                                        <tr  >
                                        <td className='description'   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                        <td className="quantity  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                        
                                        <td className="quantity  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                        <td className="price"   style={{fontSize:'10px',borderCollapse:'collapse',color:'black',textAlign:'end'}}>{item?.amount}</td>
                        
                                    </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
                        <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Total Items :{paymentAmountData?.GetPrintArr?.length > 0  ?(paymentAmountData?.GetPrintArr?.length) :0  }</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>Total Qty:{cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].qty ?parseFloat(cartItemsList[cartItemsList?.length - 1].qty):0  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Sub Total :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].subTotal ?cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2) : "0.00"  }</p>
           
               </div>
               { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.gstper != null && item.gstper != undefined && item.gstper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.gstper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.gst.length > 0  &&  item.gst ? item.gst : "0.00"  }</p>
              
             </div>
                )
               
               )}
               { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.vatper != null && item.vatper != undefined && item.vatper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.vatper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.vat.length > 0  &&  item.vat ? item.vat : "0.00"  }</p>
              
             </div>
                )
               
               )}
               { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.cessper != null && item.cessper != undefined && item.cessper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.cessper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.cess.length > 0  &&  item.cess ? item.cess : "0.00"  }</p>
              
             </div>
                )
               
               )}
               

               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Discount</p>
                  {/* localStorage.getItem('discount') != null && */}
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} { localStorage.getItem('discount') == '' ?  '0.00':localStorage.getItem('discount')}</p>
           
               </div>

               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',color:'black'}}>Total</p>
                  
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {dueamount != null && dueamount != undefined ? dueamount.toFixed(2) :0 }</p>
           
               </div>
              
               
                
                <>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse',borderTop:'1px dotted black'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CREDIT </p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {dueamount != null && dueamount != undefined ? dueamount.toFixed(2) :0 }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CHANGE</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {"0.00"}</p>
           
               </div></>
                
                <div style={{display:'flex',borderTop:'1px dotted black',borderCollapse:'collapse',justifyContent:'center'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No's :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}> {paymentAmountDataKOT?.length > 0 ? paymentAmountDataKOT.map((i)=>`${i.kotno},`):""}</p>

               </div>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black' ,borderTop:'1px dotted black'}}>{companyDetails?.remarks1}</p>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.remarks2}</p>
              

                    </div>
                </div>
                <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={kotRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " > 
            {sliceItem == "T" ?
              <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TakeAway No. {localStorage.getItem('tableno')}</p>
            :
            (localStorage.getItem("tableno") == 'CT' ? 
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CounterSale No. {localStorage.getItem('tableno')}</p>
           
            :
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Table No. {localStorage.getItem('tableno')}</p>
           
            )  
        }
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

             
                        <table className="centered" style={{borderCollapse:'collapse'}}>
                           
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                  kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length >0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                                  kotData?.PrintKotArr[0].ItemsArray.map((item, index) => 
                                       
                                       (
                                       <tr  >
                                           <td className='description'   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                           <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                           
                                           {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}
                           
                                       </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
            <p className="centered" style={{fontSize:'12px',borderTop:'1px dotted black' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{"<<< AME>>>"}</p>
              

                    </div>
                </div>
                </div>
           </div>


      </div>
      :
      <div class="d-none d-md-flex flex-column flex-grow-1 mt-2">

        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickOnlineAct()}>Online A/C Sales</button>
          </div>
        </div>
        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickOnlineCOD()}>Online COD Sales</button>
          </div>
        </div>
        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickOnlineCard()}> Online Card Sales</button>
          </div>
        </div>
        {/* <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickSplit()}>Split</button>
          </div>
        </div>
        <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height" onClick={()=>handleClickEwallet()}>E - Wallet</button>
          </div>
        </div> */}
        {/* <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Online A/C</button>
          </div>
        </div> */}
        {/* <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Voucher</button>
          </div>
        </div> */}
        {/* <div class="row flex-grow-1 m-1" >
          <div class="col-md-12">
            <button type="button" class="btn btn-warning  btn-block p-4 btn-lg btn-orange btn-block-height">Back</button>
          </div>
        </div> */}
        <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={kotRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " > 
            {sliceItem == "T" ?
              <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TakeAway No. {localStorage.getItem('tableno')}</p>
            :
            (localStorage.getItem("tableno") == 'CT' ? 
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CounterSale No. {localStorage.getItem('tableno')}</p>
           
            :
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Table No. {localStorage.getItem('tableno')}</p>
           
            )  
        }
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

             
                        <table className="centered" style={{borderCollapse:'collapse'}}>
                           
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                  kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length >0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                                  kotData?.PrintKotArr[0].ItemsArray.map((item, index) => 
                                       
                                       (
                                       <tr  >
                                           <td className='description'   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                           <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                           
                                           {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}
                           
                                       </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
            <p className="centered" style={{fontSize:'12px',borderTop:'1px dotted black' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{"<<< AME>>>"}</p>
              

                    </div>
                </div>
                </div>




{/* ///////////////////// */}
<div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " >
           {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
                      <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '50%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />

                    }
                    <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1}{companyDetails?.addr2}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno}</p>
                    <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>BILL RECEIPT</p>
                    <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Bill No :  {localStorage.getItem('TerminalName')} - {paymentAmountData?.GetPrintArr?.length > 0 && paymentAmountData?.GetPrintArr[0].billno}</p>

                <div style={{display:'flex',justifyContent:'space-between'}}>
                {sliceItem == "T" ?
              <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TakeAway No. {localStorage.getItem('tableno')}</p>
            :
            (localStorage.getItem("tableno") == 'CT' ? 
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CounterSale No. {localStorage.getItem('tableno')}</p>
           
            :
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Table No. {localStorage.getItem('tableno')}</p>
           
            )  
        }
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Date :{datetime != null && datetime != undefined ? datetime :""}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Customer : {paymentAmountData?.GetPrintArr.length > 0 && paymentAmountData?.GetPrintArr[0].customer}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  
               </div>
             
                        <table className="centered" style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            <thead>
                                <tr style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                                    <th className='description'  style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>Product</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>QTY</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>RATE</th>

                                    <th className="price" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>TOTAL</th>

                                </tr>
                                 
                            </thead>
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                   paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 && 

                                   paymentAmountData?.GetPrintArr?.map((item, index) => 
                                       
                                       (
                                        <tr  >
                                        <td className='description'   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                        <td className="quantity  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                        
                                        <td className="quantity  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                        <td className="price"   style={{fontSize:'10px',borderCollapse:'collapse',color:'black',textAlign:'end'}}>{item?.amount}</td>
                        
                                    </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
                        <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Total Items :{paymentAmountData?.GetPrintArr?.length > 0  ?(paymentAmountData?.GetPrintArr?.length) :0  }</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>Total Qty:{cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].qty ?parseFloat(cartItemsList[cartItemsList?.length - 1].qty):0  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Sub Total :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].subTotal ?cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2) : "0.00"  }</p>
           
               </div>
               { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.gstper != null && item.gstper != undefined && item.gstper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.gstper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.gst.length > 0  &&  item.gst ? item.gst : "0.00"  }</p>
              
             </div>
                )
               
               )}
               { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.vatper != null && item.vatper != undefined && item.vatper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.vatper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.vat.length > 0  &&  item.vat ? item.vat : "0.00"  }</p>
              
             </div>
                )
               
               )}
               { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.cessper != null && item.cessper != undefined && item.cessper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.cessper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.cess.length > 0  &&  item.cess ? item.cess : "0.00"  }</p>
              
             </div>
                )
               
               )}
               

               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Discount</p>
                  {/* localStorage.getItem('discount') != null && */}
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} { localStorage.getItem('discount') == '' ?  '0.00':localStorage.getItem('discount')}</p>
           
               </div>

               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',color:'black'}}>Total</p>
                  
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {dueamount != null && dueamount != undefined ? dueamount.toFixed(2) :0 }</p>
           
               </div>
              
               
                
                <>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse',borderTop:'1px dotted black'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CREDIT </p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {dueamount != null && dueamount != undefined ? dueamount.toFixed(2) :0 }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CHANGE</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {"0.00"}</p>
           
               </div></>
                
                <div style={{display:'flex',borderTop:'1px dotted black',borderCollapse:'collapse',justifyContent:'center'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No's :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}> {paymentAmountDataKOT?.length > 0 ? paymentAmountDataKOT.map((i)=>`${i.kotno},`):""}</p>

               </div>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black' ,borderTop:'1px dotted black'}}>{companyDetails?.remarks1}</p>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.remarks2}</p>
              

                    </div>
                </div>
                <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={kotRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " > 
            {sliceItem == "T" ?
              <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TakeAway No. {localStorage.getItem('tableno')}</p>
            :
            (localStorage.getItem("tableno") == 'CT' ? 
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CounterSale No. {localStorage.getItem('tableno')}</p>
           
            :
            <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Table No. {localStorage.getItem('tableno')}</p>
           
            )  
        }
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

             
                        <table className="centered" style={{borderCollapse:'collapse'}}>
                           
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                  kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length >0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                                  kotData?.PrintKotArr[0].ItemsArray.map((item, index) => 
                                       
                                       (
                                       <tr  >
                                           <td className='description'   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                           <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                           
                                           {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}
                           
                                       </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
            <p className="centered" style={{fontSize:'12px',borderTop:'1px dotted black' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{"<<< AME>>>"}</p>
              

                    </div>
                </div>
                </div>
           </div>


      </div>
      }
      </>
    );
   

}


