import React, { Component, useEffect, useState } from 'react';
import './Login.css'
import { LoginForm } from "./LoginForm";
import { Lock } from "./Lock";
import { ToastContainer, toast } from 'react-toastify';
import { apiURI, client } from '../../utilities/client'
import { useForm } from 'react-hook-form'
import { loginUser } from '../../reducers/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Input } from 'reactstrap';
import POS from '../../assets/images/pos.jpg'
import Logo from '../../assets/images/logo.png'

export function LoginPhp(props) {


  const [firstTerminalName,setFirstTerminalName] =useState("")

  const [terminalName,setTerminalName] =useState("C1")
  const [terminal,setterminal] =useState([])
  const { tokenCreate } = useSelector((state) => state.user);
  console.log(tokenCreate,"tokenCreate");
    const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [companyDetails,setCompanyDetails] = useState();
  let content;
  const onSubmit = data => {
    if (data !== '') {
      alert('You submitted the form and stuff!');
    } else {
      errors.showMessages();
      return false;
    }
  };
  // console.log(tokenCreate,"tokenCreate");
  // const [terminalName, setTerminalName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false) 
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("Login")?.length > 0? true:false);
 
  const [isUnLocked, setIsUnLocked] = useState(localStorage.getItem("locked"));
  //const [togglePassword, setTogglePassword] = useState(false)

  //setIsLoggedIn(localStorage.getItem("refreshToken")?.length > 0? true:false);
  console.log("isLoggedInm",isLoggedIn);
  const [value, setValue] = useState(
    localStorage.getItem('profileURL')
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  useEffect(() => {
    if (isLoggedIn) {

      getDataUsingPostData()
    terminalFunc()
      content = <Lock handleLogin={unlockClick}/>;
    } else {
      content = <LoginForm handleLogin={loginAuth} />;
    }
  }, [isLoggedIn])

  // useEffect(() => {

  //   localStorage.setItem('Name', name);
   
  // }, [value, name]);



   const unlockClick = (unlockCode) => {
    localStorage.setItem('locked', false);
    // window.open()
    window.location.href = `${process.env.PUBLIC_URL}/home/billing`
   }



  const loginAuth = async (email,password) => {
    console.log(email,password,"email");
    if (!email) {
    alert("Please fill username");
    return;
    }
    if (!password) {
    alert("Please fill Password");
    return;
    }
    
    let dataToSend = { username: email, password: password,softtype:'3'};
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    axios({
      method: 'post',
      url: "https://superadmin.amepos.io/TabPosApiNew/tabpos_verifyshop?",
      headers: { 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8' },
      data: formBody
    })
   
    .then((data) => {
    console.log(data.data,"data");
    
    
    if (data.data.LoginArr[0].Login == 3) {
    console.log("HIII");
    
    localStorage.setItem('locked', true);
    dispatch(loginUser(data.data.LoginArr));

        localStorage.setItem('Login', data.data.LoginArr[0].Login);
        localStorage.setItem('locked', true);

        localStorage.setItem('token', data.data.LoginArr[0].token);
        setIsLoggedIn(localStorage.getItem("token")?.length > 0? true:false);
        setIsUnLocked(false);
        getDataUsingPostData()
       
      
    } else {
    console.log("Please check your email id or password");
    alert("Sorry, Invalid Login");
    }
    
    })
    .catch((error) => {
    alert(JSON.stringify(error));
    console.error(error,"error");
    });
    
    
    }

    const getDataUsingPostData = () => {
      //POST json
      let dataToSend = {
        
        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token:localStorage.getItem('token'),
        // terminal: localStorage.getItem('TerminalName'),
        // cash: numericValue,
        // formattedDate: datetime,
        // closeusr:localStorage.getItem('UserID'),
        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          // console.log(responseJson,"companyName");
          if (responseJson.clientdtlArr[0].clientdtl != 2) {
           setCompanyDetails(responseJson.clientdtlArr[0])
            // getFinal();
            
          localStorage.setItem('CompanyName', responseJson.clientdtlArr[0].companynam);
            localStorage.setItem('imgpath',responseJson.clientdtlArr[0].imgpath);
            
            localStorage.setItem('logo',responseJson.clientdtlArr[0].logo);
  
            localStorage.setItem('billNo',responseJson.clientdtlArr[0].billno);
          } else {
            alert("Sorry Something Went Wrong in Token Details");
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });
    };


    const getCountryData = () => {
      //POST json
      let dataToSend = {
        
        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token:localStorage.getItem('token'),
        // terminal: localStorage.getItem('TerminalName'),
        // cash: numericValue,
        // formattedDate: datetime,
        // closeusr:localStorage.getItem('UserID'),
        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          // console.log(responseJson,"companyName");
          if (responseJson.clientdtlArr[0].clientdtl != 2) {
           setCompanyDetails(responseJson.clientdtlArr[0])
            // getFinal();
            
          localStorage.setItem('CompanyName', responseJson.clientdtlArr[0].companynam);
            localStorage.setItem('imgpath',responseJson.clientdtlArr[0].imgpath);
            
            localStorage.setItem('logo',responseJson.clientdtlArr[0].logo);
  
            localStorage.setItem('billNo',responseJson.clientdtlArr[0].billno);
          } else {
            alert("Sorry Something Went Wrong in Token Details");
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });
    };


    const terminalFunc =  () => {

      if(isLoggedIn === true ){
        let dataToSend = {
          token:localStorage.getItem("token")
          };
          let formBody = [];
          for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
          fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_terminals", {
          method: "POST",
          body: formBody,
          headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          })
          .then((response) => response.json())
          .then((responseJson) => {
          const arr = [];
          console.log(responseJson,"terminalData");
          
          if (responseJson.terminals[0].terminal != 2) {
          for (let i = 0; i < responseJson.terminals.length; i++) {
          arr.push({
          id: i.toString(),
          terminalname: responseJson.terminals[i].terminalname,
          });
          }
          setFirstTerminalName(arr[0].terminalname)
          setterminal(arr);
          console.log("HIII");
          } else {
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          }
          })
          .catch((error) => {
          console.error(error);
          })
      }
    }


  const setTerminalIdDataFunc = (st) =>{
    setTerminalName(st)
  }
 
    console.log(terminal,"terminal");

    const openProassist = (i) =>{
      // amepos-proassist
      window.open(`https://amepos.io/${i}/login.php?del=0`)
      
    }
  return (
    <div class="container-fluid d-flex  flex-column h-100">



      <div class="row flex-grow-1">
        <div class="col-sm-6 login-first-column d-flex flex-column " style={{backgroundImage:`url(${POS})`,backgroundSize:'cover', backgroundRepeat  : 'no-repeat',overflow: 'hidden'}}>
          
          <div className="padding5">
          {isLoggedIn === true ? 
            <h1 class="display-3 " style={{color:'#0eccba',fontSize:'3.5rem'}}>{companyDetails?.companynam}</h1>
            :
            <h1 class="display-3 text-white"><img class="loginLogo" src={Logo}></img></h1>
          }
          {/* {isLoggedIn === true ? 
          <div className="col-md-4">

          <Input type="select" name="status" placeholder="Select Status" className="form-control digits" 
          onChange={e => setTerminalIdDataFunc(e.target.value)}  */}
          {/* //  innerRef={register({ required: false })}
          > */}
                                {/* <option value="Terminal">Select Terminal</option> */}

                                {/* <option value="C1">C1</option> */}
                                 {/* {terminal?.map((item,index)=>(
                                <option value={item?.terminalname}>{item?.terminalname}</option>
                                )
                              
       )}  
                              </Input>

    </div>
    :""} */}
          </div>
          <div className="padding2   flex-fill">
            {/* <img src={POS} style={{height:'100%'}}/> */}
            </div>
          
          {isLoggedIn === true ? 
            <div className="padding5 "><img class="loginLogo" src={Logo}></img></div>
            :
            ""
          }
        </div>
        <div class="col-sm-6 login-second-column  d-flex flex-column">

          {/* <div className="displayEle ">
            &nbsp;
          </div> */}
          <div class="flex-fill  simple-login-container d-flex w-100 justify-content-center align-items-center">
            {
             isLoggedIn && isUnLocked !== true ?
                <Lock handleLogin={unlockClick} terminalName={terminalName} setTerminalName={setTerminalName} terminal={firstTerminalName} companyName={companyDetails?.companynam}/>
              :
                 <LoginForm handleLogin={loginAuth} />
              
            }

          </div>
          
       
    <div className="padding5">
    {
             isLoggedIn && isUnLocked !== true ?
             (companyDetails?.currency.split(",", 1) == 'Rs' ? 
            <p class="text-center btn-block w-100 h4" onClick={()=>{openProassist("amepos-proassist")}} style={{color:'blue'}}>amepos.io/amepos-proassist</p>
            :
            
            <p class="text-center btn-block w-100 h4" onClick={()=>{openProassist("proassist")}} style={{color:'blue'}}>amepos.io/proassist</p>
            
            )
            :
            ''
         
       }
          </div>
        </div>
      </div>

    </div>


  );

}






