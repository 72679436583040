import React, { useState ,Component } from 'react';
import { useSelector } from 'react-redux';



export function CardNumberForm({setNameonCard,setCardNumber}) {
  const {Box,totalamount,balance,dueamount} =useSelector((state)=>state.touch)
  // export class CardNumberForm extends Component {
  //   static displayName = CardNumberForm.name;
  
  //   render() {
    
    
      return (
  

// export class CardNumberForm extends Component {
//   static displayName = CardNumberForm.name;

//   const {Box,totalamount,balance,dueamount} =useSelector((state)=>state.touch)
//   render() {
//     return (
     
       
        // <div class="d-flex flex-row align-self-end p-4 flex-grow-1">
        //   <div class="w-40">
        //     <h3 class='pt-4 text-danger display-4'>Card Number</h3>
        //   </div>
        //   <div class="md-auto flex-fill">
        //     <input class="text-center btn-block inputNoBorder mb-2 height-30 " required autocomplete="off" />

        //   </div>
        // </div>invisible pb-4
        <>
        <div class="d-flex flex-row align-self-end p1-4    pr-4 flex-grow-1">
          <div class="w-50">
            <h3 class="pl-4 text-danger Display__4">Name on Card</h3>
          </div>
          <div class="md-auto flex-fill">
             
          <input class="text-center btn-block inputNoBorder-infogreen mb-2 height-30 "  style={ {borderBottom: "2px solid #42CACA"}} required autocomplete="off" onChange={(e)=>setNameonCard(e.target.value)}/>
          </div>
        </div>
        <div class="d-flex flex-row align-self-end p1-4   pr-4 flex-grow-1">
          <div class="w-50">
            <h3 class='pl-4 text-danger Display__4'>Card Number</h3>
          </div>
          <div class="md-auto flex-fill">
            <input class="text-center btn-block inputNoBorder-infogreen mb-2 height-30 "  style={ {borderBottom: "2px solid #42CACA"}} required autocomplete="off"  onChange={(e)=>setCardNumber(e.target.value)}/>

          </div>
        </div>
        <div class="d-flex flex-row align-self-end p1-4  pr-4 flex-grow-1">
          <div class="w-50">
            <h3 class="pl-4 text-danger Display__4">Amount</h3>
          </div>
          <div class="md-auto flex-fill">
            <input class="text-center btn-block inputNoBorder-infogreen mb-2 height-30 "  style={ {borderBottom: "2px solid #42CACA"}} required autocomplete="off" value={totalamount<0?0:totalamount
}/>

          </div>
        </div>
      </>
     
    );
  }

