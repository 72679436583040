
import React, { useState } from 'react';
import './Calc.css'
import { setActivePage } from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents } from '../../utilities/Constants'
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Calc(props) {

  const [numericValue, setNumericValue] = useState("");
  let content;
  const dispatch = useDispatch();

  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }

  if(props.enter==="true")
  { 
    content= <div class="col-4 padding__4 border bg-success text-light" ><strong>Enter</strong></div>
  }
  else
  {
    content=<div class="col-4 padding__4 border " ><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>
  }

  return (
    // mt-4
    <div class="btn-group-vertical  " role="group"  >

      <div class="row text-center my-4 flex-wrap w-100">
        <div class=" w-100">
          <input class="text-center form-control-lg square-edges padding__5 inputCardNumber border" type="text" value={numericValue} />
        </div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
        { content}
         
      </div>





    </div>
  )


}





