

export class BillModel {
    constructor() {
        this.id= null;
        this.customerName= null;
        this.paymentMode=null;
        this.lineitems=[]
    }
    
}