import React, { useState, useEffect } from 'react';

import './ClosingSales.css'
import { Calc } from '../Commons/Calc';
import { ClosingSummary } from './ClosingSummary';
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';
import { Denomination } from './Denomination';
import { pageComponents, ClosingSalesType } from '../../utilities/Constants'
import { setActivePage, updatePage, selectPageById, backToPreviousPage } from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import '../../utilities/fontawesome';
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ewalletModeData, onlineModeData } from '../../reducers/ConstantSlice';

export function ClosingSales() {
  const draftRef = React.useRef(null);
  const [discat, setdiscat] = React.useState([]);
  const [companyDetails, setCompanyDetails] = useState();
  const [closingSalesWithDenominationData, setClosingSalesWithDenominationData] = useState();
  const [one, setone] = React.useState(0);
  
  const [onlineCardClosingData, setOnlineCardClosingData] = useState([]);
  const [onlineCodClosingData, setOnlineCodClosingData] = useState([]);
  const [onlineAcClosingData, setOnlineAcClosingData] = useState([]);
  const [ewalletClosingData, setEwalletClosingData] = useState([]);
  console.log(ewalletClosingData, "ewalletClosingData");
  const onlineModeTypeData = useSelector((state) => state.constVar.onlineModeData)
  const ewalletModeTypeData = useSelector((state) => state.constVar.ewalletModeData)
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const onBeforeGetContentResolve = React.useRef(null);
  const [two, settwo] = React.useState(0);
  const [five, setfive] = React.useState(0);
  const [thsnd, setthsnd] = React.useState(0);
  const [twohnsnd, settwohnsnd] = React.useState(0);
  const [ten, setten] = React.useState(0);
  const [twenty, settwenty] = React.useState(0);
  const [fifty, setfifty] = React.useState(0);
  const [hnrd, sethnrd] = React.useState(0);
  const [twohnrd, settwohnrd] = React.useState(0);
  const [fivehnrd, setfivehnrd] = React.useState(0);
  ////////////// React to Print ///////////////////////
  const date = new Date();

  console.log(date.getMonth().length, "date");
  const YYYY = date.getFullYear();
  let DD = date.getDate();
  let MM = date.getMonth() + 1;
  let HH = date.getHours();
  let mm = date.getMinutes()
  let ss = date.getSeconds();

  if (DD < 10) {
    DD = `0${DD}`;
  } else {
    DD = `${DD}`;
  }
  if (MM < 10) {
    MM = `0${MM}`;
  } else {
    MM = `${MM}`;
  }
  if (HH < 10) {
    HH = `0${HH}`;
  } else {
    HH = `${HH}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  } else {
    mm = `${mm}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  } else {
    ss = `${ss}`;
  }
  const ltime = (YYYY +
    "-" + MM +
    "-" + DD +
    " " + HH +
    ":" + mm +
    ":" + ss);
  console.log(ltime, "ltime");
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  console.log(datetime, date, "anandangelcart");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
    alert("You Will Receive the Closing Sales Mail");
    localStorage.clear();
    window.location.href = `${process.env.PUBLIC_URL}/login`
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,

    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });
  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);








  /////////////////////////////////////




  var tamt = (
    1 * one +
    2 * two +
    5 * five +
    10 * ten +
    20 * twenty +
    50 * fifty +
    100 * hnrd +
    200 * twohnrd +
    500 * fivehnrd +
    2000 * twohnsnd)
  const [numericValue, setNumericValue] = useState("");
  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  useEffect(() => {
    getDataUsingPostData()
  }, [])
  // const withDenomination = () => {
  //   // getDataUsingPostData();
  //   amountPayment()
  // };
  const getDataUsingPostData = () => {
    //POST json
    let dataToSend = {

      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      // terminal: localStorage.getItem('TerminalName'),
      // cash: numericValue,
      // formattedDate: datetime,
      // closeusr:localStorage.getItem('UserID'),
      // wanip: tokenCreate[0].Wanip,
      // dbusr: tokenCreate[0].DBUser,
      // dbpwd: tokenCreate[0].DBPwd,
      // dbnam: tokenCreate[0].DBname,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.clientdtlArr[0].clientdtl != 2) {
          setCompanyDetails(responseJson.clientdtlArr[0])
          // getFinal();

        } else {
          alert("Sorry Something Went Wrong in Closing Sales");
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };


  const amountPayment = async () => {
    await getEwalletModeDetails()
    await getFinal()
    
    // await handleClickLastReceipt();
    // handlePrint()
  }


  const getFinal = () => {
    //POST json
    const date = new Date();
    console.log(date, "anandgreenangel");
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    console.log(datetime, date, "anandangelcart");
    let dataToSend = {

      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      cash: numericValue,
      formattedDate: datetime,
      closeusr: localStorage.getItem('UserID'),
      // wanip: tokenCreate[0].Wanip,
      // dbusr: tokenCreate[0].DBUser,
      // dbpwd: tokenCreate[0].DBPwd,
      // dbnam: tokenCreate[0].DBname,
      // terminal: terminalno,
      cash: tamt,
      formattedDate: datetime,
      // closeusr: pinCreate[0].UserID,
      denomination1: one,
      denomination2: two,
      denomination3: five,
      denomination4: ten,
      denomination5: twenty,
      denomination6: fifty,
      denomination7: hnrd,
      denomination8: twohnrd,
      denomination9: fivehnrd,
      denomination10: twohnsnd,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_closingsales", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson, "responseJsonwithdenomination");
        if (responseJson.CloseArr[0].close != 2) {
          
          const arr = [];
          const arr2 = [];
          const arr3 = [];
          const arr4 = [];
          const arr5 = [];
          // ewalletModeTypeData
          // if (responseJson.CloseArr.length > 0 && responseJson?.CloseArr[0].ewalletsale.length > 0) {
          //   for (let i = 0; i < responseJson?.CloseArr[0].ewalletsale.length; i++) {
          //     if (ewalletModeTypeData?.length > 0) {
          //       for (let j = 0; j < ewalletModeTypeData.length; j++) {
          //         if (ewalletModeTypeData[j].ewallet == responseJson?.CloseArr[0].ewalletsale[i].ewalletname ) {
          //           arr2.push({
          //             amount: responseJson?.CloseArr[0].ewalletsale[i].amount,
          //             ewalletname: responseJson?.CloseArr[0].ewalletsale[i].ewalletname
          //           })
          //         } else if(ewalletModeTypeData[j].ewallet != responseJson?.CloseArr[0].ewalletsale[i].ewalletname ) {
          //           arr3.push({
          //             amount: "0.00",
          //             ewalletname: ewalletModeTypeData[j].ewallet
          //           })
          //         }
          //         // closingSalesWithDenominationData?.CloseArr[0].ewalletsale
          //       }


          //     }
          //   }
          // }
          
          let  reswww = ewalletModeTypeData?.map((el1) => ({
            name: el1.ewallet,
            match: responseJson?.CloseArr[0].ewalletsale?.find((el2) => 
              el2.ewalletname === el1.ewallet 
            ),
          }))

          let res2 = onlineModeTypeData?.map((el1) => ({
            name: el1.ewallet,
            match: responseJson?.CloseArr[0].onlineacsale.find((el2) => 
            el2.onlineac === el1.ewallet 
          ),
        }))

        let res3 = onlineModeTypeData?.map((el1) => ({
          name: el1.ewallet,
          match: responseJson?.CloseArr[0].onlinecodsale.find((el2) => 
          el2.onlineac === el1.ewallet 
        ),
      }))

      let res4 = onlineModeTypeData?.map((el1) => ({
        name: el1.ewallet,
        match: responseJson?.CloseArr[0].onlinecardsale.find((el2) => 
        el2.onlineac === el1.ewallet 
      ),
    }))  

          console.log(reswww,"reswww");

          for (let i = 0; i < reswww.length; i++) {
            if(reswww[i].match != null && reswww[i].match != undefined){

              arr2.push({
                ename:reswww[i].name,
                amount:reswww[i].match?.amount,
              })
            }
            else{
              arr2.push({
                ename:reswww[i].name,
                amount:'0.00',
              })
            }
          }

          for (let i = 0; i < res2.length; i++) {
            if(res2[i].match != null && res2[i].match != undefined){

              arr3.push({
                ename:res2[i].name,
                amount:res2[i].match?.amount,
              })
            }
            else{
              arr3.push({
                ename:res2[i].name,
                amount:'0.00',
              })
            }
          }
          for (let i = 0; i < res3.length; i++) {
            if(res3[i].match != null && res3[i].match != undefined){

              arr4.push({
                ename:res3[i].name,
                amount:res3[i].match?.amount,
              })
            }
            else{
              arr4.push({
                ename:res3[i].name,
                amount:'0.00',
              })
            }
          }
          console.log(arr2,arr3,"pp")
          
          for (let i = 0; i < res4.length; i++) {
            if(res4[i].match != null && res4[i].match != undefined){

              arr5.push({
                ename:res4[i].name,
                amount:res4[i].match?.amount,
              })
            }
            else{
              arr5.push({
                ename:res4[i].name,
                amount:'0.00',
              })
            }
          }
          // let pp = arr3.filter( (ele, ind) => ind === arr3.findIndex( elem => elem.ewalletname === ele.ewalletname ) && arr2.findIndex(elem2 => elem2.ewalletname !==  ele.ewalletname) )

          // console.log(pp,"pp");

          setEwalletClosingData(arr2)
          setOnlineAcClosingData(arr3)
          setOnlineCodClosingData(arr4)
          setOnlineCardClosingData(arr5)

          setClosingSalesWithDenominationData(responseJson)
          // const arr = [];


          for (let i = 0; i < responseJson.CloseArr.length; i++) {
            //   text =
            //     text +
            //     "[L]<font size='normal' font-family='cambria' font-weight='bold'>OPENING DATE:" +
            //     responseJson.CloseArr[i].opendate +
            //     "\n" +
            //     "[L]<font size='normal' font-family='cambria' font-weight='bold'>CLOSING DATE:" +
            //     responseJson.CloseArr[i].closedate +
            //     "\n" +
            //     "[L]\n" +
            //     "[L]--------------------------------------------\n" +
            //     "[L]<font size='normal' >CASH SALES</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].cashsale +
            //     "</font>\n" +
            //     "[L]<font size='normal' >CARD SALES</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].cardsale +
            //     "</font>\n" +
            //     "[L]<font size='normal' >CREDIT SALES</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].creditsale +
            //     "</font>\n" +
            //     "[L]<font size='normal' >REFUND SALES</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].refundsale +
            //     "</font>\n" +
            //     "[L]<font size='normal' >OTHER SALES</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].othersale +
            //     "</font>\n" +
            //     "[L]--------------------------------------------\n" +
            //     "[L]<font size='normal' >TOTAL SALES</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].totalsale +
            //     "</font>\n" +
            //     "[L]--------------------------------------------\n" +
            //     "[L]<font size='normal' >OPENING CASH</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].openingcash +
            //     "</font>\n" +
            //     "[L]<font size='normal' >DROP CASH</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].dropcash +
            //     "</font>\n" +
            //     "[L]<font size='normal' >PAYOUT CASH</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].suplpayout +
            //     "</font>\n" +
            //     "[L]<font size='normal' >EMPLOYEE PAYOUT CASH</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].emppayout +
            //     "</font>\n" +
            //     "[L]--------------------------------------------\n" +
            //     "[L]<font size='normal' >CASH BALANCE</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].cashbalance +
            //     "</font>\n" +
            //     "[L]--------------------------------------------\n" +
            //     "[L]<font size='normal' >CASH COUNTED</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].cashcounted +
            //     "</font>\n" +
            //     "[L]--------------------------------------------\n" +
            //     "[L]<font size='normal' >CASH DIFFERENCE</font>[R]<font size='normal' font-family='cambria' font-weight='bold'>" +
            //     responseJson.CloseArr[i].cashdiff +
            //     "</font>\n" +
            //     "[L]\n";
          }

          handlePrint()
          console.log("HIIIsss");

        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }


      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  const printing = async (texts, ip) => {
    console.log("====================================");
    console.log(texts, "texts");
    console.log("====================================");
    try {
      //
      // bluetooth
      // await ThermalPrinterModule.printBluetooth({ payload: state.text });
      //
      console.log("We will invoke the native module here!");
      // await ThermalPrinterModule.printTcp({
      //   ip: ip,
      //   autoCut: true,
      //   port: 9100,
      //   payload: texts,
      // });
      console.log("done printing");
    } catch (err) {
      //error handling
      console.log(err.message);
    }
  };


  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }

  const [value, setvalue] = React.useState([])
  const dispatch = useDispatch();

  const withoutDenomfun = async() =>{
    
    await getEwalletModeDetails()
    getCash()
  }


  const getCash = () => {
    //POST json
    const date = new Date();
    console.log(date, "anandgreenangel");
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    console.log(datetime, date, "anandangelcart");
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      terminal: localStorage.getItem('TerminalName'),
      cash: numericValue,
      formattedDate: datetime,
      closeusr: localStorage.getItem('UserID'),
      denomination1: "0",
      denomination2: "0",
      denomination3: "0",
      denomination4: "0",
      denomination5: "0",
      denomination6: "0",
      denomination7: "0",
      denomination8: "0",
      denomination9: "0",
      denomination10: "0",
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_closingsales", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.CloseArr[0].close != 2) {

          const arr = [];
          const arr2 = [];
          const arr3 = [];
          const arr4 = [];
          const arr5 = [];
          // ewalletModeTypeData
          // if (responseJson.CloseArr.length > 0 && responseJson?.CloseArr[0].ewalletsale.length > 0) {
          //   for (let i = 0; i < responseJson?.CloseArr[0].ewalletsale.length; i++) {
          //     if (ewalletModeTypeData?.length > 0) {
          //       for (let j = 0; j < ewalletModeTypeData.length; j++) {
          //         if (ewalletModeTypeData[j].ewallet == responseJson?.CloseArr[0].ewalletsale[i].ewalletname ) {
          //           arr2.push({
          //             amount: responseJson?.CloseArr[0].ewalletsale[i].amount,
          //             ewalletname: responseJson?.CloseArr[0].ewalletsale[i].ewalletname
          //           })
          //         } else if(ewalletModeTypeData[j].ewallet != responseJson?.CloseArr[0].ewalletsale[i].ewalletname ) {
          //           arr3.push({
          //             amount: "0.00",
          //             ewalletname: ewalletModeTypeData[j].ewallet
          //           })
          //         }
          //         // closingSalesWithDenominationData?.CloseArr[0].ewalletsale
          //       }


          //     }
          //   }
          // }
          
          let  reswww = ewalletModeTypeData?.map((el1) => ({
            name: el1.ewallet,
            match: responseJson?.CloseArr[0].ewalletsale?.find((el2) => 
              el2.ewalletname === el1.ewallet 
            ),
          }))

          let res2 = onlineModeTypeData?.map((el1) => ({
            name: el1.ewallet,
            match: responseJson?.CloseArr[0].onlineacsale.find((el2) => 
            el2.onlineac === el1.ewallet 
          ),
        }))

        let res3 = onlineModeTypeData?.map((el1) => ({
          name: el1.ewallet,
          match: responseJson?.CloseArr[0].onlinecodsale.find((el2) => 
          el2.onlineac === el1.ewallet 
        ),
      }))

      let res4 = onlineModeTypeData?.map((el1) => ({
        name: el1.ewallet,
        match: responseJson?.CloseArr[0].onlinecardsale.find((el2) => 
        el2.onlineac === el1.ewallet 
      ),
    }))  

          console.log(reswww,"reswww");

          for (let i = 0; i < reswww.length; i++) {
            if(reswww[i].match != null && reswww[i].match != undefined){

              arr2.push({
                ename:reswww[i].name,
                amount:reswww[i].match?.amount,
              })
            }
            else{
              arr2.push({
                ename:reswww[i].name,
                amount:'0.00',
              })
            }
          }

          for (let i = 0; i < res2.length; i++) {
            if(res2[i].match != null && res2[i].match != undefined){

              arr3.push({
                ename:res2[i].name,
                amount:res2[i].match?.amount,
              })
            }
            else{
              arr3.push({
                ename:res2[i].name,
                amount:'0.00',
              })
            }
          }
          for (let i = 0; i < res3.length; i++) {
            if(res3[i].match != null && res3[i].match != undefined){

              arr4.push({
                ename:res3[i].name,
                amount:res3[i].match?.amount,
              })
            }
            else{
              arr4.push({
                ename:res3[i].name,
                amount:'0.00',
              })
            }
          }
          console.log(arr2,arr3,"pp")
          
          for (let i = 0; i < res4.length; i++) {
            if(res4[i].match != null && res4[i].match != undefined){

              arr5.push({
                ename:res4[i].name,
                amount:res4[i].match?.amount,
              })
            }
            else{
              arr5.push({
                ename:res4[i].name,
                amount:'0.00',
              })
            }
          }
          // let pp = arr3.filter( (ele, ind) => ind === arr3.findIndex( elem => elem.ewalletname === ele.ewalletname ) && arr2.findIndex(elem2 => elem2.ewalletname !==  ele.ewalletname) )

          // console.log(pp,"pp");

          setEwalletClosingData(arr2)
          setOnlineAcClosingData(arr3)
          setOnlineCodClosingData(arr4)
          setOnlineCardClosingData(arr5)
          setClosingSalesWithDenominationData(responseJson)
          for (let i = 0; i < responseJson.CloseArr.length; i++) {
            arr.push({
              cls: responseJson.CloseArr[i].close,
              amt: responseJson.CloseArr[i].closedamt,
              amt: responseJson.CloseArr[i].cashcounted,
            });
          }
          setvalue(arr)
          console.log("HIII");
          handlePrint()
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  const getEwalletModeDetails = () => {
    let dataToSend = {
      token: localStorage.getItem('token'),

      // tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_ewalletlist", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        console.log(responseJson, "kot");

        if (responseJson.ModePayArr[0].ModePay != 2) {
          var arr = [];
          var arr2 = [];
          // setEwalletModeData(responseJson)
          for (let i = 0; i < responseJson.ModePayArr.length; i++) {
            if (responseJson.ModePayArr[i].type == 'E') {
              // const objectURL = URL.createObjectURL(responseJson.ModePayArr[0].image)

              // console.log(objectURL,"objectURL");
              // URL.revokeObjectURL()
              // let url = URL.createObjectURL(responseJson.ModePayArr[0].image)
              // console.log(url,"url");
              // let buff = ''
              // buff = new Buffer(`${responseJson.ModePayArr[i].image}`, 'base64');
              // let base64ToStringNew = buff.toString('ascii');
              // console.log(base64ToStringNew,"base64ToStringNew");
              // var decodedString = atob(encodedString)
              // decodeBase64(responseJson.ModePayArr[i].image)
              arr.push({
                ModePay: responseJson.ModePayArr[i].ModePay,
                ewallet: responseJson.ModePayArr[i].ewallet,
                image: responseJson.ModePayArr[i].image,

                type: responseJson.ModePayArr[i].type

              })

            }
          }
          for (let i = 0; i < responseJson.ModePayArr.length; i++) {
            if (responseJson.ModePayArr[i].type == 'O') {
              // const objectURL = URL.createObjectURL(responseJson.ModePayArr[0].image)

              // console.log(objectURL,"objectURL");
              // URL.revokeObjectURL()
              // let url = URL.createObjectURL(responseJson.ModePayArr[0].image)
              // console.log(url,"url");
              // let buff = ''
              // buff = new Buffer(`${responseJson.ModePayArr[i].image}`, 'base64');
              // let base64ToStringNew = buff.toString('ascii');
              // console.log(base64ToStringNew,"base64ToStringNew");
              // var decodedString = atob(encodedString)
              // decodeBase64(responseJson.ModePayArr[i].image)
              arr2.push({
                ModePay: responseJson.ModePayArr[i].ModePay,
                ewallet: responseJson.ModePayArr[i].ewallet,
                image: responseJson.ModePayArr[i].image,

                type: responseJson.ModePayArr[i].type

              })

            }
          }
          dispatch(ewalletModeData(arr))
          dispatch(onlineModeData(arr2))
          console.log(arr, "arrWalletType");
        }

      })
      .catch((error) => {
        console.error(error);
      });

  }

  console.log(value, "value");

  const closingSales = useSelector((state) => selectPageById(state, pageComponents.CLOSINGSALES));
  let contentleft, contentRight, title;



  if (closingSales.ClosingSalesType === ClosingSalesType.WITHOUT_DENOMINATION) {
    contentleft = <div class="row ml-1 mt-4">
      <div class="col">
        <div class="row">
          <div class="col padding__2">

            <h2 class="display-4 "><strong>Total Amount</strong></h2>
          </div>
        </div>
        <div class="row">
          <div class="col padding__2">
            {/* totalsale */}
            <h2 class="text-danger display-4 "><strong>{value.length != 0 ? <>{value[0].amt}</> : null}</strong></h2>
          </div>
        </div>

      </div>

    </div>;
    // title = "Closing without Denomination";
    contentRight = <><div class=""></div><div >
      <div class="btn-group-vertical  mt-4" role="group"  >
        {/* row */}
        <div class=" text-center my-4 flex-wrap w-100" style={{ display: 'flex' }}>
          <div class=" w-100">
            <input class="text-center form-control-lg square-edges padding__5 inputCardNumber border" type="text" value={numericValue} />
          </div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

          <div class="w-100"></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

          <div class="w-100"></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

          <div class="w-100"></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
          <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
          <div class="col-4 padding__4 border " onClick={() => deleteNumber(0)}><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>








        </div>





      </div>
      <button class="btn btn-florogreen btn-lg btn-block p-4 " onClick={() => withoutDenomfun()}>Confirm Closing Sales</button>
      <button class="btn btn-florogreen btn-lg btn-block p-4" onClick={() => dispatch(backToPreviousPage())}>Cancel</button></div>

      <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
        <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
          <div class="ticket font-face-gm" >
            <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam}</p>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1}</p>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr2}</p>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno}</p>
            <p className="centered" style={{ fontSize: '16px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>CLOSING REPORT</p>


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>

              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
            </div>
            <div>
              <p className="" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Opening Date :{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].opendate}</p>

              <p className="" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderBottom: '1px dashed black' }}>Closing Date :{ltime != null && ltime != undefined ? ltime : ""}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>CASH SALES</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashsale ? closingSalesWithDenominationData?.CloseArr[0].cashsale : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>CARD SALES</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cardsale ? closingSalesWithDenominationData?.CloseArr[0].cardsale : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>CREDIT SALES</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p>
            </div>
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.length > 0 ? */}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>EWALLET SALES</p>
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderTop: '1px dashed black'  }}>EWALLET SALES</p>  </div> */}
            {/* {} */}

            {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}

            {/* // : ''
              // } */}
           
            {ewalletClosingData.length > 0 && ewalletClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))}



            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.map((i) => (
                i.ewalletname != null && i.ewalletname != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ewalletname}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE A/C SALES</p>
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
            </div>
            {onlineAcClosingData.length > 0 && onlineAcClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))} 
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.length > 0 && */}

            {/* } */}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.length > 0 && */}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE COD SALES</p>
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
            </div>
            {/* } */}
            {onlineCodClosingData.length > 0 && onlineCodClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.length > 0 && */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE CARD SALES</p>
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
            </div>
            {/* // } */}
            {onlineCardClosingData.length > 0 && onlineCardClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>OTHERS SALES</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].othersale ? closingSalesWithDenominationData?.CloseArr[0].othersale : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL SALES</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale ? closingSalesWithDenominationData?.CloseArr[0].totalsale : "0.00"}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>NC SALES</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].othersale ? closingSalesWithDenominationData?.CloseArr[0].othersale : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black', borderBottom: '1px dashed black' }}>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>REFUND SALES</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].refundsale ? closingSalesWithDenominationData?.CloseArr[0].refundsale : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>OPENING CASH</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].openingcash ? closingSalesWithDenominationData?.CloseArr[0].openingcash : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PAYOUT CASH</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].dropcash ? closingSalesWithDenominationData?.CloseArr[0].dropcash : "0.00"}</p>
            </div>
            {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>PAYOUT CASH</p>
                  
                       <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].dropcash ? closingSalesWithDenominationData?.CloseArr[0].dropcash : "0.00"}</p>
               </div> */}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>EXPENSE PAYOUT CASH</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].exppayout ? closingSalesWithDenominationData?.CloseArr[0].exppayout : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>EMPLOYEE PAYOUT CASH</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].emppayout ? closingSalesWithDenominationData?.CloseArr[0].emppayout : "0.00"}</p>
            </div>
            {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>EXPENSE PAYOUT CASH</p>
                  
                       <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].exppayout ? closingSalesWithDenominationData?.CloseArr[0].exppayout : "0.00"}</p>
               </div> */}
            {/* <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dashed black'}}>
               ,borderBottom:'2px dashed black'
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>TOTAL NO OF BILLS NO OF TIMES CASHDRAW OPENED</p>
                  
                       <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].noofbills ? closingSalesWithDenominationData?.CloseArr[0].noofbills : "0.00"}</p>
               </div> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL SALES EXCL GST</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale && closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? parseFloat(closingSalesWithDenominationData?.CloseArr[0].totalsale) - Math.round(parseFloat(closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstamount)).toFixed(2) : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL GST</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstamount : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL SALES INCL GST</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale ? closingSalesWithDenominationData?.CloseArr[0].totalsale : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASH BALANCE</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashbalance ? closingSalesWithDenominationData?.CloseArr[0].cashbalance : "0.00"}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASH COUNTED</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashcounted ? closingSalesWithDenominationData?.CloseArr[0].cashcounted : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASH DIFFERENCE</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashdiff ? closingSalesWithDenominationData?.CloseArr[0].cashdiff : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <div>
                <p className="" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL NO OF BILLS</p>
                <p className="" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>NO OF TIMES CASHDRAW OPENED</p>


              </div>


              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}> {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].noofbills ? closingSalesWithDenominationData?.CloseArr[0].noofbills : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', borderTop: '1px dashed black' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '500' }}>GST Summary:</p>

              {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashdiff ? closingSalesWithDenominationData?.CloseArr[0].cashdiff : "0.00"}</p> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST%</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstper : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Amount-{companyDetails?.currency.split(",", 1)}  </p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale ? closingSalesWithDenominationData?.CloseArr[0].totalsale : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST-{companyDetails?.currency.split(",", 1)}  </p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstamount : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', borderTop: '1px dashed black' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>VOID SALES REPORT</p>

              {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashdiff ? closingSalesWithDenominationData?.CloseArr[0].cashdiff : "0.00"}</p> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>VOID SALES AFTER KOT</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].voidsalesafterkot ? closingSalesWithDenominationData?.CloseArr[0].voidsalesafterkot : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>VOID SALES BEFORE KOT</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].voidsalesbeforekot ? closingSalesWithDenominationData?.CloseArr[0].voidsalesbeforekot : "0.00"}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* ,borderBottom:'2px dashed black' */}
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>DELETED BILL AMOUNT</p>

              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].deletedbillamt ? closingSalesWithDenominationData?.CloseArr[0].deletedbillamt : "0.00"}</p>
            </div>
            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderTop: '1px dashed black' }}>{companyDetails?.remarks1}</p>
            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.remarks2}</p>


          </div>
        </div>

      </div>
      <div class="flex-grow-1"></div></>
  }
  else {
    contentleft = <Denomination
      setone={setone}
      settwo={settwo}
      setfive={setfive}
      settwohnsnd={settwohnsnd}
      setten={setten}
      settwenty={settwenty}
      setfifty={setfifty}
      sethnrd={sethnrd}
      settwohnrd={settwohnrd}
      setfivehnrd={setfivehnrd}
    ></Denomination>;
    contentRight = <><div class="flex-grow-1">
      <ClosingSummary
        one={one} two={two} five={five} twohnsnd={twohnsnd} ten={ten} twenty={twenty} fifty={fifty} hnrd={hnrd} twohnrd={twohnrd} fivehnrd={fivehnrd}

      /> </div>
      <div>
        <div class="row ml-1 marginTop__4">
          <div class="col" style={{ display: 'flex', justifyContent: 'end' }}>
            <div class="row">
              <div class="col padding__2">

                <h2 class="Display__4 text-right pr-5"><strong>Total Amount</strong></h2>
              </div>
            </div>
            <div class="row">
              <div class="col padding__2">
                <h2 class="text-danger Display__4 text-right pr-5"><strong>{tamt != null && tamt != undefined && tamt}</strong></h2>
              </div>
            </div>

          </div>

        </div>
        <div class="text-right pb-2">
          <button class="btn btn-infogreen btn-lg w-50 padding__9 mr-5" onClick={() => amountPayment()}>Submit</button>
        </div>
        <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
          <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
            <div class="ticket font-face-gm" >
              <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr2}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno}</p>
              <p className="centered" style={{ fontSize: '16px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>CLOSING REPORT</p>


              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{localStorage.getItem('TerminalName')}</p>

                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {localStorage.getItem('UserName')} </p>
              </div>
              <div>
                <p className="" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Opening Date :{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].opendate}</p>

                <p className="" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderBottom: '1px dashed black' }}>Closing Date :{ltime != null && ltime != undefined ? ltime : ""}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>CASH SALES</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashsale ? closingSalesWithDenominationData?.CloseArr[0].cashsale : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>CARD SALES</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cardsale ? closingSalesWithDenominationData?.CloseArr[0].cardsale : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>CREDIT SALES</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p>
              </div>
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.length > 0 ? */}

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>EWALLET SALES</p>
                {/* {} */}

                {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
              </div>
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderTop: '1px dashed black'  }}>EWALLET SALES</p>  </div> */}
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}

              {/* // : ''
              // } */}
              {/* {ewalletModeTypeData?.length > 0 && ewalletModeTypeData?.map((ewalletMode) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{ewalletMode?.ewallet}</p>
                  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.length > 0 ?
                    closingSalesWithDenominationData?.CloseArr[0].ewalletsale.map((ewalletmap) => (
                      (ewalletmap.ewalletname == ewalletMode.ewallet ?
                        <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {ewalletmap?.amount}</p>
                        : <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>)
                    )
                    ) :
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>


                  }

                </div>
              ))} */}
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.map((i) => (
                i.ewalletname != null && i.ewalletname != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ewalletname}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}

{ewalletClosingData.length > 0 && ewalletClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))}



            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].ewalletsale.map((i) => (
                i.ewalletname != null && i.ewalletname != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ewalletname}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE A/C SALES</p>
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
            </div>
            {onlineAcClosingData.length > 0 && onlineAcClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))} 
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.length > 0 && */}

            {/* } */}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.length > 0 && */}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE COD SALES</p>
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
            </div>
            {/* } */}
            {onlineCodClosingData.length > 0 && onlineCodClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
            {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.length > 0 && */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE CARD SALES</p>
              {/* {} */}

              {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
            </div>
            {/* // } */}
            {onlineCardClosingData.length > 0 && onlineCardClosingData?.map((i)=>(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.ename}</p>
              <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {i?.amount}</p>
                   
            </div>
            ))}



              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                {/* <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE A/C SALES</p> */}
                {/* {} */}

                {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
              {/* </div> */}
              {/* {onlineModeTypeData?.length > 0 && onlineModeTypeData?.map((ewalletMode) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{ewalletMode?.ewallet}</p>
                  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.length > 0 ?
                    closingSalesWithDenominationData?.CloseArr[0].onlineacsale.map((onlineacsale) => (
                      (onlineacsale.onlineac == ewalletMode.ewallet ?
                        <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {onlineacsale?.amount}</p>
                        : <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>)
                    )
                    ) :
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>


                  }

                </div>
              ))} */}
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.length > 0 && */}

              {/* } */}
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlineacsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.length > 0 && */}

              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE COD SALES</p> */}
                {/* {} */}

                {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
              {/* </div> */}
              {/* } */}
              {/* {onlineModeTypeData?.length > 0 && onlineModeTypeData?.map((ewalletMode) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{ewalletMode?.ewallet}</p>
                  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.length > 0 ?
                    closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.map((onlinecodsale) => (
                      (onlinecodsale.onlineac == ewalletMode.ewallet ?
                        <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {onlinecodsale?.amount}</p>
                        : <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>)
                    )
                    ) :
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>


                  }

                </div>
              ))} */}
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecodsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.length > 0 && */}
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>ONLINE CARD SALES</p> */}
                {/* {} */}

                {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black',borderBottom:'1px dashed black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].creditsale ? closingSalesWithDenominationData?.CloseArr[0].creditsale : "0.00"}</p> */}
              {/* </div> */}
              {/* // } */}
              {/* {onlineModeTypeData?.length > 0 && onlineModeTypeData?.map((ewalletMode) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{ewalletMode?.ewallet}</p>
                  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.length > 0 ?
                    closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.map((onlinecardsale) => (
                      (onlinecardsale.onlineac == ewalletMode.ewallet ?
                        <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {onlinecardsale?.amount}</p>
                        : <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>)
                    )
                    ) :
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {"0.00"}</p>


                  }

                </div>
              ))} */}
              {/* {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.length > 0 && closingSalesWithDenominationData?.CloseArr[0].onlinecardsale.map((i) => (
                i.onlineac != null && i.onlineac != undefined ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{i?.onlineac}</p>
                    <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {i?.amount > 0 && i?.amount ? i?.amount : "0.00"}</p>
                  </div>
                  : ''
              ))} */}


              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '600' }}>OTHERS SALES</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].othersale ? closingSalesWithDenominationData?.CloseArr[0].othersale : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL SALES</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale ? closingSalesWithDenominationData?.CloseArr[0].totalsale : "0.00"}</p>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>NC SALES</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].othersale ? closingSalesWithDenominationData?.CloseArr[0].othersale : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black', borderBottom: '1px dashed black' }}>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>REFUND SALES</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].refundsale ? closingSalesWithDenominationData?.CloseArr[0].refundsale : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>OPENING CASH</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].openingcash ? closingSalesWithDenominationData?.CloseArr[0].openingcash : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PAYOUT CASH</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].dropcash ? closingSalesWithDenominationData?.CloseArr[0].dropcash : "0.00"}</p>
              </div>
              {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>PAYOUT CASH</p>
                  
                       <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].dropcash ? closingSalesWithDenominationData?.CloseArr[0].dropcash : "0.00"}</p>
               </div> */}

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>EXPENSE PAYOUT CASH</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].exppayout ? closingSalesWithDenominationData?.CloseArr[0].exppayout : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>EMPLOYEE PAYOUT CASH</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].emppayout ? closingSalesWithDenominationData?.CloseArr[0].emppayout : "0.00"}</p>
              </div>
              {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>EXPENSE PAYOUT CASH</p>
                  
                       <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].exppayout ? closingSalesWithDenominationData?.CloseArr[0].exppayout : "0.00"}</p>
               </div> */}
              {/* <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dashed black'}}>
               ,borderBottom:'2px dashed black'
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>TOTAL NO OF BILLS NO OF TIMES CASHDRAW OPENED</p>
                  
                       <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].noofbills ? closingSalesWithDenominationData?.CloseArr[0].noofbills : "0.00"}</p>
               </div> */}
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL SALES EXCL GST</p>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale && closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? parseFloat(closingSalesWithDenominationData?.CloseArr[0].totalsale) - Math.round(parseFloat(closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstamount)).toFixed(2) : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL GST</p>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstamount : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL SALES INCL GST</p>
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale ? closingSalesWithDenominationData?.CloseArr[0].totalsale : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASH BALANCE</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashbalance ? closingSalesWithDenominationData?.CloseArr[0].cashbalance : "0.00"}</p>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASH COUNTED</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashcounted ? closingSalesWithDenominationData?.CloseArr[0].cashcounted : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASH DIFFERENCE</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashdiff ? closingSalesWithDenominationData?.CloseArr[0].cashdiff : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dashed black' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <div>
                  <p className="" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TOTAL NO OF BILLS</p>
                  <p className="" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>NO OF TIMES CASHDRAW OPENED</p>


                </div>


                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}> {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].noofbills ? closingSalesWithDenominationData?.CloseArr[0].noofbills : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', borderTop: '1px dashed black' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: '500' }}>GST Summary:</p>

                {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashdiff ? closingSalesWithDenominationData?.CloseArr[0].cashdiff : "0.00"}</p> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST%</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstper : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Amount-{companyDetails?.currency.split(",", 1)}  </p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].totalsale ? closingSalesWithDenominationData?.CloseArr[0].totalsale : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST-{companyDetails?.currency.split(",", 1)}  </p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != null && closingSalesWithDenominationData?.CloseArr[0].GSTsummary != undefined && closingSalesWithDenominationData?.CloseArr[0].GSTsummary.length > 0 ? closingSalesWithDenominationData?.CloseArr[0].GSTsummary[0].gstamount : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', borderTop: '1px dashed black' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>VOID SALES REPORT</p>

                {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>{companyDetails?.currency.split(",",1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].cashdiff ? closingSalesWithDenominationData?.CloseArr[0].cashdiff : "0.00"}</p> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>VOID SALES AFTER KOT</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].voidsalesafterkot ? closingSalesWithDenominationData?.CloseArr[0].voidsalesafterkot : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>VOID SALES BEFORE KOT</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].voidsalesbeforekot ? closingSalesWithDenominationData?.CloseArr[0].voidsalesbeforekot : "0.00"}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* ,borderBottom:'2px dashed black' */}
                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>DELETED BILL AMOUNT</p>

                <p className="centered" style={{ fontSize: '8px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.currency.split(",", 1)}  {closingSalesWithDenominationData?.CloseArr.length > 0 && closingSalesWithDenominationData?.CloseArr[0].deletedbillamt ? closingSalesWithDenominationData?.CloseArr[0].deletedbillamt : "0.00"}</p>
              </div>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderTop: '1px dashed black' }}>{companyDetails?.remarks1}</p>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.remarks2}</p>


            </div>
          </div>

        </div>
      </div> </>
  }



  return (

    <div class="row flex-grow-1 ">
      <div class={closingSales.ClosingSalesType === ClosingSalesType.WITHOUT_DENOMINATION ? "col-12 col-sm-9 d-none d-sm-flex flex-column " : "col-12 col-sm-6 d-none d-sm-flex flex-column"}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PageTitle titleHeader="Closing Sales" >
            {closingSales.ClosingSalesType === ClosingSalesType.WITHOUT_DENOMINATION ? <h4>Closing without Denomination</h4> : ""}
          </PageTitle>
        </div>

        {contentleft}
      </div>
      <div class={closingSales.ClosingSalesType === ClosingSalesType.WITHOUT_DENOMINATION ? "col col-sm-3 d-none d-sm-flex flex-column align-middle" : "col col-sm-6 d-none d-sm-flex flex-column border-left"}>

        {contentRight}
      </div>

    </div>



  );

}
