import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
// import { BillingItem } from '../Models/BillingItem';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';
import { useSelector, useDispatch } from "react-redux";
import React from "react";


const itemsAdapter = createEntityAdapter();
const initialState = itemsAdapter.getInitialState({
    status: "idle",
    error: null,
    searchTerm: "",
});



export const fetchItems = createAsyncThunk('items/fetchItems', async (item) => {

    console.log('====================================');
    console.log(item, "fetchItems");
    console.log('====================================');
    //alert(id);
    //debugger;
    // const response = () => {
    // //POST json
    let dataToSend = {

        wanip: localStorage.getItem('Wanip'),
        dbusr: localStorage.getItem('DBname'),
        dbpwd: localStorage.getItem('DBPwd'),
        dbnam: localStorage.getItem('DBUser'),
        token: localStorage.getItem('token'),
        terminal: localStorage.getItem('TerminalName'),
        // wanip: item[0].tokenCreate.Wanip,
        // dbusr: item[0].tokenCreate.DBUser,
        // dbpwd: item[0].tokenCreate.DBPwd,
        // dbnam: item[0].tokenCreate.DBname,
        deptid: item[0].id,
        catid: item[0].catid,
    };
    let formBody = [];
    for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");


    const response = await fetch(
        "https://superadmin.amepos.io/TabPosApiNew/tabpos_getitems",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: formBody,
        }
    );

    // console.log("data list-----------------",response.json());
    return response.json();
});




export const searchProducts = createAsyncThunk('items/searchProducts', async (searchTerm) => {

    // const response = await client.query({query:SEARCHITEMS,variables: { "count": searchTerm}});
    // return response.data.products;

})

export const itemsSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {
        searchTerm: (state, action) => {

            state.searchTerm = action.payload;
        },
        searchAllItemsRemove(state, action) {
            // const products = state.entities[action.payload.id]

            itemsAdapter.removeAll(state);

        }
    },
    extraReducers: {
        [fetchItems.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchItems.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            console.log(action.payload, "succeeded");
            itemsAdapter.removeAll(state);
            if (action.payload.ProductArr[0].Product != 2) {
                const ProductArr = action.payload.ProductArr;
                const arr = []
                for (let i = 0; i < ProductArr.length; i++) {
                    if (ProductArr[i].detailsArray.length > 0) {

                        arr.push({
                            id: i.toString(),
                            productname: ProductArr[i].ProductName,
                            plucod: ProductArr[i].plucod,
                            gst: ProductArr[i].GST,
                            catid: ProductArr[i].catid,
                            deptid: ProductArr[i].deptid,
                            image: ProductArr[i].image,
                            itemdisplay: ProductArr[i].itemdisplay,
                            sellp: ProductArr[i].detailsArray[0].sellp,
                            sellp1: ProductArr[i].detailsArray[0].sellp1,
                            sellp2: ProductArr[i].detailsArray[0].sellp2,
                            sellp3: ProductArr[i].detailsArray[0].sellp3,
                            sellp4: ProductArr[i].detailsArray[0].sellp4,
                            sellp5: ProductArr[i].detailsArray[0].sellp5,
                            gstType:ProductArr[i].gstType,
                            GSTper:ProductArr[i].GSTper,
                            rateid:ProductArr[i].detailsArray[0].rateid,
                            edit:'yes'
                        });
                    }
                    else {

                        arr.push({
                            id: i.toString(),
                            productname: ProductArr[i].ProductName,
                            plucod: ProductArr[i].plucod,
                            gst: ProductArr[i].GST,
                            catid: ProductArr[i].catid,
                            deptid: ProductArr[i].deptid,
                            image: ProductArr[i].image,
                            itemdisplay: ProductArr[i].itemdisplay,
                            sellp: "0.00",
                            sellp1: "0.00",
                            sellp2: "0.00",
                            sellp3: "0.00",
                            sellp4: "0.00",
                            sellp5: "0.00",
                            rateid:'1',
                            gstType:ProductArr[i].gstType,
                            GSTper:ProductArr[i].GSTper,
                            edit:'yes'

                        });
                    }

                }


                arr.map((node) => (

                    itemsAdapter.addOne(state, node)

                ))
            }
        },
        [searchProducts.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        [searchProducts.pending]: (state, action) => {
            state.status = 'loading'
        },
        [searchProducts.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            console.log(action.payload);
            itemsAdapter.removeAll(state);
            action.payload.edges.map(({ node }) => (

                itemsAdapter.addOne(state, node)

            ))

        },
        [searchProducts.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const { searchTerm, searchAllItemsRemove } = itemsSlice.actions;

export default itemsSlice.reducer;
export const { selectAll: selectAllItems, } = itemsAdapter.getSelectors((state) => state.items);

// export const selectItemsForSearch = createSelector(
// [selectAllItems, (state, searchTerm) => searchTerm],
// (items, searchTerm) => items.filter((item) => item.name === searchTerm)
// )
// export const selectItemsForSearch = (state) => state.items.filter((item) => item.name.includes(searchTerm));