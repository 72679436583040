import React, {useRef, useEffect, useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Calc } from '../Commons/Calc';
// import { useSelector, useDispatch } from 'react-redux';
import { backToPreviousPage, setActivePage } from '../../reducers/homePageSlice';
import { PageTitle } from '../Commons/PageTitle';
import { CardNumberForm } from '../Payment/CardNumberForm'
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchTables } from '../../reducers/tablesSlice';
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { useReactToPrint } from "react-to-print";
import { pageComponents } from '../../utilities/Constants';
import { confirmAlert } from 'react-confirm-alert';
import { useBarcode } from 'react-barcodes';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const SplitPayment =(props)=> {
  
  
  const ewalletModeData = useSelector((state) => state.constVar.ewalletModeData)
  console.log(ewalletModeData,"ewalletModeDataewalletModeData");
  const [type, setType] = useState("");
   const dispatch = useDispatch();
   const [modeData, setModeData] = useState("");
   const [totalBalanceAmountData, setTotalBalanceAmountData] = useState("0.00");
   
   const [showCreditData, setShowCreditData] = useState("");
   const [showCardData, setShowCardData] = useState("");
   const [showEwalletData, setShowEwalletData] = useState("");
   const [showCashData, setShowCashData] = useState("");
   const [numericValue, setNumericValue] = useState("");
   const [cardNumberTextData, setCardNumberTextData] = useState("");
   
  const [showbillFooter, setShowbillFooter] = useState(true)
  const [showHeaderSettings, setShowHeaderSettings] = useState(false)
  const [showTaxDetails, setShowTaxDetails] = useState(true)
  const [billBodyContent, setBillBodyContent] = useState(false)
  const [tableHeaderSettings, setTableHeaderSettings] = useState(3);
   let content;
  //  const dispatch = useDispatch();
  const kotRef = useRef(null);
const [loadingKOT, setLoadingKOT] = useState(false);
const [textKOT, setTextKOT] = useState("old boring text");

const {Box,balance} =useSelector((state)=>state.touch)
console.log(balance,"Currentbalance");
const onBeforeGetContentResolveKOT = useRef(null);
const [loading, setLoading] = useState(false);
const [text, setText] = useState("old boring text");
const onBeforeGetContentResolve = useRef(null);
const draftRef = useRef(null);
const {dueamount} =useSelector((state)=>state.touch)
console.log(dueamount,"dueamount");
const componentRef = useRef();
const { discountPrecentage } = useSelector((state) => state.touch);
console.log(discountPrecentage,"discountPrecentage");
const cartItemsList = useSelector(selectAllCart);
const [balanceAmt,setbalanceAmt] = useState(0);
const [paymentAmountData,setPaymentAmountData] = useState();
const [paymentAmountDataKOT,setPaymentAmountDataKOT] = useState();
const [dueAmount,setDueAmount] = useState(0);
const [cardNumberData,setCardNumberData] = useState("");
const [cardNameData,setCardNameData] = useState("");
const [companyDetails, setCompanyDetails] = useState();
// const pageStyle = `{ size: 2.5in 4in }`;
const pageStyle="@page { size: 2.5in 4in }"
const [modelDiscountPopUp,setModelDiscountPopUp] = useState(false)
const [denominations,setDenominations] = useState();

const [ewalletType, setEwalletType] = useState();
const [nameonCard,setNameonCard] = useState();
const [cardNumber,setCardNumber] = useState();
const [kotData, setKotData] = useState();
const [billNoScan,setBillNoScan] = useState("0");
const LogoAme = localStorage.getItem("logo");
const activeBillId = useSelector((state) => state.constVar.paymentModeStatus)
const [discat,setdiscat]=useState([]);
const {totalamount} =useSelector((state)=>state.touch)
const activeBillIdData = useSelector((state) => state.bills.activeBillID)
console.log(activeBillId,"activeBillIdData");
// const bill = useSelector((state) => selectBillById(state, activeBillIdData))
// const payMode = bill === undefined ? undefined : bill.paymentMode;
console.log(totalamount,"totalamount");
const date = new Date();

console.log(date, "anandgreenangel");
const datetime =
  date.getFullYear() +
  "-" +
  (date.getMonth() + 1) +
  "-" +
  date.getDate() +
  " " +
  date.getHours() +
  ":" +
  date.getMinutes() +
  ":" +
  date.getSeconds();
console.log(datetime, date, "anandangelcart");
try {
  if(cartItemsList.length > 0 && cartItemsList[0].name !="yasar" ){

  var billamnt = (
    parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
    parseFloat(cartItemsList[cartItemsList.length - 1].gst)
  ).toFixed(2);
  var roundamnt = Math.round(billamnt);
  var subTotal = cartItemsList.length > 0 && cartItemsList[0].name !="yasar" && cartItemsList[cartItemsList.length - 1].subTotal
  }
 } catch (error) {
   console.log(error);
 }
// const [billamount, totalbillamount] = useState(roundamnt);
// const { Box } = useSelector((state) => state.touch);


 
   const addNumber = (num) => {
     setNumericValue(numericValue + num)
 
   }

   const { inputRef } = useBarcode({
    value: billNoScan,
    options: {
      format: "CODE39",
      displayValue: true,
      // background: '#ffc0cb',

    }
  });

 
   const deleteNumber = () => {
 
     setNumericValue(numericValue.slice(0, -1))
   }
   const clearAll = () => {
 
     setNumericValue("");
   }
   const cashbody = () =>{
   
   }
   const setTotalData = (num) =>{
    //  var cash = 0;
    //  var card = 0;
    //  var ewallet = 0;
    //  var credit = 0;
    //  if(parseInt(showCashData) != NaN && parseInt(showCashData) != undefined){
    //   var cash = parseInt(showCashData)
      
    //   console.log(cash,showCashData,"paid1" );
    //  }else{
    //    cash = 0
    //    console.log(cash,"paid2" );
    //   }
    //  if(parseInt(showCardData) != null){
    //   card = parseInt(showCardData)
    //  }
    //  if(parseInt(showEwalletData) != null){
    //   ewallet = parseInt(showEwalletData)
    //  }
    //  if(parseInt(showCreditData) != null){
    //   credit = parseInt(showCreditData)
    //  }
    //  console.log(cash,card,ewallet,credit,"paid" );
     
    //  setNumericValue("")

    //  setNumericValue("")

    var app = 0;
    //  setNumericValue("")
     if(num != null && num != undefined && num != NaN){

      app =app + parseInt(totalBalanceAmountData) +num
      // app = parseInt(showCashData) +  parseInt(showCardData)+ parseInt(showEwalletData)+ parseInt(showCreditData);
      
     }else{
      app = 0
     }
     setTotalBalanceAmountData(app)
     setNumericValue("")
   }

  //  var total = 
   const setTotalDataminus = (num) =>{
    var app = 0;
    if(num != null && num != undefined && num != NaN){
      app =app - parseInt(totalBalanceAmountData)
    //  app = parseInt(showCashData) +  parseInt(showCardData)+ parseInt(showEwalletData)+ parseInt(showCreditData);
     setTotalBalanceAmountData(app)
    }else{
     // setTotalBalanceAmountData("0.00")
    }
  }

   const saveNumberData = () =>{
     if(modeData == "Cash" && numericValue != undefined && numericValue != null){
       console.log(numericValue,"numericValue");
      // if(numericValue == '' || numericValue == null || numericValue == undefined){

      //   setTotalDataminus(showCashData)
      // }else{+

        setShowCashData(numericValue)
        const num = parseInt(numericValue)
        setTotalData(num)
        
       console.log(num,"num");
      // }\\
      
     } else if(modeData == "Card" && numericValue != undefined && numericValue != null){
      setShowCardData(numericValue)
      const num = parseInt(numericValue)
      setTotalData(num)
     } else if(modeData == "Ewallet" && numericValue != undefined && numericValue != null){
       console.log(parseInt(numericValue),"numericValue");
      setShowEwalletData(numericValue)
      const num = parseInt(numericValue)
      setTotalData(num)
     } else if(modeData == "Credit" && numericValue != undefined && numericValue != null){
      setShowCreditData(numericValue)
      const num = parseInt(numericValue)
      setTotalData(num)
     }else{
      //  setShowCreditData
       
      // setShowCardData("")
     }
     
   }

   const setTotalDataMinus = (num) =>{
    //  var cash = 0;
    //  var card = 0;
    //  var ewallet = 0;
    //  var credit = 0;
    //  if(parseInt(showCashData) != NaN && parseInt(showCashData) != undefined){
    //   var cash = parseInt(showCashData)
      
    //   console.log(cash,showCashData,"paid1" );
    //  }else{
    //    cash = 0
    //    console.log(cash,"paid2" );
    //   }
    //  if(parseInt(showCardData) != null){
    //   card = parseInt(showCardData)
    //  }
    //  if(parseInt(showEwalletData) != null){
    //   ewallet = parseInt(showEwalletData)
    //  }
    //  if(parseInt(showCreditData) != null){
    //   credit = parseInt(showCreditData)
    //  }
    //  console.log(cash,card,ewallet,credit,"paid" );
     
    //  setNumericValue("")

    //  setNumericValue("")

    var app = 0;
    //  setNumericValue("")
     if(num != null && num != undefined && num != NaN){

      app =app + parseInt(totalBalanceAmountData)  - num
      // app = parseInt(showCashData) +  parseInt(showCardData)+ parseInt(showEwalletData)+ parseInt(showCreditData);
      
     }else{
      app = 0
     }
     setTotalBalanceAmountData(app)
   }

 console.log(totalBalanceAmountData,"totalBalanceAmountData");

 const clearAllCancel = () =>{
  if(modeData == "Cash" ){
    console.log(numericValue,"numericValue");
   // if(numericValue == '' || numericValue == null || numericValue == undefined){

    setTotalDataMinus(showCashData)
   // }else{+
   setNumericValue("")
     setShowCashData("")
   // }\\
   
  } else if(modeData == "Card" ){
   
    setTotalDataMinus(showCardData)
    setNumericValue("")
    setShowCardData("")
  } else if(modeData == "Ewallet" ){
    
    setTotalDataMinus(showEwalletData)
    setNumericValue("")
    setShowEwalletData("")
  } else if(modeData == "Credit" ){
  
    setTotalDataMinus(showCreditData)
    setNumericValue("")
    setShowCreditData("")
  }else{
   //  setShowCreditData
    
   // setShowCardData("")
  }
 }
//  console.log(showCreditData.length,"showCreditData");

const alertPaymentBalance = () => {
    confirmAlert({
      customUI: ({ onClose }) => {

        return (
          <div className="alert" style={{ background: '#2d5f2e', color: '#ffe77b' }}>
            <h1 className="alert__title"> {`Balance : ${billamnt != null && billamnt != undefined && billamnt.length > 0 && parseInt(totalBalanceAmountData)-Math.round(billamnt)}`}</h1>
            {/* <p className="alert__body">You want to delete this file?</p> */}
            <button
              // onClick={printStatement(i,amount)}
              onClick={() => {
                confirmDataTosend();
                onClose();
              }}
              className="alert__btn alert__btn--no" style={{
                padding: '10px',
                color: '#2d5f2e',
                background: '#ffe77b',
                fontWeight: 'bold',
                borderRadius: '5px',
                width: '100%'
              }}>{'OK'}</button>
            {/* <button
          onClick={() => {
            this.handleClickDelete();
            onClose();
          }}
          className="alert__btn alert__btn--yes"
        >
          Yes, Delete it!
        </button> */}
          </div>
        );
      },
      // onKeypressEscape:''
      // keyCodeForClose:'13',
      closeOnClickOutside: false,
      closeOnEscape: false,
      overlayClassName: "overlayClassNamePopup",
    });
  }




 const confirmDataTosend = () =>{
   var cashpaymode = showCashData.length > 0 ? "1" :""
   var cardMode = showCardData.length > 0 ? "2" :""
   var creditpaymode = showCreditData.length > 0 ? "3" :""
   var ewalletMode = showEwalletData.length > 0 ? "4" :""

  //  console.log((cashpaymode+cardMode+creditpaymode+ewalletMode).length,"ddd");
  if((cashpaymode+cardMode+creditpaymode+ewalletMode).length > 0 && (cashpaymode+cardMode+creditpaymode+ewalletMode).length < 3){
    // console.log("hfbvi");
    getdata()
    printStatement(parseInt(totalBalanceAmountData-billamnt),cashpaymode+cardMode+creditpaymode+ewalletMode)

  }else{
    alert("Please Check Payment Should be only in two methods")
  }
 }

 const getdata = () => {
  //POST json
  let dataToSend = {
    // wanip: tokenCreate[0].Wanip,
    // dbusr: tokenCreate[0].DBUser,
    // dbpwd: tokenCreate[0].DBPwd,
    // dbnam: tokenCreate[0].DBname,

    wanip: "mysql.amepos.in",
    dbusr: "user_amepos",
    dbpwd: 'bismillah$12',
    dbnam: "amepos_10",
    token:localStorage.getItem('token'),
  };
  let formBody = [];
  for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
    method: "POST",
    body: formBody,
    headers: {
      //Header Defination
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
      //alert(JSON.stringify(responseJson));
      console.log(responseJson);
      if (responseJson.clientdtlArr[0].clientdtl != 2) {
        setCompanyDetails(responseJson.clientdtlArr[0])
        setTableHeaderSettings(responseJson.clientdtlArr[0].billformatdetails[1].status)
        if (responseJson.clientdtlArr[0].denominations != undefined && responseJson.clientdtlArr[0].denominations != null && responseJson.clientdtlArr[0].denominations.length > 0) {
          var itemDenomination = responseJson.clientdtlArr[0].denominations.split(",")
          setDenominations(itemDenomination)
        }
        
        if(responseJson.clientdtlArr[0].billformatdetails[4].status == '1'){
          setShowbillFooter(true)
          }else{
            setShowbillFooter(false)
          }
        if(responseJson.clientdtlArr[0].billformatdetails[0].status == '1'){
        setShowHeaderSettings(true)
        }else{
          setShowHeaderSettings(false)
        }
        if(responseJson.clientdtlArr[0].billformatdetails[3].status == '1'){

          setShowTaxDetails(true)
        }else{
          setShowTaxDetails(false)

        }
        if(responseJson.clientdtlArr[0].billformatdetails[2].status == '1'){

          setBillBodyContent(true)
        }else{
          setBillBodyContent(false)

        }
        localStorage.setItem('billNo',responseJson.clientdtlArr[0].billno);
        if(responseJson.clientdtlArr[0].denominations != undefined && responseJson.clientdtlArr[0].denominations != null && responseJson.clientdtlArr[0].denominations.length > 0){
          var itemDenomination  = responseJson.clientdtlArr[0].denominations.split(",")
          setDenominations(itemDenomination)
        }
      
      } else {
        console.log("Please check your email id or password");
        alert("Sorry No Data");
      }
    })
    //If response is not in json then in error
    .catch((error) => {
      //alert(JSON.stringify(error));
      console.error(error);
    });
};

 const printStatement = async (i,b) => {
  // await goToPayment()
  // await handleClickLastReceipt();
    // await getDataUsingPost();

      
      if(localStorage.getItem('tableno') != "CT"){
    await goToKot(i,b)
      }else{
         
        console.log("Please check your email id or password");
        // getClear()
        var  discount = "";
        var payno = '';
        var nameonCardData = '';
        var balanceData = '';
        var cardno = '';
        var customerId = '';
        if(localStorage.getItem("Customerid") != null && localStorage.getItem("Customerid") != undefined){
          customerId = localStorage.getItem("Customerid")
          console.log( customerId,"Customerid");
        }else{
          customerId = 1
          console.log( customerId,"Customerid")
        }
        if(discountPrecentage != null && discountPrecentage != undefined){
          discount = discountPrecentage
        }else{
          discount = '0.00'
        }

      if(activeBillId.paymentMode == 'CASH'){
        cardno= '';
        payno = '1';
        balanceData =Box
        nameonCardData = '';
      }else if(activeBillId.paymentMode == 'CARD'){
        payno = '2';
        balanceData =totalamount.toFixed(2);
      
        cardno = cardNumber;
        nameonCardData = nameonCard;
      }else if(activeBillId.paymentMode == 'EWALLET'){
        payno = '4'
        nameonCardData = '';
        cardno= '';
      }
      var blc = totalBalanceAmountData-billamnt
      console.log(balance,i,'balance');
      localStorage.setItem('payno',b)
      localStorage.setItem('Box',blc)
      localStorage.setItem('balance',i)
      // localStorage.setItem('CardNumberData',cardNumberTextData )
      localStorage.setItem('nameonCard',nameonCardData)
      localStorage.setItem('totalamount',totalamount)
      localStorage.setItem('cardNumber',cardNumberTextData)
      localStorage.setItem('discount',discount)
      localStorage.setItem('totalBalanceAmountData',totalBalanceAmountData)
      
localStorage.setItem('showCardData',showCardData)
localStorage.setItem('showCashData',showCashData)
      localStorage.setItem('custId',customerId)
       goToPayment()

      
      }
}

const goToKot = (i,b) =>{
  let dataToSend = {
    token: localStorage.getItem('token'),
   
    tabno: localStorage.getItem('tableno'),
  };
  let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
    .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        console.log(responseJson,"kot");
        
        if (responseJson.PrintKotArr[0].PrintKot != 2) {
          setKotData(responseJson)
         
  let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
  var item = [
    {
      link: link,
      tokenCreate: localStorage.getItem('token'),
    },
  ];
  var  discount = "";
  var payno = '';
  var nameonCardData = '';
  var balanceData = '';
  var cardno = '';
  var customerId = '';
  
  console.log(balance,balanceAmt,i,'balance2');
  if(discountPrecentage != null && discountPrecentage != undefined){
    discount = discountPrecentage
  }else{
    discount = '0.00'
  }
          if(localStorage.getItem("Customerid") != null && localStorage.getItem("Customerid") != undefined){
            customerId = localStorage.getItem("Customerid")
            console.log( customerId,"Customerid");
          }else{
            customerId = 1
            console.log( customerId,"Customerid")
          }
  if(activeBillId.paymentMode == 'CASH'){
    cardno= '';
    payno = '1';
    balanceData =Box
    nameonCardData = '';
  }else if(activeBillId.paymentMode == 'CARD'){
    payno = '2';
    balanceData =totalamount.toFixed(2);
   
    cardno = cardNumber;
    nameonCardData = nameonCard;
  }else if(activeBillId.paymentMode == 'EWALLET'){
    payno = '4'
    nameonCardData = '';
    balanceData =totalamount.toFixed(2);
    cardno= '';
  }
  var blc = totalBalanceAmountData-billamnt
  console.log(balance,balanceAmt,'balance');

  localStorage.setItem('payno',b)
  localStorage.setItem('Box',blc)
  localStorage.setItem('balance',i)
  
  // localStorage.setItem('CardNumberData',cardNumberTextData )
  localStorage.setItem('showCardData',showCardData)
  localStorage.setItem('showCashData',showCashData)
  localStorage.setItem('nameonCard',nameonCardData)
  localStorage.setItem('totalamount',totalamount)
  localStorage.setItem('cardNumber',cardNumberTextData)
  localStorage.setItem('totalBalanceAmountData',totalBalanceAmountData)
  localStorage.setItem('discount',discount)
  dispatch(fetchTables(item));
  localStorage.setItem('custId',customerId)
getClear();
  handlePrintKOT();
         
        } 
        else{
          
          console.log("Please check your email id or password");
          getClear()
          var  discount = "";
          var payno = '';
          var nameonCardData = '';
          var balanceData = '';
          var cardno = '';
          var customerId = '';
          if(localStorage.getItem("Customerid") != null && localStorage.getItem("Customerid") != undefined){
            customerId = localStorage.getItem("Customerid")
            console.log( customerId,"Customerid");
          }else{
            customerId = 1
            console.log( customerId,"Customerid")
          }
          if(discountPrecentage != null && discountPrecentage != undefined){
            discount = discountPrecentage
          }else{
            discount = '0.00'
          }
  
        if(activeBillId.paymentMode == 'CASH'){
          cardno= '';
          payno = '1';
          balanceData =Box
          nameonCardData = '';
        }else if(activeBillId.paymentMode == 'CARD'){
          payno = '2';
          balanceData =totalamount.toFixed(2);
        
          cardno = cardNumber;
          nameonCardData = nameonCard;
        }else if(activeBillId.paymentMode == 'EWALLET'){
          payno = '4'
          nameonCardData = '';
          cardno= '';
        }
        var blc = totalBalanceAmountData-billamnt
        console.log(balance,i,'balance');
        localStorage.setItem('payno',b)
        localStorage.setItem('Box',blc)
        localStorage.setItem('balance',i)
        localStorage.setItem('nameonCard',nameonCardData)
        localStorage.setItem('totalamount',totalamount)
        localStorage.setItem('cardNumber',cardNumberTextData)
        localStorage.setItem('discount',discount)
        localStorage.setItem('totalBalanceAmountData',totalBalanceAmountData)
        
      // localStorage.setItem('CardNumberData',cardNumberTextData )
  localStorage.setItem('showCardData',showCardData)
  localStorage.setItem('showCashData',showCashData)
        localStorage.setItem('custId',customerId)
         goToPayment()

        }
      })
      .catch((error) => {
        console.error(error);
      });
}


const handleAfterPrintKOT = useCallback(() => {
  goToPayment()
   console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  // dispatch(setActivePage(pageComponents.VIEWTABLES));
}, []);

const handleBeforePrintKOT = useCallback(() => {
  console.log("`onBeforePrint` called"); // tslint:disable-line no-console
}, []);

const handleOnBeforeGetContentKOT = useCallback(() => {
  console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
  setLoadingKOT(true);
  setTextKOT("Loading new text...");

  return new Promise((resolve) => {
    onBeforeGetContentResolveKOT.current = resolve;

    setTimeout(() => {
      // goToPayment();
      setLoadingKOT(false);
      setTextKOT("New, Updated Text!");
      resolve();
    }, 2000);
  });
}, [setLoadingKOT, setTextKOT]);

const reactToPrintContentKOT = useCallback(() => {
  return kotRef.current;
}, [kotRef.current]);


const handlePrintKOT = useReactToPrint({
  // pageShandlePrinttyle={pageStyle}, 


  
  documentTitle: "AwesomeFileName",
  onBeforeGetContent: handleOnBeforeGetContentKOT,
  onBeforePrint: handleBeforePrintKOT,
  
  content: reactToPrintContentKOT,
  onAfterPrint: handleAfterPrintKOT,
  removeAfterPrint: true
});

const getClear = () => {
  //POST json
  let dataToSend = {
    token:localStorage.getItem('token'),
    tabno:  localStorage.getItem('tableno'),
    terminal: localStorage.getItem('TerminalName'),
  };
  let formBody = [];
  for (let key in dataToSend) {
  let encodedKey = encodeURIComponent(key);
  let encodedValue = encodeURIComponent(dataToSend[key]);
  formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
  method: "POST",
  body: formBody,
  headers: {
  //Header Defination
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  })
  .then((response) => response.json())
  //If response is in json then in success
  .then((responseJson) => {
  //alert(JSON.stringify(responseJson));
  console.log(responseJson);
  if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
  const arr = [];
  for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
  arr.push({
  clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
  });
  }
  
  dispatch({type:"discountPrecentage",payload:''})
//  dispatch(setActivePage(pageComponents.VIEWTABLES));
  console.log("HIII");
  } else {
  alert("Sorry No Data");
  //navigation.replace('DrawerNavigationRoutes')
  }
  })
  //If response is not in json then in error
  .catch((error) => {
  //alert(JSON.stringify(error));
  console.error(error);
  });
  };

  const goToPayment = () => {
    const date = new Date();
    console.log(date, "anandgreenangel");
    
    var tablevalue = ''
      if (localStorage.getItem('tableno') == 'CT') {
      //   hsnoconst = localStorage.getItem('hsno')
        tablevalue = `CT-${localStorage.getItem('TerminalName')}`
      } else {
      //   hsnoconst = 0
        tablevalue = localStorage.getItem('tableno')
      }
console.log(numericValue,"numericValue-234");
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    console.log(datetime, date, "anandangelcart");
    //POST json
    const billamnt = cartItemsList.length > 0 && cartItemsList[0].name !="yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
      parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    ).toFixed(2);
    const roundamnt = Math.round(billamnt);
    var gross =
      roundamnt - parseFloat(cartItemsList[cartItemsList.length - 1].gst);
    const round=roundamnt-billamnt
    const num1 = Box;
    console.log(Box, "yyyyy2222222");
    const num2 = roundamnt;
    console.log(roundamnt, "rrrr");
    // add two numbers
    const sum = num1 - num2;
    console.log(sum, "oooooo");
    var payno = '';
    var nameonCard = '';
    var cardno = '';
    var totalamountcard = '';
    var totalamountcash = '';
    if(activeBillId.paymentMode == 'CASH'){
      payno = '1';
      nameonCard = '';
      totalamountcash = localStorage.getItem('totalamount')
    }else if(activeBillId.paymentMode == 'CARD'){
      payno = '2';
      
      cardno = localStorage.getItem('cardno');
      nameonCard = localStorage.getItem('nameonCard');
      totalamountcard = localStorage.getItem('totalamount')
    }else if(activeBillId.paymentMode == 'EWALLET'){
      payno = '4'
      nameonCard = '';
      cardno= '';
      totalamountcash = localStorage.getItem('totalamount')
    }
   
    // HOSTING_ENV="local"
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
      tabno:tablevalue,
      custid: localStorage.getItem("custId"), //or name
      orddte: datetime,
      // localStorage.setItem('totalBalanceAmountData',totalBalanceAmountData)
      payusr: localStorage.getItem('UserID'),
      openin:  localStorage.getItem('TerminalName'),
      paymode: localStorage.getItem('payno'),
      // paymode:"12",
      billamt:  localStorage.getItem('totalamount'),
      round: round,
      paidamt: localStorage.getItem('totalBalanceAmountData'),
      // paidamt:200,
      nameoncard: localStorage.getItem('nameonCard') ,//or emptystring
      cardno:localStorage.getItem('cardNumber'),
      // localStorage.setItem('showCardData',showCardData)
      // localStorage.setItem('showCashData',showCashData)
      card: localStorage.getItem('showCardData'),
      // card:12,
      cash:localStorage.getItem('showCashData'),
      balance:localStorage.getItem('balance'),
      gst:parseFloat(cartItemsList[cartItemsList.length - 1].gst).toFixed(2),
      cess:0,
      gross:gross,
      discount:localStorage.getItem('discount'),
      vat:0
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printbill", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);

 if (responseJson.GetPrintArr[0].GetPrint != 2 && responseJson.GetPrintArr[0].Printtyp != 0 ) {
   const arr = [];
   setBillNoScan(responseJson?.GetPrintArr[0].billno)
   const arryjs = [];
   setPaymentAmountData(responseJson)

   for (let i = 0; i < responseJson.GetPrintArr.length; i++) {
    arryjs.push({
                
      kotno: responseJson.GetPrintArr[i].kotno
    })
   }
   const obj = [...new Map(arryjs.map(item => [JSON.stringify(item), item])).values()];
   console.log(obj,"obj");

   setPaymentAmountDataKOT(obj)
  //  setCancelKotNumber(obj)
  //  printing(text, responseJson.GetPrintArr[0].ipaddress);
   console.log("HIII");
   let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
   var item = [
     {
       link: link,
      //  tokenCreate: tokenCreate,
     },
   ];
   
handlePrint()
   dispatch(fetchTables(item));
   getClear()
 } else {
   console.log("Please check your email id or password");
   alert("Sorry No Data");
 }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  
  const handleAfterPrint = useCallback(() => {
      
    dispatch({type:"discountPrecentage",payload:''});
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    localStorage.removeItem('Box');
     localStorage.removeItem('balance');
     localStorage.removeItem('totalamount');
     localStorage.removeItem('payno');
     localStorage.removeItem('totalBalanceAmountData');
     localStorage.removeItem('nameonCard');
     localStorage.removeItem('discount');
     localStorage.removeItem('cardNumber');
     localStorage.removeItem('CustomerName');
     localStorage.removeItem('Customerid');
     localStorage.removeItem('custId');
     localStorage.removeItem('showCardData');
     localStorage.removeItem('showCashData');
     
     dispatch(setActivePage(pageComponents.BILLING));
     dispatch(cartItems())
     // localStorage.setItem('showCardData',showCardData)
     // localStorage.setItem('showCashData',showCashData)
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrint = useCallback(() => {
    getdata()
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 


    
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    
    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

    return (

      <div >
        <div style={{display:'flex'}}>
         <PageTitle titleHeader="Split Payment"  >
            <h4>Can Split Payment to 2 Different types</h4>
          </PageTitle>
          <div style={{display:'flex'}}>
  <div class=" ml-5" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
  <div class="ml-4">
    <h2 class="display-5 ">Amount Due</h2>
    {/* ml-5
ml-5
ml-5
ml-5
ml-5 */}
  </div>
  <div class="ml-4">
    <h1 class="text-danger display-4 ">{billamnt != null && billamnt != undefined && billamnt.length > 0 && Math.round(billamnt)-parseInt(totalBalanceAmountData)}</h1>
  </div>
  
</div>
<div class="ml-5" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
<div class="ml-4">
    <h2 class="display-5 ">Amount Paid</h2>
  </div>
  <div class="ml-4">
    <h1 class="text-success display-4 ">{totalBalanceAmountData}</h1>
  </div>
</div>

</div></div>
          <div class="row  ">
          <div class="col-12 col-sm-6 d-none d-sm-flex flex-column ">

         
{/* <div class="row ml-5 pl-3 mt-5 flex-grow-1">
  <div class="col-md-6">
    <h4>Total Amount </h4>
  </div>
  <div class="col-md-6">
    <h4>562.00</h4>
  </div>
</div>
<div class="row ml-5 pl-3  flex-grow-1">
  <div class="col-md-6">
    <h4>With Card</h4>
  </div>
  <div class="col-md-6">
    <h4>500.00</h4>
  </div>
</div>
<div class="row ml-5 pl-3   flex-grow-1">
  <div class="col-md-6 ">
    <h4>With Cash</h4>
  </div>
  <div class="col-md-6">
    <h4>62.00</h4>
  </div>
</div> */}

{/* <div class="row ml-5 pl-3 mt-5  flex-grow-1">
  <div class="col-md-6">
    <h2 class="display-5">Amount Due</h2>
  </div>
  <div class="col-md-6">
    <h2 class="display-5">Amount Paid</h2>
  </div>
</div>
<div class="row ml-5 pl-3 flex-grow-1">
  <div class="col-md-6">
    <h1 class="text-danger display-4">500.00</h1>
  </div>
  <div class="col-md-6">
    <h1 class="text-success display-4">62.00</h1>
  </div>
</div> */}
{/* row */}
<div class=" ml-5 pl-3 mt-5  flex-grow-1">
  <div class=" mt-3" style={{display:'flex'}}>
    <button className="btn btn-lg btn-warning btn-block btn-block-height " onClick={()=>setModeData("Cash")} style={{width:'25%',marginRight:'20%'}}>

      Cash
    </button>
    <h1>{showCashData != null && showCashData != undefined && showCashData}</h1>
  </div>
  <div class=" mt-3" style={{display:'flex'}}>
    <button className="btn btn-lg btn-warning btn-block btn-block-height " onClick={()=>setModeData("Card")} style={{width:'25%',marginRight:'20%'}}>

      Card
    </button>
    <h1>{showCardData != null && showCardData != undefined && showCardData}</h1>
    {showCardData != null && showCardData != undefined && showCardData.length > 0 ? <input style={{width:'35%',border:'2px solid',borderRadius:'5px',marginLeft:'20px'}} onChange={(e)=>setCardNumberTextData(e.target.value)}/>:""}
  </div>
  {/* <div class="col-md-6">

  </div>
</div>
<div class="row ml-5 pl-3 mt-3 mb-4  flex-grow-1"> */}
  {/* <div class=" mt-3" style={{display:'flex'}}>
    <button className="btn btn-lg btn-warning btn-block btn-block-height " onClick={()=>setModeData("Credit")} style={{width:'25%',marginRight:'20%'}}>

      Credit
    </button>
    <h1>{showCreditData != null && showCreditData != undefined && showCreditData}</h1>
  
  </div> */}
  <div class=" mt-3" style={{display:'flex'}}>
    <button className="btn btn-lg btn-warning btn-block btn-block-height " onClick={()=>setModeData("Ewallet")} style={{width:'25%',marginRight:'20%'}}>
      E-wallet
    </button>
    <h1>{showEwalletData != null && showEwalletData != undefined && showEwalletData}</h1>
    
  
  </div>
  {showEwalletData != null && showEwalletData != undefined && showEwalletData.length > 0 ?
   <div style={{display:'flex'}}>
   <div class="row flex-grow-1 " >
   <div class="col-md-12 align-middle " style={{display:'flex',justifyContent:'space-between'}} >
   {ewalletModeData?.map((i)=>(
 <button className="border-0 btn-ewallet p-4 "  onClick={()=>setType(i.ewallet)} style={{
  backgroundImage: `url(data:image/png;base64,${i.image})`,
  backgroundSize:'100%',
  backgroundRepeat:'no-repeat',
  marginBottom:'10px'
 }} >
   {/* { (i.ewallet)} */}
 </button>
   ))}
    
     
{/* {ewalletModeData?.length > 0 && ewalletModeData?.map((i)=>(
  
  <button type="button" class="border-0 btn-ewallet p-4 " onClick={()=>setType(i.ewallet)} style={{
    backgroundImage: `url(data:image/png;base64,${i.image})`,
    backgroundSize:'100%',
    backgroundRepeat:'no-repeat',
    marginBottom:'10px'
  }}>
  </button>
  ))
    } */}
     {/* <button type="button" class="border-0 btn-ewallet p-4 bg-e-wallet-pp shadow-sm" onClick={()=>setType("PhonePay")}>

     </button> */}
   </div>
 </div>
 {/* <div class="row flex-grow-1 " >
   <div class="col-md-12 align-middle text-center">
     <button type="button" class="border-0 btn-ewallet p-4 bg-e-wallet-gpay shadow-sm" onClick={()=>setType("GooglePay")}> </button>
   </div>
 </div>
 <div class="row flex-grow-1 " >
   <div class="col-md-12 align-middle text-center">
     <button type="button" class="border-0 btn-ewallet p-4 bg-e-wallet-paytm shadow-sm" onClick={()=>setType("Paytm")}> </button>
   </div>
 </div>  */}
</div>
  
  :"" }
  <div class="col-md-6 mt-3">
     <button className="btn btn-lg btn-florogreen w-50 btn-block-height " onClick={() => dispatch(backToPreviousPage())}>
      Back
    </button>
  </div>
</div>
</div>
<div class="col col-sm-6 d-none d-sm-flex flex-column">
<div class="row ">
{/* m-5 */}
  <div class="col-md-3 ">
  {/* mt-5 */}
  </div>
  {/* mt-5 */}
  <div class="col-md-8  ">
  <div class="btn-group-vertical  " role="group"  >

      <div class="row text-center my-4 flex-wrap w-100">
        <div class=" w-100">
          <input class="text-center form-control-lg square-edges padding__5 inputCardNumber border" type="text" value={numericValue} />
        </div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
        <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
        <div class="col-4 padding__4 border bg-success text-light" onClick={() => saveNumberData()}><strong>Enter</strong></div>
        

        <div class="w-100 mt-5"></div>
        <div class="col-6 padding__4 border calcButtons btn-danger" onClick={() => clearAllCancel()}>Clear</div>
        {/* <div class="col-4 padding__4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div> */}
        <div class="col-6 padding__4 border btn-florogreen w-50" onClick={() => alertPaymentBalance()}><strong>Confirm</strong></div>
        
      </div>





</div>
  </div>
  <div class="col-md-1"></div>
</div>
{/* <div class="row mt-5 ">

  <div class="col-md-12 mt-5">
    <div class="form-group">
      <h2 class="display-5">Card Number </h2>
      <input class="text-center btn-block inputCardNumber  " required autocomplete="off" />
    </div>

  </div>

</div>
<div class="row">

  <div class="col-md-12">
    <button class="btn btn-infogreen btn-lg btn-block  p-4"><strong>Enter</strong></button>
  </div>

</div> */}

</div>
          </div>
          <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket  font-face-gm" >
           {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
                    <div style={{textAlign:'center',justifyContent: 'center', textAlign: 'center', alignItems: 'center',margin:'0px'}}>
                      <img src={`data:image/png;base64,${LogoAme}`} style={{height: '80px', width: '120px'}} />
                      </div>
                    }
           {/* {LogoAme.length >0 && LogoAme != null && LogoAme != undefined &&
                        <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '50%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />

                      } */}
               <p className="centered" style={{fontSize:'14px' ,borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>{companyDetails?.companynam}</p>
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.addr1}{companyDetails?.addr2}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>PH NO:{companyDetails?.addr3}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST No:{companyDetails?.gstno}</p>
               {
        showHeaderSettings == true ?
           
          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TAX INVOICE</p>
          :
          <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>BILL RECEIPT</p>
         
      }
               {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>BILL RECEIPT</p> */}
               <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>Bill No :  {localStorage.getItem('TerminalName')} - {paymentAmountData?.GetPrintArr?.length > 0 && paymentAmountData?.GetPrintArr[0].billno}</p>
               
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Date :{datetime != null && datetime != undefined ? datetime :""}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Customer : {paymentAmountData?.GetPrintArr.length > 0 && paymentAmountData?.GetPrintArr[0].customer}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  
               </div>
             
               {
        tableHeaderSettings == '1' && billBodyContent == true ?
          <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

              {/* </tr> */}

            </div>
            <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
              {
                paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                paymentAmountData?.GetPrintArr?.map((item, index) =>

                (
                  <div>

                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                    </div>
                  </div>
                ))

              }

            </div>
          </div>
          :

          tableHeaderSettings == '1' && billBodyContent == false ?
            <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                  <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                </tr>

              </thead>
              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                {
                  paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                  paymentAmountData?.GetPrintArr?.map((item, index) =>

                  (
                    <tr  >
                      <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                      {/* {item?.TAX1 != '0.00' ? 
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
         : (
          item?.TAX2 != '0.00' ? 
          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
         :
         <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
         
         )
        } */}
                      <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                    </tr>
                  ))

                }

              </tbody>
            </table>
            :

            tableHeaderSettings == '2' && billBodyContent == true ?
              <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                  {/* </tr> */}

                </div>
                <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                  {
                    paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                    paymentAmountData?.GetPrintArr?.map((item, index) =>

                    (
                      <div>

                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                        </div>
                      </div>
                    ))

                  }

                </div>
              </div>
              :

              tableHeaderSettings == '2' && billBodyContent == false ?
                <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                      {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                      <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                    </tr>

                  </thead>
                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    {
                      paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                      paymentAmountData?.GetPrintArr?.map((item, index) =>

                      (
                        <tr  >
                          <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity).toFixed(2)}</td>

                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                          {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                          <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                        </tr>
                      ))

                    }

                  </tbody>
                </table>
                :
                tableHeaderSettings == '3' && billBodyContent == true ?
                <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width:  '25%' }}>PRODUCT</div>
                    <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', }}>QTY</div>
                    <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40%', textAlign: 'end' }}>TOTAL</div>


                  </div>
                  <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                    {
                      paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                      paymentAmountData?.GetPrintArr?.map((item, index) =>

                      (
                        <div>

                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}> </div>
                            <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                            <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '40%', wordBreak: 'break-all' }}>{item?.amount}</div>
                          </div>
                        </div>
                      ))

                    }

                  </div>
                </div>

                :
                tableHeaderSettings == '3' && billBodyContent == false ?
                  <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                        <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                        {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th> */}
                        {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                        <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                      </tr>

                    </thead>
                    <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      {
                        paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                        paymentAmountData?.GetPrintArr?.map((item, index) =>

                        (
                          <tr  >
                            <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                            {/* <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td> */}
                            {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                            <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.amount}</td>

                          </tr>
                        ))

                      }

                    </tbody>
                  </table>
                  :
                  tableHeaderSettings == '4' && billBodyContent == true ?

                    <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', }}>QTY</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px' }}>RATE</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>SST</div>

                        <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', textAlign: 'end' }}>TOTAL</div>

                        {/* </tr> */}

                      </div>
                      <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                        {
                          paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                          paymentAmountData?.GetPrintArr?.map((item, index) =>

                          (
                            <div>

                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                                <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px', wordBreak: 'break-all' }}>{item?.unitprice}</div>
                                {
                                  item?.TAX1 != '0.00' ?
                                    <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX1}</div>
                                    : (
                                      item?.TAX2 != '0.00' ?
                                        <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX2}</div>
                                        :
                                        item?.TAX3 != '0.00' ?
                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX3}</div>
                                          :
                                          '0.00'
                                    )
                                }
                                <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '50px', wordBreak: 'break-all' }}>{item?.amount}</div>
                              </div>
                            </div>
                          ))

                        }

                      </div>
                    </div>

                    :
                    tableHeaderSettings == '4' && billBodyContent == false ?
                      <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <thead>
                          <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            <th className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th>

                            <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                          </tr>

                        </thead>
                        <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                          {
                            paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&

                            paymentAmountData?.GetPrintArr?.map((item, index) =>

                            (
                              <tr  >
                                <td className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', wordBreak: 'break-all' }}>{item?.ProductName}</td>
                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                {item?.TAX1 != '0.00' ?
                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
                                  : (
                                    item?.TAX2 != '0.00' ?
                                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
                                      :
                                      item?.TAX3 != '0.00' ?
                                        <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
                                        :
                                        '0.00'
                                  )
                                }
                                <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                              </tr>
                            ))

                          }

                        </tbody>
                      </table>

                      :
                      ''
      }

                        <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Total Items :{paymentAmountData?.GetPrintArr?.length > 0  ?(paymentAmountData?.GetPrintArr?.length) :0  }</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>Total Qty:{cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].qty ?parseFloat(cartItemsList[cartItemsList?.length - 1].qty):0  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Sub Total :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].subTotal ?cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2) : "0.00"  }</p>
           
               </div>
               {showTaxDetails == true &&  paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.gstper != null && item.gstper != undefined && item.gstper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.gstper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.gst.length > 0  &&  item.gst ? item.gst : "0.00"  }</p>
              
             </div>
                )
               
               )}
                                     {showTaxDetails == true && paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
                         paymentAmountData?.GetPrintArr[0].TAX1pergrp != null &&  paymentAmountData?.GetPrintArr[0].TAX1pergrp != undefined && paymentAmountData?.GetPrintArr[0].TAX1pergrp?.map((i)=>(
                          i.tax1name==="GST" ?
                          <>
                          <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                            {`CGST${parseInt(i.tax1per)/2}%`}
                            </p>
                            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                              {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt)/2 : "0.00"}</p>

                          </div>
                           <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                           <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                                                       {`SGST${parseInt(i.tax1per)/2}%`}
                                                       </p>
                                                       <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                                                         {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt)/2 : "0.00"}</p>
                           
                                                     </div></>
                                                     :
                                                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                            {`${i?.tax1name}${parseInt(i?.tax1per)}%`}
                            </p>
                            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                              {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                          </div>
                         ))}

{showTaxDetails == true && paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
                         paymentAmountData?.GetPrintArr[0].TAX2pergrp != null &&  paymentAmountData?.GetPrintArr[0].TAX2pergrp != undefined && paymentAmountData?.GetPrintArr[0].TAX2pergrp?.map((i)=>(
                          i.tax2name==="GST" ?
                          <>
                          <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                            {`CGST${parseInt(i.tax2per)/2}%`}
                            </p>
                            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                              {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt)/2 : "0.00"}</p>

                          </div>
                           <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                           <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                                                       {`SGST${parseInt(i.tax2per)/2}%`}
                                                       </p>
                                                       <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                                                         {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt)/2 : "0.00"}</p>
                           
                                                     </div></>
                                                     :
                                                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                            {`${i?.tax2name}${parseInt(i?.tax2per)}%`}
                            </p>
                            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                              {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                          </div>
                         ))}

{showTaxDetails == true && paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length > 0 &&
                         paymentAmountData?.GetPrintArr[0].TAX3pergrp != null &&  paymentAmountData?.GetPrintArr[0].TAX3pergrp != undefined && paymentAmountData?.GetPrintArr[0].TAX3pergrp?.map((i)=>(
                          i.tax3name==="GST" ?
                          <>
                          <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                            {`CGST${parseInt(i.tax3per)/2}%`}
                            </p>
                            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                              {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt)/2 : "0.00"}</p>

                          </div>
                           <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                           <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                                                       {`SGST${parseInt(i.tax3per)/2}%`}
                                                       </p>
                                                       <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                                                         {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt)/2 : "0.00"}</p>
                           
                                                     </div></>
                                                     :
                                                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

<p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                            {`${i?.tax3name}${parseInt(i?.tax3per)}%`}
                            </p>
                            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                              {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                          </div>
                         ))}
               {/* { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.vatper != null && item.vatper != undefined && item.vatper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.vatper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.vat.length > 0  &&  item.vat ? item.vat : "0.00"  }</p>
              
             </div>
                )
               
               )} */}
               {showTaxDetails == true &&  paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr?.map((item, index) => 
                (item.cessper != null && item.cessper != undefined && item.cessper != '0.00' &&
                <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`GST${item.cessper}%`}</p>
              
                <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {item.cess.length > 0  &&  item.cess ? item.cess : "0.00"  }</p>
              
             </div>
                )
               
               )}
               

               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Discount</p>
                  {/* localStorage.getItem('discount') != null && */}
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} { localStorage.getItem('discount') == '' ?  '0.00':localStorage.getItem('discount')}</p>
           
               </div>

               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',color:'black'}}>Total</p>
                  
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {totalamount != null && totalamount != undefined ? totalamount.toFixed(2) :0 }</p>
           
               </div>
              
               {showbillFooter == true &&  paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr[0]?.cash != '0.00' ?
               
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse',borderTop:'1px dotted black'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASH</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {paymentAmountData?.GetPrintArr.length >0 && paymentAmountData?.GetPrintArr[0].card  ? paymentAmountData?.GetPrintArr[0].cash  :"0.00"}</p>
           
               </div>
               : 
               ""}
                {showbillFooter == true &&  paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr[0]?.card != '0.00'  ?
                <>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CARD</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {paymentAmountData?.GetPrintArr.length >0 && paymentAmountData?.GetPrintArr[0].card  ? paymentAmountData?.GetPrintArr[0].card  :"0.00"}</p>
           
               </div>
               </>
                
                :""}
                {showbillFooter == true && showEwalletData != null && showEwalletData != undefined && showEwalletData.length > 0  ?
                <>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>{`Ewallet(${type != "" && type?.length > 0 ? type :''})`}</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {paymentAmountData?.GetPrintArr.length >0 ? (parseInt(paymentAmountData?.GetPrintArr[0].cashpayment) - parseInt(paymentAmountData?.GetPrintArr[0].cash)- parseInt(paymentAmountData?.GetPrintArr[0].card))  :"0.00"}</p>
           
               </div>
               </>
                
                :""}
               {showbillFooter == true && paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr[0]?.change ? 
               
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CHANGE</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {paymentAmountData?.GetPrintArr.length >0 && paymentAmountData?.GetPrintArr[0].change  ? paymentAmountData?.GetPrintArr[0].change  :"0.00"}</p>
           
               </div>
              : ""}
                
                
                {/* <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CHANGE</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {localStorage.getItem('balance') != null && localStorage.getItem('balance') != undefined ? localStorage.getItem('balance') :'0.00'}</p>
           
               </div> */}
                {/* { paymentAmountData?.GetPrintArr !== undefined && paymentAmountData?.GetPrintArr !== null && paymentAmountData?.GetPrintArr?.length >0 &&
                paymentAmountData?.GetPrintArr[0].Ewallet != '0.00'   ?
                <>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse',borderTop:'1px dotted black'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>EWALLET {ewalletType && ewalletType}</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {totalamount != null && totalamount != undefined ? totalamount.toFixed(2) :0 }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CHANGE</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {localStorage.getItem('balance') != null && localStorage.getItem('balance') != undefined ? localStorage.getItem('balance') :'0.00'}</p>
           
               </div></>
                
                :''} */}
                <div style={{display:'flex',borderTop:'1px dotted black',borderCollapse:'collapse',justifyContent:'center'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No's :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}> {paymentAmountDataKOT?.length > 0 ? paymentAmountDataKOT.map((i)=>`${i.kotno},`):""}</p>

               </div>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black' ,borderTop:'1px dotted black'}}>{companyDetails?.remarks1}</p>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.remarks2}</p>
              
               <img ref={inputRef} style={{ height: '20%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />

                    </div>
                </div>
                <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={kotRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " >
               <p className="centered" style={{fontSize:'14px' ,borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>Table No. {localStorage.getItem('tableno')}</p>
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

             
                        <table className="centered" style={{borderCollapse:'collapse'}}>
                           
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                  kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length >0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                                  kotData?.PrintKotArr[0].ItemsArray.map((item, index) => 
                                       
                                       (
                                       <tr  >
                                           <td className='description'   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                           <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                           
                                           {/* <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                           <td className="price"   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{item?.amount}</td> */}
                           
                                       </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
            <p className="centered" style={{fontSize:'12px',borderTop:'1px dotted black' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{"<<< AME>>>"}</p>
              

                    </div>
                </div>
                </div>
           </div>

      </div>



    );
  }

