import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';




const PRODUCTS = gql`
query GetItems($count:Int)
{
products(first: $count) {
  edges {
    node {
      id
      name
      description
    }
  }
}
}
`;


const billsAdapter = createEntityAdapter();
const initialState = billsAdapter.getInitialState({
  status: 'idle',
  error: null,
  activeBillID: 1
});

export const fetchBills = createAsyncThunk('tables/fetchBills', async () => {

  const response = await client
    .query({ query: PRODUCTS, variables: { "count": 20 } });

  return response.data;

})

export const billsSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {

    addBill(state, action) {
      billsAdapter.addOne(state, action.payload);
    },
    updateBill(state, action) {
      billsAdapter.updateOne(state, action.payload);
    },
    addBillItem(state, action) {
      const billID = action.payload.id
      const newLineitem = action.payload.lineitem
      const bill = state.entities[billID]
      const existingLineitems = bill ? bill.lineitems : undefined;
      const existingLineItem = existingLineitems.filter(a => a.id === newLineitem.id)

      if (existingLineItem.length > 0) {

        Object.values(existingLineitems).forEach((item) => {
          if (item.id === newLineitem.id) {
            item.qty = item.qty + 1;
            item.itemprice = (item.qty + 1) * item.uomprice;
            item.isselected = true;
          }
          else {
            //unselected all other items
            item.isselected = false
          }

        })

      }
      else {
        //unselected all other items
        Object.values(existingLineitems).forEach((billItem) => {
          billItem.isselected = false
        })

        existingLineitems.push(newLineitem);


      }



    },
    updateQty(state, action) {
      const billID = action.payload.billid
      const lineItemid = action.payload.id
      const bill = state.entities[billID]
      const existingLineitems = bill ? bill.lineitems : undefined;
      const existingLineItem = existingLineitems.filter(a => a.id === lineItemid)

      if (existingLineItem.length > 0) {


        Object.values(existingLineitems).forEach((item, index) => {


          if (item.id === lineItemid) {
            if (action.payload.qty === 0) {
              existingLineitems.splice(index, 1);
              return;
            } else { 
              item.qty = action.payload.qty;
              item.itemprice = action.payload.qty * item.uomprice;
              item.isselected = true;
            }
          }
          else {
            //unselected all other items
            item.isselected = false
          }

        });
      }         



    },
    addRemarks(state, action) {
       
      const billID = action.payload.billid
      const lineItemid = action.payload.id
      const bill = state.entities[billID]
      const existingLineitems = bill ? bill.lineitems : undefined;
      const existingLineItem = existingLineitems.filter(a => a.id === lineItemid)

      if (existingLineItem.length > 0) {


        Object.values(existingLineitems).forEach((item, index) => {


          if (item.id === lineItemid) {
           
              item.qty = action.payload.remarks;
              item.itemprice = action.payload.qty * item.uomprice;
              item.isselected = true;
             
          }
          else {
            //unselected all other items
            item.isselected = false
          }

        });
      }      

    },
    emptyCart(state, action) {
      const billID = action.payload.billid
       
      const bill = state.entities[billID]
       if(bill) 
        bill.lineitems=[];

      

    },
    selectItem(state, action) {
      const billID = action.payload.billid
      const lineItemid = action.payload.id
      const bill = state.entities[billID]
      const existingLineitems = bill ? bill.lineitems : undefined;
      const existingLineItem = existingLineitems.filter(a => a.id === lineItemid)

      if (existingLineItem.length > 0) {


        Object.values(existingLineitems).forEach((item, index) => {


          if (item.id === lineItemid) {
           
              item.isselected = true;
            
          }
          else {
            //unselected all other items
            item.isselected = false
          }

        });
      }      
      
    },


  },
  extraReducers: {
    [fetchBills.pending]: (state, action) => {

      state.status = 'loading'
    },
    [fetchBills.fulfilled]: (state, action) => {

      state.status = 'succeeded'
      // Add any fetched posts to the array
      
      console.log(action.payload);
      billsAdapter.removeAll(state);
      action.payload.products.edges.map(({ node }) => (

        billsAdapter.addOne(state, node)

      ))
      //state.tables = state.tables.concat(action.payload)
    },
    [fetchBills.rejected]: (state, action) => {

      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const { updateBill, addBill, addBillItem, updateQty, selectItem,emptyCart,addRemarks} = billsSlice.actions;

export default billsSlice.reducer;
export const { selectAll: selectAllBills, selectById: selectBillById } = billsAdapter.getSelectors((state) => state.bills);


//export const selectAllBillItems = (state) => state.items.billItems;
