import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
  } from "@reduxjs/toolkit";
  // import { BillingItem } from "../Models/BillingItem";
  import { client } from "../utilities/client";
  import { gql } from "@apollo/client";
  
  // const apolloFetch = createApolloFetch({ uri });
  // const variables = {
  // "count": 5customers
  // };
  
  const customerAdapter = createEntityAdapter();
  const initialState = customerAdapter.getInitialState({
  status: "idle",
  error: null,
  productList: null,
  });
  
  export const customerItems = createAsyncThunk(
  "customers/customerItems",
  async (item) => {
  let dataToSend = {
  token:localStorage.getItem('token'),
    
  
  };
  let formBody = [];
  for (let key in dataToSend) {
  let encodedKey = encodeURIComponent(key);
  let encodedValue = encodeURIComponent(dataToSend[key]);
  formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const response = await fetch(
  "https://superadmin.amepos.io/TabPosApiNew/tabpos_getcustomer",
  {
  method: "POST",
  headers: {
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  body: formBody,
  }
  );
  
  // console.log("data list-----------------",response.json());
  return response.json();
  }
  );
  
  // const response = await client.headers( {Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5MzA5MDgsIm93bmVyIjoic2FsZW9yIiwiZXhwIjoxNjI2OTMxMjA4LCJ0b2tlbiI6Iko4MFRBMEE5RjBmTyIsImVtYWlsIjoib25saW5lYWNjb3VudHNAYW1lcG9zLmluIiwidHlwZSI6ImFjY2VzcyIsInVzZXJfaWQiOiJWWE5sY2pveCIsImlzX3N0YWZmIjp0cnVlfQ.x_0NziiFpPnVkD87fopROrjt4NhelI38jvHdmT2wWJs'}).query({ query: PRODUCTS, variables: { "category" : "Q2F0ZWdvcnk6Nw==" } });
  // console.log("data items list",response.data);
  // return response.data;
  
  export const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
  searchTerm: (state, action) => {
  state.searchTerm = action.payload;
  },
  searchAllItemsRemove(state, action) {
  // const products = state.entities[action.payload.id]
  
  customerAdapter.removeAll(state);
  },
  },
  extraReducers: {
  [customerItems.pending]: (state, action) => {
  state.status = "loading";
  },
  [customerItems.fulfilled]: (state, action) => {
  state.status = "succeeded";
  state.productList = action.payload;
  
  customerAdapter.removeAll(state);
  console.log("====================================");
  console.log(action.payload);
  console.log("====================================");
  
  const CustomerArr = action.payload.CustomerArr;
  const arr = [];
  
  for (let i = 0; i < CustomerArr.length; i++) {
  arr.push({
  id: i.toString(),
  custid: CustomerArr[i].custid,
  custnam: CustomerArr[i].custnam,
  phno: CustomerArr[i].phno,
  dob: CustomerArr[i].dob,
  addr1: CustomerArr[i].addr1,
  addr2: CustomerArr[i].addr2,
  addr3: CustomerArr[i].addr3,
  addr4: CustomerArr[i].addr4,
  addr5: CustomerArr[i].addr5,
  email: CustomerArr[i].email,
  Customer: CustomerArr[i].Customer
  });
  }
  console.log("====================================");
  console.log(arr);
  console.log("====================================");
  arr.map((node) => customerAdapter.addOne(state, node));
  
  // console.log("ddddddddddddddddddd",action.payload.data.products.edges);
  // customerAdapter.upsertMany(state, action.payload.data.products.edges);
  },
  [customerItems.rejected]: (state, action) => {
  state.status = "failed";
  state.error = action.error.message;
  },
  },
  });
  export const { searchTerm, searchAllItemsRemove } = customerSlice.actions;
  export default customerSlice.reducer;
  export const {
  selectAll: selectAllCustomer,
  selectEntities: select,
  } = customerAdapter.getSelectors((state) => state.customers);
  
  //
  
  // export const selectItemsForSearch = createSelector(
  // [selectAllItems, (state, searchTerm) => searchTerm],
  // (items, searchTerm) => items.filter((item) => item.name === searchTerm)
  // )
  // export const selectItemsForSearch = (state) => state.items.filter((item) => item.name.includes(searchTerm));