import React, { Component } from 'react';
import { Button, Modal, } from "react-bootstrap";
import '../../utilities/fontawesome';
export class AddOnModal extends Component {
  constructor(props) {
    super(props);

  }

  render() {

    const handleClose = this.props.handleClose;

    return (
      <Modal
        show={this.props.show}
        animation={this.props.animation}
        size="xl" className="shadow-xl border addonsmodal">
        <Modal.Header className="">
          <Modal.Title className="w-100">
            <div class="d-flex">
              <div class="p-2">Addons</div>
              <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 border-0">

          <div class="mb-3">
            < h3 class=" display-6 ">Variant</h3>
            <div class="d-flex flex-row flex-wrap">
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
            
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
              <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                <div class="position-relative btn-block-height btn-block">
                  <div class="position-absolute ">Small</div>
                  <div class="position-absolute align-bottom-right">60ml</div>
                </div>
              </a>
            </div>
          </div>
          <div class="mb-3">
            <h3 class=" display-6 ">Remarks</h3>
            <input type="text" class="inputnonorder-gray btn-block w-75" id="txtRemarks"/>
          </div>
          <div class="mb-3">
            <h3 class=" display-6 ">ADDONS</h3>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Choose You Choice of Bread</label>
              <div class="d-flex flex-row flex-wrap">
                <a class="btn p-2 btn-infogreen btn-variant m-1">
                   
                    <div class="">Small</div>
                    <div class="">60ml</div>
                  
                </a>
                <a class="btn p-2 btn-infogreen btn-variant m-1"  href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
              </div>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Choose You Choice of Topping</label>
              <div class="d-flex flex-row flex-wrap">
                <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                    <div class="">Small</div>
                    <div class="">60ml</div>
                  
                </a>
                <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
              </div>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Extra</label>
              <div class="d-flex flex-row flex-wrap">
                <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                    <div class="">Small</div>
                    <div class="">60ml</div>
                  
                </a>
                <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
               <a class="btn p-2 btn-infogreen btn-variant m-1" href="#">
                   
                   <div class="">Small</div>
                   <div class="">60ml</div>
                 
               </a>
              </div>
            </div>
            <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

          <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
        </Modal.Footer> */}
      </Modal>
    );
  }
}