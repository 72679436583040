export function computeDollars(btc, btcRate) {
    return btc * btcRate;
  }
  
  export function formatDollars(dollars) {
    dollars = (Math.round(dollars * 100) / 100).toFixed(2);
    dollars = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    return dollars;
  }

  export function updateObject(oldObject, newValues) {
    // Encapsulate the idea of passing a new object as the first parameter
    // to Object.assign to ensure we correctly copy data instead of mutating
    return Object.assign({}, oldObject, newValues)
  }
  
  export function updateItemInArray(array, itemId, updateItemCallback) {
    const updatedItems = array.map(item => {
      if (item.id !== itemId) {
        // Since we only want to update one item, preserve all others as they are now
        return item
      }
  
      // Use the provided callback to create an updated item
      const updatedItem = updateItemCallback(item)
      return updatedItem
    })
  
    return updatedItems
  }

  export const printBill = (bill) => {
     alert("printing Here")

}