
import './App.css';
import { Login } from './components/Login/Login';
import { Lock } from './components/Login/Lock';
import { Home } from './components/Home';
import { Splash } from './components/Splash';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React, { Fragment, useState, useEffect } from 'react';
import { LoginForm } from './components/Login/LoginForm';
import { LoginPhp } from './components/Login/LoginPhp';

function App() {
  const [terminalName, setTerminalName] = useState(localStorage.getItem("UserID")?.length > 0 ? true : false);
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("Login")?.length > 0 ? true : false);
  const [isUnLocked, setIsUnLocked] = useState(localStorage.getItem("locked"));

  return (
    < >






      <Router  >
        {/* <Switch> LoginPhp*/}
        <Route  path="/splash" component={Splash} />
        <Route  path="/login" component={LoginPhp} />
        <Route  path="/lock" component={Lock} />
        {/* <Route path="/" component={Home} /> */}
        {/* <Route path="/home/:page" component={Home} /> */}


        {
          authenticated && isUnLocked && terminalName ?
            <>
              <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                return (<Redirect to={`${process.env.PUBLIC_URL}/home/billing`} />)
              }} />

              <Route path="/home/billing" component={Home} />
            </>
            :
            <Redirect to={`${process.env.PUBLIC_URL}/login`} />
        }
        {/* </Switch> */}
      </Router>


    </>
  );
}

export default App;
