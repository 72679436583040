import {
    createSlice,
    createEntityAdapter,
    createSelector,
    createAsyncThunk,
    } from "@reduxjs/toolkit";
    const userAdapter = createEntityAdapter();
    const initialState = userAdapter.getInitialState({
    payno: null,
    });
    
    export const paySlice = createSlice({
    name: "paymode",
    initialState,
    reducers: {
    payno(state, action) {
    console.log("====================================");
    console.log(action.payload, "aaaaaaa");
    console.log("====================================");
    state.payno = action.payload;
    },
    },
    });
    
    export const { payno } = paySlice.actions;
    
    export default paySlice.reducer;