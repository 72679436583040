import React, { Component } from 'react';
import './Backdrop.css';

export class Backdrop extends React.Component {
    render(){
      return(
        <div
          className="backdrop"
          onClick={this.props.close}
        />
      )
    }
  }