import {
    createSlice,
    createEntityAdapter,
    createSelector,
    createAsyncThunk,
    } from "@reduxjs/toolkit";
    // import { BillingItem } from "../Models/BillingItem";
    import { client } from "../utilities/client";
    import { gql } from "@apollo/client";
    
    // const apolloFetch = createApolloFetch({ uri });
    // const variables = {
    // "count": 5customers
    // };
    
    const deliveryBoyAdapter = createEntityAdapter();
    const initialState = deliveryBoyAdapter.getInitialState({
    status: "idle",
    error: null,
    productList: null,
    });
    
    export const deliveryBoyItems = createAsyncThunk(
    "deliveryBoy/deliveryBoyItems",
    async (item) => {
    let dataToSend = {
    token:localStorage.getItem('token'),
      
    
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const response = await fetch(
    "https://superadmin.amepos.io/TabPosApiNew/tabpos_deliveryboylist",
    {
    method: "POST",
    headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
    }
    );
    
    // console.log("data list-----------------",response.json());
    return response.json();
    }
    );
    
    // const response = await client.headers( {Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5MzA5MDgsIm93bmVyIjoic2FsZW9yIiwiZXhwIjoxNjI2OTMxMjA4LCJ0b2tlbiI6Iko4MFRBMEE5RjBmTyIsImVtYWlsIjoib25saW5lYWNjb3VudHNAYW1lcG9zLmluIiwidHlwZSI6ImFjY2VzcyIsInVzZXJfaWQiOiJWWE5sY2pveCIsImlzX3N0YWZmIjp0cnVlfQ.x_0NziiFpPnVkD87fopROrjt4NhelI38jvHdmT2wWJs'}).query({ query: PRODUCTS, variables: { "category" : "Q2F0ZWdvcnk6Nw==" } });
    // console.log("data items list",response.data);
    // return response.data;
    
    export const deliveryBoySlice = createSlice({
    name: "deliveryBoy",
    initialState,
    reducers: {
    searchTerm: (state, action) => {
    state.searchTerm = action.payload;
    },
    searchAllItemsRemove(state, action) {
    // const products = state.entities[action.payload.id]
    
    deliveryBoyAdapter.removeAll(state);
    },
    },
    extraReducers: {
    [deliveryBoyItems.pending]: (state, action) => {
    state.status = "loading";
    },
    [deliveryBoyItems.fulfilled]: (state, action) => {
    state.status = "succeeded";
    state.productList = action.payload;
    
    deliveryBoyAdapter.removeAll(state);
    console.log("====================================");
    console.log(action.payload);
    console.log("====================================");
    
    const CustomerArr = action.payload.deliveryusrArr;
    const arr = [];
    
    for (let i = 0; i < CustomerArr.length; i++) {
    arr.push({
    id: i.toString(),
    custid: CustomerArr[i].usrid,
    custnam: CustomerArr[i].usrnme,
    phno: CustomerArr[i].phno,
    dob: CustomerArr[i].dob,
    addr1: CustomerArr[i].addr1,
    addr2: CustomerArr[i].addr2,
    addr3: CustomerArr[i].addr3,
    addr4: CustomerArr[i].addr4,
    addr5: CustomerArr[i].addr5,
    email: CustomerArr[i].email,
    Customer: CustomerArr[i].Customer
    });
    }
    console.log("====================================");
    console.log(arr);
    console.log("====================================");
    arr.map((node) => deliveryBoyAdapter.addOne(state, node));
    
    // console.log("ddddddddddddddddddd",action.payload.data.products.edges);
    // deliveryBoyAdapter.upsertMany(state, action.payload.data.products.edges);
    },
    [deliveryBoyItems.rejected]: (state, action) => {
    state.status = "failed";
    state.error = action.error.message;
    },
    },
    });
    export const { searchTerm, searchAllItemsRemove } = deliveryBoySlice.actions;
    export default deliveryBoySlice.reducer;
    export const {
    selectAll: selectAllDeliveryBoy,
    selectEntities: select,
    } = deliveryBoyAdapter.getSelectors((state) => state.deliveryBoy);
    
    //
    
    // export const selectItemsForSearch = createSelector(
    // [selectAllItems, (state, searchTerm) => searchTerm],
    // (items, searchTerm) => items.filter((item) => item.name === searchTerm)
    // )
    // export const selectItemsForSearch = (state) => state.items.filter((item) => item.name.includes(searchTerm));