import React, { Component,useEffect,useState } from 'react';
import './Login.css'
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from 'react-redux';
import { pinUser } from '../../reducers/UserSlice';
import { cartItems } from '../../reducers/CartSlice';
import { Input } from 'reactstrap';

export function Lock(props) {


  const [firstTerminalName,setFirstTerminalName] =useState("")
  const [terminalName,setTerminalName] =useState("C1")
  const [terminal,setterminal] =useState([])
  const dispatch = useDispatch();
  const [city,setCity] = useState(terminalName);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("Login")?.length > 0? true:false);
  const { tokenCreate } = useSelector((state) => state.user);
  const [unlockCode, setunlockCode] = useState("");
  function addNumber(num) {
  
    
    var textLoginCode = document.getElementById("textLoginCode")
    // if (textLoginCode.value.length < 4) {
      textLoginCode.value = textLoginCode.value + num;
    // }

    setunlockCode(textLoginCode.value)
  }

 function deleteNumber() {

    var textLoginCode = document.getElementById("textLoginCode")
    textLoginCode.value = textLoginCode.value.slice(0, -1)

 
  }
 function clearAll() {

  
    var textLoginCode = document.getElementById("textLoginCode")
    textLoginCode.value = ""

  }



  
  const lockClick = () => props.handleLogin(unlockCode);

  const setCookie = (name,value,days) =>{
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
      goToAbout()
  }
 
  const goToAbout = () => {
    
    localStorage.setItem("hsno",'0')
    localStorage.setItem('takenoMode','0')
    localStorage.setItem('takeawayOnlinePayment','')
    if(!terminalName){
      setTerminalName(firstTerminalName)
      return;
      }
      if(!unlockCode){
      alert("please Enter PinNumber");
      return;
      }if((unlockCode.toString()).length<=3){
      alert("please Enter Valid PinNumber");
      return;
      }
      let dataToSend = {
        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,

      token:localStorage.getItem('token'),
        loginpwd: unlockCode,
        };
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_login", {
        method: "POST",
        body: formBody,
        headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        })

        .then((response) => response.json())
        .then((responseJson) =>{

        const arr = [];
        console.log(responseJson,"responseJson");
        dispatch(pinUser(responseJson.ShopLoginArr));
        // s
        // localStorage.setItem(city));

        localStorage.setItem('TerminalName', terminalName);

        if (responseJson.ShopLoginArr[0].ShopLogin != 2) {
          
        localStorage.setItem('UserName', responseJson.ShopLoginArr[0].UserName);
          for (let i = 0; i < responseJson.ShopLoginArr.length; i++) {
          arr.push({
          id: i.toString(),
          licdays: responseJson.ShopLoginArr[i].license_left_days,
          username: responseJson.ShopLoginArr[i].UserName,
          userid: responseJson.ShopLoginArr[i].UserID,
          department: responseJson.ShopLoginArr[i].Department,
          departmentname: responseJson.ShopLoginArr[i].DepartmentName,
          deptid: responseJson.ShopLoginArr[i].Deptid,
          items: responseJson.ShopLoginArr[i].items,
          });
          }
          const item = [
          {
          tableno: "CT",
          tokenCreate: tokenCreate,
          terminalno: terminalName,
          },
          ];
          console.log(arr);
          dispatch(cartItems(item));
          localStorage.setItem('tableno', "CT");
          // const changeTosellp5nam = () =>{
            localStorage.setItem('sellp',"");
            localStorage.setItem('sellpName',"Normal Price")
          // }
        
          // localStorage.setItem('LicenseLeft', responseJson.ShopLoginArr[0].LicenseLeft);
          // localStorage.setItem('CompanyName', responseJson.ShopLoginArr[0].username);
          // localStorage.setItem('CompanyName', props.companyName);
          localStorage.setItem('UserID', responseJson.ShopLoginArr[0].UserID);
          // lockClick()
          console.log("lockClick");
          lockClick()
          } else {
          // setErrortext("Please check your email id or password");
          // console.log("Please check your email id or password");
          alert("Sorry, Invalid Pin");
          //navigation.replace('DrawerNavigationRoutes')
          }
}


)
.catch((error) => {
  //alert(JSON.stringify(error));
  console.error(error);
  })
// lockClick()
  }

  console.log(terminalName,"terminalName");

 const goBack = () =>{
  localStorage.removeItem("Login")
  lockClick()
 }

 const terminalFunc =  () => {

  if(isLoggedIn === true ){
    let dataToSend = {
      token:localStorage.getItem("token")
      };
      let formBody = [];
      for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_terminals", {
      method: "POST",
      body: formBody,
      headers: {
      //Header Defination
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      })
      .then((response) => response.json())
      .then((responseJson) => {
      const arr = [];
      console.log(responseJson,"terminalData");
      
      if (responseJson.terminals[0].terminal != 2) {
      for (let i = 0; i < responseJson.terminals.length; i++) {
      arr.push({
      id: i.toString(),
      terminalname: responseJson.terminals[i].terminalname,
      });
      }
      setFirstTerminalName(arr[0].terminalname)
      setterminal(arr);
      console.log("HIII");
      } else {
      console.log("Please check your email id or password");
      alert("Sorry No Data");
      }
      })
      .catch((error) => {
      console.error(error);
      })
  }
}
const setTerminalIdDataFunc = (st) =>{
  setTerminalName(st)
}

useEffect(()=>{
  
  terminalFunc()
},[])
    return (

      <div class="btn-group-vertical  " role="group" aria-label="Basic example">
        {/* mt-4 */}
        <div className="btn-group mt-2">

<Input type="select" name="status" placeholder="Select Status" className="form-control digits" 
onChange={e => setTerminalIdDataFunc(e.target.value)} 
//  innerRef={register({ required: false })}
>
                      {/* <option value="Terminal">Select Terminal</option> */}

                      {/* <option value="C1">C1</option> */}
                       {terminal?.map((item,index)=>(
                      <option value={item?.terminalname}>{item?.terminalname}</option>
                      )
                    
)}  
                    </Input>

</div>
{/* mb-4 */}
        <div class="btn-group ">
        {/* type="password" */}
        {/* maxlength="4" size="4" */}
          <input type="password" id="textLoginCode" class="text-center form-control-lg mb-2 inputnonorder-blue square-edges w-100 text-infogreen " style={{border:'1px solid black'}}
              onChange={(e)=>setunlockCode(e.target.value)}/>
        </div>
        <div class="btn-group">
          <button type="button" class="btn  btn-lg p-3 m-2 btn-login-keypad" onClick={() => addNumber(1)}>1</button>
          <button type="button" class="btn  btn-lg  p-3 m-2  btn-login-keypad" onClick={() => addNumber(2)}>2</button>
          <button type="button" class="btn  btn-lg  p-3 m-2  btn-login-keypad " onClick={() => addNumber(3)}>3</button>
        </div>
        <div class="btn-group">
          <button type="button" class="btn  btn-lg  p-3  m-2  btn-login-keypad" onClick={() => addNumber(4)}>4</button>
          <button type="button" class="btn   btn-lg  p-3 m-2  btn-login-keypad" onClick={() => addNumber(5)}>5</button>
          <button type="button" class="btn  btn-lg  p-3 m-2 btn-login-keypad" onClick={() => addNumber(6)}>6</button>
        </div>
        <div class="btn-group">
          <button type="button" class="btn   py-3 btn-lg  m-2 btn-login-keypad" onClick={() => addNumber(7)}>7</button>
          <button type="button" class="btn   py-3 btn-lg  m-2 btn-login-keypad" onClick={() => addNumber(8)}>8</button>
          <button type="button" class="btn  py-3 btn-lg  m-2 btn-login-keypad" onClick={() => addNumber(9)}>9</button>
        </div>
        <div class="btn-group">
          <button type="button" class="btn   py-3 btn-lg btn-login-keypad  square-edges m-2" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></button>
          <button type="button" class="btn   py-3 btn-lg btn-login-keypad  m-2" onClick={() => addNumber(0)}>0</button>
          <button type="button" class="btn  py-3 btn-lg btn-login-keypad  square-edges  m-2" onClick={() => deleteNumber()}><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></button>
        </div>
        {/* mt-4 */}
        <div class="btn-group " style={{justifyContent:'center'}}>
          <p style={{fontSize:'170%'}}>DEFAULT PIN 1234</p>
        </div>
        {/* mt-4 */}
        <div class="btn-group ">
          <button type="button" class="btn btn-infogreen btm-block shadow py-4 btn-lg  m-1 rounded" onClick={() => setCookie(1,1,1)}>LOG IN</button>
          <button type="button" class="btn btn-secondary btm-block shadow py-4 btn-lg  m-1 rounded" onClick={() => goBack()}>BACK</button>
       
        </div>
        {/* mt-4 */}
        <div class="btn-group">
           </div>
       
      </div>





    );
  
}