import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';


const newOrderAdapter = createEntityAdapter();
const initialState = newOrderAdapter.getInitialState({
    status: "idle",
    error: null,
    searchTerm: "",
});





export const fetchNewOrders = createAsyncThunk('newOrders/fetchNewOrders', async (item) => {

    console.log('====================================');
    console.log(item, "fetchItems");
    console.log('====================================');
    //alert(id);
    //debugger;
    // const response = () => {
    // //POST json
    let dataToSend = {
        // localStorage.getItem('token')
        token: '61b32535f2c80',
    };
    let formBody = [];
    for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");


    const response = await fetch(
        "https://superadmin.amepos.io/TabPosApiNew/tabpos_getneworders.php",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: formBody,
        }
    );

    // console.log("data list-----------------",response.json());
    return response.json();
});




export const searchProducts = createAsyncThunk('newOrders/searchProducts', async (searchTerm) => {

    // const response = await client.query({query:SEARCHITEMS,variables: { "count": searchTerm}});
    // return response.data.products;

})

export const newOnlineOrderSlice = createSlice({
    name: 'newOrders',
    initialState,
    reducers: {
        searchTerm: (state, action) => {

            state.searchTerm = action.payload;
        },
        searchAllItemsRemove(state, action) {
            // const products = state.entities[action.payload.id]

            newOrderAdapter.removeAll(state);

        }
    },
    extraReducers: {
        [fetchNewOrders.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchNewOrders.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            console.log(action.payload, "succeeded-newordersArr");
            newOrderAdapter.removeAll(state);
            if (action.payload.newordersArr.length > 0 && action.payload.newordersArr.neworder != 2 && action.payload.newordersArr[0].ordno != null) {

                const ProductArr = action.payload.newordersArr;
                const arr = []
                for (let i = 0; i < ProductArr.length; i++) {
                    // if (ProductArr[i].length > 0) {

                        arr.push({
                            id: i.toString(),
                            address: ProductArr[i].address,
                            channelname: ProductArr[i].channelname,
                            charges: ProductArr[i].charges,
                            customername: ProductArr[i].customername,
                            deldate: ProductArr[i].deldate,
                            email: ProductArr[i].email,
                            itemsarray: ProductArr[i].itemsarray,
                            merchantdiscount: ProductArr[i].merchantdiscount,
                            neworder: ProductArr[i].neworder,
                            orddate: ProductArr[i].orddate,
                            ordertotal: ProductArr[i].ordertotal,
                            ordno: ProductArr[i].ordno,
                            paymode: ProductArr[i].paymode,
                            phone: ProductArr[i].phone,
                            subtotal: ProductArr[i].subtotal,
                            taxes: ProductArr[i].taxes,
                            totaldiscount: ProductArr[i].totaldiscount,
                            name:"anand"
                        });
                    // }
                    // else {

                        // arr.push({
                        //     id: i.toString(),
                        //     name:"anand"

                        // });
                    // }




                }
                arr.map((node) => (

                    newOrderAdapter.addOne(state, node)

                ))
            }

        },

        [searchProducts.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
        [searchProducts.pending]: (state, action) => {
            state.status = 'loading'
        },
        [searchProducts.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            console.log(action.payload);
            newOrderAdapter.removeAll(state);
            action.payload.edges.map(({ node }) => (

                newOrderAdapter.addOne(state, node)

            ))

        },
        [searchProducts.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const { searchTerm, searchAllNewOrdersRemove } = newOnlineOrderSlice.actions;

export default newOnlineOrderSlice.reducer;
export const { selectAll: selectAllOnlineNewOrders, } = newOrderAdapter.getSelectors((state) => state.newOrders);
