import React, { useState } from 'react';

import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { backToPreviousPage } from '../../reducers/homePageSlice';
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';


export function AddSupplier(props) {


  const dispatch = useDispatch();
  


  return (
    <>
      <div class="row mt-3 ml-1">
        <div class="col-md-2">
          <PageTitle titleHeader="Supplier"  >
            <h3>Add Supplier</h3>
          </PageTitle>
        </div>
        <div class="col-md-2 pt-5">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">
       
        </div>
        <div class="col-md-2 pr-4">
          <button type="button" class="btn btn-florogreen btn-lg btn-block p-4"  onClick={() => dispatch(backToPreviousPage())}>Back</button>
        </div>
      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column ml-4 pl-3 mt-3">
           
            <div class="form-group w-50">
              <label for="exampleInputEmail1" class="text-muted">Name</label>
              <input type="text" class="form-control inputnonorder-gray ded-0" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          
            <div class="form-group w-50">
              <label for="exampleInputPassword1"  class="text-muted">Phone Number</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" id="exampleInputPassword1" />
            </div>
            <div class="form-group w-50">
              <label for="exampleInputPassword1"  class="text-muted">Email</label>
              <input type="email" class="form-control inputnonorder-gray rounded-0" id="exampleInputPassword1" />
            </div>
            <div class="form-group w-50 mb-auto">
              <label for="exampleInputPassword1"  class="text-muted">Address</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" id="exampleInputPassword1" />
            </div>
            <div class="form-group text-right mr-3">  <button type="submit" class="btn btn-lg btn-infogreen mt-4 p-4 w-20">Save</button></div>
          
          


        </div>


      </div>

    </>


  );

}
