import React, { useState } from 'react';

import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';

import { setActivePage, setActiveTab } from '../../reducers/homePageSlice';
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';


export function EmployeeAttendance(props) {


  const dispatch = useDispatch();
  const handleClickCheckIn = (e) => {
    dispatch(setActivePage(pageComponents.EMPLOYEECHECKIN));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));

  }


  return (
    <>
      <div class="row mt-3 ">
        <div class="col-md-12">
          <PageTitle titleHeader="Employee Attendance"  >

          </PageTitle>
        </div>

      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5">

        <div class="d-flex flex-column bd-highlight mb-3 w-100">
          <div class="p-2 bd-highlight m-5"></div>
          <div class="p-2 bd-highlight  ">
            <div class="d-flex flex-row mb-5 justify-content-center   ">

              <div class="w-20"> <button type="button" class="btn btn-secondary btn-lg btn-block  p-5" onClick={() => handleClickCheckIn()}>Check In</button></div>



            </div>
            <div class="d-flex flex-row mt-5 justify-content-center ">
              <div class="w-20">
                <button type="button" class="btn btn-secondary btn-lg btn-block p-5" onClick={() => handleClickCheckIn()}>Check Out</button>
              </div>


            </div>
          </div>

        </div>




      </div>

    </>


  );

}
