import { gql } from '@apollo/client';
import React, { useState } from 'react'
import { updateBill, addBill, addBillItem } from '../../reducers/billsSlice';
import { selectAllItems, selectItemsForSearch } from '../../reducers/itemsSlice';
import { useSelector, useDispatch } from 'react-redux';
import './GetItems.css'
import { cartItems } from '../../reducers/CartSlice';
import { selectSearchItems } from '../../reducers/searchItemsSlice';
import { of } from 'zen-observable';
import { ChangePriceModal } from './ChangePriceModal';
import ShowChangePrice from './ShowChangePrice';
import { selectAllRefund, updateCart } from '../../reducers/refundSlice';
import { pricelevelID, productId, showHoldSaleButton } from '../../reducers/ConstantSlice';

const PRODUCTS = gql`
query GetItems($count:Int)
{
products(first: $count) {
  edges {
    node {
      id
      name
      description
    }
  }
}
}
`;

export function GetItems(props) {


  const dispatch = useDispatch();
  const items = useSelector(selectAllItems);
  const {somename}=useSelector((state)=>state.refund)
  console.log(somename,"somename");
  const itemStatus = useSelector((state) => state.items.status)
  const balanceAmtData = useSelector((state) => state.refund.balance)
  const error = useSelector((state) => state.items.error)
  const searchTerm = useSelector((state) => state.items.searchTerm)
  const [changePriceAdminModal, setChangePriceAdminModal] = useState(false)

  const [qtyPlucCode, setQtyPlucCode] = useState(false)

  const refundBill = useSelector(selectAllRefund);

  const item = useSelector(selectSearchItems);

  const CloseEditQuantity = () => setChangePriceAdminModal(false);

  const addItemtoRefund = (plucod) => {
    console.log(plucod, "plucod");
    var arr = refundBill;
    var amount = 0
    var unitprice = 0
    var qty = 1
    var gst = 0
    var newqty = 0
    var rateid = plucod.rateid
    console.log(arr, "arramount");
    // gstType:0,
    // if (plucod.gstType == "0") {
    //   amount = plucod.sellp
    // } else if (plucod.gstType == "1") {
    //   amount = plucod.sellp + (plucod.gst * plucod.sellp)
    // }

    var existingLineItem = []
    existingLineItem = arr.filter(a => (a.plucod === plucod.plucod &&  a.edit != "no"))
    var index2 = arr.findIndex(function (master) {
      return master.plucod === plucod.plucod && master.edit != "no";
    });
    console.log(existingLineItem, index2,plucod.edit, "existingLineItemamount");
    // if(existingLineItem.length > 0){
    //   arr = [...arr,]
    // }
    if (index2 > -1) {

      let newArr = [...arr];
      
      console.log("========existingLineItemamount=================");
      console.log("========parseInt(existingLineItem[0].mainqty)=================",parseInt(existingLineItem[0].mainqty));
      
      var newQty = parseInt(existingLineItem[0].mainqty) + 1
      console.log("========newQty=================",newQty);
      var newAmount = 0;
      var newGST = 0;

      if (plucod.gstType == 1) {
        newGST = (parseFloat(existingLineItem[0].unitprice) - ((parseFloat(existingLineItem[0].unitprice)) / ((parseFloat(plucod.GSTper) * 0.01) + 1))) * parseInt(newQty)

        newAmount = ((parseFloat(existingLineItem[0].unitprice) + newGST) * newQty)

      } else {
        newAmount = ((parseFloat(existingLineItem[0].unitprice) + (parseFloat(existingLineItem[0].unitprice) * parseFloat(plucod.GSTper) * 0.01)) * (parseInt(newQty)))
        newGST = ((parseFloat(existingLineItem[0].unitprice) * parseFloat(plucod.GSTper * 0.01)) * parseInt(newQty))

      }
      // var newGST = parseInt(existingLineItem[0].gst) * newQty

      // existingLineItem[0].mainqty = newQty
      console.log(existingLineItem, index2, newAmount,typeof(newAmount), newGST, "existingLineItemamount3");
      console.log(newQty, "existingLineItemamount2");
      // arr[index2].mainqty = 

      newArr[index2] = {
        ...newArr[index2],
        mainqty: newQty,
        amount: newAmount.toFixed( 2 ),

        gst: newGST,
      };
      arr = newArr;
      console.log(newArr, "newArramount2");
    } else {

      if (plucod.gstType == 1 && (localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == '')) {
        amount = parseFloat(plucod.sellp) * parseInt(qty)
        gst = (parseFloat(plucod.sellp) - ((parseFloat(plucod.sellp)) / ((parseFloat(plucod.GSTper) * 0.01) + 1))) * parseInt(qty)
        unitprice = parseFloat(plucod.sellp)
      } else if (plucod.gstType == 1 && localStorage.getItem('sellp') == 1 && plucod.edit == "yes") {
        amount = parseFloat(plucod.sellp1) * parseInt(qty)
        gst = (parseFloat(plucod.sellp1) - ((parseFloat(plucod.sellp1)) / ((parseFloat(plucod.GSTper) * 0.01) + 1))) * parseInt(qty)
        unitprice = parseFloat(plucod.sellp1)
      } else if (plucod.gstType == 1 && localStorage.getItem('sellp') == 2 && plucod.edit == "yes") {
        amount = parseFloat(plucod.sellp2) * parseInt(qty)
        gst = (parseFloat(plucod.sellp2) - ((parseFloat(plucod.sellp2)) / ((parseFloat(plucod.GSTper) * 0.01) + 1))) * parseInt(qty)
        unitprice = parseFloat(plucod.sellp2)
      } else if (plucod.gstType == 1 && localStorage.getItem('sellp') == 3 && plucod.edit == "yes") {
        amount = parseFloat(plucod.sellp3) * parseInt(qty)
        gst = (parseFloat(plucod.sellp3) - ((parseFloat(plucod.sellp3)) / ((parseFloat(plucod.GSTper) * 0.01) + 1))) * parseInt(qty)
        unitprice = parseFloat(plucod.sellp3)
      } else if (plucod.gstType == 1 && localStorage.getItem('sellp') == 4 && plucod.edit == "yes") {
        amount = parseFloat(plucod.sellp4) * parseInt(qty)
        gst = (parseFloat(plucod.sellp4) - ((parseFloat(plucod.sellp4)) / ((parseFloat(plucod.GSTper) * 0.01) + 1))) * parseInt(qty)
        unitprice = parseFloat(plucod.sellp4)
      } else if (plucod.gstType == 1 && localStorage.getItem('sellp') == 5 && plucod.edit == "yes") {
        amount = parseFloat(plucod.sellp5) * parseInt(qty)
        gst = (parseFloat(plucod.sellp5) - ((parseFloat(plucod.sellp5)) / ((parseFloat(plucod.GSTper) * 0.01) + 1))) * parseInt(qty)
        unitprice = parseFloat(plucod.sellp5)
      } else if (plucod.gstType == 0 && (localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == '')) {
        amount = ((parseFloat(plucod.sellp) + (parseFloat(plucod.sellp) * parseFloat(plucod.GSTper) * 0.01)) * (parseInt(qty)))
        gst = ((parseFloat(plucod.sellp) * parseFloat(plucod.GSTper * 0.01)) * parseInt(qty))
        unitprice = parseFloat(plucod.sellp)
      } else if (plucod.gstType == 0 && localStorage.getItem('sellp') == 1 && plucod.edit == "yes") {
        amount = ((parseFloat(plucod.sellp1) + (parseFloat(plucod.sellp1) * parseFloat(plucod.GSTper) * 0.01)) * (parseInt(qty)))
        gst = ((parseFloat(plucod.sellp1) * parseFloat(plucod.GSTper * 0.01)) * parseInt(qty))
        unitprice = parseFloat(plucod.sellp1)
      } else if (plucod.gstType == 0 && localStorage.getItem('sellp') == 2 && plucod.edit == "yes") {
        amount = ((parseFloat(plucod.sellp2) + (parseFloat(plucod.sellp2) * parseFloat(plucod.GSTper) * 0.01)) * (parseInt(qty)))
        gst = ((parseFloat(plucod.sellp2) * parseFloat(plucod.GSTper * 0.01)) * parseInt(qty))
        unitprice = parseFloat(plucod.sellp2)
      } else if (plucod.gstType == 0 && localStorage.getItem('sellp') == 3 && plucod.edit == "yes") {
        amount = ((parseFloat(plucod.sellp3) + (parseFloat(plucod.sellp3) * parseFloat(plucod.GSTper) * 0.01)) * (parseInt(qty)))
        gst = ((parseFloat(plucod.sellp3) * parseFloat(plucod.GSTper * 0.01)) * parseInt(qty))
        unitprice = parseFloat(plucod.sellp3)
      } else if (plucod.gstType == 0 && localStorage.getItem('sellp') == 4 && plucod.edit == "yes") {
        amount = ((parseFloat(plucod.sellp4) + (parseFloat(plucod.sellp4) * parseFloat(plucod.GSTper) * 0.01)) * (parseInt(qty)))
        gst = ((parseFloat(plucod.sellp4) * parseFloat(plucod.GSTper * 0.01)) * parseInt(qty))
        unitprice = parseFloat(plucod.sellp4)
      } else if (plucod.gstType == 0 && localStorage.getItem('sellp') == 5 && plucod.edit == "yes") {
        amount = ((parseFloat(plucod.sellp5) + (parseFloat(plucod.sellp5) * parseFloat(plucod.GSTper) * 0.01)) * (parseInt(qty)))
        gst = ((parseFloat(plucod.sellp5) * parseFloat(plucod.GSTper * 0.01)) * parseInt(qty))
        unitprice = parseFloat(plucod.sellp5)
      }







      console.log(amount, gst, "amount");
      var usellp = `sellp${localStorage.getItem('sellp')}`
      arr.push({
        id: (parseInt(arr[arr.length - 1].id) + 1).toString(),
        lineno: (parseInt(arr[arr.length - 1].lineno) + 1).toString(),
        prodname: plucod.productname,
        plucod: plucod.plucod,
        gst: gst,
        addon: '0',
        amount: amount.toFixed(2),
        amtt: 22,
        kotno: '0',
        lineno: '5',
        name: 'anand',
        pricelevel: 1,
        qty: 1,
        remarks: '',
        subTotal: "72",
        totalqty: '1',
        mainqty: '1',
        unitprice: unitprice,
        edit: "yes",
        sellp: plucod.sellp,
        sellp1: plucod.sellp1,
        sellp2: plucod.sellp2,
        sellp3: plucod.sellp3,
        sellp4: plucod.sellp4,
        sellp5: plucod.sellp5,
        // usellp:plucod.`sellp${localStorage.getItem('sellp')}`,
        gstType: plucod.gstType,
        GSTper: plucod.GSTper,
        rateid:rateid,
        catid: plucod.catid,
        amtpaid:somename,
        balance :balanceAmtData
      })
    }


    console.log(arr, "arr");
    dispatch(updateCart(arr))

  }


  const addLineItem = (plucod) => {
    var hsnoconst = 0;
    var tablevalue = '';
    if (localStorage.getItem('tableno') == 'CT') {
      hsnoconst = localStorage.getItem('hsno')

      // var string = localStorage.getItem('TerminalName')
      // string.replace(/[A-Za-z]/g, "").replace(/[^\d.-]/g, '');

      // var ctvalue = string.replace(/[A-Za-z]/g, "")
      // console.log(ctvalue, "string");

      tablevalue = `CT-${localStorage.getItem('TerminalName')}`
    } else {
      hsnoconst = 0

      tablevalue = localStorage.getItem('tableno')
    }

    console.log(tablevalue, "tablevalue");
    const date = new Date();
    const datetime =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      hsno: hsnoconst,
      tqty: "1",
      itemid: plucod,
      tabno: tablevalue,
      tym: datetime,
      ordusr: localStorage.getItem('UserID'),
      openin: localStorage.getItem('TerminalName'),
      sellvalue: localStorage.getItem("sellp"),
      paymode: localStorage.getItem('takenoMode'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_itemdetails", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json()
        // console.log(response,"response")
        // 
      )
      //If response is in json then in success
      .then((responseJson) => {
        console.log(responseJson, "responseJson");
        const item = [
          {
            tableno: localStorage.getItem('tableno'),
            // tokenCreate: tokenCreate,
            terminalno: localStorage.getItem('TerminalName'),
          },
        ];
        dispatch(cartItems(item));
        const arr = [];

        if (responseJson.ProductDetailsArr[0].Product != 2) {
          for (let i = 0; i < responseJson.ProductDetailsArr.length; i++) {
            arr.push({
              id: i.toString(),
              productdetails: responseJson.ProductDetailsArr[i].ProductDetails,
            });
          }

          // setproduct(arr);
        } else {
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        // alert("alert Data not found getItems alert");
      });





  }


  const addItemThroughCheckPrice = (i) => {

    // dispatch(productId(sno.sno))
    // dispatch(pricelevelID(sno.pricelevel))
    // dispatch(usellpValueId(sno.usellp))

    // dispatch(itemIdPlucode(sno.plucod))
    // localStorage.setItem('sellp',"");\

    // of(
    console.log(parseInt(i.sellp), localStorage.getItem('sellp'), "isellp");
    //   localStorage.getItem('sellp'))
    // console.log(typeof(localStorage.getItem('sellp')),"sellp");
    // if(localStorage.getItem('sellp') != null || localStorage.getItem('sellp') != ''){

    //   console.log(localStorage.getItem('sellp'),"sellp");
    // }else{

    if (localStorage.getItem("tableno") == "CT") {
      // if(localStorage.getItem('sellp') == '' || localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == undefined){
      //   if(parseInt(i.sellp) == '0'){

      //     setChangePriceAdminModal(true)
      //     setQtyPlucCode(i.plucod)
      //   }else{
      //     addLineItem(i.plucod)
      //   }

      // }
      // || localStorage.getItem('sellp') == null || localStorage.getItem('sellp') == undefined
      if (localStorage.getItem('sellp') == '' && i.sellp != '0.00') {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == '1' && i.sellp1 != '0.00') {
        addLineItem(i.plucod)
      } else if (localStorage.getItem('sellp') == "2" && i.sellp2 != "0.00") {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == "3" && i.sellp3 != '0.00') {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == "4" && i.sellp4 != '0.00') {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == "5" && i.sellp4 != '0.00') {
        addLineItem(i.plucod);
      }
      else {
        setChangePriceAdminModal(true);
        setQtyPlucCode(i.plucod);
      }

      dispatch(showHoldSaleButton(true))
    } else {
      if (localStorage.getItem('sellp') == '' && i.sellp != '0.00') {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == '1' && i.sellp1 != '0.00') {
        addLineItem(i.plucod)
      } else if (localStorage.getItem('sellp') == "2" && i.sellp2 != "0.00") {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == "3" && i.sellp3 != '0.00') {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == "4" && i.sellp4 != '0.00') {
        addLineItem(i.plucod);
      } else if (localStorage.getItem('sellp') == "5" && i.sellp4 != '0.00') {
        addLineItem(i.plucod);
      }
      else {
        setChangePriceAdminModal(true);
        setQtyPlucCode(i.plucod);
      }
    }

  }

  let content


  if (item?.length > 0) {

    content = item.map((node) => (
      //  <li class="cards_item" onClick={()=>handleAddItem(node.id,node.name,1,20,"")}>
      // node.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
      //<li class="cards_item" onClick={() => dispatch(addItem({ id: node.id, itemname: node.name, qty: 1, uomprice: 20, remark: "",isselected:true }))}>

      refundBill.length > 0 && refundBill[0].name != "yasar" ?
        // btn_height_items

        (
          node.itemdisplay == '1' ?

            (<li class="cards_item_1" onClick={() => addItemtoRefund(node)} style={{ height: '150px' }}>
              {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}
              <div class="card itemcardwidth margin-1" style={{ height: '100%' }}>
                {/* m-1 */}
                <div class="card_image" style={{ height: '100px' }}><img src={localStorage.getItem("imgpath")+"Inventory/" + node.image} style={{ height: '100px', width: '100%' }} /></div>
                <div class="card_content" style={{ height: '50px', background: 'green', color: 'white', fontWeight: 'bold' }}>
                  {node.productname}
                </div>
              </div>
              {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                   
          {node.productname}
                 
               </button> */}
            </li>)
            :
            (
              <li class="cards_item_1 btn_height_items " onClick={() => addItemtoRefund(node)} >
                {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}

                {/* <div class="card itemcardwidth  margin-1" style={{height:'100%'}}>
    m-1
      <div class="card_image" style={{height:'100px'}}><img src={localStorage.getItem("imgpath")+node.image}  style={{height:'100px',width:'100%'}}/></div>
    <div class="card_content" style={{height:'50px',background:'green',color:'white',fontWeight:'bold'}}>
    {node.productname}
    </div>
  </div> */}

                <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">


                  {node.productname}

                </button>


                {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                 
        {node.productname}
               
             </button> */}
              </li>
            )

        )
        :


        (
          node.itemdisplay == '1' ?
            (
              // btn_height_items
              <li class="cards_item_1  " onClick={() => addItemThroughCheckPrice(node)} style={{ height: '150px' }}>
                { }
                {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}
                <div class="card itemcardwidth margin-1" style={{ height: '100%' }}>
                  {/* m-1 */}
                  <div class="card_image" style={{ height: '100px' }}><img src={localStorage.getItem("imgpath")+"Inventory/"  + node.image} style={{ height: '100px', width: '100%' }} /></div>
                  <div class="card_content" style={{ height: '50px', background: 'green', color: 'white', fontWeight: 'bold' }}>
                    {node.productname}
                  </div>
                </div>
                {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                 
        {node.productname}
               
             </button> */}
              </li>)
            : (

              <li class="cards_item_1 btn_height_items " onClick={() => addItemThroughCheckPrice(node)} >
                {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}

                {/* <div class="card itemcardwidth  margin-1" style={{height:'100%'}}>
    m-1
      <div class="card_image" style={{height:'100px'}}><img src={localStorage.getItem("imgpath")+node.image}  style={{height:'100px',width:'100%'}}/></div>
    <div class="card_content" style={{height:'50px',background:'green',color:'white',fontWeight:'bold'}}>
    {node.productname}
    </div>
  </div> */}

                <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">


                  {node.productname}

                </button>


                {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                 
        {node.productname}
               
             </button> */}
              </li>
            ))



    ))

  } else if (items?.length > 0) {

    // itemdisplay:ProductArr[i].itemdisplay,
    content = items.map((node) => (

      refundBill.length > 0 && refundBill[0].name != "yasar" ?
        // btn_height_items
        (
          node.itemdisplay == '1' ?
            <li class="cards_item_1  " onClick={() => addItemtoRefund(node)} style={{ height: '150px' }}>
              {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}

              <div class="card itemcardwidth  margin-1" style={{ height: '100%' }}>
                {/* m-1 */}
                <div class="card_image" style={{ height: '100px' }}><img src={localStorage.getItem("imgpath")+"Inventory/"  + node.image} style={{ height: '100px', width: '100%' }} /></div>
                <div class="card_content" style={{ height: '50px', background: 'green', color: 'white', fontWeight: 'bold' }}>
                  {node.productname}
                </div>
              </div>

              {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">

                   
    {node.productname}
           
         </button>  */}


              {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                   
          {node.productname}
                 
               </button> */}
            </li>
            :
            <li class="cards_item_1 btn_height_items " onClick={() => addItemtoRefund(node)} >
              {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}

              {/* <div class="card itemcardwidth  margin-1" style={{height:'100%'}}>
    m-1
      <div class="card_image" style={{height:'100px'}}><img src={localStorage.getItem("imgpath")+node.image}  style={{height:'100px',width:'100%'}}/></div>
    <div class="card_content" style={{height:'50px',background:'green',color:'white',fontWeight:'bold'}}>
    {node.productname}
    </div>
  </div> */}

              <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">


                {node.productname}

              </button>


              {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                 
        {node.productname}
               
             </button> */}
            </li>
        )
        :
        (node.itemdisplay == '1' ?
          //  <li class="cards_item" onClick={()=>handleAddItem(node.id,node.name,1,20,"")}>
          // node.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
          //<li class="cards_item" onClick={() => dispatch(addItem({ id: node.id, itemname: node.name, qty: 1, uomprice: 20, remark: "",isselected:true }))}>
          <li class="cards_item_1  " onClick={() => addItemThroughCheckPrice(node)} style={{ height: '133px' }}>
            {/* btn_height_items */}
            {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}

            <div class="card itemcardwidth  margin-1" style={{ height: '100%' }} >
              {/* m-1 */}
              <div class="card_image" style={{ height: '80px' }}><img src={localStorage.getItem("imgpath")+"Inventory/"  + node.image} style={{ height: '80px', width: '100%' }} /></div>
              <div class="card_content" style={{ height: '50px', background: 'green', color: 'white', fontWeight: 'bold' }}>
                {node.productname}
              </div>
            </div>

            {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                     
            {node.productname}
                   
                 </button> */}
          </li>
          :
          <li class="cards_item_1  btn_height_items" onClick={() => addItemThroughCheckPrice(node)}>
            {/* btn_height_items */}
            {/* <li class="cards_item" onClick={() => dispatch(fetchItems())}> */}

            {/* <div class="card itemcardwidth  margin-1" style={{height:'100%'}} >
          m-1
          <div class="card_image" style={{height:'80px'}}><img src={localStorage.getItem("imgpath")+node.image}  style={{height:'80px',width:'100%'}}/></div>
       <div class="card_content" style={{height:'50px',background:'green',color:'white',fontWeight:'bold'}}>
          {node.productname}
          </div>
        </div> */}

            <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">


              {node.productname}

            </button>

            {/* <button class="btn btn-block btn__large btn-infogreen btn_items_height margin-1">
                   
          {node.productname}
                 
               </button> */}
          </li>
        )))

  }



  return (
    <ul class="cards_overflow ">

      {content}

      <ShowChangePrice show={changePriceAdminModal} handleClose={CloseEditQuantity} qtyPlucCode={qtyPlucCode} animation={true}></ShowChangePrice>
    </ul>

  );
}

