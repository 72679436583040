import React, { useEffect,useState,useRef } from 'react';
import { Container } from 'react-bootstrap';
import { fetchTables, mergeTables, selectAllTables, updateTable } from '../../reducers/tablesSlice';
import { updateBill, addBill, addBillItem } from '../../reducers/billsSlice';
import { setActivePage, setActiveTab } from '../../reducers/homePageSlice';
import { pageComponents, paymentMode } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux'
import { PageTitle } from '../Commons/PageTitle';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BillModel } from '../../Models/BillModel';
import { printBill } from '../../utilities/util';
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { useReactToPrint } from "react-to-print";


export function Tables() {
  
  const [companyDetails, setCompanyDetails] = useState();
  const [draftData,setDraftData] = useState()
  const [loadingKOT, setLoadingKOT] = React.useState(false);
  const [textKOT, setTextKOT] = React.useState("old boring text");
  const [kotDataDraft, setKotDataDraft] = useState();
  const [kotData, setKotData] = useState();
  const draftRef = React.useRef(null);
  const draftRefDraft = React.useRef(null);
  const date = new Date();
  console.log(date, "anandgreenangel");
  const datetime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  console.log(datetime, date, "anandangelcart");
  const onBeforeGetContentResolveKOT = React.useRef(null);
  
  const kotRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const onBeforeGetContentResolve = React.useRef(null);
  const onBeforeGetContentResolveDraft = React.useRef(null);
  
  const cartItemsList = useSelector(selectAllCart);
  let content;
  const dispatch = useDispatch()
  const allTables = useSelector(selectAllTables)
console.log(allTables,"allTables");
  // const tablesList = useSelector(selectAllTables);
  const tablesStatus = useSelector((state) => state.tables?.statussss);
  // const tablesStatus = useSelector((state) => state.tables.status)
  const error = useSelector((state) => state.tables.error)

  try {
    if(cartItemsList?.length > 0){
      var billamnt = cartItemsList.length > 0 && cartItemsList[0].name !="yasar" && (
        (cartItemsList[cartItemsList.length - 1].subTotal) +
        (cartItemsList[cartItemsList.length - 1].gst)
      ).toFixed(2);
      var roundamnt = Math.round(billamnt);
    }
    
   } catch (error) {
     console.log(error);
   }

  //  useEffect(() => {
  //   // useEffect(() => {
  //     const timer = setTimeout(() => {
        
  //     setCount(count + 1);
  //     if (expiry > 0) {
  //       getClientMainDetails()
  //     } else {
  //       getClientDetailsfunc()
  //     }}, 12000);
  //     return () => clearTimeout(timer);
  //   // }, []);

    
  // },[count])

  useEffect(() => {
    if (tablesStatus === 'idle') {
      dispatch(fetchTables())
    }
  }, [tablesStatus, dispatch])


  const getDataUsingPost = (table) => {
    //POST json
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
    terminal: localStorage.getItem('TerminalName'),

    token:localStorage.getItem('token'),
    tabno: table,
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_checkbusytable", {
    method: "POST",
    body: formBody,
    headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    })
    .then((response) => response.json())
    .then((responseJson) => {
    console.log(responseJson);
    
    localStorage.setItem('takenoMode','0')
    if (responseJson.CheckBusyArr[0].CheckBusy === 3) {
      handleClickTable(table)
      } else {
        handleClickTable(table)
      }
  
    })
    .catch((error) => {
    console.error(error);
    });
    };
    const getClear = (item) => {
      let dataToSend = {
        token: localStorage.getItem('token'),
      
        tabno: item,
        terminal: localStorage.getItem('TerminalName'),
      
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
        method: "POST",
        body: formBody,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
            const arr = [];
            for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
              arr.push({
                clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
              });
            }
    
            console.log("HIII");
          } else {
            alert("Sorry No data");
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });
    };


  const handleClickTable = (table) => {
    localStorage.setItem('tableno', table);
    console.log(table, "anand");
    const item = [
      {
        tableno: table,
        terminalno: localStorage.getItem('TerminalName'),
      },
    ];
    dispatch(cartItems(item));
    dispatch(setActivePage(pageComponents.BILLING));

  }

  const sendKOT = async (items) =>{
    await goToKot(items);
    
  }

  const goToKot = (items) =>{
    let dataToSend = {
      token: localStorage.getItem('token'),
     
      tabno: items,
    };
    let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          // alert(JSON.stringify(responseJson));
          console.log(responseJson,"kot");
          
          if (responseJson.PrintKotArr[0].PrintKot != 2) {
            setKotData(responseJson)
           
    let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
    var item = [
      {
        link: link,
        tokenCreate: localStorage.getItem('token'),
      },
    ];
  getClear(items)
    handlePrint()
          } else {
            
            getClear(items)
           alert("KOT Already Printed");
          //  Actions.vieworders();
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });
  }
  
  const handleAfterPrintDraftBill = React.useCallback(() => {
    // goToKot()
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
  var item = [
    {
      link: link,
      tokenCreate: localStorage.getItem('token'),
    },
  ];
  dispatch(fetchTables(item));
  }, []);

  const handleBeforePrintDraftBill = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContentDraftBill = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolveDraft.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContentDraftBill = React.useCallback(() => {
    return draftRefDraft.current;
  }, [draftRefDraft.current]);

  const handlePrintDraftBill = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 


    
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContentDraftBill,
    onBeforePrint: handleBeforePrintDraftBill,
    
    content: reactToPrintContentDraftBill,
    onAfterPrint: handleAfterPrintDraftBill,
    removeAfterPrint: true
  });
  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolveDraft.current === "function"
    ) {
      onBeforeGetContentResolveDraft.current();
    }
  }, [onBeforeGetContentResolveDraft.current, text]);

//////////////////////////////////////////////////


  
const handleAfterPrint = React.useCallback(() => {
  console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  // dispatch(setActivePage(pageComponents.VIEWTABLES));
  let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
  var item = [
    {
      link: link,
      tokenCreate: localStorage.getItem('token'),
    },
  ];
  dispatch(fetchTables(item));
}, []);

const handleBeforePrint = React.useCallback(() => {
  console.log("`onBeforePrint` called"); // tslint:disable-line no-console
}, []);

const handleOnBeforeGetContent = React.useCallback(() => {
  console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
  setLoading(true);
  setText("Loading new text...");

  return new Promise((resolve) => {
    onBeforeGetContentResolve.current = resolve;

    setTimeout(() => {
      // goToPayment();
      setLoading(false);
      setText("New, Updated Text!");
      resolve();
    }, 2000);
  });
}, [setLoading, setText]);

const reactToPrintContent = React.useCallback(() => {
  return draftRef.current;
}, [draftRef.current]);

const handlePrint = useReactToPrint({
  // pageShandlePrinttyle={pageStyle}, 


  
  documentTitle: "AwesomeFileName",
  onBeforeGetContent: handleOnBeforeGetContent,
  onBeforePrint: handleBeforePrint,
  
  content: reactToPrintContent,
  onAfterPrint: handleAfterPrint,
  removeAfterPrint: true
});
React.useEffect(() => {
  if (
    text === "New, Updated Text!" &&
    typeof onBeforeGetContentResolve.current === "function"
  ) {
    onBeforeGetContentResolve.current();
  }
}, [onBeforeGetContentResolve.current, text]);

////////////////Draft bill///////////////////

const printStatementDraft = async (item) => {
  // await goToPayment()
  // await handleClickLastReceipt();
    // await getDataUsingPost();
    await goToKotDraft(item)
}

const goToKotDraft = (item) =>{
  let dataToSend = {
    token: localStorage.getItem('token'),
   
    tabno: item,
  };
  let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
    .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        // alert(JSON.stringify(responseJson));
        console.log(responseJson,"kot");
        
        if (responseJson.PrintKotArr[0].PrintKot != 2) {
          setKotDataDraft(responseJson)
         
  let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
  var item = [
    {
      link: link,
      tokenCreate: localStorage.getItem('token'),
    },
  ];
  // dispatch(fetchTables(item));
getClear(item)
  // Actions.vieworders();
  handlePrintKOTDraft()
        } else {
          //("Please check your email id or password");
          console.log("Please check your email id or password");
          getClear(item)
        //  alert("KOT Already Printed");
         goToPayment()
        //  Actions.vieworders();
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
}

////////////////Draft Kot /////////////////

  
const handleAfterPrintKOTDraft = React.useCallback(() => {
  goToPayment()
   console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  // dispatch(setActivePage(pageComponents.VIEWTABLES));
}, []);

const handleBeforePrintKOTDraft = React.useCallback(() => {
  console.log("`onBeforePrint` called"); // tslint:disable-line no-console
}, []);

const handleOnBeforeGetContentKOTDraft = React.useCallback(() => {
  console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
  setLoadingKOT(true);
  setTextKOT("Loading new text...");

  return new Promise((resolve) => {
    onBeforeGetContentResolveKOT.current = resolve;

    setTimeout(() => {
      // goToPayment();
      setLoadingKOT(false);
      setTextKOT("New, Updated Text!");
      resolve();
    }, 2000);
  });
}, [setLoadingKOT, setTextKOT]);

const reactToPrintContentKOTDraft = React.useCallback(() => {
  return kotRef.current;
}, [kotRef.current]);


const handlePrintKOTDraft = useReactToPrint({
  // pageShandlePrinttyle={pageStyle}, 


  
  documentTitle: "AwesomeFileName",
  onBeforeGetContent: handleOnBeforeGetContentKOTDraft,
  onBeforePrint: handleBeforePrintKOTDraft,
  
  content: reactToPrintContentKOTDraft,
  onAfterPrint: handleAfterPrintKOTDraft,
  removeAfterPrint: true
});


React.useEffect(() => {
  if (
    textKOT === "New, Updated Text!" &&
    typeof onBeforeGetContentResolveKOT.current === "function"
  ) {
    onBeforeGetContentResolveKOT.current();
  }
}, [onBeforeGetContentResolveKOT.current, textKOT]);

const goToPayment = (texts) => {
  const date = new Date();


  let dataToSend = {
    token:  localStorage.getItem('token'),
    tabno:  localStorage.getItem('tableno'),
    openin:  localStorage.getItem('TerminalName'),
  };
  let formBody = [];
  for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_draftbill", {
    method: "POST",
    body: formBody,
    headers: {
      //Header Defination
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
      //alert(JSON.stringify(responseJson));
      if (responseJson.DraftBillArr.ItemsArray[0].itemsArray != 2) {
        const arr = [];
        var text = texts;
        
      setDraftData(responseJson)
   

      handlePrintDraftBill()
      getClear();


        console.log("HIII");
      } else {
        console.log("Please check your email id or password");
        alert("Sorry No Data");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
useEffect(()=>{
  getdata()
  // handleClickLastReceipt()
},[])


const getdata = () => {
  let dataToSend = {

    wanip: "mysql.amepos.in",
    dbusr: "user_amepos",
    dbpwd: 'bismillah$12',
    dbnam: "amepos_10",
    token:localStorage.getItem('token'),
  };
  let formBody = [];
  for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
    method: "POST",
    body: formBody,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      if (responseJson.clientdtlArr[0].clientdtl != 2) {
        setCompanyDetails(responseJson.clientdtlArr[0])
        
      } else {
        console.log("Please check your email id or password");
        alert("Sorry No CompanyDetails Data");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};


////////////////////////////////////



  const handlePrintDraft = (event, bill) => {
    event.stopPropagation();
    event.preventDefault();
    printBill(bill);
    console.log(bill,"bill");
    if(bill?.amount != "0" && bill?.printsts === "0"){
      sendKOT(bill.username)
    } else if(bill?.amount != "0" && bill?.printsts === "1" && bill?.draftflag === "0"){
      localStorage.setItem('tableno',bill.username)
      printStatementDraft(bill.username)
        const item = [
          {
          tableno:bill.username,
          terminalno: localStorage.getItem('TerminalName'),
          },
          ];
          dispatch(cartItems(item));
        }
        else{

        }

  }
  console.log(allTables,"allTables");

  if (tablesStatus === 'loading') {
    content = <div className="col-md-2">Loading...</div>
  } else if (tablesStatus === 'succeeded') {
    content = allTables.length != 0 && allTables[0].BusyTable != 2 && allTables.map((table, index) => (
      <div class="col-md-2 pl-1 pt-1 pr-1" style={{flex: "0 0 20%",maxWidth: "25.666667%"}}
      >
        <a class="btn-block-height text-decoration-none" href="#" onClick={() => getDataUsingPost(table.username)} >
        {/* card h-100 */}
          <div class="  "   style={{color:'white',height:'28vh',marginTop:'5px',
backgroundColor:table?.amount === "0" && table?.printsts === "0"
            ? "#646464"
            : table?.amount != "0" && table?.printsts === "0"
            ? "#f19100"
            : table?.amount != "0" &&
              table?.printsts === "1" &&
              table?.draftflag === "0"
            ? "green"
            : (table?.draftflag === "1" ||
                table?.draftflag === "2") &&
              table?.amount != "0" &&
              table?.printsts === "1"
            ? "#B7064D"
            : "#646464",
        }}>
            <div class="d-flex justify-content-between">
              <button class="rounded-circle p-2 printerButton shadow border-0 bg-white m-2" onClick={(e) => handlePrintDraft(e, table)}><FontAwesomeIcon className={"printerIcon text-maroon p-1"} icon={["fas", "print"]}></FontAwesomeIcon></button>
              <div class="m-2 h2 display-5">{table?.username}</div>
            </div>
            <div class="d-flex flex-column">
              <span class="pl-2 lh-0"> Customer: {table?.customernam}</span>
              <span class="pl-2 lh-0">No of Items: {table?.departmentname}</span>
              <span class="pl-2 lh-0">Order Taken by: {table?.ordertakenby}</span>
              <span class="pl-2 lh-0">Bill Amount: {table?.amount}</span>
              {/* <span class="pl-2 lh-0">In Time: {table?.intime} </span> */}
            </div>


          </div>
        </a>
      </div>
    ))
  } else if (tablesStatus === 'failed') {
    content = <div className="col-md-12">{error}</div>
  }


  return (
    <>
      {/* <PageTitle titleHeader="DINE IN" titleDescription="" /> */}
      <div class="row ml-4 mr-4 mb-4 d-flex flex-grow-1">
        {content}

        <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " >
               <p className="centered" style={{fontSize:'14px' ,borderCollapse:'collapse',margin:'0px',color:'black',fontWeight:'bold'}}>Table No. {localStorage.getItem('tableno')}</p>
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>

             
                        <table className="centered" style={{borderCollapse:'collapse'}}>
                            
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                  kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length >0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                                  kotData?.PrintKotArr[0].ItemsArray.map((item, index) => 
                                       
                                       (
                                       <tr  >
                                           <td className='description' >
                                             
                                             <div><small   style={{fontSize:'12px',borderCollapse:'collapse',color:'black',fontWeight:'bold'}}>{item?.ProductName}</small></div>
                                             {item?.addon.length > 0 && item?.addon.map((i)=>
                                               <div><small  style={{fontSize:'9px',borderCollapse:'collapse',color:'black'}}>ADDON'S :{i.addonnam}
                                               </small></div>
                                             )}
                                             {item?.Remarks != '' && item?.Remarks != null && item?.Remarks != undefined &&
                                             <div><small style={{fontSize:'9px',borderCollapse:'collapse',color:'black'}}>REMARKS: {item?.Remarks}</small></div>
                                             }
                                               </td>
                                             
                                           <td className="quantity  "   style={{fontSize:'12px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                           
                                           
                                       </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
            <p className="centered" style={{fontSize:'12px',borderTop:'1px dotted black' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{"<<< AME>>>"}</p>
              

                    </div>
                </div>
                </div>
                <div style={{overflow:'hidden',height:'0',width:'0'}} >
           <div class="print-container" ref={draftRefDraft} style={{ margin: "0", padding: "0"}}>
           <div class="ticket " >
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.companynam}</p>
               <p className="centered" style={{fontSize:'10px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.addr1}{companyDetails?.addr2}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>PH NO:{companyDetails?.addr3}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Email:{companyDetails?.addr4}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST No:{companyDetails?.gstno}</p>
               <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>DRAFT RECEIPT</p>
               {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p> */}
                  
                  {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>DATE: {dateData != null && dateData != undefined && dateData}</p> */}
                  {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
               </div> */}
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE :{localStorage.getItem('tableno')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Date :{datetime != null && datetime != undefined ? datetime :""}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p>
                  
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER : {localStorage.getItem('UserName')} </p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  {/* <p className="centered" style={{fontSize:'8px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL :{localStorage.getItem('TerminalName')}</p> */}
                  
                  {/* <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px'}}>CASHIER: {dateData != null && dateData != undefined && dateData}</p> */}
           
               </div>
             
                        <table className="centered" style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            <thead>
                                <tr style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                                    <th className='description'  style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>Product</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>QTY</th>
                                    <th className="quantity" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>RATE</th>

                                    <th className="price" style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>TOTAL</th>

                                </tr>
                                 {/* <tr>
                        <th class="quantity">Q.</th>s
                        <th class="description">Description</th>
                        <th class="price">$$</th>  && draftData?.DraftBillArr?.ItemsArray[0].amount != 0 
                    </tr> */}
                            </thead>
                            <tbody  style={{borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                            {
                                   draftData !== undefined && draftData?.DraftBillArr?.ItemsArray.length >0  &&

                                   draftData?.DraftBillArr?.ItemsArray.map((item, index) => 
                                       
                                       (
                                      
                                       <tr  >
                                       <td className='description'   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{item?.ProductName}</td>
                                       <td className="quantity  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{parseInt(item?.Quantity)}</td>
                                       
                                       <td className="quantity  "   style={{fontSize:'10px',borderCollapse:'collapse',color:'black'}}>{item?.unitprice}</td>
                                       <td className="price"   style={{fontSize:'10px',borderCollapse:'collapse',color:'black',textAlign:'end'}}>{item?.amount}</td>
                       
                                   </tr>
                                   ))
                            
                                }

                            </tbody>
                        </table> 
                        <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Total Items :{draftData?.DraftBillArr?.ItemsArray?.length > 0  ?(draftData?.DraftBillArr?.ItemsArray?.length) :0  }</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>Total Qty:{cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].qty ?parseFloat(cartItemsList[cartItemsList?.length - 1].qty):0  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderTop:'1px dotted black',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Sub Total :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].subTotal ?cartItemsList[cartItemsList?.length - 1].subTotal.toFixed(2):"0.00"  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].gst ? cartItemsList[cartItemsList?.length - 1].gst.toFixed(2):"0.00"  }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',color:'black'}}>Total</p>
                  
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',fontWeight:'bold',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {roundamnt != null && roundamnt != undefined ? parseFloat(roundamnt).toFixed(2) :"0.00" }</p>
           
               </div>
               <div style={{display:'flex',borderTop:'1px dotted black',borderCollapse:'collapse',justifyContent:'center'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>KOT No's :</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{ draftData?.DraftBillArr?.DraftBill?.kotno }</p>

               </div>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.remarks1}</p>
               <p className="centered" style={{fontSize:'12px' ,borderCollapse:'collapse',margin:'0px',color:'black'}}>{companyDetails?.remarks2}</p>
              

                    </div>
                </div>
           </div>

      </div>
    </>
  );

}