import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
const userAdapter = createEntityAdapter();
const initialState = userAdapter.getInitialState({

    paymentModeStatus: null,
    productId: '',
    itemIdPlucode: '',
    usellpValueId: '',
    pricelevelID: '',
    addonRemarksShow: false,
    showHoldSaleButton: false,
    customerItemData: [],
    ewalletModeData: [],
    onlineModeData: [],
    takenoMode: '',
    showRefund: false,
    showDelivery: false,
    RefundDiscount:0,
    refundCartAmount:0,
    refundCartSubTotalAmount:0,
    paidAmount : 0,
    balancePaidAmt:0,
    dummyPaidAmount:0,
    oldBillNo:''
});


export const constantSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        dummyPaidAmount(state, action) {
            state.dummyPaidAmount = action.payload;
        },
        paidAmount(state, action) {
            state.paidAmount = action.payload;
        },
        balancePaidAmt(state, action) {
            state.balancePaidAmt = action.payload;
        },
        refundCartSubTotalAmount(state, action) {
            state.refundCartSubTotalAmount = action.payload;
        },
        paymentModeStatus(state, action) {
            state.paymentModeStatus = action.payload;
        },
        showDelivery(state, action) {
            state.showDelivery = action.payload;
        },
        refundCartAmount(state, action) {
            state.refundCartAmount = action.payload
        },
        customerItemData(state, action) {
            state.customerItemData = action.payload
        },
        productId(state, action) {
            state.productId = action.payload
        },
        addonRemarksShow(state, action) {
            state.addonRemarksShow = action.payload
        },
        itemIdPlucode(state, action) {
            state.itemIdPlucode = action.payload
        },
        usellpValueId(state, action) {
            state.usellpValueId = action.payload
        },
        showHoldSaleButton(state, action) {
            state.showHoldSaleButton = action.payload
        },
        pricelevelID(state, action) {
            state.pricelevelID = action.payload
        },
        ewalletModeData(state, action) {
            state.ewalletModeData = action.payload
        },
        onlineModeData(state, action) {
            state.onlineModeData = action.payload
        },
        takenoMode(state, action) {
            state.takenoMode = action.payload
        },
        showRefund(state, action) {
            state.showRefund = action.payload
        },
        RefundDiscount(state, action) {
            state.RefundDiscount = action.payload
        },
        oldBillNo(state, action) {
            state.oldBillNo = action.payload
        },
        
    },


});
export const {oldBillNo,dummyPaidAmount,balancePaidAmt,paidAmount,refundCartSubTotalAmount,refundCartAmount, RefundDiscount,showDelivery,ewalletModeData, pricelevelID, takenoMode, showRefund, showHoldSaleButton, paymentModeStatus, onlineModeData, customerItemData, productId, addonRemarksShow, itemIdPlucode, usellpValueId } = constantSlice.actions;

export default constantSlice.reducer;
