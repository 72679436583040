import { configureStore } from '@reduxjs/toolkit'

import itemsReducer from '../reducers/itemsSlice'
import billItemsReducer from '../reducers/billItemsSlice'
import homePageReducer from '../reducers/homePageSlice'
import tablesReducer from '../reducers/tablesSlice'
import takeAwayReducer from '../reducers/takeAwaySlice'
import billsReducer from '../reducers/billsSlice'
import busyOrdersReducer from '../reducers/busyOrdersSlice'
import onlineOrdersReducer from '../reducers/onlineOrdersSlice'
import supplierReducer from '../reducers/supplierSlice'
import expenseReducer from '../reducers/expenseSlice'
import customerReducer from '../reducers/customerSlice'
import userReducer from '../reducers/UserSlice'
import  cartSlice  from '../reducers/CartSlice'
import touchReducer from '../reducers/touchReducer'
import paySlice from '../reducers/paySlice'
import ConstantSlice from '../reducers/ConstantSlice'
import customersearchSlice from "../reducers/customersearchSlice";
import addonSlice from "../reducers/addonSlice";
import searchItemsSlice from '../reducers/searchItemsSlice'
import refundSlice from '../reducers/refundSlice'
import  newOnlineOrderSlice  from '../reducers/onlineOrderUnconfirmedSlice'
import  newConfirmOrderSlice  from '../reducers/onlineOrderCofirmed'
import  newDeliveryOrderSlice  from '../reducers/DeliveryOrdersSlice'
import  newCompletedOrderSlice  from '../reducers/completedOnlineOrderSlice'
import  newDeclinedOrdersSlice  from '../reducers/delinedOrdersSlice'
import deliveryBoySlice from '../reducers/deliveryBoySlice'

export default configureStore({
  reducer: {
    items: itemsReducer,
    billItems: billItemsReducer,
    homePage:homePageReducer,
    tables:tablesReducer,
    refund:refundSlice,
    addon: addonSlice,
    takeaways:takeAwayReducer,
    bills:billsReducer,
    busyorders:busyOrdersReducer,
    newOrders:newOnlineOrderSlice,
    newconfirmedOrders:newConfirmOrderSlice,
    newDeliveryOrders:newDeliveryOrderSlice,
    newCompletedOrders:newCompletedOrderSlice,
    newDeclinedOrders:newDeclinedOrdersSlice,
    // onlineorders:onlineOrdersReducer,
    suppliers:supplierReducer,
    expense:expenseReducer,
    customers:customerReducer,
    user:userReducer,
    cart:cartSlice,
    touch:touchReducer,
    paymode:paySlice,
    constVar:ConstantSlice,
    csearch:customersearchSlice,
    search: searchItemsSlice,
    deliveryBoy:deliveryBoySlice
  },
})
