import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
  } from "@reduxjs/toolkit";
  // import { BillingItem } from "../Models/BillingItem";
  import { client } from "../utilities/client";
  import { gql } from "@apollo/client";
  
  // const apolloFetch = createApolloFetch({ uri });
  // const variables = {
  // "count": 5
  // };
  
  const expenseAdapter = createEntityAdapter();
  const initialState = expenseAdapter.getInitialState({
  status: "idle",
  error: null,
  productList: null,
  });
  
  export const expenseItems = createAsyncThunk("expense/expenseItems",async (item) => {
  let dataToSend = {
    wanip: "mysql.amepos.in",
    dbusr: "user_amepos",
    dbpwd: 'bismillah$12',
    dbnam: "amepos_10",
  token:localStorage.getItem('token'),
  };
  let formBody = [];
  for (let key in dataToSend) {
  let encodedKey = encodeURIComponent(key);
  let encodedValue = encodeURIComponent(dataToSend[key]);
  formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const response = await fetch(
  "https://superadmin.amepos.io/TabPosApiNew/tabpos_expensehistory",
  {
  method: "POST",
  headers: {
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  body: formBody,
  }
  );
  
  return response.json();
  }
  );

  export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
  searchTerm: (state, action) => {
  state.searchTerm = action.payload;
  },
  },
  extraReducers: {
  [expenseItems.pending]: (state, action) => {
  state.status = "loading";
  },
  [expenseItems.fulfilled]: (state, action) => {
  state.status = "succeeded";
  state.productList = action.payload;
  
  expenseAdapter.removeAll(state);
  console.log("====================================");
  console.log(action.payload, "expenseeeeeeeeeeeeeeeee");
  console.log("====================================");
  const ExpenseHistoryArr = action.payload.ExpenseHistoryArr;
  const arr = [];
  
  console.log(ExpenseHistoryArr,"ExpenseHistoryArr");
  const map =  ExpenseHistoryArr.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.Date) - new Date(a.Date);
  });
  console.log(map,"sort");
  for (let i = 0; i < map.length; i++) {

  arr.push({
  id: i.toString(),
  date: map[i].Date,
  expnam: map[i].ExpenseName,
  amount: map[i].Amount,
  naration: map[i].Naration,
  empnam: map[i].EmployeeName,
  role: map[i].Role,
  });
  }
  console.log("====================================");
  console.log(arr,"expenseAdapter");
  console.log("====================================");
  arr.map((node) => expenseAdapter.addOne(state, node));
  },
  [expenseItems.rejected]: (state, action) => {
  state.status = "failed";
  state.error = action.error.message;
  },
  },
  });
  
  export default expenseSlice.reducer;
  export const {
  selectAll: selectAllExpense,
  selectEntities: select,
  } = expenseAdapter.getSelectors((state) => state.expense);
  
  