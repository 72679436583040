import React, { useState } from 'react';
import './SplitBill.css';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calc } from '../Commons/Calc';
import { useSelector, useDispatch } from 'react-redux';

// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';
import { EditQuantityModal } from './EditQuantityModal';
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { setActivePage, setActiveTab } from '../../reducers/homePageSlice';
import { cashierTabs, pageComponents } from '../../utilities/Constants';

import { confirmAlert } from 'react-confirm-alert';
export function SplitBill() {

  const dispatch = useDispatch();
  const cartItemsList = useSelector(selectAllCart);
  const [master, setmaster] = useState(cartItemsList)
  const [showEditQuantity, setShowEditQuantity] = useState(false);
  const CloseEditQuantity = () => setShowEditQuantity(false);
  const [state, setstate] = useState([])
  const [stateQty, setstateQty] = useState([])

  const ShowEditQuantityModal = (i) => {
    setstateQty(i)
    setShowEditQuantity(true);
  }


  const counter3 = (items) => {
    //alert("J+HHII")
    //console.log("HII");


    setstate([...state, {

      pd: items.productname,
      qty: parseInt(items.totalqty),
      price: items.amount,
      pricelevel: items.pricelevel

    }])
    getDataUsingPost(items.sno)

  }

  const getDataUsingPost = (a, b) => {
    //POST json
    // const arr=JSON.stringify([{"sno":sno}])

    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
      split_lines: JSON.stringify(b),
      table_lines: JSON.stringify(a),
      ordusr: localStorage.getItem('UserID'),
      tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_splitbill", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.SplitTableArr[0].SplitTable != 2) {

          const item = [
            {
              tableno: localStorage.getItem('tableno'),
            },
          ];
          // dispatch(cartItems());
          gotoCSPageAfterSplit()
          console.log("HIII");
        } else {
          // setErrortext("Please check your email id or password");
          console.log("Please check your email id or password");
          alert("Sorry No Data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };
  const goToBack = () => {
    dispatch(setActivePage(pageComponents.VIEWTABLES))
  }

  const gotoCSPageAfterSplit = () => {
    localStorage.setItem('tableno', "CT");
    dispatch(setActivePage(pageComponents.BILLING));
    dispatch(setActiveTab(cashierTabs.COUNTER_SALE));
    const item = [
      {
        tableno: "CT",
        terminalno: localStorage.getItem('TerminalName'),
      },
    ];
    dispatch(cartItems(item))
  }
    ;

  const submit = () => {

    // console.log(date,splitdata,datetoint,"date");
    if (state.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {

          return (
            <div>

              <div className="alert" style={{ background: '#2d5f2e', color: '#ffe77b' }}>
                <h1 className="alert__title"> {`Do You Want To Split This Bill`}</h1>
                <div style={{ display: 'flex', flexDirection: 'row' }}>

                  {/* </div> */}
                  <button
                    // onClick={printStatement(i,amount)}
                    onClick={() => {
                      // printStatement(i, amount);
                      onClose();
                    }}
                    className="alert__btn alert__btn--no" style={{
                      padding: '10px',
                      color: '#2d5f2e',
                      background: '#ffe77b',
                      fontWeight: 'bold',
                      borderRadius: '5px',
                      width: '100%'
                    }}>{'Cancel'}</button>
                  <button
                    // onClick={printStatement(i,amount)}
                    onClick={() => {
                      gotoCSPage()
                      // printStatement(i, amount);
                      onClose();
                    }}
                    className="alert__btn alert__btn--no" style={{
                      padding: '10px',
                      color: '#2d5f2e',
                      background: '#ffe77b',
                      fontWeight: 'bold',
                      borderRadius: '5px',
                      width: '100%'
                    }}>{'OK'}</button>

                </div>

              </div>
            </div>
          )
        },

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      {
        confirmAlert({
          customUI: ({ onClose }) => {

            return (
              <div>

                <div className="alert" style={{ background: '#2d5f2e', color: '#ffe77b', position: 'fixed', top: '5%', left: '25%' }}>
                  <h1 className="alert__title"> {`Nothing is Added to Counter Sale`}</h1>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>

                    {/* </div> */}
                    <button
                      // onClick={printStatement(i,amount)}
                      onClick={() => {
                        // printStatement(i, amount);
                        onClose();
                      }}
                      className="alert__btn alert__btn--no" style={{
                        padding: '10px',
                        color: '#2d5f2e',
                        background: '#ffe77b',
                        fontWeight: 'bold',
                        borderRadius: '5px',
                        width: '100%'
                      }}>{'Cancel'}</button>
                    <button
                      // onClick={printStatement(i,amount)}
                      onClick={() => {
                        // printStatement(i, amount);
                        dispatch(setActivePage(pageComponents.BILLING));
                        dispatch(cartItems())
                        onClose();
                      }}
                      className="alert__btn alert__btn--no" style={{
                        padding: '10px',
                        color: '#2d5f2e',
                        background: '#ffe77b',
                        fontWeight: 'bold',
                        borderRadius: '5px',
                        width: '100%'
                      }}>{'OK'}</button>

                  </div>

                </div>
              </div>
            )
          },

          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  };


  const gotoCSPage = () => {
    const arr = [];
    const arr2 = [];
    for (let i = 0; i < master.length; i++) {

      arr.push({
        sno: master[i].sno,
        qty: (master[i].totalqty).toString(),
        PriceLevelID: master[i].pricelevel,
        usellp: master[i].usellp
        // {"sno":"467","qty":"3","PriceLevelID":"28"},
      })

      // [{"sno":"467","qty":"1","PriceLevelID":"28","split":"1","usellp":"50.00"},
      // {"sno":"469","qty":"2","PriceLevelID":"29","split":"1",
      // "usellp":"50.00"}]
    }
    for (let i = 0; i < state.length; i++) {

      arr2.push({
        sno: state[i].sno,
        qty: (state[i].qty).toString(),
        PriceLevelID: state[i].pricelevel,
        usellp: state[i].usellp,
        split: state[i].split
        // {"sno":"467","qty":"3","PriceLevelID":"28"},
      })


      console.log(state, "ARR");
    }

    // console.log(arr,arr2);
    getDataUsingPost(arr, arr2)
  }

  const counter4 = (items) => {
    //alert("J+HHII")
    //
    setmaster([
      ...master,
      {
        sno: items.sno,
        productname: items.pd,
        totalqty: parseInt(items.qty),
        amount: items.price,
        pricelevel: items.pricelevel,
        usellp: items.usellp

      },
    ]);
    // console.log(items.sno);
    console.log(master, "master233");
    // var index=master.indexOf({sno:items.sno});
    var index = state.findIndex(function (state) {
      return state.sno === items.sno;
    });
    console.log(index);
    if (index > -1) {
      console.log("YES");
      state.splice(index, 1)
      // console.log(news);
      setstate(state);
    }

    //getDataUsingPost(items.sno);
  };

  const counter1 = (items) => {
    console.log('childTable', items);
    if (master.length > 0) {
      var index = master.findIndex(function (master) {
        return master.sno === items.sno;
      });

      if (index > -1) {

        console.log(parseInt(items.qty) + parseInt(master[index].totalqty), "ssssssqtychildTable");
        let changeqty = 0;

        console.log(changeqty, "changeqtychildTable");
        changeqty = parseInt(items.qty) + parseInt(master[index].totalqty);
        console.log(changeqty, "changeqtychildTable");
        // master[index] = ;
        let newArr = [...master]; // copying the old datas array
        console.log(newArr, "newArrchildTable");
        newArr[index].totalqty = changeqty;
        // setmaster(newArr);

        console.log(index, "newArrchildTableindex");
        console.log(newArr, "newArrchildTable");
        setmaster(newArr);

        console.log(newArr, master, "newArrchildTable");
        var index2 = state.findIndex(function (state) {
          return state.sno === items.sno;
        });
        console.log(index2, "changeQtychildTable");
        if (index2 > -1) {
          console.log("YES");
          state.splice(index2, 1)
          // console.log(news);
          setstate(state);
        }


      } else {
        counter4(items)
      }
    } else {
      counter4(items)
    }
  }



  const counter5 = (items) => {
    //alert("J+HHII")
    //

    setstate([
      ...state,
      {
        sno: items.sno,
        pd: items.productname,
        qty: parseInt(items.totalqty),
        price: items.amount,
        pricelevel: items.pricelevel,
        usellp: items.usellp,
        split: "0"

      },
    ]);
    console.log(items.sno);
    console.log(master);
    // var index=master.indexOf({sno:items.sno});
    var index = master.findIndex(function (master) {
      return master.sno === items.sno;
    });
    console.log(index, "changeQty");
    if (index > -1) {
      console.log("YES");
      master.splice(index, 1)
      // console.log(news);
      setmaster(master);
    }

    //getDataUsingPost(items.sno);
  };

  const counter = (items) => {
    if (state.length > 0) {
      var index = state.findIndex(function (master) {
        return master.sno === items.sno;
      });

      console.log(index, "childTableMatch");
      if (index > -1) {
        // setstate([
        //   ...state,
        //   {
        //     sno:items.sno,
        //     pd: items.productname,
        console.log(parseInt(items.totalqty) + parseInt(state[index].qty), "ssssssqty");
        let changeqty = 0;

        console.log(changeqty, "changeqty");
        changeqty = parseInt(items.totalqty) + parseInt(state[index].qty);
        console.log(changeqty, "changeqty");
        // state[index] = ;
        let newArr = [...state]; // copying the old datas array
        newArr[index].qty = changeqty;
        setstate(newArr);
        //     price: items.amount,
        //     pricelevel: items.pricelevel,
        //     usellp:items.usellp,
        //     split:"0"

        //   },
        // ]);

        var index2 = master.findIndex(function (master) {
          return master.sno === items.sno;
        });
        console.log(index2, "changeQty");
        if (index2 > -1) {
          console.log("YES");
          master.splice(index2, 1)
          // console.log(news);
          setmaster(master);
        }

      } else {
        counter5(items)
      }
    } else {

      counter5(items)
    }

  }

  console.log(master, "master");
  return (
    <>
      <PageTitle titleHeader="Split Bill" subTitle="Table 1" />
      <div class="row  flex-grow-1">
        <div class="col-12 col-sm-6 d-none d-sm-flex flex-column ">
          <div class="row w-100 text-center">
            <div class="col"><h4>Master Bill</h4></div>
          </div>
          <div class="row w-100 flex-grow-1 ">
            <div class="col splitbill-outer SplitBillPanel">
              <div class="card width-85 square-edges overflow__auto__y">

                {master.map((item, index) => (
                  <div class="row border-bottom">
                    <div class="col-md-5 pt-2 pl-5">
                      <h5>{item?.productname}</h5>
                      {/* <h5>Qty:{item?.totalqty}</h5> */}

                    </div>
                    <div class="col-md-4 pt-2">
                      <small>Qty:</small>
                      <h4>
                        {parseInt(item?.totalqty)}
                        {/* {item?.amount} */}
                      </h4>
                    </div>
                    <div class="col-md-2 text-right pt-2 pr-5">
                      {parseInt(item?.totalqty) == '1' ?
                        <button type="button" class="black_arrow " onClick={() => counter(item)}> <FontAwesomeIcon icon="angle-right"></FontAwesomeIcon></button>
                        :
                        <button type="button" class="black_arrow " onClick={() => ShowEditQuantityModal(item)}> <FontAwesomeIcon icon="angle-right"></FontAwesomeIcon></button>

                      }
                    </div>

                  </div>
                ))}



              </div>
            </div>
          </div>

        </div>
        <div class="col col-sm-6 d-none d-sm-flex flex-column">
          <div class="row w-100 text-center">
            <div class="col"><h4>Current Bill</h4></div>
          </div>
          <div class="row w-100 flex-grow-1 ">
            <div class="col splitbill-outer SplitBillPanel">
              <div class="card width-85  square-edges overflow__auto__y">

                {/* w-75 overflow-auto */}
                {state.map((item, index) =>
                  <div class="row border-bottom" style={{ height: '60px' }}>
                    {/* onClick={()=>ShowEditQuantityModal(item)} */}
                    <div class="col-md-5 pt-3 pl-5">
                      <h5>{item?.pd}</h5>
                      {/* <h5>Qty:{item?.qty}</h5> */}

                    </div>
                    <div class="col-md-3 pt-3">
                      {/* <h5>Qty:{item?.qty}</h5> */}
                      {/* <small>Qty:</small>
                  
                <h4>
                {item?.qty}
                  </h4> */}
                      <h5>Qty:{item?.qty}</h5>
                      <h4>

                        {/* {item?.qty} */}
                      </h4>
                    </div>
                    <div class="col-md-3 text-right pt-1 pr-5">
                      <button type="button" class="black_arrow " onClick={() => counter1(item)}> <FontAwesomeIcon icon="angle-left"></FontAwesomeIcon></button>
                    </div>

                  </div>
                )}

              </div>



            </div>

          </div>

        </div>

      </div>
      <div class="row ">
        <div class="col col-sm-6  ">

        </div>
        <div class="col col-sm-6  text-center">
          <div class="row w-100 flex-grow-1 pb-4">
            <div class="col splitbill-outer ">
              <div class="w-75 text-right ">
                <button class="btn btn-lg btn-secondary" style={{ width: '45%', marginRight: '10px' }} onClick={() => goToBack()} >BACK</button>

                <button class="btn btn-lg btn-infogreen" style={{ width: '45%' }} onClick={() => submit()}>Confirm</button>
                {/* p-4 */}
              </div>

            </div>

          </div>


        </div>


      </div>

      <EditQuantityModal show={showEditQuantity} setstate={setstate} state={state} master={master} setmaster={setmaster}
        stateQty={stateQty} animation={true} handleClose={CloseEditQuantity} >

      </EditQuantityModal>
    </>


  );

}
