import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'; 
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { Provider } from 'react-redux';
import store from './config/store'
// ./fonts/Goldman/Goldman-Bold.ttf'
// import './assets/fontFamily/Cambria.ttf';
import './assets/Calibri/Calibri/Calibri.ttf';

const cache = new InMemoryCache();
const link = new HttpLink({
  // uri: 'https://ameshop-backend-dev.amepos.in/graphql/'
})

const client = new ApolloClient({
  cache,
  link
})

// window.onload = () => {
//   localStorage.clear();
//   console.log("mooomooo");
// }

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
    <ApolloProvider client={client}><App /></ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
