import React, { useState,useEffect } from 'react';
import './BusyOrders.css';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calc } from '../Commons/Calc';
import { useSelector, useDispatch } from 'react-redux'
import { fetchBusyOrders,selectBusyOrders} from '../../reducers/busyOrdersSlice';
import { PageTitle } from '../Commons/PageTitle';
import { setActivePage, setActiveTab } from '../../reducers/homePageSlice';
import { pageComponents } from '../../utilities/Constants';
import { printBill } from '../../utilities/util';
import { selectAllTables } from '../../reducers/tablesSlice';
import { cartItems } from '../../reducers/CartSlice';
export function BusyOrders(props) {
  let content;
  const dispatch = useDispatch()
  const allBusyOrders = useSelector(selectAllTables)
console.log(allBusyOrders,"allBusyOrders");
  const busyOrdersStatus = useSelector((state) => state.tables?.statussss);
  const error = useSelector((state) => state.tables.error)

  const handlePrintDraft = (event, bill) => {
    // event.stopPropagation();
    // event.preventDefault();
    // printBill(bill);

}

  useEffect(() => {
    if (busyOrdersStatus === 'idle') {
      dispatch(fetchBusyOrders())
    }
  }, [busyOrdersStatus, dispatch])



  const getDataUsingPost = (busyorders) => {
    //POST json
    let dataToSend = {
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
    terminal: localStorage.getItem('TerminalName'),

    token:localStorage.getItem('token'),
    tabno: busyorders,
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_checkbusytable", {
    method: "POST",
    body: formBody,
    headers: {
    //Header Defination
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
    //alert(JSON.stringify(responseJson));
    console.log(responseJson);
    if (responseJson.CheckBusyArr[0].CheckBusy === 3) {
      handleClickBusyOrders(busyorders)
      } else {
        handleClickBusyOrders(busyorders)
      // alert("Terminal "+responseJson.CheckBusyArr[0].Openin+" Work in this table")
      }
    // if (responseJson.CheckBusyArr[0].CheckBusy != 2) {
    // const arr = [];
    // for (let i = 0; i < responseJson.CheckBusyArr.length; i++) {
    // arr.push({
    // chkbusy: responseJson.CheckBusyArr[i].CheckBusy,
    // });
    // }
    
    // console.log("HIII");
    // } else {
    // alert("Sorry No Data");
    //navigation.replace('DrawerNavigationRoutes')
    // }
    })
    //If response is not in json then in error
    .catch((error) => {
    //alert(JSON.stringify(error));
    console.error(error);
    });
    };

  const handleClickBusyOrders = (busyorders) => {
    // dispatch(addBill({ id: 1, customername: "chandru", lineitems: [] }));

    localStorage.setItem('tableno', busyorders);
    console.log(busyorders, "anand");
    const item = [
      {
        tableno: busyorders,
        terminalno: localStorage.getItem('TerminalName'),
      },
    ];
    dispatch(cartItems(item));
    dispatch(setActivePage(pageComponents.BILLING));
  }

  if (busyOrdersStatus === 'loading') {
    content = <div className="col-md-2">Loading...</div>
  } else if (busyOrdersStatus === 'succeeded') {
    content = allBusyOrders.length != 0  && allBusyOrders[0].BusyTable != 2 && allBusyOrders.map((busyOrder, index) => (
      <div class="col-md-2 pl-1 pt-1 pr-1" style={{flex: "0 0 20%",maxWidth: "25.666667%"}}>
        <a  class="btn-block-height text-decoration-none" href="#" onClick={() => getDataUsingPost(busyOrder.username)}>
          {/* <div class="card h-100 btn-red "> */}
          <div class="  "   style={{color:'white',height:'28vh',marginTop:'5px',
       
       // <div class="card h-100"   style={{color:'white',
backgroundColor:busyOrder?.amount === "0" && busyOrder?.printsts === "0"
            ? "#646464"
            : busyOrder?.amount != "0" && busyOrder?.printsts === "0"
            ? "#f19100"
            : busyOrder?.amount != "0" &&
              busyOrder?.printsts === "1" &&
              busyOrder?.draftflag === "0"
            ? "green"
            : (busyOrder?.draftflag === "1" ||
                busyOrder?.draftflag === "2") &&
              busyOrder?.amount != "0" &&
              busyOrder?.printsts === "1"
            ? "#B7064D"
            : "#646464",
        }}>
            <div class="d-flex justify-content-between">
            <button class="rounded-circle shadow p-2 printerButton border-0 bg-white m-2"  onClick={(e)=>handlePrintDraft(e,busyOrder.id)}><FontAwesomeIcon className={"printerIcon text-maroon p-1"} icon={["fas", "print"]}></FontAwesomeIcon></button>
              {/* <div class="m-2 h2 display-5">{busyOrder.name}</div>
            </div>
            <div class="d-flex flex-column">
              <span class="pl-2 lh-0"> Customer: CASH SALE</span>
              <span  class="pl-2 lh-0">No of Items: 1</span>
              <span  class="pl-2 lh-0">Order Taken by: AME</span>
              <span  class="pl-2 lh-0">Bill Amount: Rs 450</span>
              <span  class="pl-2 lh-0">In Time: 11:30 AM (101:06:34 hrs) </span>
              </div>  */}
             <div class="m-2 h2 display-5">{busyOrder?.username}</div>
            </div>
            <div class="d-flex flex-column">
              <span class="pl-2 lh-0"> Customer: {busyOrder?.customernam}</span>
              <span class="pl-2 lh-0">No of Items: {busyOrder?.departmentname}</span>
              <span class="pl-2 lh-0">Order Taken by: {busyOrder?.ordertakenby}</span>
              <span class="pl-2 lh-0">Bill  Amount: {busyOrder?.amount}</span>
              {/* <span class="pl-2 lh-0">In Time: {busyOrder?.intime} </span> */}
            </div>


         
          </div>
        </a>
      </div>
    ))
  } else if (busyOrdersStatus === 'failed') {
    content = <div className="col-md-12">{error}</div>
  }



  return (
    <>
      {/* <PageTitle titleHeader="BUSY ORDERS" titleDescription="" /> */}
      <div class="row ml-4 mr-4 mb-4 d-flex ">
        {content}
      </div>
    </>


  );

}
