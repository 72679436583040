import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { selectAllCart } from '../../reducers/CartSlice';
import './TextBoxAmount.css'



export function TextBoxAmount({dueAmount,totalAmount,totalDueAmount,dueTotalAmount,roundamnt}) {
  // export class TextBoxAmount extends Component {
  // static displayName = TextBoxAmount.name;
  const {Box,totalamount,balance,dueamount} =useSelector((state)=>state.touch)
  
  const balancePaidAmt = useSelector((state) => state.constVar.balancePaidAmt)
  const paidTotalAmount = useSelector((state) => state.constVar.paidAmount)
  const cartItemsList = useSelector(selectAllCart);
  // render() {
    return (
      <>
        <div class="d-flex flex-row align-self-end p1-4    pr-4 flex-grow-1">
          <div class="w-50">
            <h3 class="pl-4 text-danger Display__4">Paid Amount</h3>
          </div>
          <div class="md-auto flex-fill">
            <input class="text-center btn-block inputNoBorder-infogreen mb-2 height-30" style={ {borderBottom: "2px solid #42CACA"}} required autocomplete="off" value={paidTotalAmount}/>

          </div>
        </div>
        <div class="d-flex flex-row align-self-end p1-4    pr-4 flex-grow-1">
          <div class="w-50">
            <h3 class="pl-4 text-danger Display__4">Remaining Amount</h3>
          </div>
          <div class="md-auto flex-fill">
            <input class="text-center btn-block inputNoBorder-infogreen mb-2 height-30" style={ {borderBottom: "2px solid #42CACA"}} required autocomplete="off" value={Math.round(balancePaidAmt)}/>

          </div>
        </div>
        <div class="d-flex flex-row align-self-end p1-4  pb-4 pr-4 flex-grow-1">
          <div class="w-50">
            <h3 class='pl-4 text-danger Display__4'>Balance</h3>
          </div>
          <div class="md-auto flex-fill">
            <input class="text-center btn-block inputNoBorder-infogreen mb-2 height-30 " style={ {borderBottom: "2px solid #42CACA"}} required autocomplete="off" value={dueAmount}/>

          </div>
        </div>
      </>
    );
  }
