import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';



const PRODUCTS = gql`
query GetItems($count:Int)
{
products(first: $count) {
  edges {
    node {
      id
      name
      description
    }
  }
}
}
`;
// const apolloFetch = createApolloFetch({ uri });
// const variables = {
//   "count": 5
// };


const itemsAdapter = createEntityAdapter();
const initialState = itemsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchItems = createAsyncThunk('items/fetchItems', async () => {
 
  const response = await client
    .query({ query: PRODUCTS, variables: { "count": 5 } });

  return response.data;

})

export const billItemsSlice = createSlice({
  name: 'billItems',
  initialState,
  reducers: {
    addItem(state, action) {
      // const normalizedData = normalize(action.payload, bill);
      // console.log(normalizedData)
      // 
      const billItems = state.entities[action.payload.id]
    
      // //if not found Add a new item
      if (billItems) {

         //unselected all other items
        Object.values(state.entities).forEach((billItem) => {
          billItem.isselected = false
        })
        
        itemsAdapter.updateOne(state,
          {
            id: billItems.id,
            changes: {
              qty: billItems.qty + 1,
              itemprice: (billItems.qty + 1) * billItems.uomprice,
              isselected:true
            },
          });

      }
      else {
        //unselected all other items
        Object.values(state.entities).forEach((billItem) => {
          billItem.isselected = false
        })
        itemsAdapter.addOne(state, action.payload);
         
      }
    },
    incrementQty(state, action) {
      const billItems = state.entities[action.payload.id]

      if (billItems) {
         //unselected all other items
         Object.values(state.entities).forEach((billItem) => {
          billItem.isselected = false
        })

        itemsAdapter.updateOne(state,
          {
            id: billItems.id,
            changes: {
              qty: billItems.qty + 1,
              itemprice: (billItems.qty + 1) * billItems.uomprice,
              isselected: "true",
            },
          });
      }

    },
    decrementQty(state, action) {
      const billItems = state.entities[action.payload.id]

      if (billItems) {
         //unselected all other items
         Object.values(state.entities).forEach((billItem) => {
          billItem.isselected = false
        })
     
        if (billItems.qty - 1 <= 0) {
          itemsAdapter.removeOne(state, billItems.id);
        } else {
          itemsAdapter.updateOne(state,
            {
              id: billItems.id,
              changes: {
                isselected: true,
                qty: billItems.qty - 1,
                itemprice: (billItems.qty - 1) * billItems.uomprice,
                
              },
            });
        }
      }
    },
    addRemarks(state, action) {
      const billItems = state.entities[action.payload.id]

      if (billItems) {
         //unselected all other items
         Object.values(state.entities).forEach((billItem) => {
          billItem.isselected = false
        })
        itemsAdapter.updateOne(state,
          {
            id: billItems.id,
            changes: {
              remark: action.payload.id,
              isselected: true,
            },
          });
      }

    },
        emptyCart(state, action) {
          itemsAdapter.removeAll(state);

    },
    selectItem(state, action) {
      
      const billItems = state.entities[action.payload.id]

     
     

      //toggle selection
      if (billItems) {
         //if the clicked item is not selected then unselected all other item
        if(!billItems.isselected)
        {
          Object.values(state.entities).forEach((billItem) => {
            billItem.isselected = false
          })
        }
        itemsAdapter.updateOne(state,
          {
            id: billItems.id,
            changes: {
              isselected: billItems.isselected?false:true,
            },
          });
      }

    },
  },
  extraReducers: {
    [fetchItems.pending]: (state, action) => {

      state.status = 'loading'
    },
    [fetchItems.fulfilled]: (state, action) => {

      state.status = 'succeeded'
      // Add any fetched posts to the array
      console.log(action.payload);
      //state.billItems = state.billItems.concat(action.payload)
    },
    [fetchItems.rejected]: (state, action) => {

      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const { addItem, removeItem, incrementQty, decrementQty, addRemarks,selectItem ,emptyCart} = billItemsSlice.actions;

export default billItemsSlice.reducer;
export const { selectAll: selectAllBillItems } = itemsAdapter.getSelectors((state) => state.billItems);


export const selectBillItemsByBillId = createSelector(
  [selectAllBillItems, (state, billId) => billId],
  (billItems, billId) => billItems.filter((billitem) => billitem.user === billId)
);
