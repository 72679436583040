import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './DiscountPopUp.css';

import { selectAllCart } from '../../reducers/CartSlice';
import { Button, Modal, } from "react-bootstrap";
// import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { apiURI } from '../../utilities/client';
const DiscountPopUp = (props) => {

    const dispatch = useDispatch();
    const [numericValue, setNumericValue] = useState("");
    const cartItemsList = useSelector(selectAllCart);
    // const TableName = useSelector(state =>state.table.tablename)
    // const TableStatus = useSelector(state =>state.table.tableStatus)

    // console.log(cartItemsList,"cartItemsList");
    const addNumber = (num) => {
        setNumericValue(numericValue + num)
    
      }


    const clearAll = () => {

        setNumericValue("");
      }

    const applyDiscount = ()=>{
        // "T3JkZXI6MTQ1NA==" 
        var totalAmt = cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  cartItemsList[cartItemsList?.length - 1].subTotal !== undefined && cartItemsList[cartItemsList?.length - 1].subTotal + cartItemsList[cartItemsList?.length - 1].gst
        console.log(totalAmt,"totalAmt");
        try{
            if(totalAmt != undefined && totalAmt > parseInt(numericValue) ){
              var percentage= cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  parseFloat(cartItemsList[cartItemsList?.length - 1].subTotal)+parseFloat(cartItemsList[cartItemsList?.length - 1].gst) -numericValue;
            console.log(percentage,"percentage");
              dispatch({type:"discountPrecentage",payload:`${numericValue}`})
              dispatch({type:"totalamount",payload:percentage})
              dispatch({type:"dueamount",payload:1})
              clearAll();
              handleClose()
                    
            
            }
            else  if(totalAmt != undefined && totalAmt <=  parseInt(numericValue)){
              alert("Discount value should not be greater than or equal to total amount")
              handleClose()
            }

     
        } catch (error) {
          console.log("Error:", error);
        }
      }
    const handleClose = props.handleClose;

    return (
        <Modal
        show={props.show}
        animation={props.animation}
        size="md" className="shadow-xl border ">
        <Modal.Header className="border-0">
          <Modal.Title className="w-100">
            <div class="d-flex">
              <div class="ml-auto "><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 border-0">

          {/* <div class=" "> */}

            {/* <div class="d-flex flex-row flex-wrap pl-2 pr-3 "> */}

              {/* <input type="text" class="btn-block  square-edges splitBillQuantityInput"></input> */}
            {/* </div> */}
          {/* </div> */}
          <div class="ml-4 ">

          <div class="row text-center margin__bottom flex-wrap w-100">
        <div class=" w-100">
          <input class="text-center form-control-lg square-edges padding_5 inputCardNumber border" type="text" value={numericValue} />
        </div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(7)}
            ><strong>7</strong> </div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(8)}
            ><strong>8</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(9)}
            ><strong>9</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(4)}
            ><strong>4</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(5)}
            ><strong>5</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(6)}
            ><strong>6</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(1)}
            ><strong>1</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(2)}
            ><strong>2</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(3)}
            ><strong>3</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding_4 border calcButtons" 
        onClick={() => clearAll()}><
                FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(0)}
            ><strong>0</strong></div>
        <div class="col-4 padding_4 border " ><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>
         
      </div>

          
          </div>
          <div class="w-100 mb-4">
 
              <Button className="btn-lg btn btn-florogreen btn-block  padding_4"
               onClick={()=>applyDiscount()}
               >Enter</Button>
            
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

          <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
        </Modal.Footer> */}
      </Modal>
    )
}

export default DiscountPopUp
