import React, { useState,useEffect } from 'react';
import { Button, Modal, } from "react-bootstrap";
import  {setActivePage,updatePage} from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents ,ClosingSalesType} from '../../utilities/Constants'
import './ClosingSales.css'
export function ClosingSalesModal(props) {

  const [showClosingSales, setShowClosingSales] = useState(true);
  const dispatch = useDispatch();
  
  const handleClosingSales = (closingsaletype) => {
    
    dispatch(updatePage({
      id: pageComponents.CLOSINGSALES,
      changes: {
        ClosingSalesType: closingsaletype,

      },
    }));
    dispatch(setActivePage(pageComponents.CLOSINGSALES));
    handleClose();  
  }


  const handleClose = props.CloseClosingSales;
 
  
  return (
    <>
      <Modal
    show={props.show}
    animation={props.animation}
    size="lg" className="shadow-lg border ">
    <Modal.Header className="">
      <Modal.Title className="w-100">
        <div class="d-flex">
          <div class="p-2">Closing Sales</div>
          <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
        </div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0 m-0 border-0 modal-body-closing">
     
      <div class="pl-3 pt-3 mb-4">
        <a onClick={()=>{handleClosingSales(ClosingSalesType.WITHOUT_DENOMINATION)}} href="#" class="text-decoration-none text-dark"><h3 class=" display-6 text-center">Closing Sales without Denomination</h3></a>
      </div>
    
    
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{handleClosingSales(ClosingSalesType.WITH_DENOMINATION)}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">Closing Sales with Denomination</h3></a>

      </div>
     

    </Modal.Body>
    {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

        <div>
          <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
        </div>
      </Modal.Footer> */}
  </Modal>
    </>
  );

}