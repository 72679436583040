import React, { useState } from 'react';
import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';

import { setActivePage } from '../../reducers/homePageSlice';
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';


export function EmployeeCheckIn(props) {


  const dispatch = useDispatch();



  return (
    <>
      <div class="row mt-3 ">
        <div class="col-md-12">
          <PageTitle titleHeader="Employee Attendance"  >

          </PageTitle>
        </div>

      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">
          <div class="row w-100 border m-5 flex-grow-1 ">
            <div class="d-flex flex-column bd-highlight mb-3  w-100">
              <div class="p-2 bd-highlight m-2">
                <h2>Check In</h2>
          </div>
              <div class="p-2 bd-highlight align-self-center w-75">
                <div class="d-flex flex-row mb-4 justify-content-center ">

                  <div class="form-group">
                    <label for="exampleInputEmail1" ><h2>Card Number</h2></label>
                    <h4 id="emailHelp" class="form-text text-muted">Scan the card</h4>
                    <input type="email" class="inputCardNumber  btn-block" id="exampleInputEmail1" aria-describedby="emailHelp" />

                  </div>  

                </div>
                <div class="d-flex flex-row mt-2 justify-content-center ">
                  <div class="flex-fill text-center">
                    <button type="button" class="btn btn-secondary btn-lg w-40 p-4" >SUBMIT</button>
                  </div>


                </div>
              </div>
              <div class="p-2 bd-highlight flex-grow-1"> </div>
            </div>
          </div>

        </div>


      </div>

    </>


  );

}
