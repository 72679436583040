import {
    createSlice,
    createEntityAdapter,
    createSelector,
    createAsyncThunk,
    } from "@reduxjs/toolkit";
    
    
    // const apolloFetch = createApolloFetch({ uri });
    // const variables = {
    // "count": 5
    // };
    const query = `query ProductList($search:String!){ #pass the search string
    products(first: 100, filter: {search:$search },channel:"default-channel") {
    edges {
    node {
    name #item name
    id #item Id
    variants {
    name #item's variant name[sub-item name]
    id #variant Id
    }
    }
    }
    }
    }
    `;
    
    const searchQuery = `query ProductList($search:String!){ #pass the search string
    products(first: 100, filter: {search:$search }) {
    edges {
    node {
    name #item name
    id #item Id
    variants {
    name #item's variant name[sub-item name]
    id #variant Id
    }
    }
    }
    }
    }
    `;
    
    const searchAdapter = createEntityAdapter();
    const initialState = searchAdapter.getInitialState({
    status: "idle",
    error: null,
    itemssearch: null,
    });
    
    
    export const searchItems = createAsyncThunk("search/items", async (item) => {
    // const response = await client.headers( {Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5MzA5MDgsIm93bmVyIjoic2FsZW9yIiwiZXhwIjoxNjI2OTMxMjA4LCJ0b2tlbiI6Iko4MFRBMEE5RjBmTyIsImVtYWlsIjoib25saW5lYWNjb3VudHNAYW1lcG9zLmluIiwidHlwZSI6ImFjY2VzcyIsInVzZXJfaWQiOiJWWE5sY2pveCIsImlzX3N0YWZmIjp0cnVlfQ.x_0NziiFpPnVkD87fopROrjt4NhelI38jvHdmT2wWJs'}).query({ query: PRODUCTS, variables: { "category" : "Q2F0ZWdvcnk6Nw==" } });
    // console.log("data items list",response.data);
    // return response.data;
    console.log("====================================");
    console.log(item);
    console.log("===================yas=================");
    //alert(id);
    //debugger;
    // const response = () => {
    // //POST json
    let dataToSend = {
        token:localStorage.getItem('token'),
    search_text: item.item[0].st,
    terminal:localStorage.getItem('TerminalName'),
    };
    let formBody = [];
    for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const response = await fetch(
    "https://superadmin.amepos.io/TabPosApiNew/tabpos_searchitems",
    {
    method: "POST",
    headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formBody,
    }
    )
    return response.json();
    });
    
    export const searchItemsSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
    searchTerm: (state, action) => {
    console.log("dararayd,s,msmsl", action.payload);
    
    state.searchTerm = action.payload;
    },
    
    //--------------------------
    searchAllItemsRemoveAfterSearch(state, action) {
    // const products = state.entities[action.payload.id]
    
    searchAdapter.removeAll(state);
    },
    //=======================
    },
    
    extraReducers: {
    [searchItems.pending]: (state, action) => {
    state.status = "loading";
    },
    [searchItems.fulfilled]: (state, action) => {
    state.status = "succeeded";
    state.itemssearch = action.payload
    
    searchAdapter.removeAll(state);
    console.log("===================PRODUCT=================");
    console.log(action.payload);
    console.log("====================================");
    
    
    const ProductArr = action.payload.ProductArr;
    const arr = [];
    if(ProductArr.length > 0 && ProductArr[0].Product != 2){
        for (let i = 0; i <ProductArr.length; i++) {
            arr.push({
            id:i.toString(),
            productname:ProductArr[i].ProductName,
            plucod:ProductArr[i].plucod,
            gst:ProductArr[i].GST,
            catid:ProductArr[i].catid,
            deptid:ProductArr[i].deptid,
            itemdisplay:ProductArr[i].itemdisplay,
            image:ProductArr[i].image,
            
            });
            }
    }else if(ProductArr.length > 0 && ProductArr[0].Product == 2){
        // alert('Product Not Found')
    }
    
    console.log("====================================");
    console.log(arr,"search");
    console.log("====================================");
    arr.map((node) => searchAdapter.addOne(state, node));
    
    },
    [searchItems.rejected]: (state, action) => {
    state.status = "failed";
    state.error = action.error.message;
    },
    },
    });
    
    export const { searchAllItemsRemoveAfterSearch } = searchItemsSlice.actions;
    
    export default searchItemsSlice.reducer;
    export const {
    selectAll: selectSearchItems,
    selectEntities: select,
    } = searchAdapter.getSelectors((state) => state.search);
    
    //
    
    // export const selectItemsForSearch = createSelector(
    // [selectAllItems, (state, searchTerm) => searchTerm],
    // (items, searchTerm) => items.filter((item) => item.name === searchTerm)
    // )
    // export const selectItemsForSearch = (state) => state.items.filter((item) => item.name.includes(searchTerm));
    