import React, { useState } from 'react';
import { Button, Modal, } from "react-bootstrap";
import './SplitBill.css';
import { Calc } from "../Commons/Calc";
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function EditQuantityModal(props) {



  const [numericValue, setNumericValue] = useState("");


  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }



  const handleClose = props.handleClose;


  const counter = (items) => {
    //alert("J+HHII")
    //

    // console.log(items.sno);
    // console.log(master);
    // var index=master.indexOf({sno:items.sno});
    var index = props.master.findIndex(function (master) {
      return master.sno === props.stateQty.sno;
    });


    console.log(index);
    if (index > -1) {
      console.log("YES", props.master);
      var qty = 0;
      if (parseInt(props.stateQty.totalqty) > 1 && (props.stateQty.totalqty - parseInt(numericValue) >= 0)) {
        qty = parseInt(props.stateQty.totalqty) - parseInt(numericValue)
        // let javascript_freelancers = freelancers.filter(function(freelancer) {
        //   return freelancer.skill == index});
        if (props.state.length > 0) {
          var index2 = props.state.findIndex(function (master) {
            return master.sno === props.stateQty.sno;
          });
          console.log(index2, "childTableMatch");
          if (index2 > -1) {
            // setstate([
            //   ...state,
            //   {
            //     sno:items.sno,
            //     pd: items.productname,
            console.log(parseInt(numericValue) + parseInt(props.state[index2].qty), "ssssssqty");
            let changeqty = 0;

            console.log(changeqty, "changeqty");
            changeqty = parseInt(numericValue) + parseInt(props.state[index2].qty);
            console.log(changeqty, "changeqty");
            // state[index] = ;
            //     price: stateQty.amount,
            //     pricelevel: stateQty.pricelevel,
            //     usellp:stateQty.usellp,
            //     split:"0"

            //   },
            // ]);

            if (qty != 0) {

              let newArr = [...props.state]; // copying the old datas array
              newArr[index2].qty = changeqty;
              newArr[index2].split = "1";
              
              props.setstate(newArr);
            } else {
              
              let newArr = [...props.state]; // copying the old datas array
              newArr[index2].qty = changeqty;
              newArr[index2].split = "0";
              
              props.setstate(newArr);

              // props.setstate([
              //   ...props.state,
              //   {
              //     sno: props.stateQty.sno,
              //     pd: props.stateQty.productname,
              //     qty: parseInt(numericValue),
              //     price: props.stateQty.amount,
              //     pricelevel: props.stateQty.pricelevel,
              //     usellp: props.stateQty.usellp,
              //     split: "0"
              //   },
              // ]);
            }
            console.log(props.state,"props.stateprops.state");
            props.master.splice(index, 1)
            console.log(parseInt(props.stateQty.totalqty), qty, "qty");
            if (qty > 0) {
              props.setmaster([
                ...props.master,
                {
                  ...props.stateQty,
                  totalqty: qty,

                  // sno:props.stateQty.sno,
                  // pd: props.stateQty.productname,
                  // qty: parseInt(numericValue),
                  // price: props.stateQty.amount,

                },
              ]);
            } else {

              // props.master.splice(index, 1)
            }


          } else {

            if (qty != 0) {
              props.setstate([
                ...props.state,
                {
                  sno: props.stateQty.sno,
                  pd: props.stateQty.productname,
                  qty: parseInt(numericValue),
                  price: props.stateQty.amount,
                  pricelevel: props.stateQty.pricelevel,
                  usellp: props.stateQty.usellp,
                  split: "1"
                },
              ]);
            } else {
              props.setstate([
                ...props.state,
                {
                  sno: props.stateQty.sno,
                  pd: props.stateQty.productname,
                  qty: parseInt(numericValue),
                  price: props.stateQty.amount,
                  pricelevel: props.stateQty.pricelevel,
                  usellp: props.stateQty.usellp,
                  split: "0"
                },
              ]);
            }

            props.master.splice(index, 1)
            console.log(parseInt(props.stateQty.totalqty), qty, "qty");
            if (qty > 0) {
              props.setmaster([
                ...props.master,
                {
                  ...props.stateQty,
                  totalqty: qty,

                  // sno:props.stateQty.sno,
                  // pd: props.stateQty.productname,
                  // qty: parseInt(numericValue),
                  // price: props.stateQty.amount,

                },
              ]);
            } else {

              // props.master.splice(index, 1)
            }
          }
        } else {

          if (qty != 0) {
            props.setstate([
              ...props.state,
              {
                sno: props.stateQty.sno,
                pd: props.stateQty.productname,
                qty: parseInt(numericValue),
                price: props.stateQty.amount,
                pricelevel: props.stateQty.pricelevel,
                usellp: props.stateQty.usellp,
                split: "1"
              },
            ]);
          } else {
            props.setstate([
              ...props.state,
              {
                sno: props.stateQty.sno,
                pd: props.stateQty.productname,
                qty: parseInt(numericValue),
                price: props.stateQty.amount,
                pricelevel: props.stateQty.pricelevel,
                usellp: props.stateQty.usellp,
                split: "0"
              },
            ]);
          }

          props.master.splice(index, 1)
          console.log(parseInt(props.stateQty.totalqty), qty, "qty");
          if (qty > 0) {
            props.setmaster([
              ...props.master,
              {
                ...props.stateQty,
                totalqty: qty,

                // sno:props.stateQty.sno,
                // pd: props.stateQty.productname,
                // qty: parseInt(numericValue),
                // price: props.stateQty.amount,

              },
            ]);
          } else {

            // props.master.splice(index, 1)
          }
        }




        console.log(props.master, "props.master3");

      } else if (parseInt(props.stateQty.totalqty) == 1 && parseInt(numericValue) == 1) {

        qty = parseInt(props.stateQty.totalqty) - parseInt(numericValue)
        if (qty != 0) {
          props.setstate([
            ...props.state,
            {
              sno: props.stateQty.sno,
              pd: props.stateQty.productname,
              qty: parseInt(numericValue),
              price: props.stateQty.amount,
              pricelevel: props.stateQty.pricelevel,
              usellp: props.stateQty.usellp,
              split: "1"
            },
          ]);
        } else {
          props.setstate([
            ...props.state,
            {
              sno: props.stateQty.sno,
              pd: props.stateQty.productname,
              qty: parseInt(numericValue),
              price: props.stateQty.amount,
              pricelevel: props.stateQty.pricelevel,
              usellp: props.stateQty.usellp,
              split: "0"
            },
          ]);
        }
        props.master.splice(index, 1)

        // console.log(news);
        props.setmaster(props.master);


        console.log(props.master, "props.master");
      } else {
        alert('Please Enter Correct Qty')
        // console.log(props.stateQty,parseInt(numericValue) - props.stateQty.qty,"props.master2");
      }
      // props.master.splice(index, 1)
      // console.log(news);
      // props.setmaster(props.master);
    }

    setNumericValue("");
    handleClose()
    //getDataUsingPost(items.sno);
  };

  const counter1 = (items) => {
    //alert("J+HHII")
    //
    // props.setmaster([
    //   ...props.master,
    //   {
    //     sno:items.sno,
    //     productname:items.pd,
    //     totalqty: parseInt(items.qty),
    //     amount: items.price,
    //   },
    // ]);
    props.setstate([
      ...props.state,
      {
        ...props.stateQty,
        // sno:props.stateQty.sno,
        // pd: props.stateQty.productname,
        qty: parseInt(numericValue),
        // price: props.stateQty.amount,
      },
    ]);
    // console.log(items.sno);
    // console.log(master,"master");
    // var index=master.indexOf({sno:items.sno});
    var index = props.stateQty.findIndex(function (state) {
      return state.sno === props.stateQty.sno;
    });
    console.log(index);
    if (index > -1) {
      console.log("YES");
      props.state.splice(index, 1)
      // console.log(news);
      props.setstate(props.state);
    }

    //getDataUsingPost(items.sno);
  };
  console.log(props.state, "props.setstate");
  return (
    <Modal
      show={props.show}
      animation={props.animation}
      size="md" className="shadow-xl border ">
      <Modal.Header className="border-0">
        <Modal.Title className="w-100">
          <div class="d-flex">
            <div class="p-2"></div>
            <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 border-0">

        <div class=" ">

          <div class="d-flex flex-row flex-wrap pl-2 pr-3 ">

            {/* <input type="text" class="btn-block  square-edges splitBillQuantityInput"></input> */}
          </div>
        </div>
        <div class="ml-4 ">

          <div class="row text-center my-4 flex-wrap w-100">
            <div class=" w-100">
              <input class="text-center form-control-lg square-edges p-5 inputCardNumber border" type="text" value={numericValue} />
            </div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

            <div class="w-100"></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
            <div class="col-4 p-4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
            <div class="col-4 p-4 border " onClick={() => deleteNumber()}><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>

          </div>


        </div>
        <div class="w-100 mb-4">

          <Button className="btn-lg btn btn-florogreen btn-block  p-4" onClick={() => counter()}>Enter</Button>

        </div>
      </Modal.Body>
      {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

          <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
        </Modal.Footer> */}
    </Modal>
  );
}
