import React, { useEffect, useState } from 'react';
import {  Modal, } from "react-bootstrap";
import  {setActivePage,updatePage} from '../../reducers/homePageSlice';
import {  useDispatch } from 'react-redux';
import { pageComponents ,ClosingSalesType} from '../../utilities/Constants'
import './Billing.css'
export function ChangePriceModal(props) {

  const [companyDetails,setCompanyDetails] = useState();
  const [showChangePrice, setShowChangePrice] = useState(true);
  const dispatch = useDispatch();
  
  const handleChangePrice = (closingsaletype) => {
    
    dispatch(updatePage({
      id: pageComponents.EXPENSEPAYOUTLIST,
      changes: {
        ChangePriceType: closingsaletype,

      },
    }));
    dispatch(setActivePage(pageComponents.EXPENSEPAYOUTLIST));
    handleClose();  
  }


  const handleClose = props.handleClose;
  const handleOpenPrice = props.handleOpenPriceModal;

  const getDataUsingPostData = () => {
    //POST json
    let dataToSend = {
      
      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token:localStorage.getItem('token'),
      // terminal: localStorage.getItem('TerminalName'),
      // cash: numericValue,
      // formattedDate: datetime,
      // closeusr:localStorage.getItem('UserID'),
      // wanip: tokenCreate[0].Wanip,
      // dbusr: tokenCreate[0].DBUser,
      // dbpwd: tokenCreate[0].DBPwd,
      // dbnam: tokenCreate[0].DBname,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        // console.log(responseJson,"companyName");
        if (responseJson.clientdtlArr[0].clientdtl != 2) {
         setCompanyDetails(responseJson.clientdtlArr[0])
          // getFinal();
          

          localStorage.setItem('billNo',responseJson.clientdtlArr[0].billno);
        } else {
          alert("Sorry Something Went Wrong in Token Details");
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };

  useEffect(()=>{
    getDataUsingPostData()
  },[])
  // handleChangePrice(ClosingSalesType.WITH_DENOMINATION)
  // handleChangePrice(ClosingSalesType.WITH_DENOMINATION)
  // handleChangePrice(ClosingSalesType.WITH_DENOMINATION)
  // handleChangePrice(ClosingSalesType.WITH_DENOMINATION)
  
  const changeToNormalPrice = () =>{
    localStorage.setItem('sellpName',"Normal Price")
    localStorage.setItem('sellp',"")
    handleClose()
  }
  const changeTosellp1nam = () =>{
    localStorage.setItem('sellpName',`${companyDetails?.sellp1nam}`)
    localStorage.setItem('sellp',1)
    handleClose()
  }
  const changeTosellp2nam = () =>{
    localStorage.setItem('sellpName',`${companyDetails?.sellp2nam}`)
    localStorage.setItem('sellp',2)
    handleClose()
  }
  const changeTosellp3nam = () =>{
    localStorage.setItem('sellpName',`${companyDetails?.sellp3nam}`)
    localStorage.setItem('sellp',3)
    handleClose()
  }
  const changeTosellp4nam = () =>{
    localStorage.setItem('sellpName',`${companyDetails?.sellp4nam}`)
    localStorage.setItem('sellp',4)
    handleClose()
  }
  const changeTosellp5nam = () =>{
    localStorage.setItem('sellpName',`${companyDetails?.sellp5nam}`)
    localStorage.setItem('sellp',5)
    handleClose()
  }

  return (
    <>
      <Modal
    show={props.show}
    animation={props.animation}
    size="md" className="shadow-lg border ">
    <Modal.Header className="">
      <Modal.Title className="w-100">
        <div class="d-flex">
          <div class="p-2">Change Price</div>
          <div class="ml-auto p-2"><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
        </div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0 m-0 border-0 modal-body-changeprice">
     
      <div class="pl-3 pt-3 mb-4">
        <a onClick={()=>{changeToNormalPrice()}}  href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">Normal Price</h3></a>
      </div>
    
    
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp1nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp1nam != null && companyDetails?.sellp1nam != undefined && companyDetails?.sellp1nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp2nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp2nam != null && companyDetails?.sellp2nam != undefined && companyDetails?.sellp2nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp3nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp3nam != null && companyDetails?.sellp3nam != undefined && companyDetails?.sellp3nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp4nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp4nam != null && companyDetails?.sellp4nam != undefined && companyDetails?.sellp4nam}</h3></a>

      </div>
      <div class="pt-3 pb-2 pl-3 mb-2  border-top">
      <a onClick={()=>{changeTosellp5nam()}} href="#"  class="text-decoration-none text-dark"><h3 class=" display-6 text-center">{companyDetails?.sellp5nam != null && companyDetails?.sellp5nam != undefined && companyDetails?.sellp5nam}</h3></a>

      </div>

    </Modal.Body>
    {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

        <div>
          <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
        </div>
      </Modal.Footer> */}
  </Modal>
    </>
  );

}