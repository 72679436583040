import { gql, useQuery } from '@apollo/client';
import './ClosingSales.css'

export function ClosingSummary({one,two, five, twohnsnd ,ten ,twenty, fifty, hnrd ,twohnrd, fivehnrd}) {


    return (
        <div class="row ml-5 pl-3 marginTop__4">
            <div class="col">
                <div class="row padding__bottom">
                    <div class="col">
                        <h2 class="float-right pr-5 Display__4">Amount</h2>
                    </div>
                </div>
                <div class="row ">
                    <div class="col d-flex pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">1 x {one}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(1 * one)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">2 x {two}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(2 * two)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">5 x {five}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(5 * five)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">10 x {ten}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(10 * ten)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">20 x {twenty}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(20 * twenty)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">50 x {fifty}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(50 * fifty)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">100 x {hnrd}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(100 * hnrd)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">200 x {twohnrd}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(200 * twohnrd)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">500 x {fivehnrd}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(500 * fivehnrd)}</h4></div>

                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex  pr-5">
                        <div class="w-40">
                            <h4 class="display-5 text-left fontSize__h4">2000 x {twohnsnd}</h4>
                        </div>
                        <div class="w-20" ><h4 class=" display-5 text-center ">=</h4></div>
                        <div class="w-40"><h4 class=" display-5 text-right fontSize__h4">{parseInt(2000 * twohnsnd)}</h4></div>

                    </div>
                </div>
            </div>
        </div>


    );
}

