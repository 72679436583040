import React, { Component, useEffect, useState } from 'react';
import './Login.css'
import { LoginForm } from "./LoginForm";
import { Lock } from "./Lock";
import { ToastContainer, toast } from 'react-toastify';
import { apiURI, client } from '../../utilities/client'
import { useForm } from 'react-hook-form'
import { Input } from 'reactstrap';

export function Login(props) {


  const { register, handleSubmit, errors } = useForm();
  let content;
  const onSubmit = data => {
    if (data !== '') {
      alert('You submitted the form and stuff!');
    } else {
      errors.showMessages();
      return false;
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("refreshToken")?.length > 0? true:false);
  const [isUnLocked, setIsUnLocked] = useState(localStorage.getItem("locked"));
  //const [togglePassword, setTogglePassword] = useState(false)

  //setIsLoggedIn(localStorage.getItem("refreshToken")?.length > 0? true:false);
  const [value, setValue] = useState(
    localStorage.getItem('profileURL')
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );
 
  useEffect(() => {
   
    if (isLoggedIn) {
      content = <Lock handleLogin={unlockClick} />;
    } else {
      content = <LoginForm handleLogin={loginAuth} />;
    }
  }, [isLoggedIn])

  useEffect(() => {

    //localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
  }, [value, name]);

  const loginAuth = async (email,password) => {
    // e.preventDefault();

    


    try {



      var query = `mutation CreateToken($email:String!,$password:String!){
        tokenCreate(email:$email, password:$password){
          token
          user{
            firstName #First Name
            lastName # Last Name
            email
            isStaff # User Type if he is Staff or User
            id
        }
          errors{
              field
              message
          }
        }
      }`;



      fetch(apiURI , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables: {
            "email": email,
            "password": password,
          }
        })
      })
        // .then((json) => {
        //   console.log("json data ::",json);
        // })
        .then(r => r.json())
        .then(data => {
          console.log('data returned:', data);
          if (data?.data?.tokenCreate?.token !== null) {
            localStorage.setItem('refreshToken', data?.data?.tokenCreate?.token);
            localStorage.setItem('locked', true);
            setIsLoggedIn(localStorage.getItem("refreshToken")?.length > 0? true:false);
            setIsUnLocked(false);
            //localStorage.setItem('Name', data?.data?.tokenCreate?.user?.firstName + " " + data?.data?.tokenCreate?.user?.lastName);
            setName(data?.data?.tokenCreate?.user?.firstName + " " + data?.data?.tokenCreate?.user?.lastName)
            sessionStorage.setItem('isStaff', data?.data?.tokenCreate?.user?.isStaff);
            sessionStorage.setItem('UserId', data?.data?.tokenCreate?.user?.id);
            // debugger;
            // window.location.hostname;
            // window.location.href = `${process.env.PUBLIC_URL}/login`
            // setTimeout(() => {
            //   props.history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
            // }, 200);
          }
          else {
            setLoading(false)
            toast.error("Login Failed");
            return false;
          }
        });





    } catch (error) {
      setLoading(false)
      setTimeout(() => {
        toast.error("Oppss.. The password is invalid or the user does not have a password.");
      }, 200);
    }
  }


   const unlockClick = (unlockCode) => {
    localStorage.setItem('locked', false);
    window.location.href = `${process.env.PUBLIC_URL}/home/billing`
   }



  //   const isLoggedIn = this.state.loggedIn;
  //   let content;
  //   if (isLoggedIn) {
  //     content =  <Lock handleLogin={this.loginClick}/>;
  //   } else {
  //     content =  <LoginForm handleLogin={this.handleLogin}/>;
  //   }s

  return (
    <div class="container-fluid d-flex  flex-column h-100">



      <div class="row flex-grow-1">
        <div class="col-sm-6 login-first-column d-flex flex-column ">
          <div class="p-5 "><h1 class="display-3 text-white">SHOP NAME</h1></div>
          <div class="p-2   flex-fill"></div>
          <div class="p-5 "><img class="loginLogo" src="images/logo-w.png"></img></div>

        </div>
        <div class="col-sm-6 login-second-column  d-flex flex-column">

          <div class=" ">
            &nbsp;
          </div>
          <div class="flex-fill  simple-login-container d-flex w-100 justify-content-center align-items-center">
            {
             isLoggedIn && isUnLocked !== true?
                <Lock handleLogin={unlockClick} />
              :
                 <LoginForm handleLogin={loginAuth} />
              
            }

          </div>
          <div class="p-5">

            <p class="text-center btn-block w-100 h4">www.amepos.io</p>


          </div>
         
        </div>
      </div>

    </div>


  );

}






