import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import './DiscountPopUp.css';

import { cartItems, selectAllCart } from '../reducers/CartSlice';
import { Button, Modal, } from "react-bootstrap";
// import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { apiURI } from '../utilities/client';
const ModalPopLogout = (props) => {

    const dispatch = useDispatch();
    const [numericValue, setNumericValue] = useState("");
    const cartItemsList = useSelector(selectAllCart);
    // const TableName = useSelector(state =>state.table.tablename)
    // const TableStatus = useSelector(state =>state.table.tableStatus)

    const addNumber = (num) => {
        setNumericValue(numericValue + num)
    
      }


    const clearAll = () => {

        setNumericValue("");
      }

    const applyDiscount = ()=>{
        // "T3JkZXI6MTQ1NA==" 
        try{
            
       var percentage= cartItemsList.length > 0 && cartItemsList[0].name !="yasar" &&  parseFloat(cartItemsList[cartItemsList?.length - 1].subTotal) -numericValue;
            
      dispatch({type:"discountPrecentage",payload:`${numericValue}`})
      dispatch({type:"totalamount",payload:percentage})
      dispatch({type:"dueamount",payload:1})
      clearAll();
      handleClose()
            
    
        } catch (error) {
          console.log("Error:", error);
        }
      }
    const handleClose = props.handleClose;
    const emptyCartData = (sno) => {
      let dataToSend = {
        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token:localStorage.getItem('token'),
        tabno: 'CT',
        terminal:localStorage.getItem('TerminalName'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_emptycart", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          //alert(JSON.stringify(responseJson));
          const item = [
            {
                tableno: 'CT',
            },
          ];
          
          dispatch(cartItems(item));


          



          if (responseJson.EmptyCartArr[0].EmptyCart != 2) {
            const arr = [];
            for (let i = 0; i < responseJson.EmptyCartArr.length; i++) {
              arr.push({
                emt: responseJson.EmptyCartArr[i].EmptyCart,
              });
            }
  
          } else {
            alert("Sorry No Data");
            //navigation.replace('DrawerNavigationRoutes')
          }
        })
        //If response is not in json then in error
        .catch((error) => {
          //alert(JSON.stringify(error));
          console.error(error);
        });
    };

    const SignOut = () => {
      

      emptyCartData();
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("Name");
        localStorage.removeItem("isStaff");
        localStorage.removeItem("locked");
        localStorage.removeItem("UserID");
        localStorage.removeItem("tableno");
        localStorage.removeItem("TerminalName");
        
        localStorage.removeItem("UserName");
        localStorage.removeItem("token");
        localStorage.removeItem("Login");
     
       window.location.href = `${process.env.PUBLIC_URL}/login`
        //toast.success("Logged out!");
       //  setTimeout(() => {
       //   props.history.push(`${process.env.PUBLIC_URL}/`);
       // }, 200);
       }
       const PinPage = () => {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("Name");
        localStorage.removeItem("isStaff");
        localStorage.removeItem("UserID");
        localStorage.removeItem("tableno");
        localStorage.removeItem("TerminalName");
        
        localStorage.removeItem("UserName");
     
       window.location.href = `${process.env.PUBLIC_URL}/login`
        //toast.success("Logged out!");
       //  setTimeout(() => {
       //   props.history.push(`${process.env.PUBLIC_URL}/`);
       // }, 200);
       }

    return (
        <Modal
        show={props.show}
        animation={props.animation}
        size="md" className="shadow-xl border ">
        <Modal.Header className="border-0">
          <Modal.Title className="w-100">
            <div class="d-flex">
              {/* <div class="ml-auto "><button class="btn-circle" onClick={() => handleClose()}>X</button></div> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 border-0">

   
          <div class="ml-4 ">


          <div class="row text-center margin__bottom flex-wrap w-100">
        <div class=" w-100" style={{fontSize:'21px'}}>
            Do you Want to LogOut
        </div>
        </div>
          
          </div>
          {/* mb-4 */}
          <div class="w-100 " style={{display:'flex'}}>
 
              <Button className="btn-lg btn btn-florogreen padding_4"
               onClick={()=>SignOut()}
               style={{display:'block',width:'95%',marginRight:'3%'}}
               >
                    {/* btn-block  */}
                   {/* <img src="/images/logout.png" alt="" class="headerMenu" style={{marginRight:'10%',color:'black'}} ></img> */}
               LogOut</Button>
               {/* <Button className="btn-lg btn btn-florogreen btn-block  padding_4"
               onClick={()=>handleClose()}
               >Cancel</Button>
            
          </div>
          <div class="w-100 mb-4">
 
              <Button className="btn-lg btn btn-florogreen btn-block  padding_4"
               onClick={()=>PinPage()}
               >Pin Page</Button>
               
            */}
            <Button className="btn-lg btn btn-secondary  padding_4"
              
              style={{display:'block',width:'95%'}}
               onClick={()=>handleClose()}
               >Cancel</Button>
          </div> 
          {/* <div class="w-100 mb-4"> */}
          {/* btn-block  */}
              {/* <Button className="btn-lg btn btn-florogreen  padding_4"
              
              style={{display:'block',width:'95%'}}
               onClick={()=>handleClose()}
               >Cancel</Button>
            
          </div> */}
        </Modal.Body>
        {/* <Modal.Footer className="py-1 d-flex justify-content-center border-0">

          <div>
            <Button className="btn-lg btn btn-danger square-edges mx-3 my-3 pl-5 pr-5">Update</Button>
          </div>
        </Modal.Footer> */}
      </Modal>
    )
}

export default ModalPopLogout
