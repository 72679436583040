import React, { useState } from 'react';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pageComponents } from '../../utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
// import { addNewCustomer } from '../../reducers/customerSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { backToPreviousPage, setActivePage } from '../../reducers/homePageSlice';
import { PageTitle } from '../Commons/PageTitle';
import { AlertModal } from '../Popups/AlertModal';
import { customerItems } from '../../reducers/customerSlice';

export function EditCustomer(props) {


  const customerDetailsItem = useSelector((state) => state.constVar.customerItemData)
  console.log(customerDetailsItem,"customerDetailsItem");
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState(customerDetailsItem[0])
  const [dob, setDOB] = useState('')
  const [phonenumber, setPhoneNumber] = useState(customerDetailsItem[1])
  const [email, setEmail] = useState(customerDetailsItem[3])
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [address3, setAddress3] = useState('')
  const [address4, setAddress4] = useState('')
  const [address5, setAddress5] = useState('')
  const [customerId, setCustomerId] = useState('')
   
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

   const CloseAlert = () => setShowAlert(false);
    const ShowAlert = () => {

        setShowAlert(true);
    }

  const onNameChanged = (e) => setName(e.target.value)
  const onDOBChanged = (e) => setDOB(e.target.value)
  const onPhoneNumberChanged = (e) => setPhoneNumber(e.target.value)
  const onEmailChanged = (e) => setEmail(e.target.value)
  const onAddressChanged1 = (e) => setAddress1(e.target.value)
  const onAddressChanged2 = (e) => setAddress2(e.target.value)
  const onAddressChanged3 = (e) => setAddress3(e.target.value)
  const onAddressChanged4 = (e) => setAddress4(e.target.value)
  const onAddressChanged5 = (e) => setAddress5(e.target.value)

  const handleClickBack = (e) => {
   dispatch(backToPreviousPage());

  }
   const canSave =
    [name,  phonenumber,email].every(Boolean) && addRequestStatus === 'idle'

   const onSaveCustomerClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(
          // addNewCustomer({ "name":name, "dob":dob, "phonenumber":phonenumber,"email":email,"address":address })
        )
        unwrapResult(resultAction)
        
        setName('')
        setDOB('')
        setPhoneNumber('')
        setEmail('')
        setAddress1('')
        debugger;
        ShowAlert();
      } catch (err) {
        console.error('Failed to save the post: ', err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }



  
   
  const editcustomer = () => {
   
    let dataToSend = {
     
      custid: customerDetailsItem[9],
      token:localStorage.getItem('token'),
        custnam: name,
        phno: phonenumber,
        addr1: address1,
        addr2: address2,
        addr3: address3,
        addr4: address4,
        addr5:address5,
        email: email,
        dob: dob,
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_editcustomer", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.UpdateCustomerArr[0].UpdateCustomer != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.UpdateCustomerArr.length; i++) {
            arr.push({
              editcustomer: responseJson.UpdateCustomerArr[i].UpdateCustomer,
            });
          }
          const item = [
            {
              tokenCreate: localStorage.getItem('token'),
            },
          ];
          dispatch(customerItems(item));
          dispatch(setActivePage(pageComponents.CUSTOMERLIST));
        } else {
          console.log("Please check your email id or password");
          alert("Sorry Edit Customer is not done");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
   

  return (
    <>
      <div class="row mt-3 ml-1">
        <div class="col-md-2">
          <PageTitle titleHeader="Customer"  >
            <h3>Edit Customer</h3>
          </PageTitle>
        </div>
        <div class="col-md-2 pt-5">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">
        {/* <div class="form-group text-right mr-3">  */}
         <button type="submit" class="btn btn-lg btn-infogreen  p-4 w-100" onClick={()=>editcustomer()} disabled={!canSave}>Save</button>
         {/* </div> */}
           
        </div>
        <div class="col-md-2 pr-4">
          <button type="button" class="btn btn-florogreen btn-lg btn-block p-4" onClick={() => handleClickBack()}>Back</button>
        </div>
      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5" style={{overflowX:'hidden',overflowY:'scroll'}}>
        <div class="col-12  d-none d-sm-flex flex-column ml-4 pl-3 mt-3">
          
            <div class="form-group w-50">
              <label for="exampleInputEmail1"  class="text-muted">Name</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[0]}  onChange={onNameChanged}  />
            </div>
            {/* <div class="form-group w-50">
              <label for="exampleInputPassword1"  class="text-muted">D.O.B</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0"  placeholder='YYYY-DD-MM' defaultValue={customerDetailsItem[2]} onChange={onDOBChanged} />
            </div> */}
            <div class="form-group w-50">
              <label for="exampleInputPassword1"  class="text-muted">Phone Number</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[1]} onChange={onPhoneNumberChanged}/>
            </div>
            <div class="form-group w-50">
              <label for="exampleInputPassword1"  class="text-muted">Email</label>
              <input type="email" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[3]}  onChange={onEmailChanged}/>
            </div>
             <div class="form-group w-50 mb-auto">
              <label for="exampleInputPassword1"  class="text-muted">Door No</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[4]} onChange={onAddressChanged1}/>
            </div> 
             <div class="form-group w-50 mb-auto">
              <label for="exampleInputPassword1"  class="text-muted">Street</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[5]} onChange={onAddressChanged2}/>
            </div> 
             <div class="form-group w-50 mb-auto">
              <label for="exampleInputPassword1"  class="text-muted">Area</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[6]} onChange={onAddressChanged3}/>
            </div> 
             <div class="form-group w-50 mb-auto">
              <label for="exampleInputPassword1"  class="text-muted">City</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[7]} onChange={onAddressChanged4}/>
            </div> 
             <div class="form-group w-50 mb-auto">
              <label for="exampleInputPassword1"  class="text-muted">Pincode</label>
              <input type="text" class="form-control inputnonorder-gray rounded-0" defaultValue={customerDetailsItem[7]} onChange={onAddressChanged5}/>
            </div> 
           
            {/* <div class="form-group text-right mr-3">  <button type="submit" class="btn btn-lg btn-infogreen mt-4 p-4 w-20" onClick={()=>editcustomer()} disabled={!canSave}>Save</button></div> */}
           
            <AlertModal show={showAlert}
                    animation={true} handleClose={CloseAlert} Title="Customer" Message="Customer Added successfully!"></AlertModal>


        </div>


      </div>

    </>


  );

}
