import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../utilities/client';
import { gql } from '@apollo/client';



const PRODUCTS = gql`
query GetItems($count:Int)
{
products(first: $count) {
  edges {
    node {
      id
      name
      description
    }
  }
}
}
`;
 

const supplierAdapter = createEntityAdapter();
const initialState = supplierAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchSuppliers = createAsyncThunk('suppliers/fetchSuppliers', async () => {
 
  const response = await client
    .query({ query: PRODUCTS, variables: { "count": 24 } });

  return response.data;

})

export const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    updateTable(state, action) {
     
    },
    mergeTables(state, action) {
     
    },
     
  },
  extraReducers: {
    [fetchSuppliers.pending]: (state, action) => {

      state.status = 'loading'
    },
    [fetchSuppliers.fulfilled]: (state, action) => {

      state.status = 'succeeded'
      // Add any fetched posts to the array
      
      console.log(action.payload);
      supplierAdapter.removeAll(state);
      action.payload.products.edges.map(({node}) => (
          
        supplierAdapter.addOne(state, node)
        
      ))
      //state.tables = state.tables.concat(action.payload)
    },
    [fetchSuppliers.rejected]: (state, action) => {

      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {  updateTable,mergeTables} = supplierSlice.actions;

export default supplierSlice.reducer;
export const { selectAll: selectAllSuppliers } = supplierAdapter.getSelectors((state) => state.suppliers);


//export const selectAllBillItems = (state) => state.items.billItems;
