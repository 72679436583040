const initstate = {
    Box: 0,

    discountPrecentage: "",
    cardnumber: "",
    Alpha: "",
    totalamount: 0,
    balance: 0,
    dueamount: 0,
    addonshow: true,
    remarkshow: false,
    sno: "",
    pricelevel: "",
    usellp: "",
    subtotalamount: 0,
    refundaddons:[]
};

export const touchReducer = (state = initstate, action) => {
    if (action.type == "ADD_TOUCH") {
        // var Box = initstate.Box+ac
        return {
            ...state,
            Box: action.payload,
        };
    }
    else if (action.type === "sno") {

        return {
            ...state,
            sno: action.payload
        }
    }
    else if (action.type === "pricelevel") {

        return {
            ...state,
            pricelevel: action.payload
        }
    }
    else if (action.type === "usellp") {

        return {
            ...state,
            usellp: action.payload
        }
    }
    else if (action.type === "refundaddons") {

        return {
            refundaddons: action.payload
        }
    }
    else if (action.type == "ALPHA_TOUCH") {
        // var Box = initstate.Box+ac
        return {
            ...state,
            Alpha: action.payload,
        };
    }
    else if (action.type == "totalamount") {
        return {
            ...state,
            totalamount: action.payload
        }

    }
    else if (action.type == "subtotalamount") {
        return {
            ...state,
            subtotalamount: action.payload
        }
    }
    else if (action.type == "discountPrecentage") {
        return {
            ...state,
            discountPrecentage: action.payload
        }

    }
    else if (action.type == "dueamount") {
        if (action.payload === 0) {
            return {
                ...state,
                dueamount: state.totalamount
            }
        } else {

            return {
                ...state,
                dueamount: state.totalamount - state.Box
            }
        }

    }
    else if (action.type == "balance") {
        if (action.payload === 0) {
            return {
                ...state,
                balance: 0
            }
        } else {
            if (state.Box < state.totalamount) {
                return {
                    ...state,
                    balance: 0
                }
            }
            else if (state.Box === state.totalamount) {
                return {
                    ...state,
                    balance: 0
                }
            }
            else if (state.Box < state.totalamount) {
                return {
                    ...state,
                    balance: parseInt(state.Box) - parseInt(state.totalamount)
                }
            }
            else {
                return {
                    ...state,
                    balance: parseInt(state.Box) - parseInt(state.totalamount)
                }
            }

        }

    } else if (action.type == "addonshow") {
        if (action.payload === "") {
            return {
                ...state,
                addonshow: false
            }
        } else {
            return {
                ...state,
                addonshow: action.payload
            }
        }

    }
    else if (action.type == "remarkshow") {
        if (action.payload === "") {
            return {
                ...state,
                remarkshow: false
            }
        } else {
            return {
                ...state,
                remarkshow: action.payload
            }
        }

    }


    return state;
};


export default touchReducer;