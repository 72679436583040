import React, { Component } from 'react';
import { updateBill } from '../../reducers/billsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, paymentMode } from '../../utilities/Constants'
import { paymentModeStatus } from '../../reducers/ConstantSlice';



export function TakeAwayPayment({printStatement,setEwalletType}) {

    const dispatch = useDispatch();
  const activeBillId = useSelector((state) => state.bills.activeBillID)
  const ewalletModeData = useSelector((state) => state.constVar.onlineModeData)
  
  const handleClickBack = (e) => {
    // debugger;
    dispatch(paymentModeStatus({
      // id: activeBillId,
      // changes: {
        paymentMode: paymentMode.CASH,

      // },
    }));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));
  }
  
  // const handleClickCash = (e) => {
    // console.log("?");
    // dispatch(updateBill({
    //   id: activeBillId,
    //   changes: {
    //     paymentMode: paymentMode.CASH,

    //   },
     
    // }));
    //dispatch(setActiveTab(cashierTabs.DINE_IN));m-1
// m-1
// m-1
// m-1
// m-1
  // }
  const setType=(i)=>{
    console.log(i,"i");
    localStorage.setItem("ewalletType",i)
    setEwalletType(i);
    printStatement()
  }

  console.log(ewalletModeData,"ewalletModeData");

   
    return (
      <div class="d-none d-md-flex flex-column flex-grow-1 mt-2">

        {/* <div class="row flex-grow-1 " >
          <div class="col-md-12 align-middle text-center">
            <button type="button" class=" border-1 border-white btn-ewallet p-4  bg-e-wallet-ame shadow-sm" onClick={()=>setType("AmePay")}>
             
              </button>
          </div>
        </div> */}
        <div class="row flex-grow-1 " >
          <div class="col-md-12 align-middle text-center" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            {ewalletModeData?.length > 0 && ewalletModeData?.map((i)=>(
                i.ewallet == localStorage.getItem("takeawayOnlinePayment") ?
// border-0 btn-ewallet p-4 bg-e-wallet-pp  shadow-sm
// bg-e-wallet-pp
            <button type="button" class="border-0 btn-ewallet p-4 " onClick={()=>setType(i.ewallet)} style={{
              // `url("https://via.placeholder.com/500")` 
              backgroundImage: `url(data:image/png;base64,${i.image})`,
              backgroundSize:'100%',
              backgroundRepeat:'no-repeat',
              marginBottom:'10px'
      // backgroundImage: `url(${ewalletModeData[0].image})`
            }}>
       {/* {i.ewallet} */}
            </button>
            :''
            ))
              }
          </div>
        </div>
        {/* <div class="row flex-grow-1 " >
          <div class="col-md-12 align-middle text-center">
            <button type="button" class="border-0 btn-ewallet p-4 bg-e-wallet-gpay shadow-sm" onClick={()=>setType("GooglePay")}> </button>
          </div>
        </div>
        <div class="row flex-grow-1 " >
          <div class="col-md-12 align-middle text-center">
            <button type="button" class="border-0 btn-ewallet p-4 bg-e-wallet-paytm shadow-sm" onClick={()=>setType("Paytm")}> </button>
          </div>
        </div>*/}
        <div class="row flex-grow-1 " >
          <div class="col-md-12 align-middle text-center">
            <button type="button" class="btn btn-florogreen btn-block p-4 btn-lg shadow" onClick={(e)=>handleClickBack(e)}>Back</button>
          </div>
        </div> 
      </div>
    );
   

}