import React, { useRef, useState, useEffect } from 'react';
import './ItemsGrid.css';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateBill, updateQty, addBill, addBillItem, selectBillById, selectItem, addRemarks } from '../../reducers/billsSlice';
import { setActivePage } from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { pageComponents, paymentMode } from '../../utilities/Constants'
import { AddOnModal } from "./AddOnModal";
import SearchProducts from "./SearchProducts"
import { cartItems, selectAllCart } from '../../reducers/CartSlice';
import { fetchItems } from '../../reducers/addonSlice';
import { addonRemarksShow, balancePaidAmt, dummyPaidAmount, itemIdPlucode, oldBillNo, paidAmount, paymentModeStatus, productId, refundCartAmount, refundCartSubTotalAmount, RefundDiscount } from '../../reducers/ConstantSlice';
import { fetchTables } from '../../reducers/tablesSlice';
import { useReactToPrint } from "react-to-print";
import QtyChangeModal from './QtyChangeModal';
import { Button } from 'reactstrap';
import Keypad from '../../assets/images/keypad.png'
import { Image } from 'react-bootstrap';
import { Calc } from '../Commons/Calc';
import CancelKotadmin from './CancelKotadmin';
import { selectAllRefund, updateCart } from '../../reducers/refundSlice';

export const RefundGrid = () => {

  const dispatch = useDispatch();
  
  const paidTotalAmount = useSelector((state) => state.constVar.paidAmount)
  const RefundDiscountValue = useSelector((state) => state.constVar.RefundDiscount)
  console.log(RefundDiscountValue, "RefundDiscountValue");
  const usellpValueIdData = useSelector((state) => state.touch.refundaddons)
  const { discountPrecentage } = useSelector((state) => state.touch);
  const addonRemarksShowData = useSelector((state) => state.constVar.addonRemarksShow)
  const productIDVALUE = useSelector((state) => state.constVar.itemIdPlucode)
  let varNoOfItems = 0;
  let varNoOfQty = 0;
  let varSubTotal = 0;
  let varTotal = 0;
  let curRow = 0;
  const cartItemsList = useSelector(selectAllRefund);
  console.log(cartItemsList, "cartItemsListinDine");
  try {
    var billamnt = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].amount) +
      parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    ).toFixed(2);
    var roundamnt = Math.round(billamnt);
  } catch (error) {
    alert(error)
  }

  const date = new Date();
  const datetime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();


  const activeBillId = useSelector((state) => state.bills.activeBillID)
  const bill = useSelector((state) => selectBillById(state, activeBillId))
  const billingItems = bill === undefined ? undefined : bill.lineitems;
  const [showAddOns, setShowAddOns] = useState(false);
  const [qtyPlucCode, setQtyPlucCode] = useState();
  const [colorchangeData, setColorchangeData] = useState();
  const [notepad, setNotePad] = useState(false);
  const [adminPanel, setAdminPanel] = useState(false);

  const [emptyCartName, setEmptyCartName] = useState("")
  const [cancelKotData, setCancelKotData] = useState("")
  const [resumeSaleNumber, setResumeSaleNumber] = useState(0)
  const [holdSaleList, setHoldSaleList] = useState([])
  const [numericValue, setNumericValue] = useState("");
  const [modelQtyPopUp, setModelQtyPopUp] = useState(false)
  const draftRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const onBeforeGetContentResolve = React.useRef(null);
  // const cartItemsList = useSelector(selectAllCart);
  const [noOfItems, setnoOfItems] = useState(0);
  const [noOfQty, setnoOfQty] = useState(0);
  const [subTotal, setsubTotal] = useState(0);
  const [gst, setgst] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [kotData, setKotData] = useState();
  try {
    var billamnt = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
      parseFloat(cartItemsList[cartItemsList.length - 1].totalgst)
    ).toFixed(2);
    var roundamnt = Math.round(billamnt);
  } catch (error) {
    alert("Data not Found")
  }

  const CloseAddOns = () => setShowAddOns(false);
  const ShowAddOns = () => {

    setShowAddOns(true);
  }


  const handlePayment = event => {
    
    var cartAmount = parseFloat(cartItemsList[cartItemsList?.length - 1].amtt).toFixed(2)
    var subTotal = parseFloat(cartItemsList[cartItemsList?.length - 1].subTotal).toFixed(2)
    console.log("=========LetsStart+++++++++++++++",cartItemsList,RefundDiscountValue,paidTotalAmount);
     if (parseInt(cartItemsList[0].discount) == 0 && RefundDiscountValue == 0 && paidTotalAmount == 0) {
      
    console.log("=========LetsStart1+++++++++++++++");
    
    var leftAmount = parseFloat(cartItemsList[cartItemsList?.length - 1].amtt).toFixed(2) - (parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2))
    
    console.log(leftAmount,cartItemsList[cartItemsList?.length - 1].amtt,cartItemsList[0].amtpaid,cartItemsList[0].balance,"leftAmount");
    
    dispatch(oldBillNo(cartItemsList[0].billno))
    dispatch(RefundDiscount(0.00))
      dispatch(balancePaidAmt(leftAmount.toFixed(2)))
      
      dispatch(dummyPaidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
      
      dispatch(paidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
      dispatch(refundCartSubTotalAmount(subTotal))
      dispatch(refundCartAmount(cartAmount))
      dispatch(setActivePage(pageComponents.PAYMENT));
      dispatch({ type: "totalamount", payload: roundamnt })
      dispatch({ type: "ADD_TOUCH", payload: 0 })
      dispatch({ type: "balance", payload: 0 })
      dispatch({ type: "dueamount", payload: 0 })
      
      dispatch(paymentModeStatus({ paymentMode: paymentMode.CASH }));
      //dispatch(setActivePage(pageComponents.SPLITPAYMENT));//TESTING
      //dispatch(setActivePage(pageComponents.SPLITBILL));//TESTING
      //dispatch(setActivePage(pageComponents.MERGETABLES));//TESTING
      event.preventDefault();
    }else if (parseInt(cartItemsList[0].discount) == 0 && RefundDiscountValue == 0 && paidTotalAmount != 0) {
      console.log("=========LetsStart2+++++++++++++++");
      dispatch(RefundDiscount(0.00))
      var leftAmount = parseFloat(cartItemsList[cartItemsList?.length - 1].amtt).toFixed(2) - paidTotalAmount
      
    dispatch(oldBillNo(cartItemsList[0].billno))
      dispatch(dummyPaidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
      
      dispatch(paidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
      
      console.log(leftAmount,cartItemsList[cartItemsList?.length - 1].amtt,cartItemsList[0].amtpaid,cartItemsList[0].balance,"leftAmount");
      
      dispatch(balancePaidAmt(leftAmount.toFixed(2)))
      dispatch(refundCartSubTotalAmount(subTotal))
      dispatch(refundCartAmount(cartAmount))
      dispatch(setActivePage(pageComponents.PAYMENT));
      dispatch({ type: "totalamount", payload: roundamnt })
      dispatch({ type: "ADD_TOUCH", payload: 0 })
      dispatch({ type: "balance", payload: 0 })
      dispatch({ type: "dueamount", payload: 0 })
      
      dispatch(paymentModeStatus({ paymentMode: paymentMode.CASH }));
      //dispatch(setActivePage(pageComponents.SPLITPAYMENT));//TESTING
      //dispatch(setActivePage(pageComponents.SPLITBILL));//TESTING
      //dispatch(setActivePage(pageComponents.MERGETABLES));//TESTING
      event.preventDefault();
    } else if (parseInt(cartItemsList[0].discount) != 0) {
      console.log("=========LetsStart3+++++++++++++++");
      var leftAmount = parseFloat(cartItemsList[cartItemsList?.length - 1].amtt).toFixed(2) - parseInt(cartItemsList[0].discount) - (parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2))
      
      dispatch(balancePaidAmt(leftAmount.toFixed(2)))
      dispatch(RefundDiscount(parseFloat(cartItemsList[0].discount).toFixed(2)))
      
    dispatch(oldBillNo(cartItemsList[0].billno))
      dispatch(dummyPaidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
     
      dispatch(paidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
      dispatch(refundCartSubTotalAmount(subTotal- parseFloat(cartItemsList[0].discount).toFixed(2) ))
      dispatch(refundCartAmount(cartAmount - parseFloat(cartItemsList[0].discount).toFixed(2) ))
      dispatch(setActivePage(pageComponents.PAYMENT));
      dispatch({ type: "totalamount", payload: roundamnt })
      dispatch({ type: "ADD_TOUCH", payload: 0 })
      dispatch({ type: "balance", payload: 0 })
      dispatch({ type: "dueamount", payload: 0 })
      dispatch(paymentModeStatus({ paymentMode: paymentMode.CASH }));
      event.preventDefault();
    } else if (RefundDiscountValue != 0) {
      console.log("=========LetsStart4+++++++++++++++");
      var leftAmount = parseFloat(cartItemsList[cartItemsList?.length - 1].amtt).toFixed(2) - parseFloat(RefundDiscountValue).toFixed(2) - (paidTotalAmount)
      
     
      console.log(leftAmount,cartItemsList[cartItemsList?.length - 1].amtt,cartItemsList[0].amtpaid,cartItemsList[0].balance,"leftAmount");
     
      dispatch(balancePaidAmt(leftAmount.toFixed(2)))

      dispatch(refundCartSubTotalAmount(subTotal - parseFloat(RefundDiscountValue).toFixed(2) ))
      dispatch(refundCartAmount(cartAmount - parseFloat(RefundDiscountValue).toFixed(2) ))
      dispatch(setActivePage(pageComponents.PAYMENT));
      dispatch({ type: "totalamount", payload: roundamnt })
      dispatch({ type: "ADD_TOUCH", payload: 0 })
      dispatch({ type: "balance", payload: 0 })
      dispatch({ type: "dueamount", payload: 0 })
      dispatch(paymentModeStatus({ paymentMode: paymentMode.CASH }));
      event.preventDefault();
    }


  }

  let content;
  // const dispatch = useDispatch();

  const addNumber = (num) => {
    setNumericValue(numericValue + num)

  }

  const deleteNumber = () => {

    setNumericValue(numericValue.slice(0, -1))
  }
  const clearAll = () => {

    setNumericValue("");
  }

  const handleRowClick = (event, payload) => {
    event.preventDefault();
    dispatch(selectItem(payload));

  }

  const handleEditItem = (event, payload) => {
    event.preventDefault();
    dispatch(selectItem(payload));
    ShowAddOns();

  }
  const handleQtyUpdate = (event, payload) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(updateQty(payload));

  }

  const AddRemarksandAddon = (sno) => {
    console.log("=============sno==================");
    if (sno.plucod === colorchangeData) {
      setColorchangeData()

      console.log("=============snosetColorchangeData==================");
    } else {
      console.log("=============snosno1==================");
      getAddon(sno)
      console.log("=============snosno2==================");
      dispatch(addonRemarksShow(true))
      console.log("=============snoaddonRemarksShow==================");
      setColorchangeData(sno.plucod)
      console.log("=============snosetColorchangeData==================");
      dispatch(productId(sno.plucod))
      console.log("=============snoproductId==================");
      dispatch(itemIdPlucode(sno.plucod))
      console.log("=============snosetColorchangeData==================");

    }
  }

  var getAddon = (sno) => {

    console.log("=============snosnorefundaddons==================");
    dispatch({ type: "refundaddons", payload: sno });
    console.log("=============snosnorefundaddons==================");
    dispatch({ type: "usellp", payload: sno.usellp });
    dispatch({ type: "pricelevel", payload: sno.pricelevel });
    dispatch({ type: "sno", payload: sno.sno });
    dispatch({ type: "addonshow", payload: false });
    dispatch({ type: "remarkshow", payload: true });



  };

  const keydownHandler = (evt) => {

    var itemGrid = document.getElementById('tblItemGrid');
    var tbody = itemGrid.getElementsByTagName('tbody')[0];
    var trows = tbody.getElementsByTagName('tr');

    // store these so we won't have to keep recalculating
    var numRows = trows.length;

    // return the old cell to normal
    for (var t = 0; t < numRows; ++t) {
      var trow = trows[t];

      if (trow.className == "highlight") {

        curRow = t;
        break;
      }

    }
    tbody.getElementsByTagName('tr')[curRow].className = '';

    // increment/decrement the position of the current cell
    // depending on the key pressed
    if (evt.keyCode == 38 && curRow > 0) // up
      curRow--;
    else if (evt.keyCode == 40 && curRow < numRows - 1) // down
      curRow++;

    // update the new cell
    tbody.getElementsByTagName('tr')[curRow].className = 'highlight';
    // tbody.getElementsByTagName('tr')[curRow].click() ;
  }






  ///////////////// SEND KOT /////////////////////////////
  const addItemsPad = (i) => {

    console.log(usellpValueIdData, numericValue, "usellpValueIdDatausellpValueIdData");
    if (numericValue != null && numericValue != undefined && numericValue != '' && numericValue.length > 0) {


      if (usellpValueIdData.edit == 'yes') {
        console.log(usellpValueIdData, "edit");
        let arr = cartItemsList
        var objIndex = arr.findIndex((obj => obj.plucod == usellpValueIdData.plucod));
        // console.log(objIndex, arr[objIndex].totalqty);
        // arr[objIndex].totalqty = parseInt(i.totalqty)+1
        // arr.splice(objIndex, 1)
        // arr = ([
        //   ...arr, {
        //     ...i,
        //     totalqty: (parseInt(i.totalqty) - 1).toString(),
        //   }
        // ])

        var newQty = parseInt(numericValue);


        let newArr = [...arr];
        var newAmount = 0;
        var newGST = 0;

        if (usellpValueIdData.gstType == 1) {
          newGST = (parseFloat(usellpValueIdData.unitprice) - ((parseFloat(usellpValueIdData.unitprice)) / ((parseFloat(usellpValueIdData.GSTper) * 0.01) + 1))) * parseInt(newQty)

          newAmount = ((parseFloat(usellpValueIdData.unitprice) + newGST) * newQty)

        } else {
          newAmount = ((parseFloat(usellpValueIdData.unitprice) + (parseFloat(usellpValueIdData.unitprice) * parseFloat(usellpValueIdData.GSTper) * 0.01)) * (parseInt(newQty)))
          newGST = ((parseFloat(usellpValueIdData.unitprice) * parseFloat(usellpValueIdData.GSTper * 0.01)) * parseInt(newQty))

        }


        newArr[objIndex] = {
          ...newArr[objIndex],
          mainqty: newQty,
          amount: newAmount.toFixed(2),

          gst: newGST,
        };


        arr = newArr;

        console.log(arr, "cartItemsList");

        dispatch(updateCart(arr))

      }



      changetoNotepad()
      clearAll();





    } else {

      changetoNotepad()
      clearAll();
    }

  }
  // const addLineItem = ()=>

  const goToKot = () => {
    let dataToSend = {
      token: localStorage.getItem('token'),

      tabno: localStorage.getItem('tableno'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_printkot", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {

        if (responseJson.PrintKotArr[0].PrintKot != 2) {
          setKotData(responseJson)

          let link = "https://superadmin.amepos.io/TabPosApiNew/tabpos_tableview";
          var item = [
            {
              link: link,
              tokenCreate: localStorage.getItem('token'),
            },
          ];
          dispatch(fetchTables(item));
          getClear()
          // Actions.vieworders();
          handlePrint()
        } else {
          getClear()
          if (cartItemsList.length > 0 && cartItemsList[0].name != 'yasar') {

            alert("KOT Already Printed");
          }
          else {
            // ""
          }
          //  Actions.vieworders();
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      .catch((error) => {
        alert("Data not Found")
      });
  }

  const getClear = () => {
    //POST json
    let dataToSend = {
      token: localStorage.getItem('token'),

      tabno: localStorage.getItem('tableno'),
      terminal: localStorage.getItem('TerminalName'),

    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_clearbusytable", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        if (responseJson.ClearBusyTableArr[0].ClearBusyTable != 2) {
          const arr = [];
          for (let i = 0; i < responseJson.ClearBusyTableArr.length; i++) {
            arr.push({
              clrbusy: responseJson.ClearBusyTableArr[i].ClearBusyTable,
            });
          }

        } else {
          alert("Sorry No data");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        alert("Data not Found")
      });
  };


  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);

  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,

    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });
  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }

  }, [onBeforeGetContentResolve.current, text]);


  const sendKOT = async () => {
    await goToKot();

  }




  /////////////////////////////////////////////
  const setModelQtyPopUpFunc = (i) => {
    setQtyPlucCode(i)
    setModelQtyPopUp(true)
  }

  const alertCustomer = () => {

    alert("Please Select Customer Name")
    // if(localStorage.getItem('CustomerName') ==null){

  }

  const alertToADDItems = () => {
    alert("Please add Items to Cart")
  }


  const CloseAdminPanel = () => setAdminPanel(false);

  const CloseEditQuantity = () => setModelQtyPopUp(false);

  const changetoNotepad = () => {
    setNotePad(!notepad)

  }



  const increaseQtyRefund = (i) => {
    if (i.edit == 'yes') {
      console.log(i, "edit");
      let arr = cartItemsList
      var objIndex = arr.findIndex((obj => obj.plucod == i.plucod));

      var newQty = parseInt(i.mainqty) + 1

      console.log(objIndex, arr[objIndex].totalqty);
      // arr[objIndex].totalqty = parseInt(i.totalqty)+1
      // arr.splice(objIndex, 1)


      let newArr = [...arr];
      var newAmount = 0;
      var newGST = 0;

      if (i.gstType == 1) {
        newGST = (parseFloat(i.unitprice) - ((parseFloat(i.unitprice)) / ((parseFloat(i.GSTper) * 0.01) + 1))) * parseInt(newQty)

        newAmount = ((parseFloat(i.unitprice) + newGST) * newQty)

      } else {
        newAmount = ((parseFloat(i.unitprice) + (parseFloat(i.unitprice) * parseFloat(i.GSTper) * 0.01)) * (parseInt(newQty)))
        newGST = ((parseFloat(i.unitprice) * parseFloat(i.GSTper * 0.01)) * parseInt(newQty))

      }
      newArr[objIndex] = {
        ...newArr[objIndex],
        mainqty: newQty,
        amount: newAmount.toFixed(2),

        gst: newGST,
      };

      // arr = ([
      //   ...arr, {
      //     ...i,
      //     totalqty: (parseInt(i.totalqty) + 1).toString(),
      //     amount: (parseInt(i.amount) + 1).toString(),
      //   }
      // ])

      arr = newArr;
      console.log(arr, "cartItemsList");

      dispatch(updateCart(arr))

    }
    // var li = ""
    // if(i.edit == 'yes'){
    //   var index=cartItemsList?.findIndex(function (master) {
    //     return master.id === i.id;
    //   });
    //    li = { ...i,
    //     totalqty:parseInt(i.totalqty)+1
    //     }
    //     console.log(li,index,"li");
    //     props.setstate([
    //       ...props.state,
    //       { ...i,
    //         totalqty:parseInt(i.totalqty)+1
    //         },
    //     ]);
    //     props.master.splice(index, 1)
    // }
  }
  const decreaseQtyRefund = (i) => {
    if (i.edit == 'yes' && parseInt(i.mainqty) > 1) {
      console.log(i, "edit");
      let arr = cartItemsList
      var objIndex = arr.findIndex((obj => obj.plucod == i.plucod));
      // console.log(objIndex, arr[objIndex].totalqty);
      // arr[objIndex].totalqty = parseInt(i.totalqty)+1
      // arr.splice(objIndex, 1)
      // arr = ([
      //   ...arr, {
      //     ...i,
      //     totalqty: (parseInt(i.totalqty) - 1).toString(),
      //   }
      // ])

      var newQty = parseInt(i.mainqty) - 1;


      let newArr = [...arr];
      var newAmount = 0;
      var newGST = 0;

      if (i.gstType == 1) {
        newGST = (parseFloat(i.unitprice) - ((parseFloat(i.unitprice)) / ((parseFloat(i.GSTper) * 0.01) + 1))) * parseInt(newQty)

        newAmount = ((parseFloat(i.unitprice) + newGST) * newQty)

      } else {
        newAmount = ((parseFloat(i.unitprice) + (parseFloat(i.unitprice) * parseFloat(i.GSTper) * 0.01)) * (parseInt(newQty)))
        newGST = ((parseFloat(i.unitprice) * parseFloat(i.GSTper * 0.01)) * parseInt(newQty))

      }


      newArr[objIndex] = {
        ...newArr[objIndex],
        mainqty: newQty,
        amount: newAmount.toFixed(2),

        gst: newGST,
      };


      arr = newArr;

      console.log(arr, "cartItemsList");

      dispatch(updateCart(arr))

    }
    // var li = ""
    // if(i.edit == 'yes'){
    //   var index=cartItemsList?.findIndex(function (master) {
    //     return master.id === i.id;
    //   });
    //    li = { ...i,
    //     totalqty:parseInt(i.totalqty)+1
    //     }
    //     console.log(li,index,"li");
    //     props.setstate([
    //       ...props.state,
    //       { ...i,
    //         totalqty:parseInt(i.totalqty)+1
    //         },
    //     ]);
    //     props.master.splice(index, 1)
    // }
  }

  const deleteQtyRefund = (i) => {
    if (i.edit == 'yes') {

      let arr = cartItemsList
      var objIndex = arr.findIndex((obj => obj.plucod == i.plucod));
      arr.splice(objIndex, 1)

      dispatch(updateCart(arr))
      console.log(arr, "arr");
    } else {
      var leftAmount = parseFloat(cartItemsList[cartItemsList?.length - 1].amtt).toFixed(2) - parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)
      
      
      dispatch(oldBillNo(cartItemsList[0].billno))
      dispatch(balancePaidAmt(leftAmount.toFixed(2)))
      dispatch(RefundDiscount(parseFloat(i.discount).toFixed(2)))
      dispatch(dummyPaidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
      
      dispatch(paidAmount(parseFloat(cartItemsList[0].amtpaid).toFixed(2) - parseFloat(cartItemsList[0].balance).toFixed(2)))
      // if (i.edit == 'yes') {
      let arr = cartItemsList
      var objIndex = arr.findIndex((obj => obj.plucod == i.plucod));
      arr.splice(objIndex, 1)

      dispatch(updateCart(arr))
      console.log(arr, "arr");
    }
    // }
  }

  console.log(resumeSaleNumber, holdSaleList, cartItemsList.length, "holdSaleList");
  console.log(resumeSaleNumber, "resumeSaleNumber");
  return (

    <div class="row flex-grow-1">
      <div class="col-md-12 d-none d-md-flex flex-column">
        <div class="row">
          <div class="col-md-12">
            <SearchProducts />
          </div>
        </div>
        <div class="row flex-grow-1 tableFixHead">
          <div class="col-md-12 ">
            <table class="table table-list-search" tabIndex={0} id="tblItemGrid">
              <thead>
                <tr className="no-top-border">
                  <th className="no-top-border h6fontSize text-center"><b>S.No</b></th>
                  <th className="no-top-border h6fontSize text-center"><b>ITEMS</b></th>
                  <th className="no-top-border h6fontSize text-center"><b>QTY</b></th>
                  <th className="no-top-border h6fontSize text-center"><b></b></th>
                  <th className="no-top-border h6fontSize text-center"><b>TOTAL</b></th>

                </tr>
              </thead>
              <tbody>
                {
                  cartItemsList !== undefined && cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].amount != 0 &&

                  cartItemsList.map((item, index) =>

                  (
                    <tr className={item.isselected ? "highlight" : ""} >
                      <td class="text-center padding2">{index + 1}</td>
                      {/* onClick={(e) => handleEditItem(e, { id: item.id, billid: activeBillId })} */}
                      {colorchangeData === item.plucod ?
                        <td className="text-center padding2 colorChange" style={{ backgroundColor: 'violet' }} onClick={() => AddRemarksandAddon(item)}>
                          <div class="h6fontSize" style={{ wordBreak: 'break-word' }}>{item?.productname}</div>
                          {/* <div><small class="text-muted">{item?.remarks}</small></div> */}
                          {/* <div><small class="text-muted">{item?.addon}</small></div> */}
                          {/* <div>{item?.addon != null && item?.addon != undefined && item?.addon?.length > 0 && item?.addon.map((i) => <div style={{ fontSize: '55%', color: 'gray' }} >{i.addonnam} <br /></div>)}</div> */}

                          {/* Qty: */}
                        </td>
                        :
                        <td class="text-center padding2" onClick={() => AddRemarksandAddon(item)}>
                          <div class="h6fontSize" style={{ wordBreak: 'break-word' }}>{item?.prodname}</div>
                          {/* <div><small class="text-muted">{item?.remarks}</small></div> */}
                          {/* {item?.addon}  */}
                          {/* <div>{item?.addon != null && item?.addon != undefined && item?.addon?.length > 0 && item?.addon.map((i) => <div style={{ fontSize: '55%', color: 'gray' }}  >{i.addonnam} <br /></div>)}</div> */}
                          {/* Qty: */}
                        </td>}
                      {item.kotno == "0" && item.kotno.length > 0 ?
                        <td class="text-center padding2" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <div style={{ width: '25%' }}>
                            {item.totalqty > 1 && <button className="btn btn-danger button_small" style={{ width: '100%' }} onClick={(e) => decreaseQtyRefund(item)}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}
                            {item.totalqty <= 1 && <button className="btn btn-danger button_small" style={{ backgroundColor: '#460d13', border: '#460d13', width: '100%' }}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}

                          </div>
                          <div style={{ width: '50%', wordBreak: 'break-all' }}>
                            <span class="text-danger    " onClick={() => setModelQtyPopUpFunc(item.plucod)} style={{ width: '100%', wordBreak: 'break-all' }}>{parseInt(item?.mainqty)}    </span>
                            {/* paddingLeft paddingRight
paddingLeft paddingRight */}
                          </div>
                          <div style={{ width: '25%' }}>
                            <button className="btn btn-success btn-sm" onClick={(e) => increaseQtyRefund(item)} ><FontAwesomeIcon icon="plus"></FontAwesomeIcon></button>

                          </div>



                        </td>
                        :
                        <td class="text-center padding2" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <div style={{ width: '25%' }}>
                            {item.totalqty > 1 && <button className="btn btn-danger button_small" style={{ width: '100%' }}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}
                            {item.totalqty <= 1 && <button className="btn btn-danger button_small" style={{ backgroundColor: '#460d13', border: '#460d13', width: '100%' }}><FontAwesomeIcon icon="minus"></FontAwesomeIcon></button>}

                          </div>
                          <div style={{ width: '50%', wordBreak: 'break-all' }}>
                            <span class="text-danger    " style={{ width: '100%', wordBreak: 'break-all' }}>{parseInt(item?.mainqty)}   </span>

                          </div>

                          <div style={{ width: '25%' }}>
                            <button className="btn btn-success btn-sm" ><FontAwesomeIcon icon="plus"></FontAwesomeIcon></button>

                          </div>
                        </td>
                      }


                      <td class="text-center padding2">{item?.amount}</td>
                      <td class="text-center padding2">

                        <button className="btn btn-danger btn-sm" onClick={() => deleteQtyRefund(item)}><FontAwesomeIcon icon="trash"></FontAwesomeIcon></button>
                      </td>

                    </tr>







                  ))

                }

              </tbody>
            </table>
          </div>
        </div>
        <div class="row ">

          <div class="col-md-12">
            <div class="card shadow  square-edges border-0" >
              <div class="Card__Body " style={{ display: 'flex' }}>
                <table id="summaryDisplay" class="table mb-0">

                  <tbody class="border-0 ">

                    <tr class="pb-2 ">
                      <td style={{ display: 'flex', justifyContent: 'center' }}>{localStorage.getItem("sellpName")}</td>
                    </tr>

                    <tr class="pb-2 ">
                      <td>No Of Items</td>
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" ? cartItemsList[cartItemsList?.length - 1].totalItem.toFixed(2) : "0.00"}</td>

                    </tr>
                    <tr class="pb-2">

                      <td>No Of Qty</td>
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].totalqty ? cartItemsList[cartItemsList?.length - 1].totalqty.toFixed(2) : "0.00"}</td>

                    </tr>
                    <tr class="pb-2">

                      <td>Sub Total</td>
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].subTotal ? (cartItemsList[cartItemsList?.length - 1].subTotal).toFixed(2) : "0.00"}</td>

                    </tr>
                    <tr class="pb-2">

                      <td>Discount</td>
                      <td>{discountPrecentage != null && discountPrecentage != undefined && discountPrecentage ? parseInt(discountPrecentage).toFixed(2) : "0.00"}</td>

                    </tr>
                    <tr class="pb-2">

                      <td>TAX</td>
                      <td>{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].totalgst ? cartItemsList[cartItemsList?.length - 1].totalgst.toFixed(2) : "0.00"}</td>

                    </tr>
                    <tr>
                      <td class=" text-danger " style={{ fontSize: '120%', fontWeight: 'bold' }}>
                        Total</td>
                      {/* </h1> */}

                      <td class=" text-danger">{cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[cartItemsList?.length - 1].amtt ? parseFloat(cartItemsList[cartItemsList?.length - 1].amtt).toFixed(2) : "0.00"}</td>

                      {/* </td> <td class=" text-danger">
                        <h1 class=" text-danger text-center">          
                        {roundamnt != null && roundamnt != undefined ? roundamnt.toFixed(2) : '0.00'}
                        </h1>
                      </td> */}
                    </tr>

                  </tbody>
                </table>
                <table id="summaryDisplay" class="table mb-0">
                  {notepad === false ?
                    <tbody class="border-0 ">
                      <tr class="pb-2 ">
                        <td style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                          {resumeSaleNumber == 0 ?
                            <Button style={{
                              padding: "0px 12% 0px 12%",
                              color: "black",
                              background: '#8ba741',
                              fontWeight: "bold",
                              // onClick={()=>holdSalefunc()}
                              fontSize: "180%"
                            }}>
                              {"<"}</Button>
                            :
                            <Button className='CHANGETABLE__Color' style={{
                              padding: "0px 12% 0px 12%",
                              color: "black",
                              fontWeight: "bold",
                              // onClick={()=>holdSalefunc()}
                              fontSize: "180%"
                            }}  >
                              {"<"}</Button>

                          }


                          <Button className='CHANGETABLE__Color' style={{
                            padding: "0px 12% 0px 12%",
                            backgroundColor: "#BAFE06 !important",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "180%"
                          }}>{">"}</Button>
                        </td>
                      </tr>
                      <tr class="pb-2 ">
                        <td style={{ display: 'flex', justifyContent: 'center' }}><Button className='SPLITTABLE__Color' style={{
                          // padding: "0px 12% 0px 12%",
                          backgroundColor: "#BAFE06 !important",
                          color: "black",
                          fontWeight: "bold", width: '25%',
                          fontSize: "150%"
                        }}  >
                          <Image src={Keypad} width="90%" onClick={() => changetoNotepad()} />
                        </Button></td>
                      </tr>
                    </tbody>
                    :
                    <div class="row text-center flex-wrap w-100">
                      <div class=" w-100">
                        <input class="text-center form-control-lg square-edges padding__5 inputCardNumber border" type="text" value={numericValue} />
                      </div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(7)}><strong>7</strong> </div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(8)}><strong>8</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(9)}><strong>9</strong></div>

                      <div class="w-100"></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(4)}><strong>4</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(5)}><strong>5</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(6)}><strong>6</strong></div>

                      <div class="w-100"></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(1)}><strong>1</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(2)}><strong>2</strong></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(3)}><strong>3</strong></div>

                      <div class="w-100"></div>
                      <div class="col-4 border calcButtons" onClick={() => clearAll()}><FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
                      <div class="col-4 border calcButtons" onClick={() => addNumber(0)}><strong>0</strong></div>
                      <div class="col-4 border bg-success text-light" onClick={() => addItemsPad()}><strong>Enter</strong></div>

                    </div>
                  }

                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row " style={{ margin: '5px' }}>
          {cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && cartItemsList[0].name !== 'yasar' ?
            <div class="col-sm-6 p-1">
              <button className="btn btn-florogreen Btn__large p4Padding btn-block  btn-block-height" style={{ marginTop: '5px' }} onClick={handlePayment}>
                Payment
              </button>

            </div>

            :
            <div class="col-sm-6 p-1">
              <button className="btn Btn__large p4Padding btn-block  btn-block-height" style={{ marginTop: '5px', backgroundColor: '#242618 ', color: 'white ', fontWeight: 'bold ', border: '#242618 ' }} onClick={() => alertToADDItems()}>
                Payment
              </button>

            </div>

          }

          <div class="col-sm-6 p-1">
            {localStorage.getItem('tableno') != undefined && localStorage.getItem('tableno') != null && localStorage.getItem('tableno').length > 0 && localStorage.getItem('tableno') != "CT" ?
              <button className="btn btn-infogreen Btn__large p4Padding btn-block  btn-block-height" style={{ marginTop: '5px' }} onClick={() => sendKOT()}>

                Send KOT
              </button>
              : <button className="btn Btn__large p4Padding btn-block  btn-block-height"
                style={{ marginTop: '5px', backgroundColor: '#285656', color: '#fff', fontWeight: 'bold' }}>

                Send KOT
              </button>

            }

          </div>
          <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
            <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
              <div class="ticket " >
                <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Table No. {localStorage.getItem('tableno')}</p>
                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No. {kotData?.PrintKotArr.length > 0 && kotData?.PrintKotArr[0].Kotno}</p>


                <table className="centered" style={{ borderCollapse: 'collapse' }}>

                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    {
                      kotData !== undefined && kotData !== null && kotData?.PrintKotArr?.length > 0 && kotData?.PrintKotArr[0].ItemsArray.length > 0 &&

                      kotData?.PrintKotArr[0].ItemsArray.map((item, index) =>

                      (
                        <tr  >
                          <td className='description' >

                            <div><small style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black', fontWeight: 'bold' }}>{item?.ProductName}</small></div>
                            {item?.addon.length > 0 && item?.addon.map((i) =>
                              <div><small style={{ fontSize: '9px', borderCollapse: 'collapse', color: 'black' }}>ADDON'S :{i.addonnam}
                              </small></div>
                            )}
                            {item?.Remarks != '' && item?.Remarks != null && item?.Remarks != undefined &&
                              <div><small style={{ fontSize: '9px', borderCollapse: 'collapse', color: 'black' }}>REMARKS: {item?.Remarks}</small></div>
                            }
                          </td>

                          <td className="quantity  " style={{ fontSize: '12px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>


                        </tr>
                      ))

                    }

                  </tbody>
                </table>
                <p className="centered" style={{ fontSize: '12px', borderTop: '1px dotted black', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{"<<< AME>>>"}</p>
                <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{datetime}</p>


              </div>
            </div>
          </div>
        </div>
        <AddOnModal show={showAddOns}
          animation={true} handleClose={CloseAddOns} >

        </AddOnModal>

      </div>
      {/* <QtyChangeModal show={modelQtyPopUp} handleClose={CloseEditQuantity} qtyPlucCode={qtyPlucCode} animation={true}></QtyChangeModal> */}
      <CancelKotadmin show={adminPanel} handleClose={CloseAdminPanel} cancelKotData={cancelKotData} cartName={emptyCartName} />
    </div>



  );
}
