import React, { useEffect, useState } from 'react';

import '../../utilities/fontawesome';
import { backToPreviousPage } from '../../reducers/homePageSlice';
import { useSelector, useDispatch } from 'react-redux'

import { Table } from 'antd';
import 'antd/dist/antd.css';
import "../Customer/antdStyles.css"


import './ReprintBill.css'
import { useReactToPrint } from "react-to-print";
// import './Billing.css';
import { PageTitle } from '../Commons/PageTitle';

import { useBarcode } from 'react-barcodes';

export function ReprintBill(props) {

  const TodayDate = new Date();
  
  const [showbillFooter, setShowbillFooter] = useState(true)
  const [showHeaderSettings, setShowHeaderSettings] = useState(false)
  const [showTaxDetails, setShowTaxDetails] = useState(true)
  const [billBodyContent, setBillBodyContent] = useState(false)
  const [tableHeaderSettings, setTableHeaderSettings] = useState(3);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [billNoScan, setBillNoScan] = useState("0");
  const LogoAme = localStorage.getItem("logo");
  var date = TodayDate.toISOString().substr(0, 10);
  console.log(TodayDate, "TodayDate");
  const draftRef = React.useRef(null);
  const [companyDetails, setCompanyDetails] = useState();
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const onBeforeGetContentResolve = React.useRef(null);
  const [dateData, setDateData] = useState("");
  const [from, setfrom] = useState("");
  const [getprint, setgetprint] = React.useState([]);
  const [total, settotal] = React.useState(0);
  const dispatch = useDispatch();
  const [reprintData, setReprintData] = useState("");

  const confirm = (value) => {

    // const datetime =
    //   value.getFullYear() +
    //   "-" +
    //   (value.getMonth() + 1) +
    //   "-" +
    //   value.getDate();

    // setfrom(datetime);dateData,


    const date = new Date();
    console.log("====================================");
    console.log(value, "reprintttttttt");
    console.log("====================================");
    //POST json
    let dataToSend = {
      token: localStorage.getItem('token'),
      date: value,
      terminal: localStorage.getItem('TerminalName')
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_reprint", {
      method: "POST",
      body: formBody,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //  alert(JSON.stringify(responseJson));
        console.log(responseJson);
        if (responseJson.ReprintArr[0].Reprint != 2 && responseJson.ReprintArr[0].reprinthidesales != 1) {
          const arr = [];
          let total = 0;
          for (let i = 0; i < responseJson.ReprintArr.length; i++) {


            arr.push({
              amtpaid: responseJson.ReprintArr[i].amtpaid,
              bill: responseJson.ReprintArr[i].billno,
              time: responseJson.ReprintArr[i].timeofsale,
              gross: responseJson.ReprintArr[i].grossamount,
              gst: responseJson.ReprintArr[i].gst,
              discount: responseJson.ReprintArr[i].discount,
              net: responseJson.ReprintArr[i].netamount,
              pay: responseJson.ReprintArr[i].paymode,
              customer: responseJson.ReprintArr[i].customer,
              items: responseJson.ReprintArr[i].items,
              balance: responseJson.ReprintArr[i].balance,
              cashier: responseJson.ReprintArr[i].cashier,
              table: responseJson.ReprintArr[i].table,
              terminal: responseJson.ReprintArr[i].terminal,
              TAX1pergrp: responseJson.ReprintArr[i].TAX1pergrp,
              TAX2pergrp: responseJson.ReprintArr[i].TAX2pergrp,

              TAX3pergrp: responseJson.ReprintArr[i].TAX3pergrp
            });

            total = total + parseInt(responseJson.ReprintArr[i].netamount);
          }
          setgetprint(arr);
          settotal(total);
          console.log("HIII");
        } else {
          alert("Sorry No Bill To Show");
          //navigation.replace('DrawerNavigationRoutes')
        }
      })
      //If response is not in json then in error
      .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
      });
  };


  const { inputRef } = useBarcode({
    value: billNoScan,
    options: {
      format: "CODE39",
      displayValue: true,
      // background: '#ffc0cb',

    }
  });

  ////////////Company Details//////////////////
  const getdata = () => {
    let dataToSend = {

      wanip: "mysql.amepos.in",
      dbusr: "user_amepos",
      dbpwd: 'bismillah$12',
      dbnam: "amepos_10",
      token: localStorage.getItem('token'),
    };
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl", {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.clientdtlArr[0].clientdtl != 2) {
          setCompanyDetails(responseJson.clientdtlArr[0])
          setTableHeaderSettings(responseJson.clientdtlArr[0].billformatdetails[1].status)
          localStorage.setItem('logo', responseJson.clientdtlArr[0].logo)
          if(responseJson.clientdtlArr[0].billformatdetails[4].status == '1'){
            setShowbillFooter(true)
            }else{
              setShowbillFooter(false)
            }
          if(responseJson.clientdtlArr[0].billformatdetails[0].status == '1'){
          setShowHeaderSettings(true)
          }else{
            setShowHeaderSettings(false)
          }
          if(responseJson.clientdtlArr[0].billformatdetails[3].status == '1'){

            setShowTaxDetails(true)
          }else{
            setShowTaxDetails(false)

          }
          if(responseJson.clientdtlArr[0].billformatdetails[2].status == '1'){

            setBillBodyContent(true)
          }else{
            setBillBodyContent(false)

          }
        } else {
          console.log("Please check your email id or password");
          alert("Sorry Customer Details Not Found");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };





  //////////////////////////

  /////////// Reprint ////////////////




  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    // dispatch(setActivePage(pageComponents.VIEWTABLES));
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        // goToPayment();
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return draftRef.current;
  }, [draftRef.current]);


  const handlePrint = useReactToPrint({
    // pageShandlePrinttyle={pageStyle}, 



    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,

    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });


  ///////////////////////////

  console.log(dateData, "dateData");
  const printThisBill = (i) => {
    console.log(i, "printThisBill");
    setReprintData(i)
    let qty = 0
    for (let j = 0; j < i.items.length; j++) {
      qty = parseFloat(qty) + parseFloat(i.items[j].Quantity);
      // amtt = subTotalamt +gst;\

      setQtyTotal(qty)
    }
    setBillNoScan(`${i?.terminal} - ${i?.bill}`)
    handlePrint()
  }



  useEffect(() => {
    getdata()
    confirm(date)
  }, [])


  const columns = [

    {
      title: 'Order No',
      // dataIndex: 'custnam',

      fixed: 'left',
      align: 'center',
      render: (text, record) => (

        <h6 style={{ height: '20px' }}>{text?.terminal} - {text?.bill}</h6>
      ),
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Time',
      // dataIndex: 'phno',
      align: 'center',
      fixed: 'left',
      render: (text, record) => (

        <h6 class="align-middle">{text?.time}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },

    {
      title: 'gross',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.gross}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },

    {
      title: 'Gst',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}> {text?.gst}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },
    {
      title: 'Discount',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.discount}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },
    {
      title: 'Net',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.net}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },
    {
      title: 'Pay',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}> {text?.pay}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },

    {
      title: 'Customer',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <h6 class="align-middle" style={{ height: '20px' }}>{text?.customer}</h6>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    }, {
      title: 'Action',
      // dataIndex: 'email',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (

        <button type="button" class="btn btn-florogreen " onClick={() => printThisBill(text)} >Print</button>
      ),
      // sorter: (a, b) => a.phno.length - b.phno.length,
    },
  ]

  return (
    <>
      <div class="row mt-3 ml-1">
        <div class="col-md-2">
          <PageTitle titleHeader="Reprint"  >
            <h3>Bill Date</h3>
          </PageTitle>
        </div>
        <div class="col-md-2 pt-5">
          <input type="date" class="form-control " defaultValue={date} onChange={(e) => confirm(e.target.value)}></input>
        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-2">
          {/* <button type="button" class="btn btn-infogreen btn-lg btn-block p-4">Print</button> */}
        </div>
        <div class="col-md-2 pr-4">
          <button type="button" class="btn btn-florogreen btn-lg btn-block p-4" onClick={() => dispatch(backToPreviousPage())}>Back</button>
        </div>
      </div>

      <div class="row flex-grow-1 ml-1 mr-1 mb-5">
        <div class="col-12  d-none d-sm-flex flex-column merge-outer">

          <div class="row w-100 border  flex-grow-1 tableFixHead ">
            {/* */}
            {/* <table class="table ametable " >
              <thead>
                <tr>
                  <th scope="col">S No</th>
                  <th scope="col">Order No</th>
                  <th scope="col">Time</th>
                  <th scope="col">gross</th>
                  <th scope="col">Gst</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Net</th>
                  <th scope="col">Pay</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
              {getprint.map((item, index) => (
                <tr>
                  <th scope="row">{index+1}</th>
                  <td>{item.terminal} - {item.bill}</td>
                  <td>{item.time}</td>
                  <td>{item.gross}</td>
                  <td> {item.gst}</td>
                  <td>{item.discount}</td>
                  <td>{item.net}</td>
                  <td> {item.pay}</td>
                  
                  <td> {item.customer}</td>
                  <td> <button type="button" class="btn btn-florogreen " onClick={()=>printThisBill(item)} >Print</button></td>
                </tr>
              ))}
               

              </tbody>
            </table> */}
            <Table className="table-striped"
              // pagination= { {total : customerItemsList.length,
              // showTotal : (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              // showSizeChanger : true,onShowSizeChange: onShowSizeChange ,itemRender : itemRender 
              // } }
              style={{ overflowX: 'auto', textAlign: 'center', width: '100%' }}
              // style={{ overflowX: 'auto' }}
              columns={columns}
              // scroll={true}
              pagination={false}
              // pagination={{ pageSize: 5 }}
              // bordered
              // bordered
              // scroll={{ y: 260 }}
              dataSource={getprint}
              rowKey={record => record.id}
            // onChange={console.log("change")}
            />
          </div>

        </div>


      </div>
      <div style={{ overflow: 'hidden', height: '0', width: '0' }} >
        <div class="print-container" ref={draftRef} style={{ margin: "0", padding: "0" }}>
          <div class="ticket " >
            {LogoAme.length > 0 && LogoAme != null && LogoAme != undefined &&
              <div style={{ textAlign: 'center', justifyContent: 'center', textAlign: 'center', alignItems: 'center', margin: '0px' }}>
                <img src={`data:image/png;base64,${LogoAme}`} style={{ height: '80px', width: '120px' }} />
              </div>
            }
            <p className="centered" style={{ fontSize: '14px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>{companyDetails?.companynam}</p>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.addr1}{companyDetails?.addr2}</p>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>PH NO:{companyDetails?.addr3}</p>
            <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>GST No:{companyDetails?.gstno}</p>
            {
        showHeaderSettings == true ?
         <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TAX INVOICE</p>
         :
         <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>BILL RECEIPT</p>
        
      }
             <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', margin: '0px', color: 'black', fontWeight: 'bold' }}>Bill No :  {localStorage.getItem('TerminalName')} - {reprintData?.bill}</p>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {reprintData?.table == "T" ?
                <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TakeAway No. {localStorage.getItem('tableno')}</p>
                :
                (reprintData?.table == 'CT' ?

                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{reprintData?.terminal}</p>

                  :
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Table No. {localStorage.getItem('tableno')}</p>

                )
              }
              {/* <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TABLE : {reprintData?.table}</p> */}

              <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Date :{reprintData?.time}</p>
            </div>
            {
              localStorage.getItem("tableno") == 'CT' ?
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Customer : {reprintData?.customer}</p>


                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {reprintData?.cashier} </p>
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>TERMINAL :{reprintData?.terminal}</p>

                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CASHIER : {reprintData?.cashier} </p>
                </div>

            }

            {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>TERMINAL : {reprintData?.terminal}</p>
                  {localStorage.getItem('UserName')} {localStorage.getItem('TerminalName')} {localStorage.getItem('tableno')}
                       <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CASHIER :  {reprintData?.cashier}</p>
               </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                  <p className="centered" style={{fontSize:'10px',borderCollapse:'collapse',margin:'0px',color:'black'}}>Customer : {reprintData?.customer}</p>
               </div> */}
            {
              localStorage.getItem("tableno") == 'CT' ?
                ""
                :
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="centered" style={{ fontSize: '10px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Customer : {reprintData?.customer}</p>

                </div>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            </div>
            {
        tableHeaderSettings == '1' && billBodyContent == true ?
          <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
              <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

              <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

              {/* </tr> */}

            </div>
            <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
            {reprintData?.items?.map((item, index) =>
                (
                  <div>

                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                      <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                      <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                    </div>
                  </div>
                ))

              }

            </div>
          </div>
          :

          tableHeaderSettings == '1' && billBodyContent == false ?
            <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                  {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                  <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                </tr>

              </thead>
              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
              {reprintData?.items?.map((item, index) =>
                  (
                    <tr  >
                      <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                      {/* {item?.TAX1 != '0.00' ? 
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
         : (
          item?.TAX2 != '0.00' ? 
          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
         :
         <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
         
         )
        } */}
                      <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                    </tr>
                  ))

                }

              </tbody>
            </table>
            :

            tableHeaderSettings == '2' && billBodyContent == true ?
              <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                  <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', }}>QTY</div>
                  <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>RATE</div>

                  <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '60px', textAlign: 'end' }}>TOTAL</div>

                  {/* </tr> */}

                </div>
                <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                {reprintData?.items?.map((item, index) =>
                    (
                      <div>

                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '50px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{item?.unitprice}</div>

                          <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '60px', wordBreak: 'break-all' }}>{item?.amount}</div>
                        </div>
                      </div>
                    ))

                  }

                </div>
              </div>
              :

              tableHeaderSettings == '2' && billBodyContent == false ?
                <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                      <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                      {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                      <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                    </tr>

                  </thead>
                  <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  {reprintData?.items?.map((item, index) =>
                      (
                        <tr  >
                          <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity).toFixed(2)}</td>

                          <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                          {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                          <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                        </tr>
                      ))

                    }

                  </tbody>
                </table>
                :
                tableHeaderSettings == '3' && billBodyContent == true ?
                <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width:  '25%' }}>PRODUCT</div>
                    <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', }}>QTY</div>
                    <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40%', textAlign: 'end' }}>TOTAL</div>


                  </div>
                  <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                  {reprintData?.items?.map((item, index) =>
                      (
                        <div>

                          <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '25%' }}> </div>
                            <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35%', wordBreak: 'break-all' }}>{parseInt(item?.Quantity).toFixed(2)}</div>

                            <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '40%', wordBreak: 'break-all' }}>{item?.amount}</div>
                          </div>
                        </div>
                      ))

                    }

                  </div>
                </div>

                :
                tableHeaderSettings == '3' && billBodyContent == false ?
                  <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                        <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                        {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th> */}
                        {/* <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th> */}

                        <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                      </tr>

                    </thead>
                    <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                    {reprintData?.items?.map((item, index) =>
                        (
                          <tr  >
                            <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                            {/* <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td> */}
                            {/* {item?.TAX1 != '0.00' ? 
             <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
           : (
            item?.TAX2 != '0.00' ? 
            <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
           :
           <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
           
           )
          } */}
                            <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.amount}</td>

                          </tr>
                        ))

                      }

                    </tbody>
                  </table>
                  :
                  tableHeaderSettings == '4' && billBodyContent == true ?

                    <div className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}> */}
                        <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}>PRODUCT</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', }}>QTY</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px' }}>RATE</div>
                        <div className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px' }}>SST</div>

                        <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', textAlign: 'end' }}>TOTAL</div>

                        {/* </tr> */}

                      </div>
                      <div style={{ borderTop: '1px dotted black', borderCollapse: 'collapse', width: '100%' }}>
                      {reprintData?.items?.map((item, index) =>
                          (
                            <div>

                              <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'left' }}>{item?.ProductName}</div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '35px' }}> </div>
                                <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '40px', wordBreak: 'break-all' }}>{parseInt(item?.Quantity)}</div>

                                <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '30px', wordBreak: 'break-all' }}>{item?.unitprice}</div>
                                {
                                  item?.TAX1 != '0.00' ?
                                    <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX1}</div>
                                    : (
                                      item?.TAX2 != '0.00' ?
                                        <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX2}</div>
                                        :
                                        item?.TAX3 != '0.00' ?
                                          <div className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', width: '45px', wordBreak: 'break-all' }}>{item?.TAX3}</div>
                                          :
                                          '0.00'
                                    )
                                }
                                <div className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end', width: '50px', wordBreak: 'break-all' }}>{item?.amount}</div>
                              </div>
                            </div>
                          ))

                        }

                      </div>
                    </div>

                    :
                    tableHeaderSettings == '4' && billBodyContent == false ?
                      <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        <thead>
                          <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                            <th className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>
                            <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>SST</th>

                            <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                          </tr>

                        </thead>
                        <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                        {reprintData?.items?.map((item, index) =>
                            (
                              <tr  >
                                <td className='description4' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', wordBreak: 'break-all' }}>{item?.ProductName}</td>
                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                                <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                                {item?.TAX1 != '0.00' ?
                                  <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX1}</td>
                                  : (
                                    item?.TAX2 != '0.00' ?
                                      <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX2}</td>
                                      :
                                      item?.TAX3 != '0.00' ?
                                        <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.TAX3}</td>
                                        :
                                        '0.00'
                                  )
                                }
                                <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                              </tr>
                            ))

                          }

                        </tbody>
                      </table>

                      :
                      ''
      }

            {/* <table className="centered" style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                  <th className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>Product</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>QTY</th>
                  <th className="quantity" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>RATE</th>

                  <th className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>TOTAL</th>

                </tr>

              </thead>
              <tbody style={{ borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
                {reprintData?.items?.map((item, index) =>

                (
                  <tr  >
                    <td className='description' style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.ProductName}</td>
                    <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{parseInt(item?.Quantity)}</td>

                    <td className="quantity  " style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black' }}>{item?.unitprice}</td>
                    <td className="price" style={{ fontSize: '10px', borderCollapse: 'collapse', color: 'black', textAlign: 'end' }}>{item?.amount}</td>

                  </tr>
                ))

                }

              </tbody>
            </table> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Total Items :{reprintData?.items?.length > 0 ? (reprintData?.items?.length) : 0}</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>Total Qty:{qtyTotal != 0 ? qtyTotal : "0.00"}</p>
              {/* {cartItemsList?.length > 0 && cartItemsList[cartItemsList?.length - 1].qty ?parseFloat(cartItemsList[cartItemsList?.length - 1].qty):0  } */}

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px dotted black', borderCollapse: 'collapse' }}>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Sub Total :</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {reprintData?.gross}</p>

            </div>

            {showTaxDetails == true && reprintData?.TAX1pergrp?.map((i) => (
              i.tax1name == "GST" ?
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                      {`CGST${parseInt(i.tax1per) / 2}%`}
                    </p>
                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                      {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt) / 2 : "0.00"}</p>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                      {`SGST${parseInt(i.tax1per) / 2}%`}
                    </p>
                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                      {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) / 2 : "0.00"}</p>

                  </div></>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`${i?.tax1name}${parseInt(i?.tax1per)}%`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                </div>
            ))}

            {showTaxDetails == true && reprintData?.TAX2pergrp?.map((i) => (
              i.tax2name === "GST" ?
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                      {`CGST${parseInt(i.tax2per) / 2}%`}
                    </p>
                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                      {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt) / 2 : "0.00"}</p>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                      {`SGST${parseInt(i.tax2per) / 2}%`}
                    </p>
                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                      {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) / 2 : "0.00"}</p>

                  </div></>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`${i?.tax2name}${parseInt(i?.tax2per)}%`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                </div>
            ))}


{showTaxDetails == true && reprintData?.TAX3pergrp?.map((i) => (
              i.tax3name === "GST" ?
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                      {`CGST${parseInt(i.tax3per) / 2}%`}
                    </p>
                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                      {companyDetails?.currency.split(",", 1)} {i.taxamt != null && i.taxamt != undefined ? parseFloat(i.taxamt) / 2 : "0.00"}</p>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                      {`SGST${parseInt(i.tax3per) / 2}%`}
                    </p>
                    <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                      {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) / 2 : "0.00"}</p>

                  </div></>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>

                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>
                    {`${i?.tax3name}${parseInt(i?.tax3per)}%`}
                  </p>
                  <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>
                    {companyDetails?.currency.split(",", 1)} {i?.taxamt != null && i?.taxamt != undefined ? parseFloat(i?.taxamt) : "0.00"}</p>

                </div>
            ))} {/* */}

            {/* <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>GST</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} {reprintData?.gst  }</p>
           
               </div> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>Discount</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {reprintData?.discount}</p>

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
              <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', color: 'black' }}>Total</p>

              <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', fontWeight: 'bold', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {reprintData?.net}</p>

            </div>

            {/* { activeBillId.paymentMode == 'CARD' ?
               <> */}

               {showbillFooter == true ?
               <>
 <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse', borderTop: '1px dotted black' }}>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{reprintData?.pay}</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {reprintData?.amtpaid}</p>

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', borderCollapse: 'collapse' }}>
              <p className="centered" style={{ fontSize: '15px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>CHANGE</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}>{companyDetails?.currency.split(",", 1)} {reprintData?.balance}</p>

            </div>
               </>
              :
              "" 
              }
           
            {/* </> */}
            {/* :(  activeBillId.paymentMode == 'CASH' ?
                <>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse',borderTop:'1px dotted black'}}>
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CARD</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} { numericValue != 0 && numericValue != null && numericValue  != undefined ? numericValue : 0.00 }</p>
           
               </div>
               <div style={{display:'flex',justifyContent:'space-between',borderCollapse:'collapse'}}>
                  <p className="centered" style={{fontSize:'15px',borderCollapse:'collapse',margin:'0px',color:'black'}}>CHANGE</p>
                  
                  <p className="centered" style={{fontSize:'12px',borderCollapse:'collapse',margin:'0px',marginRight:'4px',color:'black'}}>{companyDetails?.currency.split(",",1)} { balance != 0 && balance != null && balance  != undefined ? balance : 0.00 }</p>
           
               </div></>
                
                :'')} */}
            <div style={{ display: 'flex', borderTop: '1px dotted black', borderCollapse: 'collapse', justifyContent: 'center' }}>
              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>KOT No's :</p>

              <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', marginRight: '4px', color: 'black' }}> {reprintData?.items?.length > 0 ? (reprintData?.items[0].kotno) : 0}</p>

            </div>
            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black', borderTop: '1px dotted black' }}>{companyDetails?.remarks1}</p>
            <p className="centered" style={{ fontSize: '12px', borderCollapse: 'collapse', margin: '0px', color: 'black' }}>{companyDetails?.remarks2}</p>

            <img ref={inputRef} style={{ height: '20%', width: '100%', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />

          </div>
        </div>

      </div>
      <div className="row  ml-1 mr-1 marginBottom" >
        <div class="col-6  d-none d-sm-flex flex-column merge-outer">
        </div>
        <div class="col-3  d-none d-sm-flex flex-column merge-outer">
          <h5 className="display4Data">Total Sale</h5>
        </div>
        <div class="col-3  d-none d-sm-flex flex-column merge-outer">
          <h5 className="display4Data text-danger">Rs {total?.toFixed(2)}</h5>
        </div>
      </div>
    </>


  );

}
