import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import './Login.css'
import './LoginPhp.css'
import { Container, Row, Col, Card, CardBody, CardFooter, Form, FormGroup, Label, Button, CardHeader, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap'

 
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIcon from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

export function LoginForm (props) {
    // constructor(props) {
    //     super(props);
    //     this.state = { numericValue: '' ,loggedIn:false};
      
    // }
  const [passwordShown, setPasswordShown] = useState(false);
    const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
    const [email, setEmail] = useState("");
    
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        password: "",
        showPassword: false,
      })
    console.log(email,password,"sss");
    const loginClick = () => {
        var IsValid = true;


// debugger;

    // console.log("Email-is-valid");

    if (email.length <= 0) {
      setError("email", { type: "manual", message: "Email cannot be empty" })

      IsValid = false;
    }

    if (values.password.length <= 0) {
      setError("password", { type: "manual", message: "Password cannot be empty" })

      IsValid = false;
    }

        if (!IsValid) {
        return false
      }
  
      clearErrors()
      
        // setLoading(true)
        props.handleLogin(email,values.password) 
        // window.location.href = `${process.env.PUBLIC_URL}/login`

    }
  
   
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
      
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      
      const handlePasswordChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };

      const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
      };
    
          return (
              
            <form onSubmit={handleSubmit(loginClick)} className="w-75" style={{textAlign:'center'}}  autoComplete="off">
            <div className="btn-group-vertical margin4 w-65" role="group" aria-label="Basic example">
                 
                <div className="btn-group mb-4 h3 ">
                    <h3 className="display-4 text-center w-100">LOG IN</h3>
                </div>
                <div className="btn-group mb-4 ">
                    <h4 className="display-5 text-center w-100">Welcome! Lets Get Started</h4>
                </div>
                <div className="btn-group mb-4">
                    <input type="text" autoComplete='off' aria-autocomplete='off' id="textLoginCode"  className="textBoxLogin form-control-lg mb-2 inputnoborder-gray-thin square-edges w-100   "
                         
                        maxLength="50" onChange={(e)=>setEmail(e.target.value)} placeholder="Username" 
                        style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/user.png'})`
                        }}   
                         />
                             <span className="text-danger">{errors.email && 'Email is required'}</span>
                </div>
                <div className="btn-group mb-4">

                {/* <Input
        type={values.showPassword ? "text" : "password"}
        onChange={handlePasswordChange("password")}
        value={values.password}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      /> */}
      
      {/* <input id="textLoginCode"   class="textBoxLogin form-control-lg mb-2 inputnoborder-gray-thin square-edges w-100  "
                        maxlength="50"  placeholder="Password" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/password.png'})`
                        }} onChange={(e) => setPassword(e.target.value)}   type={passwordShown ? "text" : "password"} />
       <button id="showPassword"><i class="fa fa-eye" aria-hidden="true"></i></button> */}
                    {/* <input id="textLoginCode"   class="textBoxLogin form-control-lg mb-2 inputnoborder-gray-thin square-edges w-100  "
                        maxlength="50" type="password" placeholder="Password" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/password.png'})`
                        }} onChange={(e) => setPassword(e.target.value)}  endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          } /> */}

      <Input
      disableUnderline={true}
        className=" form-control-lg mb-2 inputnoborder-gray-thin square-edges w-100  "
        maxlength="50"  placeholder="Password" 
        style={{
          padding:'0px'
        }}
        //     backgroundImage: `url(${process.env.PUBLIC_URL + '/images/password.png'})`
        // }} 
        type={values.showPassword ? "text" : "password"}
        onChange={handlePasswordChange("password")}
        value={values.password}
        inputProps={{
          underline: {
              "&&&:before": {
                  borderBottom: "none"
              },
              "&&:after": {
                  borderBottom: "none"
              }
          }
      }}
        endAdornment={
          <InputAdornment position="end" style={{padding:'0px'}}>
            <IconButton style={{padding:'0px'}}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <VisibilityIcon style={{padding:'0px'}} /> : <VisibilityOff  style={{padding:'0px'}} />}
            </IconButton>
           </InputAdornment> 
        }
      />
                          <span className="text-danger">{errors.password && 'Password is required'}</span>
                </div>
                {/* <div class="btn-group mb-4 h3 ">
                    <p class="w-100 text-right"><a href="#" class="text-muted "><small><em>Forgot Password?</em></small></a></p>
                </div> */}

                <div className="btn-group mt-4">
                    <button type="submit"  className="btn shadow btn-infogreen btm-block  py-4 btn-lg  m-2 rounded" disabled={loading ? loading : false}>{loading ? "LOADING..." : "LOGIN"}</button>
                </div>

               
            </div>
            
            </form>



        );
     
}






