import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../utilities/fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './QtyChangeModal.css';

import { cartItems, selectAllCart } from '../../reducers/CartSlice'; 
import { Button, Modal, } from "react-bootstrap";
const ShowChangePrice = (props) => {

    const dispatch = useDispatch();
    const [numericValue, setNumericValue] = useState("");
    const cartItemsList = useSelector(selectAllCart);
    // const TableName = useSelector(state =>state.table.tablename)
    // const TableStatus = useSelector(state =>state.table.tableStatus)

    const addNumber = (num) => {
        setNumericValue(numericValue + num)
    
      }


    const clearAll = () => {

        setNumericValue("");
      }

      const addLineItem = ()=>{

        var hsnoconst = 0;
        if (localStorage.getItem('tableno') == 'CT') {
          hsnoconst = localStorage.getItem('hsno')
        } else {
          hsnoconst = 0
        }
        const date = new Date();
        const datetime =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();
        
        
        let dataToSend = {
          wanip: "mysql.amepos.in",
          dbusr: "user_amepos",
          dbpwd: 'bismillah$12',
          dbnam: "amepos_10",
          token:localStorage.getItem('token'),
          hsno:hsnoconst,
        tqty: 1,
        sellvalue:localStorage.getItem('sellp'),
        openprice:numericValue,
        itemid: props.qtyPlucCode,
        tabno: localStorage.getItem('tableno'),
        tym: datetime,
        ordusr:  localStorage.getItem('UserID'),
        openin: localStorage.getItem('TerminalName'),
        };
        let formBody = [];
        for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_itemdetails", {
        method: "POST",
        body: formBody,
        headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        })
        .then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
        //alert(JSON.stringify(responseJson));
        console.log(responseJson,"addcarttt");
        const item = [
        {
        tableno: localStorage.getItem('tableno'),
        // tokenCreate: tokenCreate,
        terminalno: localStorage.getItem('TerminalName'),
        },
        ];
        dispatch(cartItems(item));
        const arr = [];
        
        if (responseJson.ProductDetailsArr[0].Product != 2) {
        for (let i = 0; i < responseJson.ProductDetailsArr.length; i++) {
        arr.push({
        id: i.toString(),
        productdetails: responseJson.ProductDetailsArr[i].ProductDetails,
        });
        }
        handleClose();
        
      clearAll();
        // setproduct(arr);
        console.log("HIII");
        } else {
        console.log("Please check your email id or password");
        alert("Sorry No Data");
        //navigation.replace('DrawerNavigationRoutes')
        }
        })
        //If response is not in json then in error
        .catch((error) => {
        //alert(JSON.stringify(error));
        console.error(error);
        });
        
        
        
        
        
        }
    const handleClose = props.handleClose;

    return (
        <Modal
        show={props.show}
        animation={props.animation}
        size="md" className="shadow-xl border ">
        <Modal.Header className="border-0">
          <Modal.Title className="w-100">
            <div class="d-flex">
              <div class="ml-auto "><button class="btn-circle" onClick={() => handleClose()}>X</button></div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 border-0">

          {/* <div class=" "> */}

            {/* <div class="d-flex flex-row flex-wrap pl-2 pr-3 "> */}

              {/* <input type="text" class="btn-block  square-edges splitBillQuantityInput"></input> */}
            {/* </div> */}
          {/* </div> */}
          <div class="ml-4 ">

          <div class="row text-center margin__bottom flex-wrap w-100">
        <div class=" w-100">
          <input class="text-center form-control-lg square-edges padding_5 inputCardNumber border" type="text" value={numericValue} />
        </div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(7)}
            ><strong>7</strong> </div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(8)}
            ><strong>8</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(9)}
            ><strong>9</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(4)}
            ><strong>4</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(5)}
            ><strong>5</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(6)}
            ><strong>6</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(1)}
            ><strong>1</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(2)}
            ><strong>2</strong></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(3)}
            ><strong>3</strong></div>

        <div class="w-100"></div>
        <div class="col-4 padding_4 border calcButtons" 
        onClick={() => clearAll()}><
                FontAwesomeIcon icon={["far", "times-circle"]}></FontAwesomeIcon></div>
        <div class="col-4 padding_4 border calcButtons" 
            onClick={() => addNumber(0)}
            ><strong>0</strong></div>
        <div class="col-4 padding_4 border " ><FontAwesomeIcon icon="backspace"></FontAwesomeIcon></div>
         
      </div>

          
          </div>
          <div class="w-100 mb-4">
 
              <Button className="btn-lg btn btn-florogreen btn-block  padding_4"
               onClick={()=>addLineItem()}
               >Enter</Button>
            
          </div>
        </Modal.Body>
        
      </Modal>
    )
}

export default ShowChangePrice
