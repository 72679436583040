
import { ApolloClient ,createHttpLink} from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from '@apollo/client/link/context';

export const apiURI = "https://ameshop-backend-dev.amepos.in/graphql/"
const httpLink = createHttpLink({
  uri: apiURI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('refreshToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
     // Authorization: "JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjY5MzExMjUsIm93bmVyIjoic2FsZW9yIiwiZXhwIjoxNjI2OTMxNDI1LCJ0b2tlbiI6IlN6R3A5NFNjclRHdSIsImVtYWlsIjoiYW5hbmRAYW1lcG9zLmluIiwidHlwZSI6ImFjY2VzcyIsInVzZXJfaWQiOiJWWE5sY2pvMSIsImlzX3N0YWZmIjp0cnVlfQ.htEC_rXY5dp3K4QLOuqn9leNwbvq5Ygtd0yVRp1rPFE",
      Authorization: "JWT " + token,
    }
  }
});


export const client =  new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

 
 