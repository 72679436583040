import React, { Component, useEffect, useState } from 'react';
import NavMenu from './NavMenu';

import { Billing } from './Billing/Billing';
import { ViewOrders } from './ViewOrders/ViewOrders';
import { Payment } from './Payment/Payment';
import { ClosingSales } from './ClosingSales/ClosingSales';
import { SplitPayment } from './SplitPayment/SplitPayment';
import { EditTable } from './EditTable/EditTable';
import { BusyOrders } from './ViewOrders/BusyOrders';
import { OnlineOrders } from './OnlineOrders/OnlineOrders';
import { ReprintBill } from './ReprintBill/ReprintBill';
import { SplitBill } from './SplitBill/SplitBill';
import { RefundBill } from './RefundBill/RefundBill';
import { CustomerList } from './Customer/CustomerList';
import { AddCustomer } from './Customer/AddCustomer';
import { SupplierList } from './Supplier/SupplierList';
import { AddSupplier } from './Supplier/AddSupplier';
import { ExpenseList } from './Expense/ExpenseList';
import { AddExpense } from './Expense/AddExpense';
import { AddEmployee } from './Employee/AddEmployee';
import { EmployeeAttendance } from './Employee/EmployeeAttendance';
import { EmployeeCheckIn } from './Employee/EmployeeCheckIn';
import { FloatingCash } from './FloatingCash/FloatingCash';
import { useSelector } from 'react-redux';
import { pageComponents } from '../utilities/Constants';
import { ChangeTable } from './ChangeTable/ChangeTable';
import { MergeTable } from './MergeTable/MergeTable';
import { EditCustomer } from './Customer/EditCustomer';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch } from 'react-redux';
import { cartItems, selectAllCart } from '../reducers/CartSlice';
import { DeliveryBoyList } from './DeliveryBoy/DeliveryBoyList';
import { AddDeliveryBoy } from './DeliveryBoy/addDeliveryBoy';
import { EditDeliveryBoy } from './DeliveryBoy/EditDeliveryBoy';

export function Home() {

  // window.onbeforeunload = function() {
  //   localStorage.clear();
  // } setCount(
  const cartItemsList = useSelector(selectAllCart);
  const dispatch = useDispatch();
  
  const [count, setCount] = useState(0);
  const [expiry, setExpiry] = useState(0);
  const [companyDetails, setCompanyDetails] = useState();
  const page = useSelector((state) => state.homePage.activePage);


  try {
    var billamnt = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal) +
      parseFloat(cartItemsList[cartItemsList.length - 1].gst)
    ).toFixed(2);

    var billamnt2 = cartItemsList.length > 0 && cartItemsList[0].name != "yasar" && (
      parseFloat(cartItemsList[cartItemsList.length - 1].subTotal)
    ).toFixed(2);

    var roundamnt = Math.round(billamnt);
    var roundamnt2 = Math.round(billamnt2);
  } catch (error) {
    console.log(error);
  }

  useEffect(() => {

    dispatch((cartItems()))
    const timeout = setTimeout(() => {
      // dispatch(fetchNewDeliveryOrders())
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);


  // useEffect(() => {
  //   useEffect(() => {
  //     const timer = setTimeout(() => {
        
  //     setCount(count + 1);
  //     if (expiry > 0) {
  //       getClientMainDetails()
  //     } else {
  //       getClientDetailsfunc()
  //     }}, 12000);
  //     return () => clearTimeout(timer);
  //   }, []);

    
  // },[count])


  console.log(count,"countcount");

  const getClientMainDetails = () => {
    //POST json
    try {


      let dataToSend = {
        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,

        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token: localStorage.getItem('token'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl.php", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.clientdtlArr[0].clientdtl != 2) {
            // localStorage.setItem('LicenseLeft', 2)
            var date = responseJson?.clientdtlArr[0]?.LicenseLeft
            var splitdata = date != null && date != undefined && date.split("days")
            console.log(splitdata, "splitdata2");
            var datetoint = splitdata != undefined && splitdata.length > 0 && parseInt(splitdata[0])
            setExpiry(datetoint)
            // submit(responseJson?.clientdtlArr[0]?.LicenseLeft)
            // setCompanyDetails(responseJson?.clientdtlArr[0])
            // console.log(responseJson?.clientdtlArr[0], "responseJson?.clientdtlArr[0]");
          } else {
            alert("Sorry No CompanyDetails Data");
          }
        })
        .catch((error) => {
          alert(`No CompanyDetails Found ${error}`)
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getClientDetailsfunc = () => {
    //POST json
    try {


      let dataToSend = {
        // wanip: tokenCreate[0].Wanip,
        // dbusr: tokenCreate[0].DBUser,
        // dbpwd: tokenCreate[0].DBPwd,
        // dbnam: tokenCreate[0].DBname,

        wanip: "mysql.amepos.in",
        dbusr: "user_amepos",
        dbpwd: 'bismillah$12',
        dbnam: "amepos_10",
        token: localStorage.getItem('token'),
      };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch("https://superadmin.amepos.io/TabPosApiNew/tabpos_getclientdtl.php", {
        method: "POST",
        body: formBody,
        headers: {
          //Header Defination
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.clientdtlArr[0].clientdtl != 2) {
            // localStorage.setItem('LicenseLeft', 2)
            submit(responseJson?.clientdtlArr[0]?.LicenseLeft)
            // setCompanyDetails(responseJson?.clientdtlArr[0])
            // console.log(responseJson?.clientdtlArr[0], "responseJson?.clientdtlArr[0]");
          } else {
            alert("Sorry No CompanyDetails Data");
          }
        })
        .catch((error) => {
          alert(`No CompanyDetails Found ${error}`)
        });
    } catch (error) {
      console.log(error);
    }
  }
  const submit = (i) => {

    console.log(i, "companyDetailsinSubmit");
    var date = i
    console.log(date, "date");
    var splitdata = date != null && date != undefined && date.split("days")
    console.log(splitdata, "splitdata");
    var datetoint = splitdata != undefined && splitdata.length > 0 && parseInt(splitdata[0])
    setExpiry(datetoint)
    // console.log(date,splitdata,datetoint,"date");
    if (datetoint > 0 && datetoint < 12) {
      confirmAlert({
        title: 'License ',
        message: `Your License will Expire with in ${date}.`,
        buttons: [
          {
            label: 'OK',
            onClick: () => dispatch(cartItems())
            // onClick: () => days()
          },
          // {
          //   label: 'No',
          //   onClick: () =>  days()
          // }
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (datetoint == 0) {
      confirmAlert({
        title: 'License ',
        message: `Your License will Expire today .Pay to Resume and Enjoy your services`,
        buttons: [
          {
            label: 'OK',
            onClick: () => dispatch(cartItems())
            // onClick: () => days()
          },
          // {
          //   label: 'No',
          //   onClick: () =>  days()
          // }
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
    else if (datetoint < 0) {
      confirmAlert({
        title: 'License ',
        message: `Your License Expired .Pay to Resume and Enjoy your services`,
        buttons: [
          {
            label: 'OK',
            onClick: () => days()
          },
          // {
          //   label: 'No',
          //   onClick: () =>  days()
          // }
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  const days = () => {
    // if (localStorage.getItem('LicenseLeft') ==="0 days") {
    // if(datetoint <= 0){
    // Actions.pinNumber();
    localStorage.clear()
    window.location.href = `${process.env.PUBLIC_URL}/login`
    // }else{

    // }
  }

  window.onload = async () => {
    // let  getCookie = document.cookie.;
    // let getCookie = cookie.load('expires');
    // let exp = cookie.loadAll();
    // console.log(getCookie, exp, "exp");
    // console.log("first");
    await getClientDetailsfunc()
    console.log("second");
    // await 
    console.log("third");
  }



  let targetPage;
  if (page === pageComponents.BILLING) {
    targetPage = <Billing />;
  } else if (page === pageComponents.VIEWTABLES) {
    targetPage = <ViewOrders />;
  }
  else if (page === pageComponents.PAYMENT) {
    targetPage = <Payment />;
  }
  else if (page === pageComponents.CLOSINGSALES) {
    targetPage = <ClosingSales />;
  }
  else if (page === pageComponents.SPLITPAYMENT) {
    targetPage = <SplitPayment />;
  }
  else if (page === pageComponents.SPLITBILL) {
    targetPage = <SplitBill />;
  }
  else if (page === pageComponents.MERGETABLES) {
    targetPage = <MergeTable />;
  }
  // else if (page === pageComponents.BUSYORDERS) {
  //   targetPage = <BusyOrders />;
  // }
  else if (page === pageComponents.ONLINEORDERS) {
    targetPage = <OnlineOrders />;
  }
  else if (page === pageComponents.CHANGETABLES) {
    targetPage = <ChangeTable />;
  }
  else if (page === pageComponents.REPRINTBILL) {
    targetPage = <ReprintBill />;
  }
  else if (page === pageComponents.REFUNDBILL) {
    targetPage = <RefundBill />;
  }
  
  else if (page === pageComponents.DELIVERYBOYLIST) {
    targetPage = <DeliveryBoyList />;
  }
  else if (page === pageComponents.CUSTOMERLIST) {
    targetPage = <CustomerList />;
  }
  else if (page === pageComponents.ADDDELIVERYBOY) {
    targetPage = <AddDeliveryBoy />;
  }
  else if (page === pageComponents.EDITDELIVERYBOY) {
    targetPage = <EditDeliveryBoy />;
  }
  else if (page === pageComponents.ADDCUSTOMER) {
    targetPage = <AddCustomer />;
  }
  else if (page === pageComponents.EDITCUSTOMER) {
    targetPage = <EditCustomer />;
  }
  else if (page === pageComponents.SUPPLIERPAYOUTLIST) {
    targetPage = <SupplierList />;
  }
  else if (page === pageComponents.ADDSUPPLIER) {
    targetPage = <AddSupplier />;
  }
  else if (page === pageComponents.EXPENSEPAYOUTLIST) {
    targetPage = <ExpenseList />;
  }
  else if (page === pageComponents.ADDEXPENSE) {
    targetPage = <AddExpense />;
  }
  else if (page === pageComponents.ADDEMPLOYEE) {
    targetPage = <AddEmployee />;
  }
  else if (page === pageComponents.FLOATINGCASH) {
    targetPage = <FloatingCash />;
  }
  else if (page === pageComponents.EMPLOYEEATTENDANCE) {
    targetPage = <EmployeeAttendance />;
  }
  else if (page === pageComponents.EMPLOYEECHECKIN) {
    targetPage = <EmployeeCheckIn />;
  }
  else {
    targetPage = <Billing />;
  }

  return (
    <div class=" main-wrapper d-none d-flex flex-column" style={{ height: '100%', width: '100%', overflow: 'hidden' }}>

      <NavMenu roundamnt={roundamnt} roundamnt2={roundamnt2} />
      {targetPage}




    </div>
  );



}
